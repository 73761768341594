import React, { Fragment } from 'react';

//Contexts
import { v4 as uuid } from 'uuid';
import { BaseFormViewModel } from '../common/ViewModel';
import { FlexRow, onFieldChange, toasty, FormLabel } from '../common/forms/FormElements';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { QuoteChangeOrderDTO } from './Quote';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes, faClipboardCheck, faCancel, faPlane, faChartLine } from '@fortawesome/free-solid-svg-icons'
import {
    AppPageForm,
    FlexCenterRow,
    FormGroupColumn,    
    GroupedRow,
} from '../common/forms/FormElements';
import 'react-datepicker/dist/react-datepicker.css';

import { LinearProgress } from '@material-ui/core';
import { util } from '../Util';

import {
    Button,
    FormGroup,
    Table,
    ListGroup,
    ListGroupItem,
    Input,
    Label,
    Row,
    Col
} from 'reactstrap';

import { withRouter } from 'react-router-dom';
import { handleFormSaveError } from '../common/forms/ValidationError';
import authService from '../api-authorization/AuthorizeService';
import { getUserProfile } from '../common/UserProfile';
import FormErrorResponseDisplay from '../common/forms/FormErrorResponseDisplay';

//#endregion

class QuoteChangeOrderForm extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.formRef = React.createRef();

        this.state = Object.assign(
            {
                loadError: false,
                formOpened: false,
                quoteChangeOrder: new QuoteChangeOrderDTO(),
                clearableWorkCenters: [],
                workCentersToClear: [],
                errorResponse: {},
                noClearableWorkCenters: false,
                perms: []
            },
            new BaseFormViewModel()
        );
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps && (this.props.match.params.id !== (prevProps.match.params ?? {}).id)) {
            this.populateState();
        }
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            let { id } = { ...this.props.match.params };
            const { userPermissions } = await getUserProfile();

            if (!!id) {
                await this.setState({
                    loading: true,
                    errors: {},
                    warnings: {}
                });

                var noClearableWorkCenters = false;
                var workCenters = [];

                var [
                    quoteChangeOrderResponse,
                    clearableWorkCenters
                ] = await Promise.all([
                    util.fetch.get(ApiRoutes.quote.changeorder.byId(id), util.fetch.format.none),
                    util.fetch.get(ApiRoutes.quote.changeorder.clearableWorkCenters(), util.fetch.format.none)
                ]);

                //Handle any issues fetching data
                if (!quoteChangeOrderResponse.ok) {
                    //Handle erroneous links entered by the user
                    if (quoteChangeOrderResponse.status === 404)
                        this.props.history.push(AppNavPaths.NotFound);
                    else
                        this.props.history.push(AppNavPaths.ServerError);
                    return false;
                } else {
                    if (!clearableWorkCenters.ok) {
                        toasty.error('Failed to Load', 'Failed to load work centers. Please report this error.');
                    } else {
                        let raw_workcenters = await clearableWorkCenters.json();
                        if (!!!raw_workcenters.data.length) {
                            noClearableWorkCenters = true;
                        } else {
                            workCenters = raw_workcenters.data.map((rwc) => { return { value: parseInt(rwc.value), label: rwc.label }; });
                        }
                    }

                    let changeorder = await quoteChangeOrderResponse.json();

                    if (changeorder.result == "ERROR") {
                        let errors = {};
                        errors["Failed to Load"] = changeorder.message;
                        this.notifyError("Failed to Load");
                        await this.setState({
                            clearableWorkCenters: [],
                            workCentersToClear: [],
                            formOpened: false,
                            formValidated: false,
                            loading: false,
                            saving: false,
                            errorResponse: {},
                            errors: errors,
                            loadError: true,
                            noClearableWorkCenters: false
                        });
                    } else {
                        await this.setState({
                            quoteChangeOrder: changeorder.data,
                            clearableWorkCenters: workCenters,
                            loading: false,
                            saving: false,
                            errorResponse: {},
                            perms: userPermissions,
                            noClearableWorkCenters: noClearableWorkCenters
                        });
                    }
                }
            } else {
                await this.setState({
                    clearableWorkCenters: [],
                    workCentersToClear: [],
                    formOpened: false,
                    formValidated: false,
                    loading: false,
                    saving: false,
                    errorResponse: {},
                    loadError: true,
                    noClearableWorkCenters: false
                });
            }
        }
    }

    //#region METHODS
    onChange = onFieldChange;

    onClearErrorNotificationClicked = e => {
        e.stopPropagation();
        this.setState({ errors: {} });
    }

    onClearWarningNotificationClicked = e => {
        e.stopPropagation();
        this.setState({ warnings: {} });
    }

    onSubmit = async () => {
        this.clearSaving()
        this.setState({ errors: {}, saving: true, warning: {} });
        let { quoteChangeOrder, workCentersToClear, errors, warnings } = { ...this.state };

        try {
            let completeResponse = await util.fetch.put(
                ApiRoutes.quote.changeorder.complete(quoteChangeOrder.id),
                { WorkCenterIdsToClear: (workCentersToClear ?? []).map(wc => wc.value) },
                util.fetch.format.none
            );

            if (completeResponse.redirected) {
                window.location.href = completeResponse.url;
            } else if (!!completeResponse.ok) {
                var serviceResponse = await completeResponse.json();
                if (serviceResponse.result === 'SUCCESS') {
                    if (!!serviceResponse.data.warnings.length) {
                        warnings["Save Warnings"] = serviceResponse.data.warnings;
                        toasty.warning("Save completed with warnings. See below.");
                        this.setState({ warnings: warnings });
                    } else {
                        toasty.success('Change Order Complete', `Change Order Completed successfully.`);
                        this.populateState();
                    }
                } else {
                    errors["Save Errors"] = serviceResponse.message;
                    this.setState({ errors: errors });
                    this.notifyError("Could not complete the Change order. See below.");
                }
            } else {
                this.displaySaveErrors(await completeResponse.json());
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    onOrderTracking = async () => {
        let { quoteChangeOrder } = { ...this.state };
        this.props.history.push(`${AppNavPaths.SalesOrderTracking}/${quoteChangeOrder.salesOrderTrackingId}`);
    }

    onQuote = async () => {
        let { quoteChangeOrder } = { ...this.state };
        this.props.history.push(`${AppNavPaths.Quote}/${quoteChangeOrder.quoteId}`);
    }

    rejectChangeOrder = async () => {
        let { quoteChangeOrder, errors } = { ...this.state };

        try {
            let response = await util.fetch.put(
                ApiRoutes.quote.changeorder.reject(quoteChangeOrder.id), null, util.fetch.format.none
            );

            errors = {};

            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                var serviceResponse = await response.json();
                if (serviceResponse.result === 'SUCCESS') {
                    toasty.success('Change Order Discarded', `Change Order discarded successfully.`);
                    this.props.history.push(`${AppNavPaths.QuoteChangeOrders}`);
                } else {
                    errors["Save Errors"] = serviceResponse.message;
                    this.setState({ errors: errors });
                    this.notifyError("Could Not Discard the Change Order. See below");
                    this.clearSaving();
                }
            } else {
                this.displaySaveErrors(await response.json());
                this.clearSaving();
            }
        } catch (error) {
            this.notifyError(error.toString());
            this.clearSaving();
        } 
    }

    goToUpload = () => {
        let { quoteChangeOrder } = { ...this.state };

        this.props.history.push(`${AppNavPaths.QuoteChangeOrderNew}/${quoteChangeOrder.id}`)
    }

    clearSaving = () => this.setState((state) => { return { saving: false }; });

    notifyError = (message) => toasty.error('Save Unsuccessful', message);
    handleSaveError = (err) => handleFormSaveError(this, err);

    displaySaveErrors = (response) => this.setState((state) => { return { errorResponse: response }; });
    clearSaveErrors = () => this.setState((state) => { return { errorResponse: {} }; });

    onCloseClicked = () => {
        this.props.history.push(`${AppNavPaths.QuoteChangeOrders}`);
    }

    removeFile = () => {
        let { quoteFile } = { ...this.state };

        quoteFile = null

        this.setState({ quoteFile: quoteFile });
    }

    //#endregion

    //#region RENDERING
    render() {
        let {
            quoteChangeOrder,
            clearableWorkCenters,
            workCentersToClear,
            loading,
            errorResponse,
            loadError,
            noClearableWorkCenters,
            perms,
            errors,
            warnings
        } = { ...this.state };

        if (!!loading) {
            return (<LinearProgress variant="indeterminate" color="secondary" />);
        } else {
            //field editing permissions
            const canEdit = !!perms.includes("quote_change_order.edit");
            const canComplete = quoteChangeOrder.status == 'Open';

            return (
                <Fragment>
                    <AppPageForm
                        formId={"quoteChangeOrderForm"}
                        formHeadingIcon={faClipboardCheck}
                        formHeading={`Change Order: ${quoteChangeOrder.quoteNumber}`}
                        formName={"quoteChangeOrderForm"}
                        formRef={this.formRef}
                        onSubmit={this.onSubmit}
                        setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                        isValidated={this.state.formValidated}
                        saving={this.state.saving}
                        errors={errors}
                        warnings={warnings}
                        loading={this.state.loading}
                        onClearErrors={this.onClearErrorNotificationClicked}
                        onClearWarnings={this.onClearWarningNotificationClicked}
                        ValidationBottom={true}
                    >
                        {!!loadError && 
                            <GroupedRow>
                                <FormGroupColumn>
                                    <p>{"Could Not Load Change Order. Please report this error."}</p>
                                </FormGroupColumn>
                            </GroupedRow>
                        }
                        {!!!loadError &&
                            <Fragment>
                                <FlexCenterRow>
                                    <Table style={{ width: "100%" }}>
                                        <colgroup>
                                            <col style={{ width: "30%" }} />
                                            <col style={{ width: "20%" }} />
                                            <col style={{ width: "50%" }} />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>Component</th>
                                                <th>Change</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!!(quoteChangeOrder.changes ?? []).length && 
                                                (quoteChangeOrder.changes ?? []).map(qco => 
                                                    <tr key={uuid()}>
                                                        <td>{qco.componentChanged}</td>
                                                        <td>{qco.changeType}</td>
                                                        <td>{qco.value}</td>
                                                    </tr>
                                                )
                                            }
                                            {!!!(quoteChangeOrder.changes ?? []).length &&
                                                <tr key={uuid()}>
                                                    <td colSpan={3}>{'No Changes Found'}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </FlexCenterRow>
                                {!!!noClearableWorkCenters && !!canComplete &&
                                    <Row>
                                        <Col>
                                            <FormLabel text="Clear Work Centers" />
                                            <ListGroup>
                                                {clearableWorkCenters.map(cw =>
                                                    <ListGroupItem key={uuid()}>
                                                        <FormGroup check>
                                                            <Input
                                                                type="checkbox"
                                                                value={cw}
                                                                onChange={() => {
                                                                    let added = workCentersToClear.find(el => el.value == cw.value);
                                                                    if (!!added) {
                                                                        let ind = workCentersToClear.indexOf(added);
                                                                        workCentersToClear.splice(ind, 1);
                                                                    } else {
                                                                        workCentersToClear.push(cw);
                                                                    }
                                                                    this.setState({ workCentersToClear: workCentersToClear });
                                                                }}
                                                                checked={!!workCentersToClear.find(el => el.value == cw.value)}
                                                            />
                                                            {' '}
                                                            <Label check>{cw.label}</Label>
                                                        </FormGroup>
                                                    </ListGroupItem>
                                                )}
                                            </ListGroup>
                                        </Col>
                                    </Row>
                                }
                                <FlexCenterRow className="mt-3 mb-3">
                                    {!!(!!canEdit && !!canComplete && !!!(quoteChangeOrder.numberOfChanges == 0)) &&
                                        <Button
                                            size="sm"
                                            type="submit"
                                            disabled={this.state.saving}
                                            color="primary"
                                            name="completeChangeOrder">
                                            <FontAwesomeIcon
                                                className="mr-2"
                                                icon={faSave} />
                                            {"Complete Change Order"}
                                        </Button>
                                    }
                                    {!!(!!canEdit && !!canComplete) &&
                                        <Button
                                            size="sm"
                                            type="button"
                                            color="secondary"
                                            name="rejectChangeOrder"
                                            disabled={this.state.saving}
                                            onClick={this.rejectChangeOrder}
                                            className="ml-2">
                                            <FontAwesomeIcon
                                                className="mr-2"
                                                icon={faCancel} />
                                            {"Discard Change Order"}
                                        </Button>
                                    }
                                    {!!(!!canEdit && !!canComplete && !!(quoteChangeOrder.numberOfChanges == 0)) &&
                                        <Button
                                            size="sm"
                                            type="button"
                                            color="secondary"
                                            name="goToFilesForm"
                                            disabled={this.state.saving}
                                            onClick={this.goToUpload}
                                            className="ml-2">
                                            <FontAwesomeIcon
                                                className="mr-2"
                                                icon={faPlane} />
                                            {"Upload Files for Change Order"}
                                        </Button>
                                    }
                                    <Button
                                        size="sm"
                                        type="button"
                                        color="secondary"
                                        disabled={this.state.saving}
                                        name="orderTracking"
                                        onClick={this.onOrderTracking}
                                        className="ml-2">
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faChartLine} />
                                        {"Go to Order Tracking"}
                                    </Button>
                                    <Button
                                        size="sm"
                                        type="button"
                                        color="secondary"
                                        name="quote"
                                        disabled={this.state.saving}
                                        onClick={this.onQuote}
                                        className="ml-2">
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faClipboardCheck} />
                                        {"Go to Quote"}
                                    </Button>
                                    <Button
                                        size="sm"
                                        type="button"
                                        color="secondary"
                                        disabled={this.state.saving}
                                        name="closeChangeOrderForm"
                                        onClick={this.onCloseClicked}
                                        className="ml-2">
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faTimes} />
                                        {"Close"}
                                    </Button>
                                </FlexCenterRow>
                                <FlexRow>
                                    <FormErrorResponseDisplay onClear={this.clearSaveErrors} response={errorResponse} />
                                </FlexRow>
                            </Fragment>
                        }
                    </AppPageForm>
                </Fragment>
            );
        }

    }
    //#endregion
}

export default withRouter(QuoteChangeOrderForm);