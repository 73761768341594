import React, { Fragment } from 'react';

//Contexts
import { FlexRow, onFieldChange, toasty } from '../common/forms/FormElements';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { Survey, surveyStatuses, SurveyCriteria, criteriaTypes } from './FormsAndSurveys';
import { isEqual } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes, faTasks, faScrewdriverWrench, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import {
    AppPageForm,
    FlexCenterRow,
    FormGroupColumn,
    FormLabel,
    GroupedRow
} from '../common/forms/FormElements';

import { LinearProgress } from '@material-ui/core';
import { util } from '../Util';

import {
    Button,
    Table,
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupText
} from 'reactstrap';

import { Prompt, withRouter } from 'react-router-dom';
import { handleFormSaveError } from '../common/forms/ValidationError';
import authService from '../api-authorization/AuthorizeService';
import { getUserProfile } from '../common/UserProfile';
import FormErrorResponseDisplay from '../common/forms/FormErrorResponseDisplay';
import { faCheckCircle } from '@fortawesome/fontawesome-free-solid';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { v4 as uuid } from 'uuid';

//#endregion

class FormsForm extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            _form: new Survey(),
            formOpened: false,
            formValidated: false,
            loading: true,
            saving: false,
            statuses: [],
            perms: [],
            errorResponse: {},
            allLiftTypes: [],
            allCustomers: [],
            allProductFamilies: [],
            allProductCategories: [],
            allModels: [],
            allLiftOptions: [],
            allMountingInstructions: [],
            allSalesOrderTrackingDerived: [],
            allCriteriaTypes: [],
            expectedDataTypes: [],
            expectedRelationships: [],
            newCriteria: null,
            newCriteriaDerivedFieldName: null,
            newCriteriaType: null,
            newCriteriaRel: null,
            newCriteriaCriteriaType: null,
            newCriteriaOverride: false,
            addRestriction: false,
            allExportFormats: []
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.saveForm = this.saveForm.bind(this);
        this.saveNewForm = this.saveNewForm.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps && (this.props.match.params.id !== (prevProps.match.params ?? {}).id)) {
            this.populateState();
        }
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            let { id } = { ...this.props.match.params };
            const { userPermissions } = await getUserProfile();

            if (id == 'new') {
                id = undefined; //If the Id is identified as being a new UOM, then just kill the id so we don't call the API
            }

            var [
                formResponse,
                allLiftTypes,
                allCustomers,
                allProductFamilies,
                allProductCategories,
                allModels,
                allLiftOptions,
                allMountingInstructions,
                allSalesOrderTrackingDerived,
                allCriteriaTypes,
                statuses,
                expectvaltypes,
                expectedvalrels,
                allExportFormats
            ] = await Promise.all([
                !!id ? util.fetch.post(ApiRoutes.forms.byid(id), util.fetch.format.none) : new Survey(),
                util.fetch.js(ApiRoutes.typeAheads.getLiftTypes()),
                util.fetch.js(ApiRoutes.typeAheads.apteanCustomers()),
                util.fetch.js(ApiRoutes.typeAheads.apteanProductFamilies()),
                util.fetch.js(ApiRoutes.typeAheads.apteanProductCategories()),
                util.fetch.js(ApiRoutes.typeAheads.getLiftModels()),
                util.fetch.js(ApiRoutes.typeAheads.getLiftOptions()),
                util.fetch.js(ApiRoutes.typeAheads.getMountingInstructions()),
                util.fetch.js(ApiRoutes.typeAheads.getSalesOrderTrackingDerived()),
                util.fetch.js(ApiRoutes.typeAheads.getCriteriaTypes()),
                util.fetch.js(ApiRoutes.typeAheads.surveyStatuses()),
                util.fetch.js(ApiRoutes.typeAheads.questionExpectedValueTypes()),
                util.fetch.js(ApiRoutes.typeAheads.questionExpectedValRelationships()),
                util.fetch.js(ApiRoutes.forms.exportformats())
            ]);

            //Fix survey status numbers because everything is coming across as string and this fails the includes predicate
            statuses = statuses.map((ss) => { return { value: Number(ss.value), label: ss.label }; });

            allLiftTypes = allLiftTypes.map((lt) => { return { value: Number(lt.value), label: lt.label }; });
            allCustomers = allCustomers.map((ac) => { return { value: Number(ac.value), label: ac.label }; });
            allModels = allModels.map((am) => { return { value: Number(am.value), label: am.label }; });
            allLiftOptions = allLiftOptions.map((lo) => { return { value: Number(lo.value), label: lo.label }; });
            allMountingInstructions = allMountingInstructions.map((mi) => { return { value: Number(mi.value), label: mi.label }; });
            allCriteriaTypes = allCriteriaTypes.filter(ct => ct.value != 1).map((ct) => { return { value: Number(ct.value), label: ct.label }; });
            expectvaltypes = expectvaltypes.map((evt) => { return { value: Number(evt.value), label: evt.label }; });
            expectedvalrels = expectedvalrels.map((evr) => { return { value: Number(evr.value), label: evr.label }; });
            allExportFormats = allExportFormats.map((ef) => { return { value: Number(ef.value), label: ef.label }; });

            var form;

            //Handle any issues fetching data
            if (!!id && !!formResponse.error) {
                //Handle erroneous links entered by the user
                let serviceResponse = {
                    title: 'Server Error',
                    errors: {
                        Exception: [
                            formResponse.error
                        ]
                    }
                }
                this.displaySaveErrors(serviceResponse);
                return false;
            } else {
                form = formResponse;
            }

            this.setState({
                formValidated: false,
                saving: false,
                _form: form,
                loading: false,
                originalData: form,
                perms: userPermissions,
                allLiftTypes: allLiftTypes,
                allCustomers: allCustomers,
                allProductFamilies: allProductFamilies,
                allProductCategories: allProductCategories,
                allModels: allModels,
                allLiftOptions: allLiftOptions,
                allMountingInstructions: allMountingInstructions,
                allSalesOrderTrackingDerived: allSalesOrderTrackingDerived,
                allCriteriaTypes: allCriteriaTypes,
                statuses: statuses,
                expectedDataTypes: expectvaltypes,
                expectedRelationships: expectedvalrels,
                allExportFormats: allExportFormats
            });
        }
    }

    //#region METHODS
    onChange = onFieldChange;

    onSubmit() {
        let { _form } = { ...this.state };
        this.clearSaving()
        this.setState({ errors: {}, saving: true });

        if (this.props.location.pathname === AppNavPaths.FormsNew) {
            this.saveNewForm(_form);
        } else {
            this.saveForm(_form);
        }
    }

    onPublish() {
        let { _form } = { ...this.state };
        this.clearSaving()
        this.setState({ errors: {}, saving: true });

        //If a user hit the publish button, it is the same if new or old.
        _form.surveyStatusId = surveyStatuses.Published;

        if (this.props.location.pathname === AppNavPaths.FormsNew) {
            this.saveNewForm(_form);
        } else {
            this.saveForm(_form);
        }
    }

    clearSaving = () => this.setState({saving: false });

    notifySuccess = () => toasty.success('Form Saved', `Form saved successfully.`);
    notifyError = (message) => toasty.error('Save Unsuccessful', message);
    handleSaveError = (err) => handleFormSaveError(this, err);

    displaySaveErrors = (response) => this.setState({ errorResponse: response });
    clearSaveErrors = () => this.setState({ errorResponse: {} });

    onCloseClicked = () => {
        this.props.history.push(`${AppNavPaths.Forms}`);
    }

    saveNewForm = async (form) => {
        try {
            let response = await util.fetch.post(ApiRoutes.forms.create(), form, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                let formId = await response.json();
                this.notifySuccess();
                this.props.history.push(`${AppNavPaths.Forms}/${formId}`);
            } else {

                if (response.status === 400) {
                    let serviceResponse = await response.json();
                    this.displaySaveErrors(serviceResponse);
                } else {

                    let errorResp = await response.json();

                    if (errorResp.errors) {
                        this.displaySaveErrors(errorResp);
                    } else {
                        let serviceResponse = {
                            title: 'Server Error',
                            errors: {
                                Exception: [
                                    await response.text()
                                ]
                            }
                        }
                        this.displaySaveErrors(serviceResponse);
                    }
                }
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    saveForm = async (form) => {
        try {
            let response = await util.fetch.put(ApiRoutes.forms.byid(form.id), form, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                this.notifySuccess();
                this.populateState();
            } else {
                this.displaySaveErrors(await response.json());
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    onAddNewCriteria = async () => {
        let {
            _form,
            newCriteria,
            newCriteriaDerivedFieldName,
            newCriteriaType,
            newCriteriaRel,
            newCriteriaCriteriaType
        } = { ...this.state };

        let newSurveyCriteria = new SurveyCriteria();

        newSurveyCriteria.derivedFieldName = newCriteriaDerivedFieldName?.label;
        newSurveyCriteria.expectedValue = newCriteria.label; //We take the label not the value this is what the restriction should contain
        newSurveyCriteria.criteriaTypeId = newCriteriaCriteriaType.value;
        newSurveyCriteria.criteriaTypeText = newCriteriaCriteriaType.label;
        newSurveyCriteria.expectedValueTypeId = newCriteriaType.value;
        newSurveyCriteria.valueTypeText = newCriteriaType.label;
        newSurveyCriteria.expectedValueRelationshipId = newCriteriaRel.value;
        newSurveyCriteria.relationshipText = newCriteriaRel.label;

        _form.criteria.push(newSurveyCriteria);

        this.setState({
            _form: _form,
            newCriteriaDerivedFieldName: null,
            newCriteria: null,
            newCriteriaType: null,
            newCriteriaRel: null,
            newCriteriaCriteriaType: null,
            addRestriction: false,
            newCriteriaOverride: false
        });
    }

    onRemoveCriteria = async (ind) => {
        let { _form } = { ...this.state };

        _form.criteria.splice(ind, 1);

        this.setState({
            _form: _form
        });
    }

    //#endregion

    //#region RENDERING
    render() {
        const {
            loading,
            originalData,
            _form,
            perms,
            statuses,
            allLiftTypes,
            allCustomers,
            allProductFamilies,
            allProductCategories,
            allModels,
            allLiftOptions,
            allMountingInstructions,
            allSalesOrderTrackingDerived,
            allCriteriaTypes,
            expectedDataTypes,
            expectedRelationships,
            allExportFormats
        } = { ...this.state };

        let {
            errorResponse,
            newCriteria,
            newCriteriaType,
            newCriteriaRel,
            newCriteriaCriteriaType,
            newCriteriaDerivedFieldName,
            addRestriction,
            newCriteriaOverride
        } = { ...this.state };
        
        if (!!loading) {
            return (<LinearProgress variant="indeterminate" color="secondary" />);
        } else {
            const isNewForm = ((_form.id ?? 0) <= 0);
            const saveFormButtonText = (!isNewForm ? 'Save' : 'Save New Form');

            //field editing permissions
            const canEdit = {
                form: !!perms.includes("forms.edit") || (isNewForm && !!perms.includes("forms.create"))
            };

            const isAdmin = !!perms.includes("administrator.all");

            return (
                <Fragment>
                    <AppPageForm
                        formId={"formForm"}
                        formHeadingIcon={faTasks}
                        formHeading={isNewForm ? 'New Form' : 'Edit Form'}
                        formName={"formForm"}
                        formRef={this.formRef}
                        onSubmit={this.onSubmit}
                        setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                        isValidated={this.state.formValidated}
                        saving={this.state.saving}
                        errors={this.state.errors}
                        loading={this.state.loading}
                    >
                        <GroupedRow>
                            <FormGroupColumn>
                                <FormGroup>
                                    <FormLabel htmlFor="inputName" text="Form Name" required={true} />
                                    <input id="name"
                                        name="_form.name"
                                        autoComplete="off"
                                        className="form-control"
                                        required
                                        disabled={!isNewForm && !canEdit.form}
                                        onChange={!!canEdit.form ? this.onChange : undefined}
                                        defaultValue={_form.name} />
                                    <small className="invalid-feedback text-danger" hidden>Form name is required.</small>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="inputInstructions" text="Form Instructions" />
                                    <textarea id="instrutions"
                                        name="_form.instructions"
                                        autoComplete="off"
                                        className="form-control"
                                        disabled={!isNewForm && !canEdit.form}
                                        onChange={!!canEdit.form ? this.onChange : undefined}
                                        defaultValue={_form.instructions} />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="inputNotes" text="Form Notes" />
                                    <textarea id="notes"
                                        name="_form.notes"
                                        autoComplete="off"
                                        className="form-control"
                                        disabled={!isNewForm && !canEdit.form}
                                        onChange={!!canEdit.form ? this.onChange : undefined}
                                        defaultValue={_form.notes} />
                                </FormGroup>
                                {!!(allExportFormats.length) && 
                                    <FormGroup>
                                        <FormLabel htmlFor="surveyExportFormat" text="Export Format" />
                                        <ValidatedSelect
                                            id="exportFormatId"
                                            name="_form.surveyExportFormatId"
                                            options={allExportFormats}
                                            value={(allExportFormats ?? []).find(s => s.value == _form.surveyExportFormatId) ?? ''}
                                            onChange={(selection) => {
                                                _form.surveyExportFormatId = selection?.value;
                                                this.setState({ _form: _form });
                                            }}
                                        />
                                    </FormGroup>
                                }
                                {!!isAdmin &&
                                    <FormGroup>
                                        <FormLabel htmlFor="surveyStatus" text="Status" />
                                        <ValidatedSelect
                                            id="status"
                                            name="_form.surveyStatusId"
                                            options={statuses}
                                            value={(statuses ?? []).find(s => s.value == _form.surveyStatusId) ?? ''}
                                            onChange={(selection) => {
                                                _form.surveyStatusId = selection?.value;
                                                this.setState({ _form: _form });
                                            }}
                                        />
                                    </FormGroup>
                                }
                            </FormGroupColumn>
                        </GroupedRow>

                        <GroupedRow>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                {!!!addRestriction &&
                                    <Button
                                        size="sm"
                                        type="button"
                                        color="primary"
                                        name="addRestriction"
                                        onClick={() => {
                                            this.setState({
                                                addRestriction: true
                                            });
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            className="mr-2 text-dark pr-2 zoom"
                                            icon={faPlus} />
                                        {"Add Restriction"}
                                    </Button>
                                }
                                <Table style={{ width: "100%", marginTop: "0.25em" }}>
                                    <colgroup>
                                        <col style={{ width: "40%" }} />
                                        <col style={{ width: "20%" }} />
                                        <col style={{ width: "15%" }} />
                                        <col style={{ width: "15%" }} />
                                        <col style={{ width: "10%" }} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>Restriction Value</th>
                                            <th>Type</th>
                                            <th>Value Is</th>
                                            <th>Relationship</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!!(_form.criteria ?? []).length &&
                                            (_form.criteria ?? []).map((cr, cind) =>
                                                <tr key={uuid()}>
                                                    <td>{
                                                        !!(cr.derivedFieldName) ? cr.derivedFieldName + ": " + cr.expectedValue : cr.expectedValue
                                                    }</td>
                                                    <td>{cr.criteriaTypeText}</td>
                                                    <td>{cr.valueTypeText}</td>
                                                    <td>{cr.relationshipText}</td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            className="mr-2"
                                                            onClick={() => this.onRemoveCriteria(cind)}
                                                            icon={faTrashAlt}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </GroupedRow>
                        {!!addRestriction &&
                            <Fragment>
                                <GroupedRow>
                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                        <FormGroup>
                                            <FormLabel htmlFor="secCriteriaType" text="Restriction Type" />
                                            <ValidatedSelect
                                                id="secCriteriaType"
                                                formRef={this.formRef}
                                                name="newCriteriaCriteriaType"
                                                options={allCriteriaTypes}
                                                value={(allCriteriaTypes ?? []).find(s => s.value == (newCriteriaCriteriaType ?? {}).value) ?? ''}
                                                onChange={(selection) => {
                                                    newCriteria = null;
                                                    newCriteriaCriteriaType = selection;
                                                    this.setState({
                                                        newCriteriaCriteriaType: newCriteriaCriteriaType,
                                                        newCriteria: newCriteria
                                                    });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                        {!!((newCriteriaCriteriaType ?? {}).value == criteriaTypes.Customer) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="secCriteriaCustomerValue" text="Choose Customer" />
                                                <ValidatedSelect
                                                    id="secCriteriaCustomerValue"
                                                    formRef={this.formRef}
                                                    name="newCriteria"
                                                    options={allCustomers}
                                                    value={(allCustomers ?? []).find(s => s.value == (newCriteria ?? {}).value) ?? ''}
                                                    onChange={(selection) => {
                                                        newCriteria = selection;
                                                        this.setState({ newCriteria: newCriteria });
                                                    }}
                                                />
                                            </FormGroup>
                                        }
                                        {!!((newCriteriaCriteriaType ?? {}).value == criteriaTypes.Category) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="secCriteriaCategoryValue" text="Choose Product Category" />
                                                <ValidatedSelect
                                                    id="secCriteriaCategoryValue"
                                                    formRef={this.formRef}
                                                    name="newCriteria"
                                                    options={allProductCategories}
                                                    value={(allProductCategories ?? []).find(s => s.value == (newCriteria ?? {}).value) ?? ''}
                                                    onChange={(selection) => {
                                                        newCriteria = selection;
                                                        this.setState({ newCriteria: newCriteria });
                                                    }}
                                                />
                                            </FormGroup>
                                        }
                                        {!!((newCriteriaCriteriaType ?? {}).value == criteriaTypes.Family) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="secCriteriaFamilyValue" text="Choose Product Family" />
                                                <ValidatedSelect
                                                    id="secCriteriaFamilyValue"
                                                    formRef={this.formRef}
                                                    name="newCriteria"
                                                    options={allProductFamilies}
                                                    value={(allProductFamilies ?? []).find(s => s.value == (newCriteria ?? {}).value) ?? ''}
                                                    onChange={(selection) => {
                                                        newCriteria = selection;
                                                        this.setState({ newCriteria: newCriteria });
                                                    }}
                                                />
                                            </FormGroup>
                                        }
                                        {!!((newCriteriaCriteriaType ?? {}).value == criteriaTypes.Model) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="secCriteriaModelValue" text="Choose Model" />
                                                <InputGroup>
                                                    <InputGroupText>
                                                        <span style={
                                                            { position: 'absolute', top: '-2px', left: '1.125em', fontSize: '9px' }
                                                        }>Override</span>
                                                        <Input
                                                            id="evCriteriaModelValueOverride"
                                                            name="newCriteriaOverride"
                                                            autoComplete="off"
                                                            onChange={this.onChange}
                                                            type="checkbox"
                                                            checked={newCriteriaOverride ?? false}
                                                            style={{ position: 'relative', top: '2px', marginLeft: 'auto', width: '2em', height: '1.5em' }}
                                                        />
                                                    </InputGroupText>
                                                    {!!!newCriteriaOverride &&
                                                        <div className="form-control" style={{ padding: 0, border: 'none' }}>
                                                            <ValidatedSelect
                                                                id="secCriteriaModelValue"
                                                                formRef={this.formRef}
                                                                name="newCriteria"
                                                                options={allModels}
                                                                style={{ flexGrow: 3 }}
                                                                value={(allModels ?? []).find(s => s.value == (newCriteria ?? {}).value) ?? ''}
                                                                onChange={(selection) => {
                                                                    newCriteria = selection;
                                                                    this.setState({ newCriteria: newCriteria });
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    {!!newCriteriaOverride &&
                                                        <Input
                                                            type="text"
                                                            style={{ flexGrow: 3 }}
                                                            name="newCriteria"
                                                            value={newCriteria?.label ?? ""}
                                                            onChange={(e) => {
                                                                newCriteria = !!(e.target.value) ? { label: e.target.value } : null;;
                                                                this.setState({ newCriteria: newCriteria });
                                                            }}
                                                        />
                                                    }
                                                </InputGroup>
                                            </FormGroup>
                                        }
                                        {!!((newCriteriaCriteriaType ?? {}).value == criteriaTypes.LiftOption) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="secCriteriaLiftOptionValue" text="Choose Lift Option" />
                                                <InputGroup>
                                                    <InputGroupText>
                                                        <span style={
                                                            { position: 'absolute', top: '-2px', left: '1.125em', fontSize: '9px' }
                                                        }>Override</span>
                                                        <Input
                                                            id="evCriteriaLiftOptionValueOverride"
                                                            name="newCriteriaOverride"
                                                            autoComplete="off"
                                                            onChange={this.onChange}
                                                            type="checkbox"
                                                            checked={newCriteriaOverride ?? false}
                                                            style={{ position: 'relative', top: '2px', marginLeft: 'auto', width: '2em', height: '1.5em' }}
                                                        />
                                                    </InputGroupText>
                                                    {!!!newCriteriaOverride &&
                                                        <div className="form-control" style={{ padding: 0, border: 'none' }}>
                                                            <ValidatedSelect
                                                                id="secCriteriaLiftOptionValue"
                                                                formRef={this.formRef}
                                                                name="newCriteria"
                                                                options={allLiftOptions}
                                                                style={{ flexGrow: 3 }}
                                                                value={(allLiftOptions ?? []).find(s => s.value == (newCriteria ?? {}).value) ?? ''}
                                                                onChange={(selection) => {
                                                                    newCriteria = selection;
                                                                    this.setState({ newCriteria: newCriteria });
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    {!!newCriteriaOverride &&
                                                        <Input
                                                            type="text"
                                                            style={{ flexGrow: 3 }}
                                                            name="newCriteria"
                                                            value={newCriteria?.label ?? ""}
                                                            onChange={(e) => {
                                                                newCriteria = !!(e.target.value) ? { label: e.target.value } : null;;
                                                                this.setState({ newCriteria: newCriteria });
                                                            }}
                                                        />
                                                    }
                                                </InputGroup>
                                            </FormGroup>
                                        }
                                        {!!((newCriteriaCriteriaType ?? {}).value == criteriaTypes.MountingInstruction) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="secCriteriaMountingInstructionValue" text="Choose Mounting Instruction" />
                                                <InputGroup>
                                                    <InputGroupText>
                                                        <span style={
                                                            { position: 'absolute', top: '-2px', left: '1.125em', fontSize: '9px' }
                                                        }>Override</span>
                                                        <Input
                                                            id="secCriteriaMountingInstructionValueOverride"
                                                            name="newCriteriaOverride"
                                                            autoComplete="off"
                                                            onChange={this.onChange}
                                                            type="checkbox"
                                                            checked={newCriteriaOverride ?? false}
                                                            style={{ position: 'relative', top: '2px', marginLeft: 'auto', width: '2em', height: '1.5em' }}
                                                        />
                                                    </InputGroupText>
                                                    {!!!newCriteriaOverride &&
                                                        <div className="form-control" style={{ padding: 0, border: 'none' }}>
                                                            <ValidatedSelect
                                                                id="secCriteriaMountingInstructionValue"
                                                                formRef={this.formRef}
                                                                name="newCriteria"
                                                                options={allMountingInstructions}
                                                                value={(allMountingInstructions ?? []).find(s => s.value == (newCriteria ?? {}).value) ?? ''}
                                                                onChange={(selection) => {
                                                                    newCriteria = selection;
                                                                    this.setState({ newCriteria: newCriteria });
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    {!!newCriteriaOverride &&
                                                        <Input
                                                            type="text"
                                                            style={{ flexGrow: 3 }}
                                                            name="newCriteria"
                                                            value={newCriteria?.label ?? ""}
                                                            onChange={(e) => {
                                                                newCriteria = !!(e.target.value) ? { label: e.target.value } : null;;
                                                                this.setState({ newCriteria: newCriteria });
                                                            }}
                                                        />
                                                    }
                                                </InputGroup>
                                            </FormGroup>
                                        }
                                        {!!((newCriteriaCriteriaType ?? {}).value == criteriaTypes.LiftType) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="secCriteriaLiftTypeValue" text="Choose Lift Type" />
                                                <ValidatedSelect
                                                    id="secCriteriaLiftTypeValue"
                                                    formRef={this.formRef}
                                                    name="newCriteria"
                                                    options={allLiftTypes}
                                                    value={(allLiftTypes ?? []).find(s => s.value == (newCriteria ?? {}).value) ?? ''}
                                                    onChange={(selection) => {
                                                        newCriteria = selection;
                                                        this.setState({ newCriteria: newCriteria });
                                                    }}
                                                />
                                            </FormGroup>
                                        }
                                        {!!((newCriteriaCriteriaType ?? {}).value == criteriaTypes.SalesOrderTrackingDerived) &&
                                            <>
                                                <FormGroup>
                                                    <FormLabel htmlFor="secCriteriaSOTDerivedValue" text="Choose Property" />
                                                    <ValidatedSelect
                                                        id="secCriteriaSOTDerivedValue"
                                                        formRef={this.formRef}
                                                        name="newCriteriaDerivedFieldName"
                                                        options={allSalesOrderTrackingDerived}
                                                        value={(allSalesOrderTrackingDerived ?? []).find(s => s.value == (newCriteriaDerivedFieldName ?? {}).value) ?? ''}
                                                        onChange={(selection) => {
                                                            newCriteriaDerivedFieldName = selection;
                                                            this.setState({ newCriteriaDerivedFieldName: newCriteriaDerivedFieldName });
                                                        }}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        name="newCriteria"
                                                        value={newCriteria?.label ?? ""}
                                                        onChange={(e) => {
                                                            newCriteria = !!(e.target.value) ? { label: e.target.value } : null;
                                                            this.setState({ newCriteria: newCriteria });
                                                        }}
                                                    />
                                                </FormGroup>
                                            </>
                                        }
                                    </Col>
                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                        {!!(newCriteriaCriteriaType) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="newCriteriaType" text="Evaluation Type" />
                                                <ValidatedSelect
                                                    id="newCriteriaType"
                                                    formRef={this.formRef}
                                                    options={expectedDataTypes}
                                                    onChange={(selection) => {
                                                        newCriteriaType = selection;
                                                        this.setState({ newCriteriaType: newCriteriaType });
                                                    }}
                                                    validationMessage=""
                                                    value={newCriteriaType}
                                                />
                                                <small className="invalid-feedback text-danger" hidden></small>
                                            </FormGroup>
                                        }
                                    </Col>
                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                        {!!(newCriteriaCriteriaType) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="newCriteriaRel" text="Restriction Relationship" />
                                                <ValidatedSelect
                                                    id="newCriteriaRel"
                                                    formRef={this.formRef}
                                                    options={expectedRelationships}
                                                    onChange={(selection) => {
                                                        newCriteriaRel = selection;
                                                        this.setState({ newCriteriaRel: newCriteriaRel });
                                                    }}
                                                    validationMessage=""
                                                    value={newCriteriaRel}
                                                />
                                                <small className="invalid-feedback text-danger" hidden></small>
                                            </FormGroup>
                                        }
                                    </Col>
                                </GroupedRow>
                                <GroupedRow>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                                    </Col>
                                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
                                        {(!!(newCriteriaCriteriaType) && !!(newCriteriaRel) && 
                                            !!(newCriteriaType) && !!(newCriteria))
                                            && (
                                                !!((newCriteriaCriteriaType ?? {}).value != criteriaTypes.SalesOrderTrackingDerived) ||
                                                (!!((newCriteriaCriteriaType ?? {}).value == criteriaTypes.SalesOrderTrackingDerived)  
                                                    && !!(newCriteriaDerivedFieldName))
                                            ) &&
                                            <Button
                                                size="sm"
                                                type="button"
                                                color="primary"
                                                name="addRestriction"
                                                onClick={() => this.onAddNewCriteria()}
                                            >
                                                <FontAwesomeIcon
                                                    className="mr-2"
                                                    icon={faPlus} />
                                                {"Add"}
                                            </Button>
                                        }
                                    </Col>
                                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
                                        <Button
                                            size="sm"
                                            type="button"
                                            color="secondary"
                                            name="cancelRestriction"
                                            onClick={() => this.setState({ addRestriction: false })}
                                            className="ml-2">
                                            <FontAwesomeIcon
                                                className="mr-2"
                                                icon={faTimes} />
                                            {"Cancel"}
                                        </Button>
                                    </Col>
                                </GroupedRow>
                            </Fragment>
                        }
                        <FlexCenterRow className="mb-3">
                            {!!canEdit.form &&
                                <Button
                                    size="sm"
                                    type="submit"
                                    color="primary"
                                    disabled={this.state.saving}
                                    name="formForm">
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faSave} />
                                    {saveFormButtonText}
                                </Button>
                            }
                            {(!!!isAdmin && !!_form.surveyStatusId == surveyStatuses.Draft) &&
                                <Button
                                    size="sm"
                                    type="button"
                                    color="primary"
                                    disabled={this.state.saving}
                                    onClick={this.onPublish}
                                    name="formForm"
                                >
                                    <FontAwesomeIcon className="mr-2" icon={faCheckCircle} /> {"Publish Form"}
                                </Button>
                            }
                            {(!!canEdit.form && !isNewForm) &&
                                <Button
                                    size="sm"
                                    type="button"
                                    color="primary"
                                    className="ml-2"
                                    disabled={this.state.saving}
                                    onClick={() => { this.props.history.push(`${AppNavPaths.FormsBuild}/${_form.id}`) } }
                                >
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faScrewdriverWrench} />
                                    {"Build Form"}
                                </Button>
                            }
                            <Button
                                size="sm"
                                type="button"
                                color="secondary"
                                name="formFormClose"
                                disabled={this.state.saving}
                                onClick={this.onCloseClicked}
                                className="ml-2">
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faTimes} />
                                {"Close"}
                            </Button>
                        </FlexCenterRow>
                        <FlexRow>
                            <FormErrorResponseDisplay onClear={this.clearSaveErrors} response={errorResponse} />
                        </FlexRow>
                    </AppPageForm>
                    <Prompt
                        when={!this.state.saving && !isEqual(originalData, _form)}
                        message='You have unsaved changes, are you sure you want to leave?'
                    />
                </Fragment>
            );
        }

    }
    //#endregion
}

export default withRouter(FormsForm);