import React, { Fragment } from 'react';
import "./WorkCenterDiagram.scss";
import createEngine, {
    DiagramModel
    ,DefaultNodeModel
    ,DefaultPortModel
    ,PathFindingLinkFactory   
} from '@projectstorm/react-diagrams';
import { CanvasWidget } from '@projectstorm/react-canvas-core';
import {
    AppPageForm,
    FlexRow
} from '../common/forms/FormElements';
import {
    Row,
    Button
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import CommonContext, { ApiRoutes, ApplicationPermissions, AppNavPaths } from '../Common';
import { handleFormSaveError } from '../common/forms/ValidationError';
import authService from '../api-authorization/AuthorizeService';
import { getUserProfile } from '../common/UserProfile';
import FormErrorResponseDisplay from '../common/forms/FormErrorResponseDisplay';
import { LinearProgress } from '@material-ui/core';
import { util } from '../Util';

//Suports the font-awesome icon picker
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

//End icon picker support

class WorkCenterDiagram extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            formOpened: false,
            formValidated: false,
            loading: true,
            perms: [],
            workCenterMapping: [],
            errorResponse: {},
            engine: {}
        }
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidMount = async () => {
        this._subscription = authService.subscribe(() => this.populateState());
        await this.populateState();
    }

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();
        
        if (!!isAuthenticated) {
            const { userPermissions } = await getUserProfile();

            var [workCenterMapResponse] = await Promise.all([
                util.fetch.get(ApiRoutes.workCenters.getDiagram(), util.fetch.format.none)
            ]);

            var workCenterMapping;
            const engine = createEngine();
            const model = new DiagramModel();

            //Handle any issues fetching data
            if (!!workCenterMapResponse.error) {
                //Handle erroneous links entered by the user
                let serviceResponse = {
                    title: 'Server Error',
                    errors: {
                        Exception: [
                            workCenterMapResponse.error
                        ]
                    }
                }
                this.displaySaveErrors(serviceResponse);
                return false;
            } else {
                workCenterMapping = await workCenterMapResponse.json();

                let unmapped = workCenterMapping.unmappedSYNCNodes;

                let maxYOffset = 0;
                let maxXindex = workCenterMapping.mappedNodes.length;

                let currentXOffset = 0;
                let currentYOffset = 0;

                unmapped.forEach((umap, ind) => {

                    if ((currentXOffset + 1) > maxXindex) {
                        currentXOffset = 0;
                        currentYOffset++;
                    } else {
                        currentXOffset++;
                    }

                    let m_node = new DefaultNodeModel(
                        umap.syncWorkCenterName !== 'None' ? umap.syncWorkCenterName : umap.apteanWorkCenterName
                        , 'rgb(255,255,0)'
                    );
                    m_node.setPosition((200 * currentXOffset) + 25, (50 * currentYOffset) + 50);                   

                    model.addNode(m_node);
                });

                //Previous iteration out ports
                let outPorts = [];
                let inPorts = [];

                let addObjects = [];

                for (let cnt = 0; cnt < workCenterMapping.mappedNodes.length; cnt++) {
                    for (let mpInd = 0; mpInd < workCenterMapping.mappedNodes[cnt].length; mpInd++) {

                        let mapping = workCenterMapping.mappedNodes[cnt][mpInd];
                        let m_node = new DefaultNodeModel(
                            mapping.syncWorkCenterName !== 'None' ? mapping.syncWorkCenterName : mapping.apteanWorkCenterName
                            , 'rgb(0,192,255)'
                        );
                        m_node.setPosition((400 * cnt) + 25, 200 + (100 * mpInd));

                        if ((200 + (60 * mpInd)) > maxYOffset) {
                            maxYOffset = 200 + (100 * mpInd);
                        }

                        if (mapping.inTags.length) {
                            for (let itd = 0; itd < mapping.inTags.length; itd++) {
                                inPorts.push(m_node.addPort(new DefaultPortModel(true, mapping.inTags[itd], mapping.inTags[itd])));
                            }
                        }

                        if (mapping.outTags.length) {
                            for (let otd = 0; otd < mapping.outTags.length; otd++) {
                                outPorts.push(m_node.addPort(new DefaultPortModel(false, mapping.outTags[otd], mapping.outTags[otd])));
                            }
                        }

                        addObjects.push(m_node);
                    }
                }

                //currentXOffset = 0;
                //currentYOffset = 0;
                //maxYOffset = maxYOffset + 100;

                //workCenterMapping.unmappedERPNodes.forEach((u_mapped, ind) => {
                //    if ((currentXOffset + 1) > maxXindex) {
                //        currentXOffset = 0;
                //        currentYOffset++;
                //    } else {
                //        currentXOffset++;
                //    }

                //    let m_node = new DefaultNodeModel(
                //        u_mapped.syncWorkCenterName !== 'None' ? u_mapped.syncWorkCenterName : u_mapped.apteanWorkCenterName
                //        , 'rgb(0,0,0)'
                //    );
                //    m_node.setPosition((400 * currentXOffset) + 25, (50 * currentYOffset) + maxYOffset);

                //    model.addNode(m_node);
                //});

                const pathfinding = engine.getLinkFactories().getFactory(PathFindingLinkFactory.NAME);

                outPorts.forEach((o_port) => {
                    let linkInPorts = inPorts.filter((ip) => {
                        return ip.getName() == o_port.getName()
                    });

                    if (linkInPorts.length) {
                        if (linkInPorts.length > 1) {
                            //We only need path finding if a link leaving an object has more than one destination.
                            linkInPorts.forEach((i_port) => {
                                let link = o_port.link(i_port, pathfinding);
                                //Lable for link. so we can add the Out tag names at some point
                                //link.addLabel(o_port.getName());
                                addObjects.push(link);

                            });
                        } else {
                            linkInPorts.forEach((i_port) => {
                                let link = o_port.link(i_port);
                                //Lable for link. so we can add the Out tag names at some point
                                //link.addLabel(o_port.getName());
                                addObjects.push(link);
                            });
                        }
                    }
                });

                model.addAll(...addObjects);
                
                engine.setModel(model);
            }

            this.setState({
                formValidated: false,
                saving: false,
                loading: false,
                perms: userPermissions,
                engine: engine
            });
        }
    }

    handleSaveError = (err) => handleFormSaveError(this, err);

    onCloseClicked = () => {
        this.props.history.push(`${AppNavPaths.WorkCenters}`);
    }

    render() {
        const {
            loading,
            perms,
            engine
        } = { ...this.state };
        let { errorResponse } = { ...this.state };

        if (!!loading) {
            return (<LinearProgress variant="indeterminate" color="secondary" />);
        } else {

            return (
                <Fragment>
                    <AppPageForm
                        formId={"workCenterMapForm"}
                        formHeadingIcon={"fa-map"}
                        formHeading={'Work Center Diagram'}
                        formName={"workCenterDiagramForm"}
                        formRef={this.formRef}
                        errors={this.state.errors}
                        loading={this.state.loading}
                    >
                        <Row>
                            <Button
                                size="sm"
                                type="button"
                                color="secondary"
                                name="workCenterMapClose"
                                onClick={this.onCloseClicked}
                                className="ml-2">
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon="fa-times" />
                                {"Close"}
                            </Button>
                        </Row>
                        {!!!perms.includes(ApplicationPermissions.work_center_view) &&
                            <h5>User Does not Have Permission to View this Page</h5>
                        }
                        {!!perms.includes(ApplicationPermissions.work_center_view) &&
                            <div style={{ "height": "80vh", "width": "70vw", "border": "2px solid #777777", "borderRadius": "5px","marginTop": "0.25em"}} >
                                <CanvasWidget engine={engine} />
                            </div>
                        }
                        <FlexRow>
                            <FormErrorResponseDisplay response={errorResponse} />
                        </FlexRow>
                    </AppPageForm>
                </Fragment>
            );
        }
    }

}
export default withRouter(WorkCenterDiagram);