import React, { Fragment } from 'react';
import "./CameraTest.scss";
import CommonContext from '../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTimes,
    faCamera,
    faRepeat
} from '@fortawesome/free-solid-svg-icons'
import {
    AppPageForm
} from '../common/forms/FormElements';
import {
    Button,
    Container,
    Row,
    Col,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Modal
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Webcam from "react-webcam";
import { v4 as uuid } from 'uuid';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/dist/styles.css";

class CameraTest extends React.Component {
    static contextType = CommonContext;
    constructor(props) {
        super(props);

        this.formRef = React.createRef();
        this.webCamRef = React.createRef();
        this.debugInfoRef = React.createRef();

        this.state = {
            getPicutre: false,
            lbIsOpen: false,
            lbImgIndex: 0,
            lbImages: [],
            images: [],
            noCamera: false,
            videoConstraints: {
                width: 1920,
                height: 1080
            },
            deviceIds: [],
            currentDeviceId: 0,
            debugInfo: [{ message: "Camera testing Started" }]
        }
    }

    componentDidMount = () => {
        this.populateState();
    }

    componentWillUnmount = async () => {
        let { stream, video } = { ...this.webCamRef.current };

        let currentTracks = stream?.getTracks();

        if (currentTracks) {
            currentTracks.forEach(track => {
                track.stop();
                stream.removeTrack(track);
            });
        }

        if (video) {
            video.pause();
            video.src = "";
            video.srcObject = null;
        }
    }

    handleCancelCamera = async () => {
        let { stream, video } = { ...this.webCamRef.current };

        let currentTracks = stream?.getTracks();

        if (currentTracks) {
            currentTracks.forEach(track => {
                track.stop();
                stream.removeTrack(track);
            });
        }

        if (video) {
            video.pause();
            video.src = "";
            video.srcObject = null;
        }

        this.setState({ getPicutre: false });
    }

    async populateState() {
        let { videoConstraints, deviceIds } = { ...this.state };

        let noCamera = true;

        if (navigator.mediaDevices) {
            let devices = await navigator.mediaDevices.enumerateDevices();
            let videoDevices = devices.filter((d) => d.kind == "videoinput");

            this.addDebugInfo("Attempting to asses Camera permission...");

            var component = this;

            navigator.permissions.query(
                { name: 'camera' }
            ).then(function (permissionStatus) {
                component.addDebugInfo("---------- Video Information --------");
                component.addDebugInfo("---------- All Devices --------------");
                devices.map((device) => component.addDebugInfo(JSON.stringify(device)));
                component.addDebugInfo("---------- Video Devices --------------");
                component.addDebugInfo(`${videoDevices.length} Video Devices found`);
                videoDevices.map((vdevice) => component.addDebugInfo(JSON.stringify(vdevice)));
                component.addDebugInfo("----------Supported Constraints --------------");
                component.addDebugInfo(JSON.stringify(navigator.mediaDevices.getSupportedConstraints()));

                if (videoDevices.length) {
                    component.addDebugInfo('Video input devices found');
                    noCamera = false;

                    deviceIds.push(videoDevices[0].deviceId);
                    videoConstraints.deviceId = permissionStatus.state == 'granted' ? { exact: deviceIds[0] } : deviceIds[0];

                    if (videoDevices.length > 1) {
                        component.addDebugInfo('multiple video devices found');
                        deviceIds.push(videoDevices[1].deviceId);
                    }
                }

                component.addDebugInfo(`Has Camera? : ${noCamera ? "No" : "Yes"}`);
                component.addDebugInfo("---------- End Video Information --------");

                component.addDebugInfo("---------- Camera Permissions --------");
                component.addDebugInfo(permissionStatus.state); // granted, denied, prompt

                component.setState({
                    loading: false,
                    noCamera: noCamera,
                    videoConstraints: videoConstraints,
                    deviceIds: deviceIds
                });

                if (permissionStatus.state == 'granted') {
                    if (videoDevices.length > 1) {
                        component.switchWhichCamera(); //try and use a different camera to prompt again
                    }
                }

                permissionStatus.onchange = async function () {
                    if (this.state == 'granted') {
                        let nDeviceIds = [];
                        let pDevices = await navigator.mediaDevices.enumerateDevices();
                        let pVideoDevices = pDevices.filter((d) => d.kind == "videoinput");

                        component.addDebugInfo("Permission changed to " + this.state);

                        component.addDebugInfo("---------- All Devices --------------");
                        pDevices.map((device) => component.addDebugInfo(JSON.stringify(device)));
                        component.addDebugInfo("---------- Video Devices --------------");
                        component.addDebugInfo(`${pVideoDevices.length} Video Devices found`);
                        pVideoDevices.map((vdevice) => component.addDebugInfo(JSON.stringify(vdevice)));

                        nDeviceIds.push(pVideoDevices[0].deviceId);
                        if (pVideoDevices.length > 1) {
                            nDeviceIds.push(pVideoDevices[1].deviceId);
                            component.setState({
                                deviceIds: nDeviceIds
                            });
                        }
                    }
                }

            })
        }
    }

    addDebugInfo = (message) => {
        setTimeout(() => {
            let { debugInfo } = { ...this.state };

            let newDebug = [...debugInfo, { message: message }];

            if (this.debugInfoRef.current) {
                this.debugInfoRef.current.scrollBottom = this.debugInfoRef.current.scrollHeight;
            }

            this.setState({
                debugInfo: newDebug
            });
        }, 100);
    }

    handleOnRemoveAnswerImage = async (idx) => {
        let { images } = { ...this.state };

        images.splice(idx, 1);

        this.setState({
            images: images
        });
    }

    switchWhichCamera = async () => {
        this.addDebugInfo('Switching Camera');
        let { deviceIds, videoConstraints, currentDeviceId } = { ...this.state };

        let { stream, video } = { ...this.webCamRef.current };
        
        let currentTracks = stream?.getTracks();

        if (currentTracks) {
            this.addDebugInfo('Video Iniatlized, clearing existing tracks');

            currentTracks.forEach(track => {
                track.stop();
                stream.removeTrack(track);
            });
        }

        this.addDebugInfo(JSON.stringify(videoConstraints));

        if (currentDeviceId == 0) {
            this.addDebugInfo(`Current Device index : ${currentDeviceId} ${deviceIds[0]}, switching to 1`);
            videoConstraints.deviceId = { exact: deviceIds[1] };
            currentDeviceId = 1;
            this.setState({
                currentDeviceId: currentDeviceId 
            });
        } else {
            this.addDebugInfo(`Current Device index : ${currentDeviceId} ${deviceIds[1]}, switching to 0`);
            videoConstraints.deviceId = { exact: deviceIds[0] };
            currentDeviceId = 0;
            this.setState({
                currentDeviceId: currentDeviceId 
            });
        }

        if (video) {
            video.pause();
            video.src = "";
            video.srcObject = null;

            navigator.mediaDevices.getUserMedia({
                video: videoConstraints,
                audio: false
            }).then((newStream) => {
                video.srcObject = newStream;
                video.play();
            });
        }
    }

    handleOnAnswerImage = () => {
        let { images } = { ...this.state };

        images = [...images, this.webCamRef.current.getScreenshot()];

        this.setState({
            images: images,
            getPicutre: false
        });
    }

    render() {
        const {
            deviceIds,
            noCamera,
            videoConstraints
        } = { ...this.state };

        let {
            getPicutre,
            lbIsOpen,
            lbImgIndex,
            lbImages,
            images,
            debugInfo
        } = { ...this.state };

        return (
            <Fragment>
                <AppPageForm
                    formId={"cameraTestForm"}
                    formHeadingIcon={faCamera}
                    formHeading={"Test Camera Permissions"}
                    formName={"cameraTestForm"}
                    formRef={this.formRef}
                >
                    <div>
                        <Modal isOpen={getPicutre} size={"lg"} >
                            <ModalHeader>Take Picture</ModalHeader>
                            <ModalBody style={{ textAlign: "center" }} >
                                {noCamera && <div><p>Camera is not present or permission was not granted.</p></div>}
                                {!noCamera &&
                                    <Webcam
                                        audio={false}
                                        height={500}
                                        ref={this.webCamRef}
                                        screenshotFormat="image/jpeg"
                                        width={"100%"}
                                        videoConstraints={videoConstraints}
                                    />
                                }
                            </ModalBody>
                            <ModalFooter>
                                {(deviceIds.length > 1) &&
                                    <Button
                                        size="sm"
                                        type="button"
                                        color="secondary"
                                        name="SwitchCamera"
                                        onClick={() => this.switchWhichCamera()}
                                    >
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faRepeat} />
                                        {"Switch Camera"}
                                    </Button>
                                }
                                {!noCamera &&
                                    <Button
                                        size="sm"
                                        type="button"
                                        color="primary"
                                        name="takeCapture"
                                        onClick={() => this.handleOnAnswerImage()}
                                    >
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faCamera} />
                                        {"Take Picture"}
                                    </Button>
                                }
                                <Button
                                    size="sm"
                                    type="button"
                                    color="secondary"
                                    name="cancelTakeCapture"
                                    onClick={() => this.handleCancelCamera()}
                                    className="ml-2">
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faTimes} />
                                    {"Cancel"}
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                    <div>
                        <Lightbox
                            open={lbIsOpen}
                            index={lbImgIndex}
                            close={() =>
                                this.setState({
                                    lbIsOpen: false,
                                    lbImgIndex: 0,
                                    lbImages: []
                                })
                            }
                            slides={lbImages}
                        />
                    </div>
                    <Container>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                <FontAwesomeIcon
                                    className="mr-2 text-dark pr-2 zoom"
                                    onClick={() => {
                                        this.setState({
                                            getPicutre: true
                                        });
                                    }}
                                    icon={faCamera} />
                            </Col>
                        </Row>
                        <Row>
                            {images.map((img, idx) =>
                                <Col key={uuid()} xs={12} sm={6} md={6} lg={3} xl={3} xxl={3} style={{ textAlign: 'center' }} >
                                    <div className={"answer-image-thumbnail-wrapper"} >
                                        <FontAwesomeIcon
                                            className={"remove-img-icon"}
                                            icon={faTimes}
                                            onClick={() => {
                                                this.handleOnRemoveAnswerImage(idx);
                                            }}
                                        />
                                        <img className={"answer-image-thumbnail"}
                                            src={img} alt={""}
                                            onClick={() => {
                                                let imgs = images.map((i) => { return { src: i } });

                                                this.setState({
                                                    lbIsOpen: true,
                                                    lbImgIndex: idx,
                                                    lbImages: imgs
                                                });
                                            }}
                                        />
                                    </div>
                                </Col>
                            )}
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                <div ref={this.debugInfoRef} style={{ overflowY: "scroll", whiteSpace: "normal", wordWrap: "break-word" }} >
                                    {debugInfo.map((m, idx) =>
                                        <p key={uuid()}>{`${m.message}`}</p>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </AppPageForm>
            </Fragment>
        )

    }

}
export default withRouter(CameraTest);