import React from 'react';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    LinkCellWithIconRenderer,
    ConditionalPureIconCellRenderer,
    TextFilterDefaults,
    indexCellRenderer
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons'
import { LinearProgress } from '@material-ui/core';
import { PageHeading, PageWrap, SmallPrimaryButton } from '../common/forms/FormElements';
import { withRouter } from "react-router-dom";
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import authService from '../api-authorization/AuthorizeService';
import { Can } from '../Can';

class WorkCenterIndex extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selectedRow: null
        }
        this.onEditWorkCenter = this.onEditWorkCenter.bind(this);
        this.onAddWorkCenterClicked = this.onAddWorkCenterClicked.bind(this);
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
        this.setState = (state, callback) => { return };
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {

            let gridOptions = createGridOptions(this);
            let context = this;

            gridOptions.components = {
                selectFilter: DataGridSelectFilter,
                selectFloatingFilter: DataGridSelectFloatingFilter,
                nameRenderer: LinkCellWithIconRenderer,
                iconRenderer: ConditionalPureIconCellRenderer,
                indexCellRenderer: indexCellRenderer
            };

            gridOptions.columnDefs = [
                {
                    flex: 0,
                    width: 70,
                    minWidth: 70,
                    maxWidth: 70,
                    headerName: "",
                    valueGetter: "node.id",
                    sortable: false,
                    cellRenderer: 'indexCellRenderer'
                },
                {
                    colId: 'Name',
                    sortable: true,
                    headerName: 'Name',
                    field: 'name',
                    tooltipField: 'name',
                    sort: { direction: 'asc', priority: 0 },
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                    cellRenderer: 'nameRenderer',
                    cellRendererParams: {
                        clicked: this.onEditWorkCenter,
                        nameField: 'name',
                        iconName: 'iconName',
                        idField: 'id',
                        title: 'View this WorkCenter'
                    }
                },
                {
                    colId: 'Description',
                    sortable: true,
                    headerName: 'Description',
                    field: 'description',
                    tooltipField: 'description',
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    }
                },
                {
                    colId: 'MappedWorkCenter.Name',
                    sortable: true,
                    headerName: 'Aptean Work Center',
                    field: 'mappedWorkCenter.name',
                    tooltipField: 'apteanWorkCenter',
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    }
                },
                {
                    flex: 0,
                    width: 40,
                    minWidth: 40,
                    maxWidth: 40,
                    colId: 'SystemProvided',
                    sortable: false,
                    headerName: ' ',
                    field: 'systemProvided',
                    tooltipField: 'systemProvided',
                    cellRenderer: 'iconRenderer',
                    cellRendererParams: {
                        iconClass: 'fa-lock'
                    }
                }
            ];

            // Cache and reload filter and sort changes 
            gridOptions.onFilterChanged = (params) => {
                let filterModel = gridOptions.api.getFilterModel();
                sessionStorage.setItem("workCenter_gridFilterSettings", JSON.stringify(filterModel));
            }

            gridOptions.onSortChanged = (params) => {
                let sortModel = gridOptions.api.columnModel.getColumnState();
                sessionStorage.setItem("workCenter_gridSortSettings", JSON.stringify(sortModel));
            }

            gridOptions.onFirstDataRendered = (params) => {
                let sortModel = sessionStorage.getItem("workCenter_gridSortSettings");
                if (sortModel) {
                    //var obj = JSON.parse(sortModel);
                    //TODO: Uncaught TypeError: gridOptions.api.setSortModel is not a function
                    //gridOptions.api.setSortModel(obj);
                }
            }

            gridOptions.useLoading = true;
            gridOptions.loadingTemplate = '<span className="ag-overlay-loading-center "><i class="faSaveSpinner fa fa-md fa-circle-notch"></i> Loading ...</span>';
            
            gridOptions.onGridReady = (params) => {
                let filterModel = sessionStorage.getItem("workCenter_gridFilterSettings");
                if (filterModel) {
                    var obj = JSON.parse(filterModel);
                    
                    if (!!obj['WorkCenters.Name']) {
                        //todo: filterInstance not working
                        //const filterInstance = gridOptions.api.getFilterInstance('WorkCenters.Name');
                        //filterInstance.setModel(obj['WorkCenters.Name']);
                        gridOptions.api.onFilterChanged();
                    }
                    if (!!obj['WorkCenters.Description']) {
                        //todo: filterInstance not working
                        //const filterInstance = gridOptions.api.getFilterInstance('WorkCenters.Description');
                        //filterInstance.setModel(obj['WorkCenters.Description']);
                        gridOptions.api.onFilterChanged();
                    }

                    if (!!obj['WorkCenters.MappedWorkCenter.Name']) {
                        //todo: filterInstance not working
                        //const filterInstance = gridOptions.api.getFilterInstance('WorkCenters.MappedWorkCenter.Name');
                        //filterInstance.setModel(obj['WorkCenters.MappedWorkCenter.Name']);
                        gridOptions.api.onFilterChanged();
                    }
                }

                params.api.setDatasource(context.state.dataSource);
                context.setState({ gridApi: params.api });
            }

            let ds = createDataSource(ApiRoutes.workCenters.search(), gridOptions);

            this.setState(state => {
                return {
                    loading: false,
                    gridOptions: gridOptions,
                    dataSource: ds
                }
            });
        }
    }

    getInitialListFiltersFromCache(initialFilters) {

        var json = sessionStorage.getItem("workCenter_gridFilterSettings"); //todo: change this

        if (!!json) {
            var obj = JSON.parse(json);

            initialFilters.groupId = !!obj.Group ? parseInt(obj.Group.value) : 0;
        }

        return initialFilters;
    }

    onAddWorkCenterClicked = () => this.props.history.push(`${AppNavPaths.WorkCenterNew}`);
    onViewWorkCenterMapClicked = () => this.props.history.push(`${AppNavPaths.WorkCenterMap}`);
    onViewWorkCenterDiagramClicked = () => this.props.history.push(`${AppNavPaths.WorkCenterDiagram}`);
    onEditWorkCenter = (id) => this.props.history.push(`${AppNavPaths.WorkCenter}/${id}`);

    render() {

        if (!!this.state.loading || !this.context?.permissions)
            return (<LinearProgress variant="indeterminate" color="secondary" />);

        const { rowData, gridOptions } = { ...this.state };

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon icon={faBuilding} className="mr-2 text-muted" />
                    <span>Work Centers</span>
                    <Can I="create" a={"work_center"}>
                        <SmallPrimaryButton type="button" onClick={this.onAddWorkCenterClicked} className="btn-success float-right">
                            <i className={`fa fa-md mr-2 fa-plus-square`} />
                            Add Work Center
                        </SmallPrimaryButton>
                    </Can>
                    <SmallPrimaryButton type="button" onClick={this.onViewWorkCenterMapClicked} className="btn-primary float-right mr-2">
                        <i className={`fa fa-md mr-2 fa-map`} />
                        View Map
                    </SmallPrimaryButton>
                    <SmallPrimaryButton type="button" onClick={this.onViewWorkCenterDiagramClicked} className="btn-primary float-right mr-2">
                        <i className={`fa fa-md mr-2 fa-map`} />
                        View Diagram
                    </SmallPrimaryButton>
                </PageHeading>
                <DataGridToolbar
                    entity="work_center"
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                    hideAdd={true}
                    addIcon={'fa-user-plus'}
                    hideExcelButton={true}
                    gridOptions={this.state.gridOptions}
                    serverExport={{ apiPath: ApiRoutes.workCenters.excelExport(), filePrefix: 'WorkCenterSearch' }}
                    serverExportDisabled={!!this.state.saving || !!this.state.loading || !!this.state.loadingData}

                >
                </DataGridToolbar>
                <DataGrid
                    domLayout={"normal"}
                    rowData={rowData}
                    gridOptions={gridOptions}
                    gridStatus={this.state.gridStatus}
                />
            </PageWrap>
        );
    }
}

export default withRouter(WorkCenterIndex);