import React, { Fragment } from 'react';

//Contexts
import { FlexRow, onFieldChange, toasty } from '../common/forms/FormElements';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { UnitOfMeasurement } from './FormsAndSurveys';
import { isEqual, sortBy } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import { faBalanceScale } from '@fortawesome/fontawesome-free-solid';
import {
    AppPageForm,
    FlexCenterRow,
    FormGroupColumn,
    FormLabel,
    GroupedRow
} from '../common/forms/FormElements';

import { LinearProgress } from '@material-ui/core';
import { util } from '../Util';

import {
    Button,
    FormGroup
} from 'reactstrap';

import { Prompt, withRouter } from 'react-router-dom';
import { handleFormSaveError } from '../common/forms/ValidationError';
import authService from '../api-authorization/AuthorizeService';
import { getUserProfile } from '../common/UserProfile';
import FormErrorResponseDisplay from '../common/forms/FormErrorResponseDisplay';
import ValidatedSelect from '../common/forms/ValidatedSelect';

//#endregion

class UnitOfMeasurementForm extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            unitOfMeasure: new UnitOfMeasurement(),
            uomDataTypes: [],
            formOpened: false,
            formValidated: false,
            loading: true,
            saving: false,
            statuses: [],
            perms: [],
            errorResponse: {}
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.save = this.save.bind(this);
        this.saveNew = this.saveNew.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps && (this.props.match.params.id !== (prevProps.match.params ?? {}).id)) {
            this.populateState();
        }
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            let { id } = { ...this.props.match.params };
            const { userPermissions } = await getUserProfile();

            if (id == 'new') {
                id = undefined; //If the Id is identified as being a new UOM, then just kill the id so we don't call the API
            }

            var [uomResponse, uomDataTypes] = await Promise.all([
                !!id ? util.fetch.post(ApiRoutes.unitsofmeasurement.byid(id), util.fetch.format.none) : new UnitOfMeasurement(),
                util.fetch.js(ApiRoutes.typeAheads.unitsOfMeasureDataTypes())
            ]);

            var uom;
            uomDataTypes = sortBy(uomDataTypes, ['label']);
            uomDataTypes = uomDataTypes.map((uomdt) => { return { value: Number(uomdt.value), label: uomdt.label }; });

            //Handle any issues fetching data
            if (!!id && !!uomResponse.error) {
                //Handle erroneous links entered by the user
                let serviceResponse = {
                    title: 'Server Error',
                    errors: {
                        Exception: [
                            uomResponse.error
                        ]
                    }
                }
                this.displaySaveErrors(serviceResponse);
                return false;
            } else {
                uom = uomResponse;
            }

            this.setState({
                formValidated: false,
                saving: false,
                unitOfMeasure: uom,
                uomDataTypes: uomDataTypes,
                loading: false,
                originalData: uom,
                perms: userPermissions,
            });
        }
    }

    //#region METHODS
    onChange = onFieldChange;

    onSubmit() {
        let { unitOfMeasure } = { ...this.state };
        this.clearSaving()
        this.setState({ errors: {}, saving: true });

        if (this.props.location.pathname === AppNavPaths.UnitsOfMeasurementNew)
            this.saveNew(unitOfMeasure);
        else
            this.save(unitOfMeasure);
    }

    clearSaving = () => this.setState({ saving: false });

    notifySuccess = () => toasty.success('Unit Of Measure Saved', `Unit Of Measure saved successfully.`);
    notifyError = (message) => toasty.error('Save Unsuccessful', message);
    handleSaveError = (err) => handleFormSaveError(this, err);

    displaySaveErrors = (response) => this.setState({ errorResponse: response });
    clearSaveErrors = () => this.setState({ errorResponse: {} });

    onCloseClicked = () => {
        this.props.history.push(`${AppNavPaths.UnitsOfMeasurement}`);
    }

    saveNew = async (uom) => {
        try {
            let response = await util.fetch.post(ApiRoutes.unitsofmeasurement.create(), uom, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                let uomId = await response.json();
                this.notifySuccess();
                this.props.history.push(`${AppNavPaths.UnitsOfMeasurement}/${uomId}`);
            } else {

                if (response.status === 400) {
                    let serviceResponse = await response.json();
                    this.displaySaveErrors(serviceResponse);
                } else {

                    let errorResp = await response.json();

                    if (errorResp.errors) {
                        this.displaySaveErrors(errorResp);
                    } else {
                        let serviceResponse = {
                            title: 'Server Error',
                            errors: {
                                Exception: [
                                    await response.text()
                                ]
                            }
                        }
                        this.displaySaveErrors(serviceResponse);
                    }
                }
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    save = async (uom) => {
        try {
            let response = await util.fetch.put(ApiRoutes.unitsofmeasurement.byid(uom.id), uom, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                this.notifySuccess();
                this.populateState();
            } else {
                this.displaySaveErrors(await response.json());
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    //#endregion

    //#region RENDERING
    render() {
        const { loading, originalData, unitOfMeasure, perms, uomDataTypes } = { ...this.state };
        let { errorResponse } = { ...this.state };

        if (!!loading) {
            return (<LinearProgress variant="indeterminate" color="secondary" />);
        } else {
            const isNewUOM = ((unitOfMeasure.id ?? 0) <= 0);
            const saveUOMButtonText = (!isNewUOM ? 'Save' : 'Save New Unit Of Measurement');

            //field editing permissions
            const canEdit = {
                uom: !!perms.includes("unitofmeasure.edit") || (isNewUOM && !!perms.includes("unitofmeasure.create"))
            };

            return (
                <Fragment>
                    <AppPageForm
                        formId={"uomForm"}
                        formHeadingIcon={faBalanceScale}
                        formHeading={isNewUOM ? 'New Unit Of Measurement' : 'Edit Unit Of Measurement'}
                        formName={"uomForm"}
                        formRef={this.formRef}
                        onSubmit={this.onSubmit}
                        setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                        isValidated={this.state.formValidated}
                        saving={this.state.saving}
                        errors={this.state.errors}
                        loading={this.state.loading}
                    >
                        <GroupedRow>
                            <FormGroupColumn>
                                <FormGroup>
                                    <FormLabel htmlFor="inputName" text="Name" required={true} />
                                    <input id="name"
                                        name="unitOfMeasure.name"
                                        autoComplete="off"
                                        className="form-control"
                                        required
                                        disabled={!isNewUOM && !canEdit.uom}
                                        onChange={!!canEdit.uom ? this.onChange : undefined}
                                        defaultValue={unitOfMeasure.name} />
                                    <small className="invalid-feedback text-danger" hidden>Name is required.</small>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="uomDataType" text="Choose Data Type" />
                                    <ValidatedSelect
                                        id="uomDataType"
                                        name="unitOfMeasure.UnitOfMeasurementDataTypeId"
                                        required={true}
                                        options={uomDataTypes}
                                        value={(uomDataTypes ?? []).find(s => s.value == unitOfMeasure.unitOfMeasurementDataTypeId) ?? ''}
                                        onChange={(selection, event) => {
                                            unitOfMeasure.unitOfMeasurementDataTypeId = selection.value;
                                            this.setState({ unitOfMeasure: unitOfMeasure });
                                        }}
                                    /> 
                                </FormGroup>
                            </FormGroupColumn>
                        </GroupedRow>
                        <FlexCenterRow className="mb-3">
                            {!!canEdit.uom &&
                                <Button
                                    size="sm"
                                    type="submit"
                                    color="primary"
                                    disabled={this.state.saving}
                                    name="uomForm">
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faSave} />
                                    {saveUOMButtonText}
                                </Button>
                            }
                            <Button
                                size="sm"
                                type="button"
                                color="secondary"
                                name="uomFormClose"
                                disabled={this.state.saving}
                                onClick={this.onCloseClicked}
                                className="ml-2">
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faTimes} />
                                {"Close"}
                            </Button>
                        </FlexCenterRow>
                        <FlexRow>
                            <FormErrorResponseDisplay onClear={this.clearSaveErrors} response={errorResponse} />
                        </FlexRow>
                    </AppPageForm>
                    <Prompt
                        when={!this.state.saving && !isEqual(originalData, unitOfMeasure)}
                        message='You have unsaved changes, are you sure you want to leave?'
                    />
                </Fragment>
            );
        }

    }
    //#endregion
}

export default withRouter(UnitOfMeasurementForm);