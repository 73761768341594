export class WorkCenter {
    id = null;
    name = '';
    description = '';
    iconName = 'fas fa-building';
    mappedWorkCenter_Id = '';
    mappedWorkCenterName = '';
    mappedWorkCenter = null;
    workCenterTags = [];
    completedTags = [];
    systemProvided = false;
    surveys = [];
    workCenterRestrictionCriteria = [];
    isShippingActivity = false;
    requiresIssueResolution = false;
}

export class WorkCenterMapping {
    workCenters = [];
}

export class WorkCenterRestriction {
    id = 0;
    expectedValue = "";
    derivedFieldName = "";
    criteriaTypeId = 0;
    criteriaTypeText = "";
    expectedValueTypeId = 0;
    workCenterRestrictionTypeText = "";
    expectedValueRelationshipId = 0;
    workCenterRestrictionRelationshipText = 0;
    workCenter_Id = 0;
    workCenterName = "";
}