import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinearProgress } from '@material-ui/core';
import CommonContext, { ApiRoutes, AppNavPaths } from "../Common";
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    IconCellRenderer,
    LinkCellRenderer,
    TextFilterDefaults,
    DateFilterDefaults,
    VariableLinkCellRenderer
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import { PageHeading, PageWrap, SmallButton } from '../common/forms/FormElements';
import authService from '../api-authorization/AuthorizeService';
import { faComments } from '@fortawesome/fontawesome-free-solid';

export default class OpenIssueSearch extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            invalid: false,
            rowData: [],
            rowsSelected: [],
            isExporting: false            
        };

        this.onRowSelected = this.onRowSelected.bind(this);
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    }

    populateState = async () => {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            const gridOptions = createGridOptions(this);

            gridOptions.components = {
                selectFilter: DataGridSelectFilter,
                selectFloatingFilter: DataGridSelectFloatingFilter,
                nameRenderer: LinkCellRenderer,
                iconRenderer: IconCellRenderer,
                variableLinkRenderer: VariableLinkCellRenderer
            };
            gridOptions.onRowSelected = this.onRowSelected;
            gridOptions.columnDefs = this.getColumnDefs();
            gridOptions.useLoading = true;
            gridOptions.loadingTemplate = '<span className="ag-overlay-loading-center "><i class="faSaveSpinner fa fa-md fa-circle-notch"></i> Loading ...</span>';

            const dataSource = createDataSource(ApiRoutes.SalesOrderTracking.issueTrackingSearch(), gridOptions);

            this.setState({
                loading: false,
                gridOptions: gridOptions,
                dataSource: dataSource
            });
        }
    }

    getColumnDefs() {

        //https://www.ag-grid.com/documentation/javascript/column-definitions/

        let statusFilterParams = {
            suppressFilterButton: true,
            labelText: 'Filter by Status',
            options: [
                { label: "Complete", value: "Complete" }
                , { label: "Open", value: "Open" }
            ],
            optionsLabel: 'label',
            optionsValue: 'value',
            initialFilterValue: 'Open'
        }

        const defs = [
            {
                headerName: "",
                valueGetter: "node.id",
                sortable: false,
                hide: true,
                flex: 1,
                maxWidth: 35,
                minWidth: 35,
                cellRenderer: this.indexCellRenderer
            },
            {
                colId: 'Id',
                minWidth: 75,
                maxWidth: 75,
                headerName: '',
                sortable: false,
                cellStyle: { color: "rgba(0,0,0,0)" },
                cellRenderer: (params) => {
                    if (params.data) {

                        return (
                            <SmallButton
                                type="button"
                                disabled={!!this.props.formIsOpen}
                                onClick={() => this.props.history.push(`${AppNavPaths.IssueTracking}/${params.data.id}`)}
                            >
                                <i className={`fa fa-eye fa-md mr-2`} />
                            </SmallButton>
                        );
                    }

                    return null;
                }
            },
            {
                colId: 'RequiredDate',
                headerName: 'Required Date',
                field: 'requiredDate',
                sortable: true,
                maxWidth: 120,
                minWidth: 120,
                sort: 'asc',
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults
            },
            {
                colId: 'SerialNumber',
                headerName: 'Serial# / Shipping#',
                field: 'serialNumber',
                sortable: true,
                sort: { direction: 'asc', priority: 0 },
                flex: 3,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                },
                cellRenderer: (params) => {
                    if (params.data) {
                        if (params.data.serialNumber[0] == '0') {
                            return 'S-' + params.data.serialNumber.replace(/^0+/, "");
                        }
                        return params.data.serialNumber;
                    }
                    return null;
                }
            },
            {
                colId: 'Model',
                headerName: 'Model',
                field: 'model',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'WorkCenter',
                headerName: 'Work Center',
                field: 'workCenter',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'SurveyName',
                headerName: 'Form',
                field: 'surveyName',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'SectionName',
                headerName: 'Section',
                field: 'sectionName',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'Question',
                headerName: 'Question',
                field: 'question',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'Opened',
                headerName: 'Open',
                field: 'opened',
                sortable: true,
                maxWidth: 120,
                minWidth: 120,
                sort: { direction: 'asc', priority: 0 },
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults
            },
            {
                colId: 'ResolvedDate',
                headerName: 'Close',
                field: 'resolved',
                sortable: true,
                maxWidth: 120,
                minWidth: 120,
                sort: { direction: 'asc', priority: 0 },
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults
            },
            {
                colId: 'Status',
                headerName: 'Status',
                field: 'status',
                sortable: true,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: statusFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: statusFilterParams
            }
        ];

        return defs;
    }

    //https://stackoverflow.com/questions/44263350/count-number-of-selected-rows-in-ag-grid
    //Possibly use lodash dequeue?
    onRowSelected(e) {
        const rs = e.api.getSelectedRows();
        this.setState({
            rowsSelected: rs
        });
    }

    render() {
        const {
            rowData,
            gridOptions,
            loading
        } = this.state;

        if (!!loading) {
            return (<LinearProgress variant="indeterminate" color="secondary" />);
        } else {
            return (
                <CommonContext.Consumer>
                    {
                        value => {
                            if (this.state.loading) return (<LinearProgress variant="indeterminate" color="secondary" />);

                            return (
                                <PageWrap>
                                    <PageHeading>
                                        <FontAwesomeIcon icon={faComments} className="mr-2 text-muted" />
                                        <span>{"Issue Tracking"}</span>
                                    </PageHeading>
                                    <DataGridToolbar
                                        entity={"SalesOrderTrackingSurveyIssue"}
                                        gridApi={this.state.gridApi}
                                        dataSource={this.state.dataSource}
                                        hideAdd={true}
                                        hideExcelButton={true}
                                        gridOptions={this.state.gridOptions}
                                        serverExport={{ apiPath: ApiRoutes.SalesOrderTracking.issueTrackingExport(), filePrefix: 'IssueTracking'}}
                                    />
                                    <DataGrid
                                        domLayout={"normal"}
                                        rowData={rowData}
                                        gridOptions={gridOptions}
                                        gridStatus={this.state.gridStatus}
                                    />
                                </PageWrap>
                            )
                        }
                    }
                </CommonContext.Consumer>
            )
        }
    }
}