import React, { Fragment } from 'react';
import moment from 'moment';
//Contexts
import { FlexRow, onFieldChange, toasty } from '../common/forms/FormElements';
import CommonContext, { ApiRoutes, ApplicationPermissions, AppNavPaths } from '../Common';
import { Quote } from './Quote';
import { isEqual } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes, faClipboardCheck, faEye } from '@fortawesome/free-solid-svg-icons'
import { faAngleDoubleRight, faArrowRight } from '@fortawesome/fontawesome-free-solid';
import {
    AppPageForm,
    FlexCenterRow,
    FormGroupColumn,
    FormLabel,
    GroupedRow,
} from '../common/forms/FormElements';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { LinearProgress } from '@material-ui/core';
import { util } from '../Util';
import {
    Button,
    FormGroup,
    Row,
    Col,
    Input,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Modal
} from 'reactstrap';
import Dropzone from 'react-dropzone';
import FileLink from '../common/forms/FileLink';
import { Prompt, withRouter } from 'react-router-dom';
import { handleFormSaveError } from '../common/forms/ValidationError';
import authService from '../api-authorization/AuthorizeService';
import { getUserProfile } from '../common/UserProfile';
import FormErrorResponseDisplay from '../common/forms/FormErrorResponseDisplay';
import { DocumentTypes } from '../workflow/SalesOrderTracking';
import ValidatedSelect from '../common/forms/ValidatedSelect';

//#endregion

class QuoteForm extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            quote: new Quote(),
            quoteFile: null,
            quoteFileName: null,
            quoteFileDescription: null,
            existingQuoteCustomers: null,
            formOpened: false,
            formValidated: false,
            loading: true,
            saving: false,
            statuses: [],
            perms: [],
            errorResponse: {},
            allDealers: [],
            allCustomers: [],
            allQuoteCustomers: [],
            allWorkedonBy: [],
            chosenDealerSalesman: { email: ""},
            chosenDealer: {},
            completeQuote: false,
            quoteDispositions: [],
            allLiftTypes: [],
            errors: {},
            creatingChangeOrder: false,
            revision: ""
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.saveQuote = this.saveQuote.bind(this);
        this.saveNewQuote = this.saveNewQuote.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps && (this.props.match.params.id !== (prevProps.match.params ?? {}).id)) {
            this.populateState();
        }
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            let { id } = { ...this.props.match.params };
            const { userPermissions } = await getUserProfile();

            var [
                quoteResponse,
                allDealers,
                allQuoteCustomers,
                allWorkedOnBy,
                quoteDispositions,
                allLiftTypes
            ] = await Promise.all([
                !!id ? util.fetch.get(ApiRoutes.quote.byId(id), util.fetch.format.none) : new Quote(),
                util.fetch.js(ApiRoutes.quote.dealers()),
                util.fetch.js(ApiRoutes.quote.quoteCustomerAll()),
                util.fetch.js(ApiRoutes.quote.workedonby()),
                util.fetch.js(ApiRoutes.typeAheads.quoteDispositionStatuses()),
                util.fetch.js(ApiRoutes.typeAheads.getLiftTypes())
            ]);

            var chosenDealer = {};
            var chosenDealerSalesman = {};
            var quote;
            var loadedQuoteFile;
            var loadedQuoteFileName;
            var loadedQuoteFileDescription;

            //Handle any issues fetching data
            if (!!id && !quoteResponse.ok) {
                //Handle erroneous links entered by the user
                if (quoteResponse.status === 404)
                    this.props.history.push(AppNavPaths.NotFound);
                else
                    this.props.history.push(AppNavPaths.ServerError);
                return false;
            } else {
                if (!!id){
                    quote = await quoteResponse.json();
                    if (!!quote?.quoteDocument?.document) {
                        loadedQuoteFile = quote.quoteDocument.document;
                        loadedQuoteFile.file = {
                            path: quote.quoteDocument.document.name,
                            name: quote.quoteDocument.document.name,
                            extension: quote.quoteDocument.document.contentType
                        }
                        loadedQuoteFileName = quote.quoteDocument.document.name;
                        loadedQuoteFileDescription = quote.quoteDocument.document.description;
                    }

                    if (!!quote.quoteDealerCustomerId) {
                        chosenDealer = allDealers.find(d => d.customer_Id == quote.quoteDealerCustomerId);

                        if (!!!(chosenDealer)) {
                            toasty.warning("The Chosen Dealer no longer exists in Aptean.");
                        } else {

                            if (!!quote.dealerSalesmanId) {
                                chosenDealerSalesman = chosenDealer?.dealerSalesmen.find(ds => ds.id == quote.dealerSalesmanId);
                                if (!!!(chosenDealerSalesman)) {
                                    toasty.warning("The Chosen Dealer Salesman no longer exists in Aptean.");
                                }
                            }
                        }                        
                    }

                } else {
                    quote = quoteResponse;

                    await util.fetch.get(ApiRoutes.quote.nextNumber(), util.fetch.format.none).then(async (response) => {
                        if (!!id && !response.ok) {
                            if (response.status === 404) {
                                this.props.history.push(AppNavPaths.NotFound);
                            }
                            else {
                                this.props.history.push(AppNavPaths.ServerError);
                            }
                        } else {
                            quote.number = await response.json();
                        }
                    });
                }
            }

            await this.setState({
                formValidated: false,
                saving: false,
                quote: quote,
                quoteFile: loadedQuoteFile,
                quoteFileName: loadedQuoteFileName,
                quoteFileDescription: loadedQuoteFileDescription ?? "",
                quoteFileChanged: false,
                loading: false,
                originalData: quote,
                perms: userPermissions,
                allDealers: allDealers,
                allQuoteCustomers: allQuoteCustomers,
                allWorkedOnBy: allWorkedOnBy,
                quoteDispositions: quoteDispositions,
                chosenDealer: chosenDealer,
                chosenDealerSalesman: chosenDealerSalesman,
                allLiftTypes: allLiftTypes
            });
        }
    }

    //#region METHODS
    onChange = onFieldChange;

    onSubmit = async () => {
        let { quote, quoteFile, quoteFileName, quoteFileDescription, quoteFileChanged } = { ...this.state };
        this.clearSaving()
        this.setState({ errors: {}, saving: true });

        const isNewQuote = this.props.location.pathname === AppNavPaths.QuoteNew;
        if (isNewQuote) {

            if (!!quoteFile?.file) {
                await Promise.resolve(this.upload(ApiRoutes.quote.upload(), quoteFile.file, quoteFileName, quoteFileDescription, DocumentTypes.QuoteDocument));
            } else {
                quote.quoteDocumentId = 0;
            }
            this.saveNewQuote(quote);
        }
        else {
            if (!!quoteFileChanged) {
                //Only do this if there is a file to upload.
                if (!!(quoteFile?.file ?? false)) {
                    //upload doc before saving quote updates, if quote file has been changed in the UI
                    await Promise.resolve(this.upload(ApiRoutes.quote.upload(), quoteFile.file, quoteFileName, quoteFileDescription, DocumentTypes.QuoteDocument));
                }
            }
                
            // tag along the edited file description on edit, in case it has changed
            quote.editedQuoteDocumentDescription = quoteFileDescription;
            this.saveQuote(quote);
        }
    }

    onDisposition = async () => {
        let { quote, quoteFile, quoteFileName, quoteFileDescription, quoteFileChanged } = { ...this.state };
        this.clearSaving()
        this.setState({ errors: {}, saving: true });

        if (quoteFileChanged) {
            //upload doc before saving quote updates, if quote file has been changed in the UI
            await Promise.resolve(this.upload(ApiRoutes.quote.upload(), quoteFile.file, quoteFileName, quoteFileDescription, DocumentTypes.QuoteDocument));
        }

        // tag along the edited file description on edit, in case it has changed
        quote.editedQuoteDocumentDescription = quoteFileDescription;

        quote.quoteFileChanged = quoteFileChanged ?? false; // was a new file selected for upload?

        try {
            let response = await util.fetch.put(ApiRoutes.quote.update(quote.id), quote, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                var serviceResponse = await response.json();
                if (serviceResponse.result === 'SUCCESS') {
                    try {
                        let sdresponse = await util.fetch.put(ApiRoutes.quote.setdisposition(quote.id), quote, util.fetch.format.none);
                        if (sdresponse.redirected) {
                            window.location.href = sdresponse.url;
                        } else if (!!sdresponse.ok) {
                            var inrServiceResponse = await sdresponse.json();
                            if (inrServiceResponse.result === 'SUCCESS') {
                                this.notifySuccess();

                                this.setState({
                                    completeQuote: false
                                });

                                this.populateState();
                            } else {
                                this.notifyError(inrServiceResponse.message);
                            }
                        } else {
                            this.displaySaveErrors(await sdresponse.json());
                        }
                    } catch (error) {
                        this.notifyError(error.toString());
                    } 
                } else {
                    this.notifyError(serviceResponse.message);
                }
            } else {
                this.displaySaveErrors(await response.json());
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    upload = (url, file, name, description, docType) => {
        return new Promise((resolve) => {
            var formData = new FormData();
            formData.append('file', file, file.path);
            formData.append("documentName", name);
            formData.append("documentDescription", description);
            formData.append("documentTypeId", docType);

            var xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);

            let xsrfToken = util.getCookie('X-DAL-AF');
            xhr.setRequestHeader('X-CSRF-TOKEN', xsrfToken);

            xhr.upload.onprogress = (e) => this.onProgressUpdated(e);
            xhr.responseType = 'json';
            xhr.onload = () => {
                let { quote } = { ...this.state };
                let fid = xhr.response; //TODO: harden this error handling, see email from m. hand re: file path not being accessible

                quote.quoteDocumentId = fid;

                this.setState({ quote: quote });
                resolve();
            };
            xhr.send(formData);
        });
    }

    clearSaving = () => this.setState((state) => { return { saving: false }; });

    notifySuccess = () => toasty.success('Quote Saved', `Quote saved successfully.`);
    notifyError = (message) => toasty.error('Save Unsuccessful', message);
    handleSaveError = (err) => handleFormSaveError(this, err);

    displaySaveErrors = (response) => this.setState((state) => { return { errorResponse: response }; });
    clearSaveErrors = () => this.setState((state) => { return { errorResponse: {}, errors: {} }; });

    onClearErrorNotificationClicked = e => {
        e.stopPropagation();
        this.setState({ errors: {} });
    }

    onCloseClicked = () => {
        this.props.history.push(`${AppNavPaths.Quotes}`);
    }

    saveNewQuote = async (quote) => {
        quote.quoteFileChanged = false; // new file selected for upload
        try {
            let response = await util.fetch.post(ApiRoutes.quote.create(), quote, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                var serviceResponse = await response.json();
                if (serviceResponse.result === 'SUCCESS') {
                    this.notifySuccess();
                    var quoteId = serviceResponse.data;
                    this.props.history.push(`${AppNavPaths.Quote}/${quoteId}`);
                } else {
                    this.notifyError(serviceResponse.message);
                }
            } else {

                if (response.status === 400) {
                    let serviceResponse = await response.json();
                    this.displaySaveErrors(serviceResponse);
                } else {
                    let serviceResponse = {
                        title: 'Server Error',
                        errors: {
                            Exception: [
                                await response.text()
                            ]
                        }
                    }
                    this.displaySaveErrors(serviceResponse);
                }
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    saveQuote = async (quote) => {
        let { quoteFileChanged } = { ...this.state };
        quote.quoteFileChanged = quoteFileChanged ?? false; // was a new file selected for upload?

        try {
            let response = await util.fetch.put(ApiRoutes.quote.update(quote.id), quote, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                var serviceResponse = await response.json();
                if (serviceResponse.result === 'SUCCESS') {
                    this.notifySuccess();
                    this.populateState();
                } else {
                    this.notifyError(serviceResponse.message);
                }
            } else {
                this.displaySaveErrors(await response.json());
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    removeFile = () => {
        let { quoteFile, quoteFileChanged, quote } = { ...this.state };

        quoteFile = null
        quoteFileChanged = true;
        quote.quoteDocumentId = 0;

        this.setState({ quoteFile: quoteFile, quoteFileChanged: quoteFileChanged, quote: quote });
    }

    onProgressUpdated = (e) => {
        if (e.lengthComputable) {
            var percentage = Math.round((e.loaded / e.total) * 100);
            let { quoteFile } = { ...this.state };
            quoteFile.progress = percentage;
            this.setState({ quoteFile: quoteFile });
        }
    }

    showCompleteQuote = () => {
        let { quote, errors } = { ...this.state };

        //Reset Errors
        errors = {};

        let valid = true;

        if (!!!quote.receivedDate) {
            valid = false;
            errors["Received Date"] = "Received Date is Required";
        }

        if (!!!quote.dueDate) {
            valid = false;
            errors["Due Date"] = "Due Date is Required";
        }

        if (!!!quote.quoteCustomer || !!!quote.quoteCustomer?.name?.length) {
            valid = false;
            errors["End User"] = "End User is Required";
        }

        if (!!!quote.liftTypeText) {
            valid = false;
            errors["Lift Type"] = "Lift Type is Required";
        }

        if(!!!quote.quoteDealerCustomerId) {
            valid = false;
            errors["Dealer"] = "Dealer is Required";
        }

        if (!!!quote.dealerSalesmanId) {
            valid = false;
            errors["Dealer Salesman"] = "Dealer Salesman is Required";
        }

        if (!!!quote.workedOnBy || !!!quote.workedOnBy?.name?.length) {
            valid = false;
            errors["Worked On By Salesman"]  = "Worked On By Salesman is Required";
        }

        if (!valid) {
            this.setState({ errors: errors });
            toasty.warning("Please review the form for validation errors");
        } else {
            this.setState({ completeQuote: true });
        }
    }

    onCreateSalesOrderTracking = () => {
        let { quote } = { ...this.state };
        this.props.history.push(`${AppNavPaths.SalesOrderTrackingNew}/1/${quote.id}`);
    }

    onViewSalesOrderTracking = () => {
        let { quote } = { ...this.state };
        this.props.history.push(`${AppNavPaths.SalesOrderTracking}/${quote.salesOrderTracking_Id}`);
    }

    handleCreateWorkedOnByOption = (newOption) => {
        let { quote, allWorkedOnBy } = { ...this.state };
        let newWorkedOnBy = { id: null, name: newOption, email: null };
        allWorkedOnBy.push(newWorkedOnBy);
        quote.workedOnBy = newWorkedOnBy;
        this.setState({ quote: quote, allWorkedOnBy: allWorkedOnBy });
    }

    handleCreateQuoteCustomerOption = (newOption) => {
        let { quote, allQuoteCustomers } = { ...this.state };
        let newQuoteCustomer = { id: null, name: newOption };
        allQuoteCustomers.push(newQuoteCustomer);
        quote.quoteCustomer = newQuoteCustomer;
        this.setState({ quote: quote, allQuoteCustomers: allQuoteCustomers });
    }

    onCreateChangeOrder = async () => {
        this.clearSaving()
        this.setState({ errors: {}, saving: true });
        let { quote } = { ...this.state };

        if (!!quote.hasOpenChangeOrder) {
            this.props.history.push(`${AppNavPaths.QuoteChangeOrder}/${quote.hasOpenChangeOrder}`);
        } else {

            this.clearSaving();
            this.setState({creatingChangeOrder: true});
        }
    }

    finishCreatingChangeOrder = async () => {
        this.clearSaving()
        this.setState({ errors: {}, saving: true });
        let { quote, revision } = { ...this.state };

        try {
            let response = await util.fetch.get(ApiRoutes.quote.changeorder.create(quote.id, revision), util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                var serviceResponse = await response.json();
                if (serviceResponse.result === 'SUCCESS') {
                    this.props.history.push(`${AppNavPaths.QuoteChangeOrderNew}/${serviceResponse.data}`);
                } else {
                    this.notifyError(serviceResponse.message);
                    this.clearSaving();
                }
            } else {
                this.displaySaveErrors(await response.json());
                this.clearSaving();
            }
        } catch (error) {
            this.notifyError(error.toString());
            this.clearSaving();
        } 
    }
    //#endregion

    //#region RENDERING
    getReadOnlyLabel = (text) => <span className="ml-3 pb-3 text-muted font-weight-bold" > {text}</span>;

    render() {
        let {
            loading,
            originalData,
            quote,
            perms,
            quoteFile,
            quoteFileName,
            quoteFileDescription,
            saving,
            errorResponse,
            allDealers,
            chosenDealerSalesman,
            chosenDealer,
            completeQuote,
            quoteDispositions,
            allQuoteCustomers,
            allWorkedOnBy,
            allLiftTypes,
            quoteFileChanged,
            creatingChangeOrder,
            revision
        } = { ...this.state };
        
        const isDateBlank = (date) => date == null || date == '0001-01-01T00:00:00';

        const completionDateBlank = isDateBlank(quote.completionDate);
        const receivedDateBlank = isDateBlank(quote.receivedDate);
        const dueDateBlank = isDateBlank(quote.dueDate);

        if (!!loading) {
            return (<LinearProgress variant="indeterminate" color="secondary" />);
        } else {
            const isNewQuote = ((quote.id ?? 0) <= 0);
            const saveQuoteButtonText = (!isNewQuote ? 'Save' : 'Save New Quote');

            //field editing permissions
            const canEdit = {
                quote: !!perms.includes(ApplicationPermissions.quote_edit)
            };

            const canCreateChangeOrder = !!perms.includes("quote_change_order.create");

            const canCreateSalesOrderTracking = !!perms.includes("sales_order_tracking.create");
            const canViewSalesOrderTracking = !!perms.includes("sales_order_tracking.view");

            const hasSalesOrderTracking = !!(quote.salesOrderTracking_Id);

            const isComplete = !!(quote.quoteDispositionStatus_Id);
            const isAccepted = !!(quote.quoteDispositionStatus_Id === 1);

            let quoteHasDocument = !!quote?.quoteDocument?.document;

            const acceptProp = {
                'application/vnd.ms-excel': ['.xls','.xlsx'],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx']
            };

            return (
                <Fragment>
                    <AppPageForm
                        formId={"quoteForm"}
                        formHeadingIcon={faClipboardCheck}
                        formHeading={isNewQuote ? 'New Quote' : 'Edit Quote'}
                        formName={"quoteForm"}
                        formRef={this.formRef}
                        onSubmit={this.onSubmit}
                        setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                        isValidated={this.state.formValidated}
                        saving={this.state.saving}
                        errors={this.state.errors}
                        loading={this.state.loading}
                        onClearErrors={this.onClearErrorNotificationClicked}
                    >
                        <GroupedRow>
                            <FormGroupColumn>
                                <FormGroup>
                                    <FormLabel htmlFor="quote.number" text="Number" />
                                    <input
                                        autoComplete="off"
                                        id="quote.number"
                                        name="quote.number"
                                        defaultValue={quote.number}
                                        className="form-control"
                                        disabled={true}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="quote.receivedDate" text="Received Date" />
                                    <DatePicker
                                        className="form-control"
                                        id="quote.receivedDate"
                                        name="quote.receivedDate"
                                        disabled={(!isNewQuote && !canEdit.quote) || !!isComplete}
                                        selected={receivedDateBlank ? '' : moment(quote.receivedDate).toDate()}
                                        onChange={(date) => { quote.receivedDate = date; this.setState({ quote }); }}
                                        autoComplete='off'
                                    />
                                    <small className="invalid-feedback text-danger" hidden>Received date is required.</small>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="quote.dueDate" text="Due Date" />
                                    <DatePicker
                                        className="form-control"
                                        id="quote.dueDate"
                                        name="quote.dueDate"
                                        disabled={(!isNewQuote && !canEdit.quote) || !!isComplete}
                                        autoComplete='off'
                                        selected={dueDateBlank ? '' : moment(quote.dueDate).toDate()}
                                        onChange={(date) => { quote.dueDate = date; this.setState({ quote }); }}
                                    />
                                    <small className="invalid-feedback text-danger" hidden>Due date is required.</small>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="quoteCustomer" text="End User" />
                                    <ValidatedSelect
                                        id="quoteCustomer"
                                        isClearable={true}
                                        creatable={true}
                                        validationMessage={"End User is required"}
                                        options={allQuoteCustomers}
                                        isDisabled={(!isNewQuote && !canEdit.quote) || !!isComplete}
                                        value={(allQuoteCustomers ?? []).find(s => s.name == quote.quoteCustomer?.name) ?? ''}
                                        handleCreate={this.handleCreateQuoteCustomerOption}
                                        getOptionLabel={option => option.name}
                                        getOptionValue={option => option.name}
                                        onChange={(selection) => {
                                            quote.quoteCustomer = selection;
                                            this.setState({ quote: quote });
                                        }}
                                        getNewOptionData={(inputValue, optionLabel) =>( { id: null, name: optionLabel })}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="quoteLiftType" text="Lift Type" />
                                    <ValidatedSelect
                                        id="quoteLiftType"
                                        validationMessage={"Lift Type is required"}
                                        isClearable={true}
                                        options={allLiftTypes}
                                        isDisabled={(!isNewQuote && !canEdit.quote) || !!isComplete}
                                        value={(allLiftTypes ?? []).find(s => s.label == quote.liftTypeText) ?? ''}
                                        onChange={(selection) => {
                                            quote.liftTypeId = selection?.value;
                                            quote.liftTypeText = selection?.label;
                                            this.setState({ quote: quote });
                                        }}
                                    />
                                </FormGroup>
                                {isComplete &&
                                    <FormGroup>
                                        <FormLabel htmlFor="quote.poNumber" text="PO Number" />
                                        <input
                                            className="form-control"
                                            id="quote.poNumber"
                                            name="quote.poNumber"
                                            value={!!(quote.poNumber) ? quote.poNumber : "Not Provided"}
                                            onChange={() => { }}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                }
                            </FormGroupColumn>
                            <FormGroupColumn>
                                <FormGroup>
                                    <FormLabel htmlFor="quote.serialNumber" text="Serial Number" />
                                    <input
                                        autoComplete="off"
                                        id="quote.serialNumber"
                                        name="quote.serialNumber"
                                        value={!!(quote.serialNumber) ? quote.serialNumber : "Not Assigned"}
                                        className="form-control"
                                        disabled={true}
                                    />
                                </FormGroup>
                                {!isNewQuote &&
                                    <FormGroup>
                                        <FormLabel htmlFor="quote.model" text="Model" />
                                        <input
                                            autoComplete="off"
                                            id="quote.model"
                                            name="quote.model"
                                            defaultValue={quote.model}
                                            className="form-control"
                                            disabled={true}
                                        />
                                    </FormGroup>
                                }
                                <FormGroup>
                                    <FormLabel htmlFor="dealer" text="Dealer" />
                                    <ValidatedSelect
                                        autoComplete="on"
                                        id="quoteDealerCustomerId"
                                        validationMessage={"Dealer is Required"}
                                        name="quote.quoteDealerCustomerId"
                                        options={allDealers}
                                        isClearable={true}
                                        isDisabled={(!isNewQuote && !canEdit.quote) || !!isComplete}
                                        disabled={!isNewQuote && !canEdit.quote}
                                        value={(allDealers ?? []).find(s => s.customer_Id == (chosenDealer?.customer_Id ?? 0)) ?? ''}
                                        getOptionLabel={option => option.customer_Name}
                                        getOptionValue={option => option.customer_Id}
                                        onChange={(selection) => {
                                            quote.quoteDealerCustomerId = selection?.customer_Id;
                                            quote.territoryName = selection?.territoryName;
                                            chosenDealer = selection;

                                            this.setState({ quote: quote, chosenDealer: chosenDealer });

                                            if (!!!selection) {
                                                quote.dealerSalesmanId = null;
                                                chosenDealerSalesman = null;
                                                this.setState({ quote: quote, chosenDealerSalesman: chosenDealerSalesman });
                                            }

                                        }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="quote.territory" text="Territory" />
                                    <input
                                        id="quote.territoryName"
                                        name="quote.territoryName"
                                        value={quote.territoryName}
                                        className="form-control"
                                        disabled={true}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="dealersalesman" text="Dealer Salesman" />
                                    <ValidatedSelect
                                        id="quoteDealerSalesmanId"
                                        validationMessage={"Dealer Salesman is Required"}
                                        name="quote.dealerSalesmanId"
                                        isClearable={true}
                                        isDisabled={(!isNewQuote && !canEdit.quote) || !!isComplete}
                                        options={chosenDealer?.dealerSalesmen ?? []}
                                        value={(chosenDealer?.dealerSalesmen ?? []).find(s => s.id == (chosenDealerSalesman?.id ?? 0)) ?? ''}
                                        getOptionLabel={option => option.name}
                                        getOptionValue={option => option.id}
                                        onChange={(selection) => {
                                            quote.dealerSalesmanId = selection?.id;
                                            chosenDealerSalesman = selection;
                                            this.setState({ quote: quote, chosenDealerSalesman: chosenDealerSalesman });
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="chosenDealerSalesman" text="Dealer Salesman Email" />
                                    <input
                                        className="form-control"
                                        id="chosenDealerSalesman"
                                        name="chosenDealerSalesman"
                                        value={chosenDealerSalesman?.email ?? ""}
                                        onChange={() => { } }
                                        disabled={true}
                                    />
                                </FormGroup>
                            </FormGroupColumn>
                        </GroupedRow>
                        <Row style={{
                            margin: "1em 0.5em 0 0.5em",
                            borderTop: "2px solid lightgray",
                            borderLeft: "2px solid lightgray",
                            padding: '1em',
                            borderRight: '2px solid lightgray'
                        }} >
                            <Col>
                                <Dropzone
                                    /*Only accept excel files*/
                                    accept={acceptProp}
                                    multiple={false}
                                    onDrop={(acceptedFiles) => {
                                        let { quoteFile, quoteFileName, saving } = { ...this.state };

                                        if (!!saving) {
                                            return false;
                                        }

                                        //We are only uploading a single file at a time and only the first in the list
                                        quoteFile = { file: acceptedFiles[0], progress: 0.0 };
                                        quoteFileName = acceptedFiles[0].name;

                                        this.setState({ quoteFile: quoteFile, quoteFileName: quoteFileName, quoteFileChanged: true });
                                    }}
                                    inputContent={(files, extra) => (extra.reject ? 'File type not permitted.' : 'Drag and drop your quote file here, or click to select a file')}
                                    maxSize={25000000} /*25MB application wide*/
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <>
                                            {
                                                !!(quoteFile) &&
                                                <div className="file-uploads-preview">
                                                    <label className="control-label">Quote File</label>
                                                    <FileLink
                                                        preview={true}
                                                        showFileSize={true}
                                                        viewOnly={!!hasSalesOrderTracking}
                                                        key={!!quoteFile.path ?  quoteFile.file.path : quoteFileName}
                                                        url={'#'}
                                                        file={quoteFile.file}
                                                        remove={() => this.removeFile()}
                                                        progress={quoteFile.progress}
                                                    />
                                                </div>
                                            }
                                            {!!!(quoteFile) &&
                                                <section className="file-upload-section" hidden={!!saving}>
                                                    <label className="control-label">Quote File</label>
                                                    <div {...getRootProps({ className: 'dropzone' })} className="file-upload-section-inner">
                                                        <Input {...getInputProps()} />
                                                        {/*<small className="invalid-feedback text-danger" hidden>Quote file is required.</small>*/}
                                                        <span className="border-bottom mb-2">Drag and drop your quote file here, or click to select a file.</span>
                                                        <small>Supported file types:<span className="ml-2 text-success">{"MS Excel Files (.xls, .xlsx)"}</span></small>
                                                    </div>
                                                </section>
                                            }
                                        </>
                                    )}
                                </Dropzone>
                            </Col>
                        </Row>
                        <Row style={{
                            margin: "0 0.5em 0 0.5em",
                            borderBottom: "2px solid lightgray",
                            borderLeft: "2px solid lightgray",
                            padding: '1em',
                            borderRight: '2px solid lightgray'
                        }}>
                            <Col>
                                <FormGroup>
                                    <FormLabel htmlFor="quoteFileDescription"
                                        text="Quote File Description"
                                    />
                                    <Input
                                        type="textarea"
                                        rows="1"
                                        name="quoteFileDescription"
                                        value={quoteFileDescription ?? ''}
                                        disabled={(!isNewQuote && !canEdit.quote) || !!isComplete}
                                        onChange={(evt) => {
                                            let value = evt.target.value;
                                            quoteFileDescription = value;
                                            this.setState({ quoteFileDescription: quoteFileDescription });
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <GroupedRow>
                            <FormGroupColumn>                
                                <FormGroup>
                                    <FormLabel htmlFor="workedOnBy" text="Worked On By Salesman"/>
                                    <ValidatedSelect
                                        id="quoteWorkedOnBy"
                                        creatable={true}
                                        isClearable={true}
                                        validationMessage={"Worked On By Salesmen is Required"}
                                        options={allWorkedOnBy}
                                        isDisabled={(!isNewQuote && !canEdit.quote) || !!isComplete}
                                        value={(allWorkedOnBy ?? []).find(s => s.name == quote.workedOnBy?.name) ?? ''}
                                        handleCreate={this.handleCreateWorkedOnByOption}
                                        getOptionLabel={option => option.name}
                                        getOptionValue={option => option.name}
                                        onChange={(selection) => {
                                            quote.workedOnBy = selection;
                                            this.setState({ quote: quote });
                                        }}
                                        getNewOptionData={(inputValue, optionLabel) =>( { id: null, name: optionLabel })}
                                    />
                                </FormGroup>
                            </FormGroupColumn>
                        </GroupedRow>
                        <div>
                            <Modal isOpen={creatingChangeOrder}>
                                <ModalHeader>Change Order Revision</ModalHeader>
                                <ModalBody>
                                    <GroupedRow>
                                        <FormGroupColumn>
                                            <FormGroup>
                                                <FormLabel htmlFor="revision" text="Change Order Revision" />
                                                <input
                                                    required={true}
                                                    id="revision"
                                                    className="form-control"
                                                    name="revision"
                                                    value={revision ?? ""}
                                                    onChange={this.onChange}
                                                />
                                                <small className="invalid-feedback text-danger" hidden>Change Order Revision is Required</small>
                                            </FormGroup>
                                        </FormGroupColumn>
                                    </GroupedRow>
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        size="sm"
                                        type="button"
                                        color="primary"
                                        name="quoteChangeOrderInitial"
                                        onClick={() => this.finishCreatingChangeOrder()}
                                    >
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faArrowRight} />
                                        {"Continue"}
                                    </Button>
                                    <Button
                                        size="sm"
                                        type="button"
                                        color="secondary"
                                        name="cancelquoteChangeOrderInitial"
                                        onClick={() => this.setState({ creatingChangeOrder: false, revision: null })}
                                        className="ml-2">
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faTimes} />
                                        {"Cancel"}
                                    </Button>
                                </ModalFooter>
                            </Modal>
                            <Modal isOpen={completeQuote}>
                                <ModalHeader>Complete Quote</ModalHeader>
                                <ModalBody>
                                    <GroupedRow>
                                        <FormGroupColumn>
                                            <FormGroup>
                                                <FormLabel htmlFor="quotedisposition" text="Disposition" />
                                                <ValidatedSelect
                                                    id="quoteDispositionId"
                                                    formRef={this.formRef}
                                                    name="quoteDispositionStatus_Id"
                                                    options={quoteDispositions}
                                                    value={(quoteDispositions ?? []).find(s => s.value == quote.quoteDispositionStatus_Id) ?? ''}
                                                    onChange={(selection) => {
                                                        quote.quoteDispositionStatus_Id = selection?.value;
                                                        this.setState({ quote: quote });
                                                    }}
                                                />
                                            </FormGroup>
                                            {!!((quote.quoteDispositionStatus_Id ?? 0) == 1) &&  
                                                <FormGroup>
                                                    <FormLabel htmlFor="quotePONumber" text="PO Number" required={true} />
                                                    <input
                                                        required={quote.quoteDispositionStatus_Id == 1}
                                                        id="quotePONumber"
                                                        className="form-control"
                                                        name="quote.poNumber"
                                                        value={quote.poNumber ?? ""}
                                                        onChange={this.onChange}
                                                    />
                                                    <small className="invalid-feedback text-danger" hidden>PO Number is Required</small>
                                                </FormGroup>
                                            }
                                            <FormGroup>
                                                <FormLabel htmlFor="completionDate" text="Completion Date" required={false} />
                                                <DatePicker
                                                    autoComplete='off'
                                                    className="form-control"
                                                    name="quote.completionDate"
                                                    defaultValue={quote.completionDate}
                                                    selected={completionDateBlank ? '' : moment(quote.completionDate).toDate()}
                                                    onChange={(date) => { quote.completionDate = date; this.setState({ quote }); }}
                                                />
                                                <small className="invalid-feedback text-danger" hidden>Completion date is required.</small>
                                            </FormGroup>
                                        </FormGroupColumn>
                                    </GroupedRow>
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        size="sm"
                                        type="button"
                                        color="primary"
                                        name="workCenterForm"
                                        onClick={() => this.onDisposition()}
                                    >
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faSave} />
                                        {"Save"}
                                    </Button>
                                    <Button
                                        size="sm"
                                        type="button"
                                        color="secondary"
                                        name="cancelCompleteQuote"
                                        onClick={() => this.setState({completeQuote: false})}
                                        className="ml-2">
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faTimes} />
                                        {"Cancel"}
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                        <FlexCenterRow className="mt-3 mb-3">
                            {!!(!!canEdit.quote && (!isComplete || !!quoteFileChanged) ) &&
                                <Button
                                    size="sm"
                                    type="submit"
                                    color="primary"
                                    disabled={this.state.saving}
                                    name="workCenterForm">
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faSave} />
                                    {saveQuoteButtonText}
                                </Button>
                            }
                            {!!(canEdit.quote && !isComplete && !isNewQuote && quoteHasDocument) &&
                                <Button
                                    size="sm"
                                    type="button"
                                    color="secondary"
                                    disabled={this.state.saving}
                                    name="showCompleteQuoteButton"
                                    onClick={this.showCompleteQuote}
                                    className="ml-2">
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faSave} />
                                    {"Complete"}
                                </Button>
                            }
                            {!!(!!canCreateSalesOrderTracking && !!isAccepted && !!!hasSalesOrderTracking && quoteHasDocument) &&
                                <Button
                                    size="sm"
                                    type="button"
                                    color="secondary"
                                    disabled={this.state.saving}
                                    name="createSalesOrder"
                                    onClick={this.onCreateSalesOrderTracking}
                                    className="ml-2">
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faSave} />
                                    {"Create Sales Order"}
                                </Button>
                            }
                            {!!(!!hasSalesOrderTracking && !!canViewSalesOrderTracking) &&
                                <Button
                                    size="sm"
                                    type="button"
                                    color="secondary"
                                    disabled={this.state.saving}
                                    name="viewSalesOrder"
                                    onClick={this.onViewSalesOrderTracking}
                                    className="ml-2">
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faEye} />
                                    {"View Sales Order"}
                                </Button>
                            }
                            {/* Can only make a change order if there is something to change. The file drives that change*/ }
                            {(!!!isNewQuote && !!canCreateChangeOrder && quoteHasDocument && !!hasSalesOrderTracking) &&
                                <Button
                                    size="sm"
                                    type="button"
                                    color="secondary"
                                    disabled={this.state.saving}
                                    name="createQuoteChangeOrder"
                                    onClick={this.onCreateChangeOrder}
                                    className="ml-2">
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faAngleDoubleRight} />
                                    {!!quote.hasOpenChangeOrder ? "Go to Change Order" : "Create Change Order"}
                                </Button>
                            }
                            <Button
                                size="sm"
                                type="button"
                                color="secondary"
                                disabled={this.state.saving}
                                name="workCenterFormClose"
                                onClick={this.onCloseClicked}
                                className="ml-2">
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faTimes} />
                                {"Close"}
                            </Button>
                        </FlexCenterRow>
                        <FlexRow>
                            <FormErrorResponseDisplay onClear={this.clearSaveErrors} response={errorResponse} />
                        </FlexRow>
                    </AppPageForm>
                    <Prompt
                        when={!this.state.saving && !isEqual(originalData, quote)}
                        message='You have unsaved changes, are you sure you want to leave?'
                    />
                </Fragment>
            );
        }

    }
    //#endregion
}

export default withRouter(QuoteForm);