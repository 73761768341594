import React from 'react';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    LinkCellRenderer,
    ConditionalPureIconCellRenderer,
    TextFilterDefaults,
    ConditionalEditLinkCellRenderer,
    IndexCellRendererWithEdit,
    DateFilterDefaults,
    dateCellRenderer
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons'
import { PageHeading, PageWrap, SmallPrimaryButton } from '../common/forms/FormElements';
import { withRouter } from "react-router-dom";
import { LinearProgress } from '@material-ui/core';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import authService from '../api-authorization/AuthorizeService';
import { util } from '../Util';
import { Can } from '../Can';

class QuoteIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selectedRow: null
        }
        this.onEditQuote = this.onEditQuote.bind(this);
        this.onAddQuoteClicked = this.onAddQuoteClicked.bind(this);
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
        this.setState = (state, callback) => { return };
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {

            let gridOptions = createGridOptions(this);
            
            let [statusList, liftTypes] = await Promise.all([
                util.fetch.js(ApiRoutes.typeAheads.quoteDispositionStatuses()),
                util.fetch.js(ApiRoutes.typeAheads.getLiftTypes())
            ]);

            let statusFilterParams = {
                suppressFilterButton: true,
                labelText: 'Filter by Status',                
                options: [...new Set([
                    ...statusList.map((s) => { return { label: s.label, value: s.label } }),
                    { label: 'Open', value: 'Open' }])
                ],
                optionsLabel: 'label',
                optionsValue: 'value',
                initialFilterValue: 'Open'
            }

            let liftTypeFilterParams = {
                suppressFilterButton: true,
                labelText: 'Filter by Lift Type',
                options: liftTypes,
                optionsLabel: 'label',
                optionsValue: 'value',
            }

            gridOptions.components = {
                selectFilter: DataGridSelectFilter,
                selectFloatingFilter: DataGridSelectFloatingFilter,
                nameRenderer: LinkCellRenderer,
                iconRenderer: ConditionalPureIconCellRenderer,
                complianceAvailableRenderer: ConditionalEditLinkCellRenderer,
                indexCellRenderer: IndexCellRendererWithEdit
            };

            gridOptions.columnDefs = [
                {
                    flex: 0,
                    width: 100,
                    minWidth: 100,
                    maxWidth: 100,
                    headerName: "",
                    valueGetter: "node.id",
                    sortable: false,
                    cellStyle: { color: "rgba(0,0,0,0)" },
                    cellRenderer: 'indexCellRenderer',
                    cellRendererParams: {
                        clicked: this.onEditQuote,
                        nameField: 'name',
                        idField: 'id',
                        title: 'View or edit this quote'
                    }
                },
                {
                    colId: 'Number',
                    sortable: true,
                    headerName: 'Number',
                    field: 'number',
                    tooltipField: 'number',
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'SerialNumber',
                    sortable: true,
                    headerName: 'Serial Number',
                    field: 'serialNumber',
                    tooltipField: 'serialNumber',
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'LiftType.Name',
                    headerName: 'Lift Type',
                    field: 'liftTypeText',
                    sortable: true,
                    filter: 'selectFilter',
                    floatingFilter: true,
                    filterParams: liftTypeFilterParams,
                    floatingFilterComponent: 'selectFloatingFilter',
                    floatingFilterComponentParams: liftTypeFilterParams
                },
                {
                    colId: 'Model',
                    sortable: true,
                    headerName: 'Model',
                    field: 'model',
                    tooltipField: 'model',
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'ReceivedDate',
                    headerName: 'Received Date',
                    field: 'receivedDate',
                    sortable: true,
                    cellRenderer: dateCellRenderer,
                    maxWidth: 120,
                    minWidth: 120,
                    sort: 'asc', 
                    filter: 'agDateColumnFilter',
                    filterParams: DateFilterDefaults
                },
                {
                    colId: 'DueDate',
                    headerName: 'Due Date',
                    field: 'dueDate',
                    sortable: true,
                    cellRenderer: dateCellRenderer,
                    maxWidth: 120,
                    minWidth: 120,
                    sort: 'asc', 
                    filter: 'agDateColumnFilter',
                    filterParams: DateFilterDefaults
                },
                {
                    colId: 'Dealer.Customer_Name',
                    sortable: true,
                    headerName: 'Dealer',
                    field: 'quoteDealerCustomerName',
                    tooltipField: 'dealer',
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    }
                },
                {
                    colId: 'WorkedOnBy.Name',
                    sortable: true,
                    headerName: 'Salesman',
                    field: 'workedOnBy.name',
                    tooltipField: 'salesman',
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    }
                },
                {
                    colId: 'QuoteCustomer.Name',
                    sortable: true,
                    headerName: 'Customer',
                    field: 'quoteCustomer.name',
                    tooltipField: 'customer',
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    }
                },
                {
                    colId: 'Dealer.TerritoryName',
                    sortable: true,
                    headerName: 'Territory',
                    field: 'territoryName',
                    tooltipField: 'territory',
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    }
                },
                {
                    colId: 'PONumber',
                    sortable: true,
                    headerName: 'PO Number',
                    field: 'poNumber',
                    tooltipField: 'number',
                    sort: { direction: 'asc', priority: 0 },
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                    cellRendererParams: {
                        clicked: this.onEditQuote,
                        nameField: 'number',
                        idField: 'id',
                        title: 'View this Quote'
                    }
                },
                {
                    colId: 'QuoteDispositionStatus.Status',
                    headerName: 'Status',
                    field: 'quoteDispositionStatusName',
                    sortable: true,
                    filter: 'selectFilter',
                    floatingFilter: true,
                    filterParams: statusFilterParams,
                    floatingFilterComponent: 'selectFloatingFilter',
                    floatingFilterComponentParams: statusFilterParams
                }
            ];            

            gridOptions.useLoading = true;
            gridOptions.loadingTemplate = '<span className="ag-overlay-loading-center "><i class="faSaveSpinner fa fa-md fa-circle-notch"></i> Loading ...</span>';

            let ds = createDataSource(ApiRoutes.quote.search(), gridOptions);

            this.setState(state => {
                return {
                    loading: false,
                    gridOptions: gridOptions,
                    dataSource: ds
                }
            });
        }
    }

    getInitialListFiltersFromCache(initialFilters) {

        var json = sessionStorage.getItem("quote_gridFilterSettings"); //todo: change this

        if (!!json) {
            var obj = JSON.parse(json);

            initialFilters.groupId = !!obj.Group ? parseInt(obj.Group.value) : 0;
        }

        return initialFilters;
    }

    onAddQuoteClicked = () =>  this.props.history.push(`${AppNavPaths.QuoteNew}`);
    onEditQuote = (id) => this.props.history.push(`${AppNavPaths.Quote}/${id}`);

    render() {

        if (!!this.state.loading || !this.context?.permissions)
            return (<LinearProgress variant="indeterminate" color="secondary" />);

        const { rowData, gridOptions } = { ...this.state };

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon icon={faClipboardCheck} className="mr-2 text-muted" />
                    <span>Quotes</span>
                    <Can do="create" on={"quote"}>
                        <SmallPrimaryButton type="button" onClick={this.onAddQuoteClicked} className="btn-success float-right">
                            <i className={`fa fa-md mr-2 fa-user-plus`} />
                            Add Quote
                        </SmallPrimaryButton>
                    </Can>
                </PageHeading>
                <DataGridToolbar
                    entity="quote"
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                    hideAdd={true}
                    addIcon={'fa-user-plus'}
                    hideExcelButton={true}
                    gridOptions={this.state.gridOptions}
                    serverExport={{ apiPath: ApiRoutes.quote.excelExport(), filePrefix: 'QuoteSearch' }}
                    serverExportDisabled={!!this.state.saving || !!this.state.loading || !!this.state.loadingData}
                >
                </DataGridToolbar>
                <DataGrid
                    domLayout={"normal"}
                    rowData={rowData}
                    gridOptions={gridOptions}
                    gridStatus={this.state.gridStatus}
                />
            </PageWrap>
        );
    }
}

export default withRouter(QuoteIndex);