import React, { Fragment } from 'react';
import moment from 'moment';
import { BaseFormViewModel } from '../common/ViewModel';
import { DocumentTypes } from '../workflow/SalesOrderTracking';
import { CreateQuoteChangeOrderModel, QuoteChangeOrderDTO, QuoteEditDTO } from './Quote';
import { FormGroup, Button, Input, Row, Col } from 'reactstrap';
import {
    FormGroupColumn,
    FormLabel,
    GroupedRow,
    toasty,
    onFieldChange,
    AppPageForm
} from '../common/forms/FormElements';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';
import Dropzone from 'react-dropzone';
import FileLink from '../common/forms/FileLink';
import "react-datepicker/dist/react-datepicker.css";
import authService from '../api-authorization/AuthorizeService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';

export default class QuoteChangeOrderUploadForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        let stateBase = Object.assign(
            {
                changeOrderId: null,
                quoteChangeOrder: new QuoteChangeOrderDTO(),
                fileCreation: new CreateQuoteChangeOrderModel(),
                quoteEditDTO: new QuoteEditDTO(),
                loadError: false,
                allDealers: [],
                allCustomers: [],
                allQuoteCustomers: [],
                allLiftTypes: [],
                chosenDealer: null,
                chosenDealerSalesman: null
            },
            new BaseFormViewModel()
        );

        this.state = stateBase;
        this.handleSaveError = this.handleSaveError.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onChange = onFieldChange;

    componentDidMount = async () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps && (
            (this.props.match.params.coId !== (prevProps.match.params ?? {}).coId)
        )
        ) {
            this.populateState();
        }
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            const { coId } = { ...this.props.match.params };

            var quoteChangeOrder = new QuoteChangeOrderDTO();

            if (!!coId) {

                let { quoteEditDTO, chosenDealer, chosenDealerSalesman } = { ...this.state };

                var [
                    changeOrderResponse,
                    editstubResponse,
                    allDealers,
                    allQuoteCustomers,
                    allLiftTypes
                ] = await Promise.all([
                    util.fetch.get(ApiRoutes.quote.changeorder.byId(coId), util.fetch.format.none),
                    util.fetch.get(ApiRoutes.quote.changeorder.quoteEdit(coId), util.fetch.format.none),
                    util.fetch.js(ApiRoutes.quote.dealers()),
                    util.fetch.js(ApiRoutes.quote.quoteCustomerAll()),
                    util.fetch.js(ApiRoutes.typeAheads.getLiftTypes())
                ]);

                allLiftTypes = allLiftTypes.map((lt) => { return { value: parseInt(lt.value), label: lt.label }; });

                if (!changeOrderResponse.ok) {
                    //Handle erroneous links entered by the user
                    if (changeOrderResponse.status === 404)
                        this.props.history.push(AppNavPaths.NotFound);
                    else
                        this.props.history.push(AppNavPaths.ServerError);
                    return false;
                } else {
                    quoteChangeOrder = await changeOrderResponse.json();
                }

                if (!editstubResponse.ok) {
                    //Handle erroneous links entered by the user
                    if (editstubResponse.status === 404)
                        this.props.history.push(AppNavPaths.NotFound);
                    else
                        this.props.history.push(AppNavPaths.ServerError);
                    return false;
                } else {
                    let quoteEditDTOMid = await editstubResponse.json();
                    quoteEditDTO = quoteEditDTOMid.data;

                    chosenDealer = allDealers.find(d => d.customer_Id == quoteEditDTO.quoteDealerCustomerId);
                    chosenDealerSalesman = chosenDealer?.dealerSalesmen.find(ds => ds.id == quoteEditDTO.dealerSalesmanId);
                }

                await this.setState({
                    changeOrderId: coId,
                    quoteChangeOrder: quoteChangeOrder.data,
                    quoteEditDTO: quoteEditDTO,
                    allDealers: allDealers,
                    allQuoteCustomers: allQuoteCustomers,
                    allLiftTypes: allLiftTypes,
                    chosenDealer: chosenDealer,
                    chosenDealerSalesman: chosenDealerSalesman,
                    loading: false
                });
            } else {
                var err = { createQuoteChangeOrder: "Can't continue change order creation because the change order Id is missing" };
                await this.setState({
                    changeOrderId: coId,
                    errors: err,
                    loadError: true,
                    loading: false
                });
                //error
                toasty.error("There was an error creating the change order. See below");
            }
        }
    }

    handleSaveError = (err) => handleFormSaveError(this, err);

    onClearErrorNotificationClicked = e => {
        e.stopPropagation();
        this.setState({ errors: {} });
    }

    onClearWarningNotificationClicked = e => {
        e.stopPropagation();
        this.setState({ warnings: {} });
    }

    onCancelQuoteChangeOrder = async () => {
        await this.setState({ saving: true });
        this.setState((state) => { return { errors: {} }; });

        let { changeOrderId, errors } = { ...this.state };

        try {
            var cancel_resp = await util.fetch.put(
                ApiRoutes.quote.changeorder.cancel(changeOrderId),
                null,
                util.fetch.format.none //do this so we can properly handle the error
            );

            if (!!cancel_resp.ok) {
                let chngResp = await cancel_resp.json();
                if (!!(chngResp.message?.length ?? "")) {
                    errors.cancelQuoteChangeOrder = chngResp.message;
                    toasty.error("Change Order could not be cancelled.");
                    this.setState({ saving: false, errors: errors });
                } else {
                    this.props.history.push(`${AppNavPaths.Quote}/${chngResp.data}`)
                }
            }
            else {
                let resp = await cancel_resp.json()
                errors.createQuoteChangeOrder = resp.message;
                toasty.error("There was an error cancelling the change order. See below");
                this.setState({ saving: false, errors: errors });
            }
        } catch (ex) {
            console.error(ex);
            toasty.error("There was an issue cancelling the change order. Please try again or contact support for assistance.");
            this.setState({ saving: false });
        } 

    };

    onSubmit = async () => {
        await this.setState({ saving: true });
        this.setState((state) => { return { errors: {} }; });

        let { fileCreation, errors, changeOrderId, quoteEditDTO } = { ...this.state };

        //Adding by file
        try {

            let valid = true;

            if (!fileCreation.mountingInstructionsFile) {
                valid = false;
                errors.mountingInstructionsFile = "Must choose a mounting instruction file";
            }

            if (!fileCreation.workOrderFile) {
                valid = false;
                errors.workOrderFile = "Must choose a work order file";
            }

            if (!fileCreation.salesOrderFile) {
                valid = false;
                errors.salesOrderFile = "Must choose a Quote file";
            }

            if (!valid) {
                this.setState({ errors: errors });
                toasty.warning("Please review the form for validation errors");
            } else {

                await this.upload(
                    ApiRoutes.quote.upload(),
                    fileCreation.workOrderFile.file,
                    fileCreation.workOrderFileName,
                    (fileCreation.workOrderFileDescription ?? ""),
                    'wo'
                );

                await this.upload(
                    ApiRoutes.quote.upload(),
                    fileCreation.mountingInstructionsFile.file,
                    fileCreation.mountingInstructionsFileName,
                    (fileCreation.mountingInstructionsFileDescription ?? ""),
                    'mi'
                )

                await this.upload(
                    ApiRoutes.quote.upload(),
                    fileCreation.salesOrderFile.file,
                    fileCreation.salesOrderFileName,
                    (fileCreation.salesOrderFileDescription ?? ""),
                    'so'
                )

                var cff_resp = await util.fetch.post(
                    ApiRoutes.quote.changeorder.fromfiles(),
                    {
                        ChangeOrderId: changeOrderId,
                        SalesOrderDocumentId: fileCreation.salesOrderDocumentId,
                        WorkOrderDocumentId: fileCreation.workOrderDocumentId,
                        MountingInstructionsDocumentId: fileCreation.mountingInstructionsDocumentId,
                        QuoteEditDTO: quoteEditDTO
                    },
                    util.fetch.format.none //do this so we can properly handle the error
                );

                if (!!cff_resp.ok) {
                    let chngResp = await cff_resp.json();

                    if (!!(chngResp?.message?.length)) {
                        errors["Create Quote Change Order"] = chngResp.message;
                        this.setState({ errors: errors });
                        //error
                        toasty.error("There was an error creating the change order. See below");
                        this.setState({ saving: false });
                    } else {
                        toasty.success("Change Order Saved");
                        this.props.history.push(`${AppNavPaths.QuoteChangeOrder}/${chngResp.data}`)
                    }
                }
                else {
                    let resp = await cff_resp.json()
                    errors["Create Quote Change Order"] = resp.message;
                    this.setState({ errors: errors });
                    //error
                    toasty.error("There was an error creating the change order. See below");
                    this.setState({ saving: false });
                }
            }
        } catch (ex) {
            toasty.error("There was an issue creating the change order. Please try again or contact support for assistance.");
            this.setState({ saving: false });
        } 
    }

    upload = (url, file, name, description, type) => {

        return new Promise((resolve) => {

            let docType = 0;

            switch (type) {
                case 'wo':
                    docType = DocumentTypes.WorkOrder;
                    break;
                case 'mi':
                    docType = DocumentTypes.MountingInstructions;
                    break;
                case 'so':
                    docType = DocumentTypes.QuoteDocument;
                    break;
                default:
                    docType = DocumentTypes.WorkOrder;
                    break;
            }

            var formData = new FormData();
            formData.append('file', file, file.path);
            formData.append("documentName", name);
            formData.append("documentDescription", description);
            formData.append("documentTypeId", docType);

            var xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);

            let xsrfToken = util.getCookie('X-DAL-AF');
            xhr.setRequestHeader('X-CSRF-TOKEN', xsrfToken);

            xhr.upload.onprogress = (e) => this.onProgressUpdated(e, type);
            xhr.responseType = 'json';
            xhr.onload = () => {
                let { fileCreation } = { ...this.state };
                let docId = xhr.response;

                if (type === 'wo') {
                    fileCreation.workOrderDocumentId = docId;
                }

                if (type === 'mi') {
                    fileCreation.mountingInstructionsDocumentId = docId;
                }

                if (type === 'so') {
                    fileCreation.salesOrderDocumentId = docId;
                }

                this.setState({ fileCreation: fileCreation });
                resolve();
            };
            xhr.send(formData);
        });
    }

    remove = (designator) => {
        let { fileCreation } = { ...this.state };

        switch (designator) {
            case 'workOrder':
                fileCreation.workOrderFile = null;
                break;
            case 'mountInst':
                fileCreation.mountingInstructionsFile = null;
                break;
            case 'salesOrder':
                fileCreation.salesOrderFile = null;
                break;
            default:
                break;
        }
        this.setState({ fileCreation: fileCreation });
    }

    onProgressUpdated = (e, type) => {
        if (e.lengthComputable) {
            var percentage = Math.round((e.loaded / e.total) * 100);

            let { fileCreation } = { ...this.state };

            if (type === 'wo') {
                fileCreation.workOrderFile.progress = percentage;
            }

            if (type === 'mi') {
                fileCreation.mountingInstructionsFile.progress = percentage;
            }

            if (type === 'so') {
                fileCreation.salesOrderFile.progress = percentage;
            }

            this.setState({
                fileCreation: fileCreation
            });
        }
    }

    render() {
        let {
            fileCreation,
            quoteChangeOrder,
            formValidated,
            validationMessage,
            loading,
            saving,
            errors,
            warnings,
            loadError,
            allDealers,
            allQuoteCustomers,
            allLiftTypes,
            quoteEditDTO,
            chosenDealer,
            chosenDealerSalesman
        } = { ...this.state };

        const isDateBlank = (date) => date == null || date == '0001-01-01T00:00:00';

        const dueDateBlank = isDateBlank(quoteEditDTO.dueDate);

        const acceptProp = {
            'application/vnd.ms-excel': ['.xls', '.xlsx'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx']
        };

        return (
            <Fragment>
                <AppPageForm
                    formId={"quoteChangeOrderFromFilesForm"}
                    formHeadingIcon="fa-chart-line"
                    formHeading='Create Change Order'
                    formName={"quoteChangeOrderFromFilesForm"}
                    formRef={this.formRef}
                    onSubmit={this.onSubmit}
                    setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                    isValidated={formValidated}
                    saving={this.state.saving}
                    errors={errors}
                    warnings={warnings}
                    loading={loading}
                    onClearErrors={this.onClearErrorNotificationClicked}
                    onClearWarnings={this.onClearWarningNotificationClicked}
                    validationMessage={validationMessage}
                    showThisValidation={false}
                    ValidationBottom={true}
                >
                    {!!loadError &&
                        <div style={{ margin: "0 0.5em 0 0.5em", padding: '1em', }}>
                            <Row>
                                <Col>
                                    <p>Failed to Load. Please try again.</p>
                                </Col>
                            </Row>
                        </div>
                    }
                    {!!!loadError &&
                        <div style={{margin: "0 0.5em 0 0.5em",padding: '1em',}}>
                            <GroupedRow>
                                <FormGroupColumn>
                                    <FormGroup>
                                        <FormLabel htmlFor="quoteChangeOrder.quoteNumber" text="Quote Number" />
                                        <input
                                            autoComplete="off"
                                            name="quoteChangeOrder.quoteNumber"
                                            value={!!(quoteChangeOrder.quoteNumber) ? quoteChangeOrder.quoteNumber : "Not Assigned"}
                                            className="form-control"
                                            disabled={true}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel htmlFor="quoteChangeOrder.receivedDate" text="Received Date" />
                                        <input
                                            autoComplete="off"
                                            name="quoteChangeOrder.receivedDate"
                                            value={!!(quoteChangeOrder.receivedDate) ? quoteChangeOrder.receivedDate : "Not Received"}
                                            className="form-control"
                                            disabled={true}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel htmlFor="quoteEditDTO.dueDate" text="Due Date" />
                                        <DatePicker
                                            showIcon
                                            icon={faCalendar}
                                            className="form-control"
                                            id="quoteEditDTO.dueDate"
                                            name="quoteEditDTO.dueDate"                                            
                                            autoComplete='off'
                                            selected={dueDateBlank ? '' : moment(quoteEditDTO.dueDate).toDate()}
                                            onChange={(date) => { quoteEditDTO.dueDate = date; this.setState({ quoteEditDTO: quoteEditDTO }); }}
                                        />
                                        <small className="invalid-feedback text-danger" hidden>Due date is required.</small>
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel htmlFor="quoteEditDTO.quoteCustomerId" text="End User" />
                                        <ValidatedSelect
                                            id="quoteEditDTO.quoteCustomerId"
                                            isClearable={true}                                            
                                            validationMessage={"End User is required"}
                                            options={allQuoteCustomers}
                                            value={(allQuoteCustomers ?? []).find(s => s.id == quoteEditDTO.quoteCustomerId) ?? ''}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.id}
                                            onChange={(selection) => {
                                                quoteEditDTO.quoteCustomerId = selection?.id;
                                                this.setState({ quoteEditDTO: quoteEditDTO });
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel htmlFor="quoteEditDTO.liftTypeId" text="Lift Type" />
                                        <ValidatedSelect
                                            id="quoteEditDTO.liftTypeId"
                                            validationMessage={"Lift Type is required"}
                                            isClearable={true}
                                            options={allLiftTypes}
                                            isDisabled={true}
                                            value={(allLiftTypes ?? []).find(s => s.value == quoteEditDTO.liftTypeId) ?? ''}
                                            onChange={(selection) => {
                                                quoteEditDTO.liftTypeId = selection?.value;
                                                this.setState({ quoteEditDTO: quoteEditDTO });
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel htmlFor="quoteEditDTO.poNumber" text="PO Number" />
                                        <input
                                            className="form-control"
                                            id="quoteEditDTO.poNumber"
                                            name="quoteEditDTO.poNumber"
                                            value={!!(quoteEditDTO.poNumber) ? quoteEditDTO.poNumber : "Not Provided"}
                                            onChange={this.onChange}
                                        />
                                    </FormGroup>
                                </FormGroupColumn>
                                <FormGroupColumn>
                                    <FormGroup>
                                        <FormLabel htmlFor="quoteChangeOrder.serialNumber" text="Serial Number" />
                                        <input
                                            autoComplete="off"
                                            name="quoteChangeOrder.serialNumber"
                                            value={!!(quoteChangeOrder.serialNumber) ? quoteChangeOrder.serialNumber : "Not Assigned"}
                                            className="form-control"
                                            disabled={true}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel htmlFor="quoteChangeOrder.modelNumber" text="Model" />
                                        <input
                                            autoComplete="off"
                                            id="quoteChangeOrder.modelNumber"
                                            name="quoteChangeOrder.modelNumber"
                                            defaultValue={quoteChangeOrder.modelNumber}
                                            className="form-control"
                                            disabled={true}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel htmlFor="quoteEditDTO.quoteDealerCustomerId" text="Dealer" />
                                        <ValidatedSelect
                                            autoComplete="on"
                                            id="quoteEditDTO.quoteDealerCustomerId"
                                            validationMessage={"Dealer is Required"}
                                            name="quoteEditDTO.quoteDealerCustomerId"
                                            options={allDealers}
                                            isClearable={true}
                                            value={(allDealers ?? []).find(s => s.customer_Id == (quoteEditDTO?.quoteDealerCustomerId ?? 0)) ?? ''}
                                            getOptionLabel={option => option.customer_Name}
                                            getOptionValue={option => option.customer_Id}
                                            onChange={(selection) => {
                                                quoteEditDTO.quoteDealerCustomerId = selection?.customer_Id;
                                                chosenDealer = selection;
                                                this.setState({
                                                    quoteEditDTO: quoteEditDTO,
                                                    chosenDealer: chosenDealer,
                                                    chosenDealerSalesman: null
                                                });
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel htmlFor="territoryName" text="Territory" />
                                        <input
                                            id="territoryName"
                                            name="territoryName"
                                            value={chosenDealer?.territoryName ?? ''}
                                            className="form-control"
                                            disabled={true}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel htmlFor="quoteEditDTO.dealerSalesmanId" text="Dealer Salesman" />
                                        <ValidatedSelect
                                            id="quoteEditDTO.quoteDealerSalesmanId"
                                            validationMessage={"Dealer Salesman is Required"}
                                            name="quoteEditDTO.dealerSalesmanId"
                                            isClearable={true}
                                            options={chosenDealer?.dealerSalesmen ?? []}
                                            value={(chosenDealer?.dealerSalesmen ?? []).find(s => s.id == (quoteEditDTO.dealerSalesmanId ?? 0)) ?? ''}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.id}
                                            onChange={(selection) => {
                                                quoteEditDTO.dealerSalesmanId = selection?.id;
                                                chosenDealerSalesman = selection;
                                                this.setState({
                                                    quoteEditDTO: quoteEditDTO,
                                                    chosenDealerSalesman: chosenDealerSalesman
                                                });
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel htmlFor="chosenDealerSalesman.email" text="Dealer Salesman Email" />
                                        <input
                                            className="form-control"
                                            id="chosenDealerSalesman.email"
                                            name="chosenDealerSalesman.email"
                                            value={chosenDealerSalesman?.email ?? ""}
                                            onChange={() => { }}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </FormGroupColumn>
                            </GroupedRow>
                                
                            {/*Sales Order  file*/}
                            <Row style={{
                                margin: "1em 0.5em 0 0.5em",
                                borderTop: "2px solid lightgray",
                                borderLeft: "2px solid lightgray",
                                padding: '1em',
                                borderRight: '2px solid lightgray'
                            }} >
                                <Col>
                                    <FormGroup>
                                        <FormLabel htmlFor="salesOrderFileName"
                                            text="Quote File Name"
                                            required={true}
                                        />
                                        <Input
                                            required={true}
                                            id="salesOrderFileName"
                                            name="salesOrderFileName"
                                            className="form-control"
                                            value={fileCreation.salesOrderFileName ?? ''}
                                            onChange={ev => {
                                                const val = ev?.target?.value;
                                                fileCreation.salesOrderFileName = val;
                                                this.setState({ fileCreation: fileCreation });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <FormLabel htmlFor="salesOrderFileDescription"
                                            text="Quote File Description"
                                        />
                                        <Input
                                            type="textarea"
                                            name="salesOrderFileDescription"
                                            value={fileCreation.salesOrderFileDescription ?? ''}
                                            onChange={(evt) => {
                                                let value = evt.target.value;
                                                fileCreation.salesOrderFileDescription = value;
                                                this.setState({ fileCreation: fileCreation });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row style={{
                                margin: "0 0.5em 0 0.5em",
                                borderBottom: "2px solid lightgray",
                                borderLeft: "2px solid lightgray",
                                padding: '1em',
                                borderRight: '2px solid lightgray'
                            }}>
                                <Col>
                                    <Dropzone
                                        /*Only Accept excel files*/
                                        accept={acceptProp}
                                        multiple={false}
                                        onDrop={(acceptedFiles) => {
                                            let { fileCreation, saving } = { ...this.state };

                                            if (!!saving) {
                                                return false;
                                            }

                                            //We are only uploading a single file at a time and only the first in the list
                                            fileCreation.salesOrderFile = { file: acceptedFiles[0], progress: 0.0 };
                                            if (!(fileCreation.salesOrderFileName.length)) {
                                                //No file name assigned so make it the same as this file's name
                                                fileCreation.salesOrderFileName = acceptedFiles[0].name;
                                            }

                                            this.setState({ fileCreation: fileCreation });
                                        }}
                                        required={true}
                                        inputContent={(files, extra) => (extra.reject ? 'File type not permitted.' : 'Drag and drop your sales order file here, or click to select a file')}
                                        maxSize={25000000} /*25MB application wide*/
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <>
                                                {
                                                    !!(fileCreation.salesOrderFile) &&
                                                    <div className="file-uploads-preview">
                                                        <FileLink
                                                            preview={true}
                                                            showFileSize={true}
                                                            key={fileCreation.salesOrderFile.url}
                                                            url={'#'}
                                                            file={fileCreation.salesOrderFile.file}
                                                            remove={() => this.remove('salesOrder')}
                                                            progress={fileCreation.salesOrderFile.progress}
                                                        />
                                                    </div>
                                                }
                                                {!!!(fileCreation.salesOrderFile) &&
                                                    <section className="file-upload-section" hidden={!!saving}>
                                                        <div {...getRootProps({ className: 'dropzone' })} className="file-upload-section-inner">
                                                            <input {...getInputProps()} />
                                                            <span className="border-bottom mb-2">Drag and drop your quote file here, or click to select a file.</span>
                                                            <small>Supported file types:<span className="ml-2 text-success">{"MS Excel Files (.xls, .xlsx)"}</span></small>
                                                        </div>
                                                    </section>
                                                }
                                            </>
                                        )}
                                    </Dropzone>
                                </Col>
                            </Row>
                            {/*Work Order file*/}
                            <Row style={{
                                margin: "1em 0.5em 0 0.5em",
                                borderTop: "2px solid lightgray",
                                borderLeft: "2px solid lightgray",
                                padding: '1em',
                                borderRight: '2px solid lightgray'
                            }}>
                                <Col>
                                    <FormGroup>
                                        <FormLabel htmlFor="workOrderFileName"
                                            text="Work Order File Name"
                                            required={true} />
                                        <Input
                                            required={true}
                                            id="workOrderFileName"
                                            name="workOrderFileName"
                                            className="form-control"
                                            value={fileCreation.workOrderFileName ?? ''}
                                            onChange={ev => {
                                                const val = ev?.target?.value;
                                                fileCreation.workOrderFileName = val;
                                                this.setState({ fileCreation: fileCreation });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <FormLabel htmlFor="workOrderFileDescription"
                                            text="Work Order File Description"
                                        />
                                        <Input
                                            type="textarea"
                                            name="workOrderFileDescription"
                                            value={fileCreation.workOrderFileDescription ?? ''}
                                            onChange={(evt) => {
                                                let value = evt.target.value;
                                                fileCreation.workOrderFileDescription = value;
                                                this.setState({ fileCreation: fileCreation });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row style={{
                                margin: "0 0.5em 0 0.5em",
                                borderBottom: "2px solid lightgray",
                                borderLeft: "2px solid lightgray",
                                padding: '1em',
                                borderRight: '2px solid lightgray'
                            }}>
                                <Col>
                                    <Dropzone
                                        /*Only Accept excel files*/
                                        accept={acceptProp}
                                        multiple={false}
                                        onDrop={(acceptedFiles) => {
                                            let { fileCreation, saving } = { ...this.state };

                                            if (!!saving) {
                                                return false;
                                            }

                                            //We are only uploading a single file at a time and only the first in the list
                                            fileCreation.workOrderFile = { file: acceptedFiles[0], progress: 0.0 };
                                            fileCreation.workOrderFileName = acceptedFiles[0].name; //Always overwrite file name on drop

                                            this.setState({ fileCreation: fileCreation });
                                        }}
                                        inputContent={(files, extra) => (extra.reject ? 'File type not permitted.' : 'Drag and drop your work order file here, or click to select a file')}
                                        maxSize={25000000} /*25MB application wide*/
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <>
                                                {
                                                    !!(fileCreation.workOrderFile) &&
                                                    <div className="file-uploads-preview">
                                                        <FileLink
                                                            preview={true}
                                                            showFileSize={true}
                                                            key={fileCreation.workOrderFile.file.path}
                                                            url={'#'}
                                                            file={fileCreation.workOrderFile.file}
                                                            remove={() => this.remove('workOrder')}
                                                            progress={fileCreation.workOrderFile.progress}
                                                        />
                                                    </div>
                                                }
                                                {!!!(fileCreation.workOrderFile) &&
                                                    <section className="file-upload-section" hidden={!!saving}>
                                                        <div {...getRootProps({ className: 'dropzone' })} className="file-upload-section-inner">
                                                            <input {...getInputProps()} />
                                                            <small className="invalid-feedback text-danger" hidden>Work Order file is required.</small>
                                                            <span className="border-bottom mb-2">Drag and drop your work order file here, or click to select a file.</span>
                                                            <small>Supported file types:<span className="ml-2 text-success">{"MS Excel Files (.xls, .xlsx)"}</span></small>
                                                        </div>
                                                    </section>
                                                }
                                            </>
                                        )}
                                    </Dropzone>
                                </Col>
                            </Row>
                            {/*Mounting Instructions file*/}
                            <Row style={{
                                margin: "1em 0.5em 0 0.5em",
                                borderTop: "2px solid lightgray",
                                borderLeft: "2px solid lightgray",
                                padding: '1em',
                                borderRight: '2px solid lightgray'
                            }}>
                                <Col>
                                    <FormGroup>
                                        <FormLabel htmlFor="mountingInstructionsFileName"
                                            text="Mounting Instructions File Name"
                                            required={true} />
                                        <Input
                                            required={true}
                                            id="mountingInstructionsFileName"
                                            name="mountingInstructionsFileName"
                                            className="form-control"
                                            value={fileCreation.mountingInstructionsFileName ?? ''}
                                            onChange={ev => {
                                                const val = ev?.target?.value;
                                                fileCreation.mountingInstructionsFileName = val;
                                                this.setState({ fileCreation: fileCreation });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <FormLabel htmlFor="mountingInstructionsFileDescription"
                                            text="Mounting Instructions File Description"
                                        />
                                        <Input
                                            type="textarea"
                                            name="mountingInstructionsFileDescription"
                                            value={fileCreation.mountingInstructionsFileDescription ?? ''}
                                            onChange={(evt) => {
                                                let value = evt.target.value;
                                                fileCreation.mountingInstructionsFileDescription = value;
                                                this.setState({ fileCreation: fileCreation });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row style={{
                                margin: "0 0.5em 0 0.5em",
                                borderBottom: "2px solid lightgray",
                                borderLeft: "2px solid lightgray",
                                padding: '1em',
                                borderRight: '2px solid lightgray'
                            }}>
                                <Col>
                                    <Dropzone
                                        /*Only Accept excel files*/
                                        accept={acceptProp}
                                        multiple={false}
                                        onDrop={(acceptedFiles) => {
                                            let { fileCreation, saving } = { ...this.state };

                                            if (!!saving) {
                                                return false;
                                            }

                                            //We are only uploading a single file at a time and only the first in the list
                                            fileCreation.mountingInstructionsFile = { file: acceptedFiles[0], progress: 0.0 };
                                            fileCreation.mountingInstructionsFileName = acceptedFiles[0].name;

                                            this.setState({ fileCreation: fileCreation });
                                        }}
                                        inputContent={(files, extra) => (extra.reject ? 'File type not permitted.' : 'Drag and drop your mounting instructions file here, or click to select a file')}
                                        maxSize={25000000} /*25MB application wide*/
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <>
                                                {
                                                    !!(fileCreation.mountingInstructionsFile) &&
                                                    <div className="file-uploads-preview">
                                                        <FileLink
                                                            preview={true}
                                                            showFileSize={true}
                                                            key={fileCreation.mountingInstructionsFile.file.path}
                                                            url={'#'}
                                                            file={fileCreation.mountingInstructionsFile.file}
                                                            remove={() => this.remove('mountInst')}
                                                            progress={fileCreation.mountingInstructionsFile.progress}
                                                        />
                                                    </div>
                                                }
                                                {!!!(fileCreation.mountingInstructionsFile) &&
                                                    <section className="file-upload-section" hidden={!!saving}>
                                                        <div {...getRootProps({ className: 'dropzone' })} className="file-upload-section-inner">
                                                            <input {...getInputProps()} />
                                                            <small className="invalid-feedback text-danger" hidden>Mounting Instructions file is required.</small>
                                                            <span className="border-bottom mb-2">Drag and drop your mounting instructions file here, or click to select a file.</span>
                                                            <small>Supported file types:<span className="ml-2 text-success">{"MS Excel Files (.xls, .xlsx)"}</span></small>
                                                        </div>
                                                    </section>
                                                }
                                            </>
                                        )}
                                    </Dropzone>
                                </Col>
                            </Row>
                            <Row style={{ padding: '1em' }}>
                                <Col sm={6} md={4} lg={4} >
                                    <FormGroup>
                                        <Button type="button"
                                            className="mr-3"
                                            color="success"
                                            disabled={!!saving}
                                            onClick={this.onSubmit}>
                                            <FontAwesomeIcon size="lg" icon={saving ? "fa-circle-notch" : "fa-save"} className={saving ? "mr-2 faSaveSpinner" : "mr-2"} />
                                            {saving ? 'Saving, Please Wait...' : 'Create Change Order'}
                                        </Button>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} md={4} lg={4} >
                                    <FormGroup>
                                        <Button type="button"
                                            className="mr-3"
                                            disabled={!!saving}
                                            onClick={() => this.onCancelQuoteChangeOrder()}>
                                            <FontAwesomeIcon size="lg" icon={"fa-times"} className="mr-2" />
                                            {"Cancel"}
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    }
                </AppPageForm>
            </Fragment>
        );
    };
}