export class QuoteCustomer {
    id = null;
    name = '';
}

export class WorkedOnBySalesman {
    id = null;
    name = '';
}

export class Quote {
    id = null;
    number = 0;
    poNumber = null;
    receivedDate = null;
    dueDate = null;
    serialNumber = "";
    quoteCustomer = new QuoteCustomer();
    quoteDealerCustomerId = "";
    quoteDealerCustomerName = "";
    territoryName = "";
    dealerSalesmanId = null;
    quoteFile = null;
    quoteFileName = null;
    quoteFileDesc = null;
    quoteDocumentId = null;
    quoteFileChanged = null;
    quoteDispositionStatus_Id = null;
    model = "";
    workedOnBy = new WorkedOnBySalesman();
    completionDate = null;
    salesOrderTracking_Id = null;
    liftTypeId = null;
    liftTypeText = "";
    hasOpenChangeOrder = 0;
}

export class CreateQuoteChangeOrderModel {
    changeOrderId = 0;

    quoteId = 0;
    salesOrderTrackingId = 0;

    salesOrderFileName = "";
    salesOrderFileDescription = "";
    salesOrderFile = null;
    salesOrderDocumentId = 0;

    workOrderFileName = "";
    workOrderFileDescription = "";
    workOrderFile = null;
    workOrderDocumentId = 0;

    mountingInstructionsFileName = "";
    mountingInstructionsFileDescription = "";
    mountingInstructionsFile = null;
    mountingInstructionsDocumentId = 0;

}

export class QuoteChangeOrderDTO {
    id = 0;
    quoteId = 0;
    status = "";
    previousQuoteNumber = "";
    quoteNumber = "";
    serialNumber = "";
    numberOfChanges = 0;
    createdOn = "";

    changes = [];
}

export class QuoteChangeOrderChangeDTO {
    id = 0;
    changeOrderId = 0;
    changeType = "";
    componentChanged = "";
    value = "";
}

export class QuoteEditDTO {
    pONumber = ""; //string
    dueDate = null //DateTime
    quoteCustomerId = 0;
    quoteDealerCustomerId = "";
    dealerSalesmanId = 0;
    liftTypeId = 0;
    workedOnBySalesmanId = 0;
}