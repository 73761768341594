import React, { Component } from "react";
import CacheBuster from "./CacheBuster";

//Routing
import { Route, withRouter, Switch } from "react-router";
import { Layout } from "./components/Layout";

import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants";

//Permissions
import { AbilityContext } from "./components/Can";
import { ApiRoutes } from "./components/Common";
import defineAbilityFor from "./AppAbility";
import { util } from "./components/Util";
import { AppNavPaths } from "./components/Common";

//Style
import "./style/App.scss";

//Components
import EmployeeForm from './components/employee/EmployeeForm';
import EmployeeIndex from './components/employee/EmployeeIndex';
import EmployeeAccountStatusIndex from './components/employeeAccountStatus/EmployeeAccountStatusIndex';
import WorkCenterForm from "./components/workCenter/WorkCenterForm";
import WorkCenterIndex from "./components/workCenter/WorkCenterIndex";
import WorkFlowStatusTagsIndex from "./components/workflow/WorkFlowStatusTagsIndex";
import WorkFlowStatusTagForm from "./components/workflow/WorkFlowStatusTagForm";
import QuoteIndex from "./components/quote/QuoteIndex";
import QuoteForm from "./components/quote/QuoteForm";
import QuoteChangeOrderUploadForm from "./components/quote/QuoteChangeOrderUpload";
import QuoteChangeOrderIndex from "./components/quote/QuoteChangeOrderIndex";
import QuoteChangeOrderForm from "./components/quote/QuoteChangeOrderForm";
import GroupIndex from './components/group/GroupIndex';
import GroupForm from './components/group/GroupForm';
import UserGroupIndex from './components/userGroup/UserGroupIndex';
import UserGroupForm from './components/userGroup/UserGroupForm'
import RoleIndex from './components/role/RoleIndex';
import RoleForm from './components/role/RoleForm';
import PermissionIndex from './components/permission/PermissionIndex';
import PermissionForm from './components/permission/PermissionForm';
import authService from './components/api-authorization/AuthorizeService';
import NotFound from './components/api-authorization/NotFound';
import { ServerError } from './components/api-authorization/ServerError';
import { NotAuthorizedPage } from './components/status/StatusCodes';
import { NoPermission } from './components/api-authorization/NoPermission';
import Dashboard from './Dashboard';
import SalesOrderTrackingSearch from './components/workflow/SalesOrderTrackingSearch';
import SalesOrderTrackingForm from './components/workflow/SalesOrderTrackingForm';
import WorkFlowWorkCenterGrid from './components/workflow/WorkFlowWorkCenterGrid';
import FormsSearch from './components/formsAndSurveys/FormsSearch';
import FormsForm from './components/formsAndSurveys/FormsForm';
import UnitOfMeasurementForm from './components/formsAndSurveys/UnitOfMeasurementForm';
import UnitsOfMeasurementSearch from './components/formsAndSurveys/UnitOfMeasurementSearch';
import FormBuilder from './components/formsAndSurveys/FormBuilder';
import QuestionOptionGroupOptionsSearch from './components/formsAndSurveys/QuestionOptionGroupOptionSearch';
import QuestionOptionGroupOptionsForm from './components/formsAndSurveys/QuestionOptionGroupOptionsForm';
import QuestionOptionGroupSearch from './components/formsAndSurveys/QuestionOptionGroupSearch';
import QuestionOptionGroupForm from './components/formsAndSurveys/QuestionOptionGroupForm';
import QuestionsSearch from './components/formsAndSurveys/QuestionsSearch';
import QuestionsForm from './components/formsAndSurveys/QuestionsForm';
import WorkCenterMap from './components/workCenter/WorkCenterMap';
import WorkCenterDiagram from './components/workCenter/WorkCenterDiagram';
import QuoteCustomerIndex from "./components/quote/QuoteCustomerIndex";
import QuoteCustomerForm from "./components/quote/QuoteCustomerForm";
import WorkedOnByIndex from "./components/quote/WorkedOnByIndex";
import WorkedOnByForm from "./components/quote/WorkedOnByForm";
import WorkFlowSalesOrderTrackingForm from './components/workflow/WorkFlowSalesOrderTrackingForm';
import FormFiller from './components/workflow/WorkFlowSalesOrderTrackingSurveyForm';
import OpenIssueSearch from './components/workflow/OpenIssueSearch';
import OpenIssueForm from './components/workflow/OpenIssueForm';
import MutableSettingsForm from "./components/administration/MutableSettingsForm";
import SalesOrderTrackingEngineerChangeOrderForm from "./components/workflow/SalesOrderTrackingEngineerChangeOrderForm";
import CameraTest from "./components/common/CameraTest";
import ShippingSearch from "./components/workflow/ShippingSearch";
import ShippingForm from "./components/workflow/ShippingForm";
class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = {
            permissions: [],
            tenant: {},
            ready: false,
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    checkUserHasPermission = (permission) => {
        const { permissions } = { ...this.state };
        const hasPerm = !!permissions
            .map((p) => `${p.permission}.${p.action}`).includes(permission);
        return hasPerm;
    };

    populateState = async () => {
        const isAuthenticated = await authService.isAuthenticated();
        //When auth'd, populate the permissions.
        //Used in ability permissions context app-wide, starting with Routes below.
        if (!!isAuthenticated) {
            const [permissions] = await Promise.all([
                util.fetch.js(ApiRoutes.auth.permissions()),
            ]);

            await fetch("/api/v1/antiforgery/set", {
                credentials: "same-origin"
            });

            this.setState({ permissions: permissions, ready: true });
        } else {
            this.setState({ permissions: null, ready: true });
        }
    };

    render() {

        return (
            <CacheBuster>
                {({
                    loading,
                    isLatestVersion,
                    refreshCacheAndReload,
                    currentVersion,
                }) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        refreshCacheAndReload();
                    }
                    const { ready } = this.state;
                    if (ready) {
                        return (
                            <AbilityContext.Provider
                                value={defineAbilityFor(this.state.permissions)}
                            >
                                <Layout
                                    permissions={this.state.permissions}
                                    permissionChecker={this.checkUserHasPermission}
                                    version={currentVersion}
                                >
                                    <Switch>
                                        <AuthorizeRoute exact path='/' component={Dashboard} />

                                        <AuthorizeRoute exact perm="role" action="edit" path={`${AppNavPaths.MutableSettings}`} component={MutableSettingsForm} />
                                        <AuthorizeRoute exact path={`${AppNavPaths.CameraTest}`} component={CameraTest} />
                                        {/* Employee */}
                                        <AuthorizeRoute exact perm="employee" action="view" path={`${AppNavPaths.Employee}/:id`} component={EmployeeForm} />
                                        <AuthorizeRoute exact perm="employee" action="create" path={`${AppNavPaths.EmployeeNew}`} component={EmployeeForm} />
                                        <AuthorizeRoute exact perm="employee" action="view" path={`${AppNavPaths.Employees}`} component={EmployeeIndex} />
                                        <AuthorizeRoute exact perm="employeeaccountstatus" action="view" path={`${AppNavPaths.EmployeeAccountStatuses}`} component={EmployeeAccountStatusIndex} />

                                        {/* Work Centers */}
                                        <AuthorizeRoute exact perm="work_center" action="view" path={`${AppNavPaths.WorkCenters}`} component={WorkCenterIndex} />
                                        <AuthorizeRoute exact perm="work_center" action="view" path={`${AppNavPaths.WorkCenter}/:id`} component={WorkCenterForm} />
                                        <AuthorizeRoute exact perm="work_center" action="create" path={`${AppNavPaths.WorkCenterNew}`} component={WorkCenterForm} />
                                        <AuthorizeRoute exact perm="work_center" action="view" path={`${AppNavPaths.WorkCenterMap}`} component={WorkCenterMap} />
                                        <AuthorizeRoute exact perm="work_center" action="view" path={`${AppNavPaths.WorkCenterDiagram}`} component={WorkCenterDiagram} />
                                        <Route exact path={`${AppNavPaths.WorkCenterWorkFlow}/:id`} component={WorkFlowWorkCenterGrid} /> 
                                        <Route exact path={`${AppNavPaths.WorkCenterWorkFlow}/:wcid/:sotid`} component={WorkFlowSalesOrderTrackingForm} />
                                        <Route exact path={`${AppNavPaths.Shipping}/:wcid/:shpid`} component={WorkFlowSalesOrderTrackingForm} /> 
                                        <Route exact path={`${AppNavPaths.Shipping}/:wcid/:shpid/:sotsid`} component={FormFiller} /> 
                                        <Route exact path={`${AppNavPaths.WorkCenterWorkFlow}/:wcid/:sotid/:sotsid`} component={FormFiller} />
                                        <Route exact path={`${AppNavPaths.SalesOrderTrackingSurvey}/:wcid/:sotid/:sotsid`} component={FormFiller} /> 

                                        {/* Sales Order Tracking */}
                                        <AuthorizeRoute exact perm="sales_order_tracking" action="create" path={`${AppNavPaths.SalesOrderTrackingNew}/:addByMethod`} component={SalesOrderTrackingForm} />
                                        <AuthorizeRoute exact perm="sales_order_tracking" action="create" path={`${AppNavPaths.SalesOrderTrackingNew}/:addByMethod/:quoteId`} component={SalesOrderTrackingForm} />
                                        <AuthorizeRoute exact perm="sales_order_tracking" action="view" path={`${AppNavPaths.SalesOrderTracking}`} component={SalesOrderTrackingSearch} />
                                        <AuthorizeRoute exact perm="sales_order_tracking" action="view" path={`${AppNavPaths.SalesOrderTracking}/:id`} component={SalesOrderTrackingForm} />
                                        <AuthorizeRoute exact perm="engineer_change_order" action="create" path={`${AppNavPaths.SalesOrderTrackingEngineerChangeOrder}/:id`} component={SalesOrderTrackingEngineerChangeOrderForm} />
                                        <AuthorizeRoute exact perm="engineer_change_order" action="create" path={`${AppNavPaths.SalesOrderTrackingEngineerChangeOrder}/:id/:rwcId`} component={SalesOrderTrackingEngineerChangeOrderForm} />

                                        {/* Shipping */}
                                        <AuthorizeRoute exact perm="shipping" action="view" path={`${AppNavPaths.Shipping}`} component={ShippingSearch} />
                                        <AuthorizeRoute exact perm="shipping" action="view" path={`${AppNavPaths.Shipping}/:id`} component={ShippingForm} />
                                        <AuthorizeRoute exact perm="shipping" action="create" path={`${AppNavPaths.ShippingCreate}`} component={ShippingForm} />

                                        {/* Work Flow Status Tags */}
                                        <AuthorizeRoute exact perm="work_flow_status_tag" action="view" path={`${AppNavPaths.WorkFlowStatusTags}`} component={WorkFlowStatusTagsIndex} />
                                        <AuthorizeRoute exact perm="work_flow_status_tag" action="view" path={`${AppNavPaths.WorkFlowStatusTag}/:id`} component={WorkFlowStatusTagForm} />
                                        <AuthorizeRoute exact perm="work_flow_status_tag" action="create" path={`${AppNavPaths.WorkFlowStatusTagNew}`} component={WorkFlowStatusTagForm} />

                                        {/* Quotes */}
                                        <AuthorizeRoute exact perm="quote" action="view" path={`${AppNavPaths.Quotes}`} component={QuoteIndex} />
                                        <AuthorizeRoute exact perm="quote" action="view" path={`${AppNavPaths.Quote}/:id`} component={QuoteForm} />
                                        <AuthorizeRoute exact perm="quote" action="create" path={`${AppNavPaths.QuoteNew}`} component={QuoteForm} />

                                        <AuthorizeRoute exact perm="quote_customers" action="view" path={`${AppNavPaths.QuoteCustomers}`} component={QuoteCustomerIndex} />
                                        <AuthorizeRoute exact perm="quote_customers" action="view" path={`${AppNavPaths.QuoteCustomer}/:id`} component={QuoteCustomerForm} />
                                        <AuthorizeRoute exact perm="quote_customers" action="create" path={`${AppNavPaths.QuoteCustomerNew}`} component={QuoteCustomerForm} />

                                        <AuthorizeRoute exact perm="quote_worked_on_by" action="view" path={`${AppNavPaths.QuotesWorkedOnBy}`} component={WorkedOnByIndex} />
                                        <AuthorizeRoute exact perm="quote_worked_on_by" action="view" path={`${AppNavPaths.QuoteWorkedOnBy}/:id`} component={WorkedOnByForm} />
                                        <AuthorizeRoute exact perm="quote_worked_on_by" action="create" path={`${AppNavPaths.QuoteWorkedOnByNew}`} component={WorkedOnByForm} />

                                        <AuthorizeRoute exact perm="quote_change_order" action="create" path={`${AppNavPaths.QuoteChangeOrderNew}/:coId`} component={QuoteChangeOrderUploadForm} />
                                        <AuthorizeRoute exact perm="quote_change_order" action="view" path={`${AppNavPaths.QuoteChangeOrders}`} component={QuoteChangeOrderIndex} />
                                        <AuthorizeRoute exact perm="quote_change_order" action="view" path={`${AppNavPaths.QuoteChangeOrder}/:id`} component={QuoteChangeOrderForm} />
                                        
                                        {/* Permissions */}
                                        <AuthorizeRoute exact perm="group" action="view" path={`${AppNavPaths.Groups}`} component={GroupIndex} />
                                        <AuthorizeRoute exact perm="group" action="view" path={`${AppNavPaths.Group}/:id`} component={GroupForm} />
                                        <AuthorizeRoute exact perm="group" action="create" path={`${AppNavPaths.GroupNew}`} component={GroupForm} />
                                        <AuthorizeRoute exact perm="user_group" action="view" path={`${AppNavPaths.UserGroups}`} component={UserGroupIndex} />
                                        <AuthorizeRoute exact perm="user_group" action="view" path={`${AppNavPaths.UserGroup}/:id`} component={UserGroupForm} />
                                        <AuthorizeRoute exact perm="user_group" action="create" path={`${AppNavPaths.UserGroupNew}`} component={UserGroupForm} />
                                        <AuthorizeRoute exact perm="role" action="view" path={`${AppNavPaths.Roles}`} component={RoleIndex} />
                                        <AuthorizeRoute exact perm="role" action="view" path={`${AppNavPaths.Role}/:id`} component={RoleForm} />
                                        <AuthorizeRoute exact perm="role" action="create" path={`${AppNavPaths.RoleNew}`} component={RoleForm} />
                                        <AuthorizeRoute exact perm="permission" action="view" path={`${AppNavPaths.Permissions}`} component={PermissionIndex} />
                                        <AuthorizeRoute exact perm="permission" action="view" path={`${AppNavPaths.Permission}/:id`} component={PermissionForm} />
                                        <AuthorizeRoute exact perm="permission" action="create" path={`${AppNavPaths.PermissionNew}`} component={PermissionForm} />

                                        {/* Forms and Questions */}
                                        <AuthorizeRoute exact perm="questionoption" action="view" path={`${AppNavPaths.QuestionOptions}/:id`} component={QuestionOptionGroupOptionsForm} />
                                        <AuthorizeRoute exact perm="questionoption" action="view" path={`${AppNavPaths.QuestionOptions}`} component={QuestionOptionGroupOptionsSearch} />

                                        <AuthorizeRoute exact perm="questionoption" action="view" path={`${AppNavPaths.QuestionGroups}`} component={QuestionOptionGroupSearch} />
                                        <AuthorizeRoute exact perm="questionoption" action="view" path={`${AppNavPaths.QuestionGroups}/:id`} component={QuestionOptionGroupForm} />

                                        <AuthorizeRoute exact perm="question" action="view" path={`${AppNavPaths.Questions}`} component={QuestionsSearch} />
                                        <AuthorizeRoute exact perm="question" action="view" path={`${AppNavPaths.Questions}/:id`} component={QuestionsForm} />

                                        <AuthorizeRoute exact perm="unitofmeasure" action="view" path={`${AppNavPaths.UnitsOfMeasurement}`} component={UnitsOfMeasurementSearch} />
                                        <AuthorizeRoute exact perm="unitofmeasure" action="view" path={`${AppNavPaths.UnitsOfMeasurement}/:id`} component={UnitOfMeasurementForm} />

                                        <AuthorizeRoute exact perm="forms" action="edit" path={`${AppNavPaths.FormsBuild}/:id`} component={FormBuilder} />

                                        <AuthorizeRoute exact perm="forms" action="view" path={`${AppNavPaths.Forms}`} component={FormsSearch} />
                                        <AuthorizeRoute exact perm="forms" action="view" path={`${AppNavPaths.Forms}/:id`} component={FormsForm} />

                                        <AuthorizeRoute exact perm="issuetracking" action="view" path={`${AppNavPaths.IssueTracking}`} component={OpenIssueSearch} />
                                        <AuthorizeRoute exact perm="issuetracking" action="view" path={`${AppNavPaths.IssueTracking}/:id`} component={OpenIssueForm} />

                                        <Route exact path={AppNavPaths.Unauthorized} component={NotAuthorizedPage} />
                                        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                                        <AuthorizeRoute exact path={`${AppNavPaths.NotFound}`} component={NotFound} />
                                        <AuthorizeRoute exact path={`${AppNavPaths.ServerError}`} component={ServerError} />
                                        <AuthorizeRoute exact path={`${AppNavPaths.NoPermission}`} component={NoPermission} />
                                        <AuthorizeRoute component={NotFound} />
                                    </Switch>
                                </Layout>
                            </AbilityContext.Provider>
                        );
                    } else {
                        return <div></div>;
                    }
                }}
            </CacheBuster>
        );
    }
}

export default withRouter(App);
