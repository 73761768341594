import React, { Fragment } from 'react';
import { Prompt, withRouter } from 'react-router-dom';

import { FlexRow, onFieldChange, toasty } from '../common/forms/FormElements';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { isEqual } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import {
    AppPageForm,
    FlexCenterRow,
    FormGroupColumn,
    FormLabel,
    GroupedRow
} from '../common/forms/FormElements';

import { LinearProgress } from '@material-ui/core';
import { util } from '../Util';

import {
    Button,
    FormGroup
} from 'reactstrap';

import { handleFormSaveError } from '../common/forms/ValidationError';
import authService from '../api-authorization/AuthorizeService';
import { getUserProfile } from '../common/UserProfile';
import FormErrorResponseDisplay from '../common/forms/FormErrorResponseDisplay';
import { QuoteCustomer } from './Quote';


class QuoteCustomerForm extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            unitOfMeasure: new QuoteCustomer(),
            formOpened: false,
            formValidated: false,
            loading: true,
            saving: false,
            statuses: [],
            perms: [],
            errorResponse: {}
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.save = this.save.bind(this);
        this.saveNew = this.saveNew.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps && (this.props.match.params.id !== (prevProps.match.params ?? {}).id)) {
            this.populateState();
        }
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            let { id } = { ...this.props.match.params };
            const { userPermissions } = await getUserProfile();

            var [quoteCustomerResponse] = await Promise.all([
                !!id ? util.fetch.get(ApiRoutes.quote.quoteCustomerById(id), util.fetch.format.none) : new QuoteCustomer()
            ]);

            var quoteCustomer;

            //Handle any issues fetching data
            if (!!id && !quoteCustomerResponse.ok) {
                //Handle erroneous links entered by the user
                if (quoteCustomerResponse.status === 404)
                    this.props.history.push(AppNavPaths.NotFound);
                else
                    this.props.history.push(AppNavPaths.ServerError);
                return false;
            } else {
                quoteCustomer = !!id ? await quoteCustomerResponse.json() : quoteCustomerResponse;
            }

            this.setState({
                formValidated: false,
                saving: false,
                quoteCustomer: quoteCustomer,
                loading: false,
                originalData: quoteCustomer,
                perms: userPermissions,
            });
        }
    }

    //#region METHODS
    onChange = onFieldChange;

    onSubmit() {
        let { quoteCustomer } = { ...this.state };
        this.clearSaving()
        this.setState({ errors: {}, saving: true });

        if (this.props.location.pathname === AppNavPaths.QuoteCustomerNew)
            this.saveNew(quoteCustomer);
        else
            this.save(quoteCustomer);
    }

    clearSaving = () => this.setState({ saving: false });

    notifySuccess = () => toasty.success('Quote Customer Saved', `Quote Customer saved successfully.`);
    notifyError = (message) => toasty.error('Save Unsuccessful', message);
    handleSaveError = (err) => handleFormSaveError(this, err);

    displaySaveErrors = (response) => this.setState({ errorResponse: response });
    clearSaveErrors = () => this.setState({ errorResponse: {} });

    onCloseClicked = () => {
        this.props.history.push(`${AppNavPaths.QuoteCustomers}`);
    }

    saveNew = async (uom) => {
        try {
            let response = await util.fetch.post(ApiRoutes.quote.quoteCustomerCreate(), uom, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                var serviceResponse = await response.json();
                if (serviceResponse.result === 'SUCCESS') {
                    this.notifySuccess();
                    let quoteCustomerId = serviceResponse.data;
                    this.props.history.push(`${AppNavPaths.QuoteCustomer}/${quoteCustomerId}`);
                } else {
                    this.notifyError(serviceResponse.message);
                }
            } else {

                if (response.status === 400) {
                    let serviceResponse = await response.json();
                    this.displaySaveErrors(serviceResponse);
                } else {

                    let errorResp = await response.json();

                    if (errorResp.errors) {
                        this.displaySaveErrors(errorResp);
                    } else {
                        let serviceResponse = {
                            title: 'Server Error',
                            errors: {
                                Exception: [
                                    await response.text()
                                ]
                            }
                        }
                        this.displaySaveErrors(serviceResponse);
                    }
                }
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    save = async (quoteCustomer) => {
        try {
            let response = await util.fetch.put(ApiRoutes.quote.quoteCustomerById(quoteCustomer.id), quoteCustomer, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                this.notifySuccess();
                this.populateState();
            } else {
                this.displaySaveErrors(await response.json());
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    //#endregion

    //#region RENDERING
    render() {
        const { loading, originalData, quoteCustomer, perms } = { ...this.state };
        let { errorResponse } = { ...this.state };

        if (!!loading) {
            return (<LinearProgress variant="indeterminate" color="secondary" />);
        } else {
            const isNewQuoteCustomer = ((quoteCustomer.id ?? 0) <= 0);
            const saveQuoteCustomerButtonText = (!isNewQuoteCustomer ? 'Save' : 'Save New Quote Customer');
            const canEdit = !!perms.includes("quote_customers.edit") || (isNewQuoteCustomer && !!perms.includes("quote_customers.create"));

            return (
                <Fragment>
                    <AppPageForm
                        formId={"quoteCustomerForm"}
                        formHeadingIcon={faClipboardCheck}
                        formHeading={isNewQuoteCustomer ? 'New Quote Customer' : 'Edit Quote Customer'}
                        formName={"quoteCustomerForm"}
                        formRef={this.formRef}
                        onSubmit={this.onSubmit}
                        setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                        isValidated={this.state.formValidated}
                        saving={this.state.saving}
                        errors={this.state.errors}
                        loading={this.state.loading}
                    >
                        <GroupedRow>
                            <FormGroupColumn>
                                <FormGroup>
                                    <FormLabel htmlFor="inputName" text="Name" required={true} />
                                    <input id="name"
                                        name="quoteCustomer.name"
                                        autoComplete="off"
                                        className="form-control"
                                        required
                                        disabled={!canEdit}
                                        onChange={!!canEdit? this.onChange : undefined}
                                        defaultValue={quoteCustomer.name} />
                                    <small className="invalid-feedback text-danger" hidden>Name is required.</small>
                                </FormGroup>
                            </FormGroupColumn>
                        </GroupedRow>
                        <FlexCenterRow className="mb-3">
                            {!!canEdit &&
                                <Button
                                    size="sm"
                                    type="submit"
                                    disabled={this.state.saving}
                                    color="primary"
                                    name="quoteCustomerForm">
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faSave} />
                                    {saveQuoteCustomerButtonText}
                                </Button>
                            }
                            <Button
                                size="sm"
                                type="button"
                                color="secondary"
                                disabled={this.state.saving}
                                name="quoteCustomerFormClose"
                                onClick={this.onCloseClicked}
                                className="ml-2">
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faTimes} />
                                {"Close"}
                            </Button>
                        </FlexCenterRow>
                        <FlexRow>
                            <FormErrorResponseDisplay onClear={this.clearSaveErrors} response={errorResponse} />
                        </FlexRow>
                    </AppPageForm>
                    <Prompt
                        when={!this.state.saving && !isEqual(originalData, quoteCustomer)}
                        message='You have unsaved changes, are you sure you want to leave?'
                    />
                </Fragment>
            );
        }

    }
    //#endregion
}

export default withRouter(QuoteCustomerForm);