import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks } from '@fortawesome/free-solid-svg-icons'
import { LinearProgress } from '@material-ui/core';
import CommonContext, { ApiRoutes, AppNavPaths } from "../Common";
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    IconCellRenderer,
    LinkCellRenderer,
    TextFilterDefaults,
    VariableLinkCellRenderer
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import {
    onFieldChange,
    PageHeading,
    PageWrap,
    toasty,
    SmallOutlineButton,
    SmallButton
} from '../common/forms/FormElements';
import { Can } from '../Can';
import authService from '../api-authorization/AuthorizeService';
import { util } from '../Util';

export default class FormsSearch extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            rowData: [],
            rowsSelected: [],
            isExporting: false
        };

        this.onRowSelected = this.onRowSelected.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    }

    onChange = onFieldChange;

    notifyError = (message) => toasty.error('Action Failed', message);
    displayErrors = (response) => this.setState({ errorResponse: response });
    clearErrors = () => this.setState({ errorResponse: {} });

    populateState = async () => {
        const gridOptions = createGridOptions(this);

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: LinkCellRenderer,
            iconRenderer: IconCellRenderer,
            variableLinkRenderer: VariableLinkCellRenderer
        };
        gridOptions.onRowSelected = this.onRowSelected;
        gridOptions.columnDefs = this.getColumnDefs();
        gridOptions.useLoading = true;
        gridOptions.loadingTemplate = '<span className="ag-overlay-loading-center "><i class="faSaveSpinner fa fa-md fa-circle-notch"></i> Loading ...</span>';

        const dataSource = createDataSource(ApiRoutes.forms.search(), gridOptions);

        this.setState({
            loading: false,
            gridOptions: gridOptions,
            dataSource: dataSource
        });
    }

    getColumnDefs() {

        //https://www.ag-grid.com/documentation/javascript/column-definitions/

        const defs = [
            {
                headerName: "",
                valueGetter: "node.id",
                sortable: false,
                hide: true,
                flex: 1,
                cellRenderer: this.indexCellRenderer
            },
            {
                colId: 'Id',
                headerName: '',
                sortable: false,
                cellRenderer: 'iconRenderer',
                maxWidth: 75,
                cellStyle: { color: "rgba(0,0,0,0)" },
                cellRendererParams: {
                    clicked: (id) => { this.props.history.push(`${AppNavPaths.Forms}/${id}`) },
                    idField: 'id',
                    iconClass: 'fa-edit'
                }
            },
            {
                colId: 'Id',
                headerName: '',
                sortable: false,
                maxWidth: 75,
                cellStyle: { color: "rgba(0,0,0,0)" },
                cellRenderer: 'iconRenderer',
                cellRendererParams: {
                    clicked: (id) => { this.props.history.push(`${AppNavPaths.FormsBuild}/${id}`) },
                    idField: 'id',
                    iconClass: 'fa-screwdriver'
                }
            },
            {
                colId: 'name',
                headerName: 'Name',
                field: 'name',
                sortable: true,
                flex: 5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'Instructions',
                headerName: 'Instructions',
                field: 'instructions',
                sortable: true,
                flex: 10,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'version',
                headerName: 'Version',
                field: 'version',
                sortable: true,
                flex: 1
            },
            {
                colId: 'status',
                headerName: 'Status',
                field: 'status.status',
                sortable: true,
                flex: 1,
                filter: 'agTextColumnFilter',
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'Id',
                headerName: '',
                sortable: false,
                maxWidth: 75,
                cellStyle: { color: "rgba(0,0,0,0)" },
                cellRenderer: (params) => {
                    if (!!(params.data)) {
                        if (params.data.status.status == 'Draft') {
                            return (
                                <SmallButton
                                    type="button"
                                    onClick={
                                        async () => {
                                            let { gridOptions, dataSource } = { ...this.state };
                                            try {
                                                let response = await util.fetch.delete(ApiRoutes.forms.byid(params.data.id), params.data, util.fetch.format.none);
                                                if (response.redirected) {
                                                    window.location.href = response.url;
                                                } else if (!!response.ok) {
                                                    toasty.success('Form Deleted', `Form deleted successfully.`);
                                                    gridOptions.api.setDatasource(dataSource);
                                                } else {

                                                    if (response.status === 400) {
                                                        let serviceResponse = await response.json();
                                                        this.displayErrors(serviceResponse);
                                                    } else {

                                                        let errorResp = await response.json();

                                                        if (errorResp.errors) {
                                                            this.displayErrors(errorResp);
                                                        } else {
                                                            let serviceResponse = {
                                                                title: 'Server Error',
                                                                errors: {
                                                                    Exception: [
                                                                        await response.text()
                                                                    ]
                                                                }
                                                            }
                                                            this.displayErrors(serviceResponse);
                                                        }
                                                    }
                                                }
                                            } catch (error) {
                                                this.notifyError(error.toString());
                                            }
                                        }
                                    }
                                >
                                    <i className={`fa fa-trash fa-md mr-2`} />
                                </SmallButton>
                            );
                        }
                    }
                    return null;
                }
            }
        ];

        return defs;
    }

    //https://stackoverflow.com/questions/44263350/count-number-of-selected-rows-in-ag-grid
    //Possibly use lodash dequeue?
    onRowSelected(e) {

        const rs = e.api.getSelectedRows();

        this.setState({
            rowsSelected: rs
        });
    }

    render() {
        const {
            rowData,
            gridOptions
        } = this.state;

        return (
            <CommonContext.Consumer>
                {
                    value => {
                        if (this.state.loading) return (<LinearProgress variant="indeterminate" color="secondary" />);

                        return (
                            <PageWrap>
                                <PageHeading>
                                    <FontAwesomeIcon icon={faTasks} className="mr-2 text-muted" />
                                    <span>Forms</span>
                                    <Can do="create" on="forms">
                                        <SmallOutlineButton onClick={() => { this.props.history.push(`${AppNavPaths.FormsNew}`) }} className="float-right">
                                            <i className="fa fa-plus-square fa-lg" />
                                            <span className="ml-2 small-viewport-hide">
                                                Create Form
                                            </span>
                                        </SmallOutlineButton>
                                    </Can>
                                </PageHeading>
                                <DataGridToolbar
                                    entity="Survey"
                                    gridApi={this.state.gridApi}
                                    dataSource={this.state.dataSource}
                                    hideAdd={true}
                                    hideExcelButton={true}
                                    gridOptions={this.state.gridOptions}
                                    serverExport={{ apiPath: ApiRoutes.forms.excelExport(), filePrefix: 'FormsSearch' }}
                                />
                                <DataGrid
                                    domLayout={"normal"}
                                    rowData={rowData}
                                    gridOptions={gridOptions}
                                    gridStatus={this.state.gridStatus}
                                />
                            </PageWrap>
                        )
                    }
                }
            </CommonContext.Consumer>
        )
    }
}