import React, { Component } from 'react';
import { Can } from './Can';
import {
    Navbar,
    NavbarBrand,
    NavLink,
    ListGroupItem,
    Collapse,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import '../style/NavMenu.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCog,
    faBars,
    faAngleUp,
    faAngleDown,
    faUser,
    faBuilding,
    faTags,
    faChartLine,
    faTasks,
    faClipboardCheck
} from '@fortawesome/free-solid-svg-icons';
import authService from './api-authorization/AuthorizeService';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import CommonContext, {
    AppNavPaths,
    ApplicationPermissions
} from './Common';
import {
    FlexCenterRow,
    FlexEndRow,
    FlexStartRow,
} from './common/forms/FormElements';
import { getUserProfile } from './common/UserProfile';

class NavMenuItem extends Component {
    static contextType = CommonContext;

    render() {
        const { permissions } = { ...this.context };
        const perms = (permissions ?? []).map(
            (x) => `${x.permission}.${x.action}`
        );

        if (!perms.includes(`${this.props.entity}.${this.props.action}`))
            return null;

        const label_className = `mr-2 cursor-pointer${
            !this.props.isChild ? ' font-weight-bold' : ' text-info'
        }`;

        return (
            <ListGroupItem className="flex-row-nowrap-left p-1 pr-2">
                <NavLink
                    tag={Link}
                    className="text-light p-1 flex-fill cursor-pointer d-flex flex-row flex-nowrap align-items-center"
                    to={this.props.searchUrl}
                    title={this.props.searchTitle}
                    onClick={this.props.onNavLinkClicked}
                >
                    {this.props.icon && (
                        <FontAwesomeIcon
                            icon={this.props.icon}
                            className="menu-icon cursor-pointer"
                        />
                    )}
                    {!this.props.icon && <i className="fa fa-fw menu-icon"></i>}
                    <label className={label_className}>
                        {this.props.label}
                    </label>
                    {!!this.props.notificationCount && (
                        <span className="nav-menu-notification-badge badge badge-lg badge-danger ml-1">
                            {this.props.notificationCount}
                        </span>
                    )}
                </NavLink>
                {!this.props.hideNew && (
                    <Can I="create" a={this.props.entity}>
                        <NavLink
                            tag={Link}
                            className="text-light p-1 cursor-pointer"
                            to={this.props.newUrl}
                            title={this.props.newTitle}
                            onClick={this.props.onNavLinkClicked}
                        >
                            <span className=" fa fa-plus-circle fa-lg text-muted cursor-pointer"></span>
                        </NavLink>
                    </Can>
                )}
                {this.props.children}
            </ListGroupItem>
        );
    }
}

//Actions that require admins.
const adminEntitiesActions = [
    ApplicationPermissions.role_create
];

class NavMenu extends Component {
    static displayName = NavMenu.name;
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            userName: null,
            collapseId: '',
            notifications: null
        };
        this.onLogoutClicked = this.onLogoutClicked.bind(this);
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

   /**
   * Alert if clicked on outside of element
   */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.toggleMenuClosed();
        }
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([
            authService.isAuthenticated(),
            authService.getUser(),
        ]);

        var userProfile = null;

        if (!!isAuthenticated) {
            userProfile = await Promise.all([
                getUserProfile()
            ]);
        }
        //TODO: Get notifictions here?

        this.setState({
            isAuthenticated,
            userName: user && user.name,
            siteLogo: userProfile[0]?.logo 
        });
    }

    toggleMenu = (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.toggleMenuClasses();
    };

    toggleMenuClasses = () => {
        var offCanvasMenu = document.getElementById('offCanvasMenu');
        offCanvasMenu.classList.toggle('show');
        document.body.classList.toggle('offcanvas-active');
    };

    toggleMenuClosed = () => {
        var offCanvasMenu = document.getElementById('offCanvasMenu');
        offCanvasMenu.classList.remove('show');
        document.body.classList.remove('offcanvas-active');
    };

    toggleCollapse = (collapseId) => () => {
        this.setState((prevState) => ({
            collapseId: prevState.collapseId !== collapseId ? collapseId : '',
        }));
    };

    onNavLinkClicked = (event) => {
        event.preventDefault();
        let navLink = event.currentTarget;
        this.toggleMenuClosed();
        this.props.history.push(navLink.getAttribute('href'));
    };

    onLogoutClicked = (event) => {
        event.preventDefault();
        this.toggleMenuClosed();
        const logoutPath = {
            pathname: `${ApplicationPaths.LogOut}`,
            state: { local: true },
        };
        this.props.history.push(logoutPath.pathname, logoutPath.state);
    };

    renderMenuButton() {
        return (
            <button
                data-trigger="offCanvasMenu"
                type="button shadow-none p-2"
                className="offCanvasMenuButton btn p-0 m-0"
                onClick={this.toggleMenu}
            >
                <FontAwesomeIcon size="lg" icon={faBars} />
            </button>
        );
    }

    userHasAdminFunction = () =>
        (this.context.permissions ?? [])
            .map((p) => `${p.permission}.${p.action}`)
            .some((p) => adminEntitiesActions.indexOf(p) >= 0);

    renderMenu = () => {
        /* 
         *  Remap permissions back to their original form for quick lookup.  
            If the user has at least one admin function,
            show the admin collapse menu. 
        */
        const userHasAdminFunction = this.userHasAdminFunction();

        return (
            <>
                <aside className="offcanvas offcanvas-left" id="offCanvasMenu">
                    <header className="offcanvas-header p-0 m-0 border-bottom">
                        <div className="d-flex flex-row flex-nowrap align-items-center menu-button-wrapper">
                            {this.renderMenuButton()}
                        </div>
                    </header>

                    <nav className="list-group list-group-flush">

                        <NavMenuItem
                            action={'view'}
                            entity={'employee'}
                            onNavLinkClicked={this.onNavLinkClicked}
                            label="Employees"
                            icon={faUser}
                            hideNew={true}
                            searchTitle="View Employees"
                            searchUrl={`${AppNavPaths.Employees}`}
                        >
                            <Can I="create" an="employee" >
                                <NavLink
                                    tag={Link}
                                    className="text-light p-1 cursor-pointer"
                                    to={AppNavPaths.EmployeeNew}
                                    title={'Add a new employee'}
                                    onClick={this.onNavLinkClicked}
                                >
                                    <span className="text-muted cursor-pointer fa fa-plus-circle fa-lg" />
                                </NavLink>
                            </Can>
                        </NavMenuItem>

                        <NavMenuItem
                            action={'view'}
                            entity={'work_center'}
                            onNavLinkClicked={this.onNavLinkClicked}
                            label="Work Centers"
                            icon={faBuilding}
                            hideNew={true}
                            searchTitle="View Work Centers"
                            searchUrl={`${AppNavPaths.WorkCenters}`}
                        >
                        </NavMenuItem>

                        <NavMenuItem
                            action={'view'}
                            entity={'work_flow_status_tag'}
                            onNavLinkClicked={this.onNavLinkClicked}
                            label="Work Flow Status Tags"
                            icon={faTags}
                            hideNew={true}
                            searchTitle="View Work Flow Status Tags"
                            searchUrl={`${AppNavPaths.WorkFlowStatusTags}`}
                        >
                        </NavMenuItem>

                        <NavMenuItem
                            action={'view'}
                            entity={'sales_order_tracking'}
                            onNavLinkClicked={this.onNavLinkClicked}
                            label="Order Tracking"
                            icon={faChartLine}
                            hideNew={true}
                            searchTitle="View Order Tracking"
                            searchUrl={`${AppNavPaths.SalesOrderTracking}`}
                        >
                        </NavMenuItem>

                        <Can I="view" an="quote" >
                        <ListGroupItem
                            className="flex-row-nowrap-left p-1 pr-2 cursor-pointer"
                            onClick={this.toggleCollapse(
                                'quoteCollapse'
                            )}
                        >
                            <div className="flex-fill p-1 cursor-pointer">
                                <FontAwesomeIcon
                                    icon={faClipboardCheck}
                                    className="menu-icon"
                                />
                                <label className="mr-2 font-weight-bold">
                                    Quotes Management
                                </label>
                            </div>
                            <FontAwesomeIcon
                                icon={
                                    this.state.collapseId ===
                                        'quoteCollapse'
                                        ? faAngleUp
                                        : faAngleDown
                                }
                                className="mr-2 text-muted"
                                size="lg"
                            />
                        </ListGroupItem>

                        <Collapse
                            isOpen={
                                this.state.collapseId ===
                                'quoteCollapse'
                            }
                        >
                            <NavMenuItem
                                isChild={true}
                                action={'view'}
                                entity={'quote'}
                                onNavLinkClicked={this.onNavLinkClicked}
                                label="Quotes"
                                newTitle="Add a Quote"
                                newUrl={`${AppNavPaths.QuoteNew}`}
                                searchTitle="View Roles"
                                searchUrl={`${AppNavPaths.Quotes}`}
                            />
                            <NavMenuItem
                                isChild={true}
                                action={'edit'}
                                entity={'quote_customers'}
                                onNavLinkClicked={this.onNavLinkClicked}
                                label="Quote Customers"
                                hideNew={true}
                                searchTitle="View Quote Customers"
                                searchUrl={`${AppNavPaths.QuoteCustomers}`}
                            />
                            <NavMenuItem
                                isChild={true}
                                action={'edit'}
                                entity={'quote_worked_on_by'}
                                onNavLinkClicked={this.onNavLinkClicked}
                                label="Quotes Worked On By"
                                hideNew={true}
                                searchTitle="View Quotes Worked On By"
                                searchUrl={`${AppNavPaths.QuotesWorkedOnBy}`}
                            />
                        </Collapse>
                        </Can>
                        {userHasAdminFunction ? (
                            <>
                                <ListGroupItem
                                    className="flex-row-nowrap-left p-1 pr-2 cursor-pointer"
                                    onClick={this.toggleCollapse(
                                        'formsCollapse'
                                    )}
                                >
                                    <div className="flex-fill p-1 cursor-pointer">
                                        <FontAwesomeIcon
                                            icon={faTasks}
                                            className="menu-icon"
                                        />
                                        <label className="mr-2 font-weight-bold">Forms Management</label>
                                    </div>
                                    <FontAwesomeIcon
                                        icon={
                                            this.state.collapseId ===
                                                'formsCollapse'
                                                ? faAngleUp
                                                : faAngleDown
                                        }
                                        className="mr-2 text-muted"
                                        size="lg"
                                    />
                                </ListGroupItem>

                                <Collapse isOpen={ this.state.collapseId === 'formsCollapse' } >
                                    <NavMenuItem
                                        isChild={true}
                                        action={'view'}
                                        entity={'forms'}
                                        onNavLinkClicked={this.onNavLinkClicked}
                                        label="Forms"
                                        newTitle="Add a Form"
                                        newUrl={`${AppNavPaths.FormsNew}`}
                                        searchTitle="View Forms"
                                        searchUrl={`${AppNavPaths.Forms}`}
                                    />
                                    <NavMenuItem
                                        isChild={true}
                                        action={'view'}
                                        entity={'unitofmeasure'}
                                        onNavLinkClicked={this.onNavLinkClicked}
                                        label="Units Of Measure"
                                        newTitle="Add a Unit Of Measure"
                                        newUrl={`${AppNavPaths.UnitsOfMeasurementNew}`}
                                        searchTitle="View Units Of Measurement"
                                        searchUrl={`${AppNavPaths.UnitsOfMeasurement}`}
                                    />
                                    <NavMenuItem
                                        isChild={true}
                                        action={'view'}
                                        entity={'questionoption'}
                                        onNavLinkClicked={this.onNavLinkClicked}
                                        label="Question Option Group Options"
                                        newTitle="Add a Question Option"
                                        newUrl={`${AppNavPaths.QuestionOptionsNew}`}
                                        searchTitle="View Question Options"
                                        searchUrl={`${AppNavPaths.QuestionOptions}`}
                                    />
                                    <NavMenuItem
                                        isChild={true}
                                        action={'view'}
                                        entity={'questionoption'}
                                        onNavLinkClicked={this.onNavLinkClicked}
                                        label="Question Option Groups"
                                        newTitle="Add a Question Option Group"
                                        newUrl={`${AppNavPaths.QuestionGroupsNew}`}
                                        searchTitle="View Question Option Groups"
                                        searchUrl={`${AppNavPaths.QuestionGroups}`}
                                    />
                                    <NavMenuItem
                                        isChild={true}
                                        action={'view'}
                                        entity={'question'}
                                        onNavLinkClicked={this.onNavLinkClicked}
                                        label="Questions"
                                        newTitle="Add a Question"
                                        newUrl={`${AppNavPaths.QuestionsNew}`}
                                        searchTitle="View Questions"
                                        searchUrl={`${AppNavPaths.Questions}`}
                                    />
                                </Collapse>
                                <ListGroupItem
                                    className="flex-row-nowrap-left p-1 pr-2 cursor-pointer"
                                    onClick={this.toggleCollapse(
                                        'adminCollapse'
                                    )}
                                >
                                    <div className="flex-fill p-1 cursor-pointer">
                                        <FontAwesomeIcon
                                            icon={faCog}
                                            className="menu-icon"
                                        />
                                        <label className="mr-2 font-weight-bold">
                                            Administrative
                                        </label>
                                    </div>
                                    <FontAwesomeIcon
                                        icon={
                                            this.state.collapseId ===
                                            'adminCollapse'
                                                ? faAngleUp
                                                : faAngleDown
                                        }
                                        className="mr-2 text-muted"
                                        size="lg"
                                    />
                                </ListGroupItem>

                                <Collapse
                                    isOpen={
                                        this.state.collapseId ===
                                        'adminCollapse'
                                    }
                                >
                                    <NavMenuItem
                                        isChild={true}
                                        action={'view'}
                                        entity={'role'}
                                        onNavLinkClicked={this.onNavLinkClicked}
                                        label="Roles"
                                        newTitle="Add a Role"
                                        newUrl={`${AppNavPaths.RoleNew}`}
                                        searchTitle="View Roles"
                                        searchUrl={`${AppNavPaths.Roles}`}
                                    />
                                    <NavMenuItem
                                        isChild={true}
                                        action={'view'}
                                        entity={'role'}
                                        onNavLinkClicked={this.onNavLinkClicked}
                                        label="Mutable Settings"
                                        newTitle="Edit Mutable Settings"
                                        newUrl={`${AppNavPaths.MutableSettings}`}
                                        searchTitle="Edit Mutable Settings"
                                        searchUrl={`${AppNavPaths.MutableSettings}`}
                                    />
                                </Collapse>
                            </>
                        ) : (
                            ''
                        )}
                    </nav>
                </aside>
            </>
        );
    };

    render() {
        const { isAuthenticated, siteLogo } = { ...this.state };
        const { common, user, permissions, pageTitle } = {
            ...this.context,
        };

        document.title = 'DTI Sync';

        return (
            <header ref={this.wrapperRef} id="siteHeader">
                <Navbar
                    className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom p-0 m-0"
                    light
                >
                    <div className="container-fluid m-0 p-0">
                        <div className="menu-button-wrapper">
                            {!!isAuthenticated &&
                                !!user &&
                                !!permissions &&
                                this.renderMenuButton()}
                        </div>
                        <FlexStartRow className="p-0">
                            <NavbarBrand tag={Link} to="/">
                                {!!siteLogo && (
                                    <img src={siteLogo} alt="Logo" />
                                )}
                            </NavbarBrand>
                            <h1>{pageTitle}</h1>
                        </FlexStartRow>
                        <FlexCenterRow className={"verionNumberTitle"} >
                            {!!common?.version && `v.${common.version}`}
                        </FlexCenterRow>
                        {!!user && (
                            <FlexEndRow>
                                <h6 className="mb-0 flex-column text-dark text-right d-none d-xl-flex d-lg-flex d-md-flex d-xl-none">
                                    <FlexEndRow>
                                        <span>{user?.userName}</span>
                                    </FlexEndRow>
                                </h6>
                                <span className="user-stacked-icon fa-stack fa-2x mr-2">
                                    <i className="fas fa-circle fa-stack-2x"></i>
                                    <i className="fas fa-user fa-stack-1x fa-inverse"></i>
                                </span>
                                <span
                                    title="Logout"
                                    className="fa fa-sign-out-alt fa-lg cursor-pointer mr-3"
                                    onClick={this.onLogoutClicked}
                                ></span>
                            </FlexEndRow>
                        )}
                        {!!user &&
                            !!permissions &&
                            this.renderMenu()}
                    </div>
                </Navbar>
            </header>
        );
    }
}

export default withRouter(NavMenu);
