import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks } from '@fortawesome/free-solid-svg-icons'
import { LinearProgress } from '@material-ui/core';
import CommonContext, { ApiRoutes, AppNavPaths } from "../Common";
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    IconCellRenderer,
    LinkCellRenderer,
    TextFilterDefaults,
    VariableLinkCellRenderer
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import { onFieldChange, PageHeading, PageWrap, SmallOutlineButton, toasty, SmallButton } from '../common/forms/FormElements';
import { Can } from '../Can';
import authService from '../api-authorization/AuthorizeService';
import { getUserProfile } from '../common/UserProfile';
import { util } from '../Util';
import ValidationMessageDisplay from '../common/forms/ValidationMessageDisplay';

export default class QuestionOptionGroupOptionsSearch extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            rowData: [],
            rowsSelected: [],
            isExporting: false
        };

        this.onRowSelected = this.onRowSelected.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    }

    notifyError = (message) => toasty.error('Action Failed', message);
    displayErrors = (response) => this.setState({ errorResponse: response });
    clearErrors = () => this.setState({ errorResponse: {} });

    onChange = onFieldChange;

    populateState = async () => {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            const { userPermissions } = await getUserProfile();

            const gridOptions = createGridOptions(this);

            const canEdit = !!userPermissions.includes("questionoption.edit");
            const canDelete = !!userPermissions.includes("questionoption.delete");

            gridOptions.components = {
                selectFilter: DataGridSelectFilter,
                selectFloatingFilter: DataGridSelectFloatingFilter,
                nameRenderer: LinkCellRenderer,
                iconRenderer: IconCellRenderer,
                variableLinkRenderer: VariableLinkCellRenderer
            };
            gridOptions.onRowSelected = this.onRowSelected;
            gridOptions.columnDefs = this.getColumnDefs(canEdit, canDelete);
            gridOptions.useLoading = true;
            gridOptions.loadingTemplate = '<span className="ag-overlay-loading-center "><i class="faSaveSpinner fa fa-md fa-circle-notch"></i> Loading ...</span>';

            const dataSource = createDataSource(ApiRoutes.questionoptions.search(), gridOptions);

            this.setState({
                loading: false,
                gridOptions: gridOptions,
                dataSource: dataSource
            });
        }
    }

    getColumnDefs(canEdit, canDelete) {

        //https://www.ag-grid.com/documentation/javascript/column-definitions/

        const defs = [
            {
                headerName: "",
                valueGetter: "node.id",
                sortable: false,
                hide: true,
                flex: 1,
                maxWidth: 35,
                minWidth: 35,
                cellRenderer: this.indexCellRenderer
            },
            (canEdit ? {
                colId: 'Id',
                minWidth: 75,
                maxWidth: 75,
                headerName: '',
                sortable: false,
                cellStyle: { color: "rgba(0,0,0,0)" },
                cellRenderer: 'iconRenderer',
                cellRendererParams: {
                    clicked: (id) => { this.props.history.push(`${AppNavPaths.QuestionOptions}/${id}`) },
                    idField: 'id',
                    iconClass: 'fa-edit'
                }
            } : {
                colId: 'Id',
                minWidth: 75,
                maxWidth: 75,
                headerName: '',
                sortable: false
            }),
            {
                colId: 'ChoiceText',
                headerName: 'Choice Text',
                field: 'choiceText',
                sortable: true,
                sort: 'asc',
                flex: 1,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'QuestionOptionGroups.OrderBy(qog => qog.QuestionGroup.Name).First()',
                headerName: 'Used In Groups',
                field: 'groups',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'Id',
                minWidth: 75,
                maxWidth: 75,
                headerName: '',
                sortable: false,
                cellStyle: { color: "rgba(0,0,0,0)" },
                cellRenderer: (params, canDelete) => {
                    if (!!(params.data) && canDelete) {
                        if (!!!(params.data.groups?.length ?? false)) {
                            return (
                                <SmallButton
                                    type="button"
                                    onClick={
                                        async () => {
                                            let { gridOptions, dataSource } = { ...this.state };
                                            try {
                                                let response = await util.fetch.delete(ApiRoutes.questionoptions.byid(params.data.id), params.data, util.fetch.format.none);
                                                if (response.redirected) {
                                                    window.location.href = response.url;
                                                } else if (!!response.ok) {
                                                    toasty.success('Option Deleted', `Option deleted successfully.`);
                                                    gridOptions.api.setDatasource(dataSource);
                                                } else {

                                                    if (response.status === 400) {
                                                        let serviceResponse = await response.json();
                                                        this.displayErrors(serviceResponse);
                                                    } else {

                                                        let errorResp = await response.json();

                                                        if (errorResp.errors) {
                                                            this.displayErrors(errorResp);
                                                        } else {
                                                            let serviceResponse = {
                                                                title: 'Server Error',
                                                                errors: {
                                                                    Exception: [
                                                                        await response.text()
                                                                    ]
                                                                }
                                                            }
                                                            this.displayErrors(serviceResponse);
                                                        }
                                                    }
                                                }
                                            } catch (error) {
                                                this.notifyError(error.toString());
                                            }
                                        }
                                    }
                                >
                                    <i className={`fa fa-trash fa-md mr-2`} />
                                </SmallButton>
                            );
                        }
                    }
                    return null;
                }
            } 
        ];

        return defs;
    }

    //https://stackoverflow.com/questions/44263350/count-number-of-selected-rows-in-ag-grid
    //Possibly use lodash dequeue?
    onRowSelected(e) {

        const rs = e.api.getSelectedRows();

        this.setState({
            rowsSelected: rs
        });
    }

    render() {
        const {
            rowData,
            gridOptions,
            errorResponse
        } = this.state;

        return (
            <CommonContext.Consumer>
                {
                    value => {
                        if (this.state.loading) return (<LinearProgress variant="indeterminate" color="secondary" />);

                        return (
                            <PageWrap>
                                <PageHeading>
                                    <FontAwesomeIcon icon={faTasks} className="mr-2 text-muted" />
                                    <span>Question Option Group Options</span>
                                    <Can do="create" on="questionoption">
                                        <SmallOutlineButton onClick={() => { this.props.history.push(`${AppNavPaths.QuestionOptionsNew}`) }} className="float-right">
                                            <i className="fa fa-plus-square fa-lg" />
                                            <span className="ml-2 small-viewport-hide">
                                                Create Option
                                            </span>
                                        </SmallOutlineButton>
                                    </Can>
                                </PageHeading>
                                <DataGridToolbar
                                    entity="QuestionOption"
                                    gridApi={this.state.gridApi}
                                    dataSource={this.state.dataSource}
                                    hideAdd={true}
                                    hideExcelButton={true}
                                    gridOptions={this.state.gridOptions}
                                    serverExport={{ apiPath: ApiRoutes.questionoptions.excelExport(), filePrefix: 'QuestionOptionGroupOptions' }}
                                />
                                <DataGrid
                                    domLayout={"normal"}
                                    rowData={rowData}
                                    gridOptions={gridOptions}
                                    gridStatus={this.state.gridStatus}
                                />
                                <ValidationMessageDisplay onClear={this.clearErrors} errors={errorResponse?.errors ?? []} />
                            </PageWrap>
                        )
                    }
                }
            </CommonContext.Consumer>
        )
    }
}