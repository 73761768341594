export class EmployeePayRate {
    id = null;
    employeeId = null;
    startDate = null;
    endDate = null;
    rateReceivedDate = null;
}

export class Employee {
    id = null;
    userName = '';
    firstName = '';
    lastName = '';
    roles = [];
    employeeNumber = '';
    accountStatus = '';
    mobilePhone = '';
    email = '';
    password = '';
    confirmPassword = '';
    addresses = [];
}