import React from 'react';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    TextFilterDefaults,
    IndexCellRendererWithEdit,
    IconCellRenderer,
    DateFilterDefaults,
    dateCellRenderer
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck } from '@fortawesome/free-solid-svg-icons'
import { PageHeading, PageWrap, SmallPrimaryButton } from '../common/forms/FormElements';
import { withRouter } from "react-router-dom";
import { LinearProgress } from '@material-ui/core';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import authService from '../api-authorization/AuthorizeService';
import { util } from '../Util';
import { Can } from '../Can';

class ShippingSearch extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selectedRow: null,
            shippingReady: false,
            workCenterId: null
        }
        this.onEditShipping = this.onEditShipping.bind(this);
        this.onAddShippingClicked = this.onAddShippingClicked.bind(this);
        this.onViewShipping = this.onViewShipping.bind(this);
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
        this.setState = (state, callback) => { return };
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {

            let gridOptions = createGridOptions(this);

            let statusFilterParams = {
                suppressFilterButton: true,
                labelText: 'Filter by Status',
                options: [
                    { label: 'Yes', value: 'Shipped' },
                    { label: 'No', value: 'Progress' }
                ],
                optionsLabel: 'label',
                optionsValue: 'value',
                initialFilterValue: 'Progress'
            }

            gridOptions.components = {
                selectFilter: DataGridSelectFilter,
                selectFloatingFilter: DataGridSelectFloatingFilter,
                indexCellRenderer: IndexCellRendererWithEdit,
                iconRenderer: IconCellRenderer,
            };

            gridOptions.columnDefs = [
                {
                    flex: 0,
                    width: 100,
                    minWidth: 100,
                    maxWidth: 100,
                    headerName: "",
                    valueGetter: "node.id",
                    sortable: false,
                    cellStyle: { color: "rgba(0,0,0,0)" },
                    cellRenderer: 'iconRenderer',
                    cellRendererParams: {
                        clicked: (id) => { this.onViewShipping(id) },
                        idField: 'id',
                        iconClass: 'fa-eye'
                    }
                },
                {
                    colId: 'ShippingNumber',
                    sortable: true,
                    headerName: 'Number',
                    field: 'shippingNumber',
                    cellRenderer: (params) => {
                        if (params.data) {
                            return 'S-' + params.data.shippingNumber.replace(/^0+/, "");
                        }
                        return null;
                    },
                    tooltipField: 'number',
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'ShippingSerialNumbers',
                    sortable: true,
                    headerName: 'Serial Numbers',
                    field: 'shippingSerialNumbers',
                    tooltipField: 'serialNumbers',
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'IsComplete',
                    headerName: 'Shipped',
                    field: 'isComplete',
                    sortable: true,
                    filter: 'selectFilter',
                    floatingFilter: true,
                    filterParams: statusFilterParams,
                    floatingFilterComponent: 'selectFloatingFilter',
                    floatingFilterComponentParams: statusFilterParams,
                    cellRenderer: (params) => {
                        if (params.data) {
                            let status = params.data.isComplete ? "Yes" : "No";
                            return ( <span>{status}</span> );
                        }

                        return <span>{"N/A"}</span>;
                    }
                },
                {
                    colId: 'CreatedOn',
                    headerName: 'Created Date',
                    field: 'CreatedOn',
                    sortable: true,
                    cellRenderer: dateCellRenderer,
                    maxWidth: 120,
                    minWidth: 120,
                    sort: 'desc',
                    filter: 'agDateColumnFilter',
                    filterParams: DateFilterDefaults
                },
                {
                    flex: 0,
                    width: 100,
                    minWidth: 100,
                    maxWidth: 100,
                    headerName: "",
                    valueGetter: "node.id",
                    sortable: false,
                    cellStyle: { color: "rgba(0,0,0,0)" },
                    cellRenderer: 'indexCellRenderer',
                    cellRendererParams: {
                        clicked: this.onEditShipping,
                        nameField: 'name',
                        idField: 'id',
                        title: 'Edit this shipping'
                    }
                }
            ];

            gridOptions.useLoading = true;
            gridOptions.loadingTemplate = '<span className="ag-overlay-loading-center "><i class="faSaveSpinner fa fa-md fa-circle-notch"></i> Loading ...</span>';

            let ds = createDataSource(ApiRoutes.shipping.search(), gridOptions);

            var [shippingReadyResponse] = await Promise.all([
                util.fetch.get(ApiRoutes.shipping.base(), util.fetch.format.none)
            ]);
            let isReady = false;
            let workCenterId = 0;

            if (!shippingReadyResponse.ok) {
                //Handle erroneous links entered by the user
                if (shippingReadyResponse.status === 404)
                    this.props.history.push(AppNavPaths.NotFound);
                else
                    this.props.history.push(AppNavPaths.ServerError);
                return false;
            } else {
                let isReadyRaw = await shippingReadyResponse.json();
                isReady = isReadyRaw.shippingReady
                workCenterId = isReadyRaw.workCenterId;
            }

            this.setState(state => {
                return {
                    loading: false,
                    gridOptions: gridOptions,
                    dataSource: ds,
                    shippingReady: isReady,
                    workCenterId: workCenterId
                }
            });
        }
    }

    onAddShippingClicked = () => this.props.history.push(`${AppNavPaths.ShippingCreate}`);
    onEditShipping = (id) => {
        this.props.history.push(`${AppNavPaths.Shipping}/${id}`);
    };
    onViewShipping = (shippingId) => {
        let { workCenterId } = { ...this.state };

        this.props.history.push(`${AppNavPaths.Shipping}/${workCenterId}/${shippingId}`)
    };

    render() {

        const { rowData, gridOptions, shippingReady, loading } = { ...this.state };

        if (!!loading || !this.context?.permissions) {
            return (<LinearProgress variant="indeterminate" color="secondary" />);
        }

        if (!shippingReady) {
            return (
                <PageWrap>
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center">
                            <span className="display-4 d-block mb-4">The Shipping Activity has not been set up properly</span>
                            <div className="mb-4 lead">Please Contact An Administrator to complete Shipping Setup</div>
                            <a href="/" className="btn btn-link">Back to Home</a>
                        </div>
                    </div>
                </PageWrap>
            );
        }

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon icon={faTruck} className="mr-2 text-muted" />
                    <span>Shipping</span>
                    <Can do="create" on={"shipping"}>
                        <SmallPrimaryButton type="button" onClick={this.onAddShippingClicked} className="btn-success float-right">
                            <i className={`fa fa-md mr-2 fa-user-plus`} />
                            Add Shipping
                        </SmallPrimaryButton>
                    </Can>
                </PageHeading>
                <DataGridToolbar
                    entity="shipping"
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                    hideAdd={true}
                    addIcon={'fa-user-plus'}
                    hideExcelButton={true}
                    gridOptions={this.state.gridOptions}
                    serverExport={{ apiPath: ApiRoutes.shipping.excelExport(), filePrefix: 'ShippingSearch' }}
                    serverExportDisabled={!!this.state.saving || !!this.state.loading || !!this.state.loadingData}
                >
                </DataGridToolbar>
                <DataGrid
                    domLayout={"normal"}
                    rowData={rowData}
                    gridOptions={gridOptions}
                    gridStatus={this.state.gridStatus}
                />
            </PageWrap>
        );
    }
}

export default withRouter(ShippingSearch);