import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinearProgress } from '@material-ui/core';
import CommonContext, { ApiRoutes, AppNavPaths } from "../Common";
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    TextFilterDefaults,
    IndexCellRendererWithEdit
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import { onFieldChange, PageHeading, PageWrap, SmallOutlineButton } from '../common/forms/FormElements';
import { Can } from '../Can';
import authService from '../api-authorization/AuthorizeService';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';

export default class QuoteCustomerIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            rowData: [],
            rowsSelected: [],
            isExporting: false
        };

        this.onRowSelected = this.onRowSelected.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    }

    onChange = onFieldChange;

    populateState = async () => {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {

            const gridOptions = createGridOptions(this);

            gridOptions.components = {
                selectFilter: DataGridSelectFilter,
                selectFloatingFilter: DataGridSelectFloatingFilter,
                indexCellRenderer: IndexCellRendererWithEdit,
            };
            gridOptions.onRowSelected = this.onRowSelected;
            gridOptions.columnDefs = this.getColumnDefs();
            gridOptions.useLoading = true;
            gridOptions.loadingTemplate = '<span className="ag-overlay-loading-center "><i class="faSaveSpinner fa fa-md fa-circle-notch"></i> Loading ...</span>';

            const dataSource = createDataSource(ApiRoutes.quote.quoteCustomerSearch(), gridOptions);

            this.setState({
                loading: false,
                gridOptions: gridOptions,
                dataSource: dataSource
            });
        }
    }

    getColumnDefs() {

        //https://www.ag-grid.com/documentation/javascript/column-definitions/

        const defs = [
            {
                headerName: "",
                valueGetter: "node.id",
                sortable: false,
                hide: true,
                flex: 1,
                maxWidth: 35,
                minWidth: 35,
                cellRenderer: this.indexCellRenderer
            },
            {
                colId: 'Id',
                minWidth: 75,
                maxWidth: 75,
                headerName: '',
                sortable: false,
                cellRenderer: 'indexCellRenderer',
                cellRendererParams: {
                    clicked: (id) => { this.props.history.push(`${AppNavPaths.QuoteCustomer}/${id}`) },
                    nameField: 'name',
                    idField: 'id',
                    title: 'View or edit this quote customer'
                }
            },
            {
                colId: 'name',
                headerName: 'Name',
                field: 'name',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            }];

        return defs;
    }

    //https://stackoverflow.com/questions/44263350/count-number-of-selected-rows-in-ag-grid
    //Possibly use lodash dequeue?
    onRowSelected(e) {

        const rs = e.api.getSelectedRows();

        this.setState({
            rowsSelected: rs
        });
    }

    render() {
        const {
            rowData,
            gridOptions
        } = this.state;

        return (
            <CommonContext.Consumer>
                {
                    value => {
                        if (this.state.loading) return (<LinearProgress variant="indeterminate" color="secondary" />);

                        return (
                            <PageWrap>
                                <PageHeading>
                                    <FontAwesomeIcon icon={faClipboardCheck} className="mr-2 text-muted" />
                                    <span>Quote Customers</span>
                                    <Can do="create" on="quote_customers">
                                        <SmallOutlineButton onClick={() => { this.props.history.push(`${AppNavPaths.QuoteCustomerNew}`) }} className="float-right">
                                            <i className="fa fa-plus-square fa-lg" />
                                            <span className="ml-2 small-viewport-hide">
                                                Create Quote Customer
                                            </span>
                                        </SmallOutlineButton>
                                    </Can>
                                </PageHeading>
                                <DataGridToolbar
                                    entity="QuoteCustomer"
                                    gridApi={this.state.gridApi}
                                    dataSource={this.state.dataSource}
                                    hideAdd={true}
                                    hideExcelButton={true}
                                    gridOptions={this.state.gridOptions}
                                    serverExport={{ apiPath: ApiRoutes.quote.quoteCustomersExcelExport(), filePrefix: 'QuoteCustomersSearch' }}
                                />
                                <DataGrid
                                    domLayout={"normal"}
                                    rowData={rowData}
                                    gridOptions={gridOptions}
                                    gridStatus={this.state.gridStatus}
                                />
                            </PageWrap>
                        )
                    }
                }
            </CommonContext.Consumer>
        )
    }
}