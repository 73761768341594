import { faCheckCircle, faFileAlt, faImage, faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
//import { Link } from "react-router-dom";
import { SupportedFileExtensions } from "../../Common";
import { util } from "../../Util";
import cls from 'classnames';
import { LinearProgress } from "@material-ui/core";
import * as moment from "moment";

export default class FileLink extends React.Component {

    render() {

        const { path, name, extension } = { ...this.props.file };

        if (!path) return null;

        const ext = extension ?? (path.substring(path.lastIndexOf('.'), path.length) || path);
        const modifiedDate = moment(this.props.file.lastModified);
        const modified = modifiedDate.toDate() >= new Date() ? 'just now' : modifiedDate.fromNow();
        const fileSizeDisplay = !!this.props.file.size
            ? util.file.printFileSize(this.props.file.size, true)
            : '';

        return (
            <div className={cls("border rounded mb-1 p-2 d-flex flex-column mr-2")}>
                <small className="d-flex flex-row justify-space-between">
                    <div className="flex-fill">
                        {!!this.props.preview && <FontAwesomeIcon icon={faPlusCircle} className="text-success mr-2" size="lg" />}
                        {!!SupportedFileExtensions.image.includes(ext) && <FontAwesomeIcon size="lg" icon={faImage} className="mr-2" />}
                        {!!SupportedFileExtensions.document.includes(ext) && <FontAwesomeIcon size="lg" icon={faFileAlt} className="mr-2" />}
                        {!!this.props.preview && <span>{`${path}`}</span>}
                        {!this.props.preview && <span className="site-link cursor-pointer" onClick={ this.props.onDownload }>{`${name}`}</span>}
                    </div>
                    <div className="flex-fill d-flex flex-row justify-content-end align-items-center">
                        {!this.props.viewOnly && !this.props.preview && this.props.file.lastModified && <span className="ml-3 text-success">{`Added ${modified}`}</span>}
                        {!!this.props.showFileSize && <span className="ml-2">{fileSizeDisplay}</span>}
                        {!this.props.viewOnly && !!((this.props.progress ?? 0) < 100) && <FontAwesomeIcon icon={faMinusCircle} size="lg" className="text-danger ml-3 cursor-pointer" onClick={this.props.remove} />}
                        {((this.props.progress ?? 0) >= 100) && <FontAwesomeIcon icon={faCheckCircle} size="lg" className="text-success ml-3" />}
                    </div>
                </small>
                {!this.props.viewOnly && ((this.props.progress ?? 0) < 100) && <LinearProgress className="file-upload-progress" variant="determinate" value={this.props.progress ?? 0} />}
            </div>
        );
    }
}