export class SalesOrderTrackingSearchResult {
    id = 0;
    serialNumber = "";
    itemName = "";
    salesOrderNumber = "";
    percentageComplete = 0;
}

export class SalesOrderTrackingSurveySearchResultDTO {
    id = 0;
    workCenterId = 0;
    workCenterSurveyId = 0;
    salesOrderTrackingId = 0;
    workCenterName = "";
    surveyName = "";
    status = "";
    openIssueCount = 0;
    percentageComplete = 0;
}

export class ChassisInfoDTO {
    makeAndModel = "";
    year = "";
    engine = "";
    transmission = "";
    bodyBrand = "";
    bodyType = "";
    cabToAxel = "";
    wheelBase = "";
}
export class SalesOrderTrackingDTO {
    id = 0;
    serialNumber = "";
    itemName = "";
    salesOrderNumber = "";
    workOrderCount = 0;
    salesOrderDocumentName = "";
    salesOrderDocumentId = 0;
    workOrderDocumentName = "";
    workOrderDocumentId = 0;
    mountingInstructionsDocumentName = "";
    mountingInstructionsDocumentId = 0;
    workOrders = [];
    orderDate = null;
    promisedDate = null;
    requiredDate = null;
    customerAddress = "";
    customerCityStateZip = "";
    customer = "";
    drawings = [];
    liftOptions = [];
    mountingInstructions = [];
    electricalDrawings = [];
    hydraulicDrawings = [];
    chassisInfo = new ChassisInfoDTO();
    quoteId = 0;
    mediaItems = [];
    mediaItemCategories = [];
    workFlowTags = []; //List of SalesOrderTrackingWorkFlowStatusTagDTO
}

export class SalesOrderTrackingWorkOrderDTO {
    sYNCWorkCenterIcon = "";
    sYNCWorkCenterName = "";
    apteanWorkCenterName = "";
    sequence = 0;
    status = "";
    startDate = "";
    completedDate = "";
    project = "";
    predecessors = [];
    successors = [];
}

export class SalesOrderTrackingEngineerChangeOrderDTO {
    salesOrderTrackingId = 0;
    workOrderDocumentId = 0;
    mountingInstructionsDocumentId = 0;
}

export class CreateSalesOrderModel {
    quoteId = 0;

    salesOrderFileName = "";
    salesOrderFileDescription = "";
    salesOrderFile = null;
    salesOrderDocumentId = 0;

    workOrderFileName = "";
    workOrderFileDescription = "";
    workOrderFile = null;
    workOrderDocumentId = 0;

    mountingInstructionsFileName = "";
    mountingInstructionsFileDescription = "";
    mountingInstructionsFile = null;
    mountingInstructionsDocumentId = 0;

    customerNumber = "";
    productFamily = "";
    productCategory = "";

    orderDate = null;
    promiseDate = null;
    requiredDate = null;
}

export class SalesOrderTrackingDrawingDTO {
    id = 0;
    number = "";
    url = "";
}

export class SalesOrderTrackingMediaItemDTO
{
    recordId = "";
    path = "";
    name = "";
    category = "";
}

export class SalesOrderTrackingLiftOptionDTO {
    id = 0;
    option = "";
    isCompleted = false;
    modifiedByUser = "";
    modifiedOn = "";
}

export class SalesOrderTrackingMountingInstructionDTO {
    quantity = 0.0;
    partNumber = "";
    description = "";
    instructions = [];
    isCompleted = false;
    modifiedByUser = "";
    modifiedOn = "";
}

export const DocumentTypes = {
    SalesOrder: 1,
    WorkOrder: 2,
    MountingInstructions: 3,
    AnswerDocument: 4,
    QuoteDocument: 5
}

export class WorkflowSurveySectionDTO {
    sectionId = 0;
    questionCount = 0;
    questionsAnsweredCount = 0;
    sectionName = "";
}

export class WorkflowSurveyDTO {
    surveyName = "";
    workCenterSurveyId = 0;
    SalesOrderTrackingSurveyId = 0;
    sections = []; //List of WorkflowSurveySectionDTO
    totalRequiredQuestions = 0;
    totalRequiredQuestionsComplete = 0;
    deleted = false;
}

export class SalesOrderTrackingSurveyOpenIssueDTO {
    id = 0;
    surveyName = "";
    sectionName = "";
    questionText = "";
    answerNotes = "";
    identifiedBy = "";
}

export class SalesOrderTrackingWorkFlowDTO {
    salesOrderTrackingId = 0;
    workCenterName = "";
    iconName = "";
    serialNumber = "";
    model = "";
    workCenterStatus = "";
    openIssueCount = 0;
    completedFormCount = 0;
    totalFormCount = 0;
    openIssues = []; //List of SalesOrderTrackingSurveyOpenIssueDTO
    workflowSurveys = []; //List of WorkflowSurveyDTO
    workCenterComplete = false;
    requiresIssueResolution = false;
}

export class OpenIssueNotesDTO {
    id = 0;
    notes = "";
    notesOn = "Now";
    notesBy = "";
}

export class OpenIssueDTO {
    id = 0;
    serialNumber = "";
    model = "";
    workCenter = "";
    openedDate = new Date();
    resolvedDate = null;

    surveyName = "";
    version = 0.0;
    sectionName = "";
    sectionInstructions = "";
    question = "";
    questionSubText = "";
    answerText = "";
    answerNotes = "";
    answeredOn = "";
    answeredBy = "";
    resolved = false;
    notes = []; //List of OpenIssueNotesDTO
    images = [];
}

export class SalesOrderTrackingWorkFlowStatusTagDTO {
    salesOrderTrackingId = 0;
    workflowTagIds = []; //List lof long integers
}