import React, { Fragment } from 'react';

//Contexts
import { FlexRow, onFieldChange, toasty } from '../common/forms/FormElements';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { QuestionOption } from './FormsAndSurveys';
import { isEqual } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes, faTasks, faPlus } from '@fortawesome/free-solid-svg-icons'
import {
    AppPageForm,
    FlexCenterRow,
    FormGroupColumn,
    FormLabel,
    GroupedRow
} from '../common/forms/FormElements';

import { LinearProgress } from '@material-ui/core';
import { util } from '../Util';

import {
    Button,
    FormGroup,
    Table
} from 'reactstrap';

import { Prompt, withRouter } from 'react-router-dom';
import { handleFormSaveError } from '../common/forms/ValidationError';
import authService from '../api-authorization/AuthorizeService';
import { getUserProfile } from '../common/UserProfile';
import FormErrorResponseDisplay from '../common/forms/FormErrorResponseDisplay';
//#endregion

class QuestionOptionGroupOptionsForm extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            //general form state
            formOpened: false,
            formValidated: false,
            loading: true,
            saving: false,
            errorResponse: {},
            //Form Specific state
            option: new QuestionOption(),
            groups: []
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.save = this.save.bind(this);
        this.saveNew = this.saveNew.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps && (this.props.match.params.id !== (prevProps.match.params ?? {}).id)) {
            this.populateState();
        }
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            let { id } = { ...this.props.match.params };
            const { userPermissions } = await getUserProfile();

            if (id == 'new') {
                id = undefined; //If the Id is identified as being a new UOM, then just kill the id so we don't call the API
            }

            var [optionResponse] = await Promise.all([
                !!id ? util.fetch.post(ApiRoutes.questionoptions.byid(id), util.fetch.format.none) : new QuestionOption()
            ]);

            let grps = [];
            var questionOption;

            //Handle any issues fetching data
            if (!!id && !!optionResponse.error) {
                //Handle erroneous links entered by the user
                let serviceResponse = {
                    title: 'Server Error',
                    errors: {
                        Exception: [
                            optionResponse.error
                        ]
                    }
                }
                this.displaySaveErrors(serviceResponse);
                return false;
            } else {
                questionOption = optionResponse;
                //We bring the groups across as a comma delimited string
                if (questionOption.groups.length) {
                    grps = questionOption.groups.split(',');
                }
            }

            this.setState({
                formValidated: false,
                saving: false,
                loading: false,
                originalData: questionOption,
                perms: userPermissions,
                option: questionOption,
                groups: grps
            });
        }
    }

    //#region METHODS
    onChange = onFieldChange;

    onSubmit() {
        let { option } = { ...this.state };
        this.clearSaving()
        this.setState({ errors: {}, saving: true });

        if (this.props.location.pathname === AppNavPaths.QuestionOptionsNew) {
            this.saveNew(option);
        } else {
            this.save(option);
        }
    }

    clearSaving = () => this.setState({ saving: false });

    notifySuccess = () => toasty.success('Option Saved', `Option saved successfully.`);
    notifyError = (message) => toasty.error('Save Unsuccessful', message);
    handleSaveError = (err) => handleFormSaveError(this, err);

    displaySaveErrors = (response) => this.setState({ errorResponse: response });
    clearSaveErrors = () => this.setState({ errorResponse: {} });

    onCloseClicked = () => {
        this.props.history.push(`${AppNavPaths.QuestionOptions}`);
    }

    onSaveAndAdd = () => {
        let { option } = { ...this.state };
        this.clearSaving()
        this.setState({ errors: {}, saving: true });

        this.saveNew(option, true);
    }

    saveNew = async (option, andAdd) => {
        try {
            let response = await util.fetch.post(ApiRoutes.questionoptions.create(), option, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                let optionId = await response.json();
                this.notifySuccess();

                if (andAdd) {
                    
                    let questionOption = new QuestionOption();

                    this.setState({
                        originalData: questionOption,
                        option: questionOption
                    });
                } else {
                    this.props.history.push(`${AppNavPaths.QuestionOptions}/${optionId}`);
                }
            } else {

                if (response.status === 400) {
                    let serviceResponse = await response.json();
                    this.displaySaveErrors(serviceResponse);
                } else {

                    let errorResp = await response.json();

                    if (errorResp.errors) {
                        this.displaySaveErrors(errorResp);
                    } else {
                        let serviceResponse = {
                            title: 'Server Error',
                            errors: {
                                Exception: [
                                    await response.text()
                                ]
                            }
                        }
                        this.displaySaveErrors(serviceResponse);
                    }
                }
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    save = async (option) => {
        try {
            let response = await util.fetch.put(ApiRoutes.questionoptions.byid(option.id), option, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                this.notifySuccess();
                this.populateState();
            } else {
                this.displaySaveErrors(await response.json());
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    //#endregion

    //#region RENDERING
    render() {
        const { loading, originalData, option, perms, groups } = { ...this.state };
        let { errorResponse } = { ...this.state };

        if (!!loading) {
            return (<LinearProgress variant="indeterminate" color="secondary" />);
        } else {
            const isNew = ((option.id ?? 0) <= 0);
            const saveButtonText = (!isNew ? 'Save' : 'Save New Option');

            //field editing permissions
            const canEdit = {
                option: !!perms.includes("questionoption.edit") || (isNew && !!perms.includes("questionoption.create"))
            };

            return (
                <Fragment>
                    <AppPageForm
                        formId={"optionForm"}
                        formHeadingIcon={faTasks}
                        formHeading={isNew ? 'New Option' : 'Edit Option'}
                        formName={"optionForm"}
                        formRef={this.formRef}
                        onSubmit={this.onSubmit}
                        setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                        isValidated={this.state.formValidated}
                        saving={this.state.saving}
                        errors={this.state.errors}
                        loading={this.state.loading}
                    >
                        <GroupedRow>
                            <FormGroupColumn>
                                <FormGroup>
                                    <FormLabel htmlFor="choiceText" text="Choice Text" required={true} />
                                    <input id="choiceText"
                                        name="option.choiceText"
                                        autoComplete="off"
                                        className="form-control"
                                        required
                                        value={option.choiceText}
                                        disabled={!isNew && !canEdit.option}
                                        onChange={!!canEdit.option ? this.onChange : undefined}
                                    />
                                    <small className="invalid-feedback text-danger" hidden>Choice text is required.</small>
                                </FormGroup>
                            </FormGroupColumn>
                        </GroupedRow>
                        <FlexCenterRow>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>{"Used in Question"}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!!groups.length && groups.map(g => <tr key={g}><td>{g}</td></tr>) }
                                    {!!!groups.length && <tr><td>{"Not Used In Any Questions"}</td></tr> }
                                </tbody>
                            </Table>
                        </FlexCenterRow>
                        <FlexCenterRow className="mb-3">
                            {isNew && !!canEdit.option &&
                                <Button
                                    size="sm"
                                    type="button"
                                    color="primary"
                                    onClick={this.onSaveAndAdd}
                                    name="optionForm"
                                >
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faSave} />
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faPlus} />
                                    {"Save and Add"}
                                </Button>
                            }
                            {!!canEdit.option &&
                                <Button
                                    size="sm"
                                    type="submit"
                                    color="primary"
                                    name="optionForm"
                                    className="ml-2"
                                    disabled={this.state.saving}
                                >
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faSave} />
                                    {saveButtonText}
                                </Button>
                            }
                            <Button
                                size="sm"
                                type="button"
                                color="secondary"
                                name="optionFormClose"
                                disabled={this.state.saving}
                                onClick={this.onCloseClicked}
                                className="ml-2">
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faTimes} />
                                {"Close"}
                            </Button>
                        </FlexCenterRow>
                        <FlexRow>
                            <FormErrorResponseDisplay onClear={this.clearSaveErrors} response={errorResponse} />
                        </FlexRow>
                    </AppPageForm>
                    <Prompt
                        when={!this.state.saving && !isEqual(originalData, option)}
                        message='You have unsaved changes, are you sure you want to leave?'
                    />
                </Fragment>
            );
        }

    }
    //#endregion
}

export default withRouter(QuestionOptionGroupOptionsForm);