import React, { Fragment } from 'react';
import "./SalesOrderTrackingForm.scss";
import { BaseFormViewModel } from '../common/ViewModel';
import { CreateSalesOrderModel, DocumentTypes } from '../workflow/SalesOrderTracking';
import { FormGroup, Button, Input, Row, Col} from 'reactstrap';
import { FormLabel, onFieldChange, onReactSelectChanged, toasty, AppPageForm } from '../common/forms/FormElements';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';
import Dropzone from 'react-dropzone';
import FileLink from '../common/forms/FileLink';
import "react-datepicker/dist/react-datepicker.css";
import authService from '../api-authorization/AuthorizeService';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons_sol from '@fortawesome/free-solid-svg-icons';
import * as Icons_reg from '@fortawesome/free-regular-svg-icons';

const iconList_reg = Object
    .keys(Icons_reg)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons_reg[icon])

library.add(...iconList_reg)

const iconList_sol = Object
    .keys(Icons_sol)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons_sol[icon])

library.add(...iconList_sol)

export default class SalesOrderTrackingEngineerChangeOrderForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        const abortController = new AbortController();

        let stateBase = Object.assign(
            {
                serialNumber: '',
                salesorderTrackingId: null,
                fileCreation: new CreateSalesOrderModel(),
                workCenterId: 0,
                abortController: abortController
            },
            new BaseFormViewModel()
        );

        this.state = stateBase;
        this.onChange = this.onChange.bind(this);
        this.onSelectChanged = this.onSelectChanged.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount = async () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps && 
            (this.props.match.params.id !== (prevProps.match.params ?? {}).id)
        ) {
            this.populateState();
        }
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            const { id, rwcId } = { ...this.props.match.params };
            const { abortController } = { ...this.state };

            if (!!id) {

                let [srt] = await Promise.all([
                    util.fetch.js(
                        ApiRoutes.SalesOrderTracking.baseById(id),
                        { signal: abortController.signal }
                    )
                ]);

                await this.setState({
                    serialNumber: srt.serialNumber,
                    salesorderTrackingId: id,
                    workCenterId: rwcId || 0,
                    loading: false
                });
            }
        }
    }

    onChange = onFieldChange;
    onSelectChanged = onReactSelectChanged;
    handleSaveError = (err) => handleFormSaveError(this, err);

    onClearErrorNotificationClicked = e => {
        e.stopPropagation();
        this.setState({ errors: {} });
    }

    onClearWarningNotificationClicked = e => {
        e.stopPropagation();
        this.setState({ warnings: {} });
    }

    onCancel = async () => {
        let { salesorderTrackingId, workCenterId } = {...this.state };

        if (workCenterId) {
            this.props.history.push(`${AppNavPaths.WorkCenterWorkFlow}/${workCenterId}/${salesorderTrackingId}`)
        } else {
            this.props.history.push(`${AppNavPaths.SalesOrderTracking}/${salesorderTrackingId}`)
        }
    }

    onSubmit = async () => {
        await this.setState({ saving: true });
        this.setState((state) => { return { errors: {} }; });

        let { fileCreation, errors, warnings, salesorderTrackingId, workCenterId } = { ...this.state };

        try {

            let valid = true;

            if (!fileCreation.mountingInstructionsFile) {
                valid = false;
                errors.mountingInstructionsFile = "Must choose a mounting instruction file";
            }

            if (!fileCreation.workOrderFile) {
                valid = false;
                errors.workOrderFile = "Must choose a work order file";
            }

            if (!valid) {
                this.setState({ errors: errors });
                toasty.warning("Please review the form for validation errors");
            } else {

                await this.upload(
                    ApiRoutes.SalesOrderTracking.upload(),
                    fileCreation.workOrderFile.file,
                    fileCreation.workOrderFileName,
                    (fileCreation.workOrderFileDescription ?? ""),
                    'wo'
                );

                await this.upload(
                    ApiRoutes.SalesOrderTracking.upload(),
                    fileCreation.mountingInstructionsFile.file,
                    fileCreation.mountingInstructionsFileName,
                    (fileCreation.mountingInstructionsFileDescription ?? ""),
                    'mi'
                )

                var cff_resp = await util.fetch.post(
                    ApiRoutes.SalesOrderTracking.engineeringChangeOrder(),
                    {
                        SalesOrderTrackingId: salesorderTrackingId,
                        WorkOrderDocumentId: fileCreation.workOrderDocumentId,
                        MountingInstructionsDocumentId: fileCreation.mountingInstructionsDocumentId
                    },
                    util.fetch.format.none //do this so we can properly handle the error
                );

                if (!!cff_resp.ok) {
                    let sotResp = await cff_resp.json();

                    if (!!sotResp.error?.length ?? 0) {
                        errors["Create Change Order"] = sotResp.error;
                        this.setState({ errors: errors });
                        //error
                        toasty.error("There was an error creating the Change Order. See below");
                    } else {
                        if (!!(sotResp.apteanErrors?.length ?? 0)) {
                            warnings.ApteanErrors = sotResp.apteanErrors;
                            toasty.warning("Change Order Created with warnings.");
                        } else {
                            toasty.success("Change Order Created");
                        }

                        if (workCenterId) {
                            this.props.history.push(`${AppNavPaths.WorkCenterWorkFlow}/${workCenterId}/${sotResp.data}`)
                        } else {
                            this.props.history.push(`${AppNavPaths.SalesOrderTracking}/${sotResp.data}`)
                        }
                    }
                }
                else {
                    let resp = await cff_resp.json()
                    errors["Create Change Order"] = resp.message;
                    this.setState({ errors: errors });
                    //error
                    toasty.error("There was an error creating the Change Order. See below");
                }
            }
        } catch (ex) {
            errors.createChangeOrder = ex.toString();
            this.setState({ errors: errors });
            toasty.error("There was an issue creating the Change Order. Please try again or contact support for assistance.");
        } finally {
            this.setState({ saving: false });
        }
    }

    upload = (url, file, name, description, type) => {

        return new Promise((resolve) => {

            let docType = 0;

            switch (type) {
                case 'wo':
                    docType = DocumentTypes.WorkOrder;
                    break;
                case 'mi':
                    docType = DocumentTypes.MountingInstructions;
                    break;
                default:
                    docType = DocumentTypes.WorkOrder;
                    break;
            }

            var formData = new FormData();
            formData.append('file', file, file.path);
            formData.append("documentName", name);
            formData.append("documentDescription", description);
            formData.append("documentTypeId", docType);

            var xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);

            let xsrfToken = util.getCookie('X-DAL-AF');
            xhr.setRequestHeader('X-CSRF-TOKEN', xsrfToken);

            xhr.upload.onprogress = (e) => this.onProgressUpdated(e, type);
            xhr.responseType = 'json';
            xhr.onload = () => {
                let { fileCreation } = { ...this.state };
                let docId = xhr.response;

                if (type === 'wo') {
                    fileCreation.workOrderDocumentId = docId;
                }

                if (type === 'mi') {
                    fileCreation.mountingInstructionsDocumentId = docId;
                }

                this.setState({ fileCreation: fileCreation });
                resolve();
            };
            xhr.send(formData);
        });
    }

    remove = (designator) => {
        let { fileCreation } = { ...this.state };

        switch (designator) {
            case 'workOrder':
                fileCreation.workOrderFile = null;
                break;
            case 'mountInst':
                fileCreation.mountingInstructionsFile = null;
                break;
            default:
                break;
        }
        this.setState({ fileCreation: fileCreation });
    }

    onProgressUpdated = (e, type) => {
        if (e.lengthComputable) {
            var percentage = Math.round((e.loaded / e.total) * 100);

            let { fileCreation } = { ...this.state };

            if (type === 'wo') {
                fileCreation.workOrderFile.progress = percentage;
            }

            if (type === 'mi') {
                fileCreation.mountingInstructionsFile.progress = percentage;
            }

            this.setState({
                fileCreation: fileCreation
            });
        }
    }

    render() {
        let {
            fileCreation,
            formValidated,
            validationMessage,
            loading,
            saving,
            errors,
            warnings,
            serialNumber
        } = this.state;

        const acceptProp = {
            'application/vnd.ms-excel': ['.xls', '.xlsx'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx']
        };

        return (
            <Fragment>
                <AppPageForm
                    formId={"salesOrderTrackingEngineerChangeOrderForm"}
                    formHeadingIcon="fa-edit"
                    formHeading={`Engineering Change Order: ${serialNumber}`}
                    formName={"salesOrderTrackingEngineerChangeOrderForm"}
                    formRef={this.formRef}
                    onSubmit={this.onSubmit}
                    setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                    isValidated={formValidated}
                    saving={this.state.saving}
                    errors={errors}
                    warnings={warnings}
                    loading={loading}
                    onClearErrors={this.onClearErrorNotificationClicked}
                    onClearWarnings={this.onClearWarningNotificationClicked}
                    validationMessage={validationMessage}
                    showThisValidation={false}
                    ValidationBottom={true}
                >
                    <div style={{
                            margin: "0 0.5em 0 0.5em",
                            padding: '1em',
                        }}
                    >
                        {/*Work Order file*/}
                        <Row style={{
                            margin: "1em 0.5em 0 0.5em",
                            borderTop: "2px solid lightgray",
                            borderLeft: "2px solid lightgray",
                            padding: '1em',
                            borderRight: '2px solid lightgray'
                        }}>
                            <Col>
                                <FormGroup>
                                    <FormLabel htmlFor="workOrderFileName"
                                        text="Work Order File Name"
                                        required={true} />
                                    <Input
                                        required={true}
                                        id="workOrderFileName"
                                        name="workOrderFileName"
                                        className="form-control"
                                        value={fileCreation.workOrderFileName ?? ''}
                                        onChange={ev => {
                                            const val = ev?.target?.value;
                                            fileCreation.workOrderFileName = val;
                                            this.setState({ fileCreation: fileCreation });
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <FormLabel htmlFor="workOrderFileDescription"
                                        text="Work Order File Description"
                                    />
                                    <Input
                                        type="textarea"
                                        name="workOrderFileDescription"
                                        value={fileCreation.workOrderFileDescription ?? ''}
                                        onChange={(evt) => {
                                            let value = evt.target.value;
                                            fileCreation.workOrderFileDescription = value;
                                            this.setState({ fileCreation: fileCreation });
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{
                            margin: "0 0.5em 0 0.5em",
                            borderBottom: "2px solid lightgray",
                            borderLeft: "2px solid lightgray",
                            padding: '1em',
                            borderRight: '2px solid lightgray'
                        }}>
                            <Col>
                                <Dropzone
                                    /*Only Accept excel files*/
                                    accept={acceptProp}
                                    multiple={false}
                                    onDrop={(acceptedFiles) => {
                                        let { fileCreation, saving } = { ...this.state };

                                        if (!!saving) {
                                            return false;
                                        }

                                        //We are only uploading a single file at a time and only the first in the list
                                        fileCreation.workOrderFile = { file: acceptedFiles[0], progress: 0.0 };
                                        fileCreation.workOrderFileName = acceptedFiles[0].name; //Always overwrite file name on drop

                                        this.setState({ fileCreation: fileCreation });
                                    }}
                                    inputContent={(files, extra) => (extra.reject ? 'File type not permitted.' : 'Drag and drop your work order file here, or click to select a file')}
                                    maxSize={25000000} /*25MB application wide*/
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <>
                                            {
                                                !!(fileCreation.workOrderFile) &&
                                                <div className="file-uploads-preview">
                                                    <FileLink
                                                        preview={true}
                                                        showFileSize={true}
                                                        key={fileCreation.workOrderFile.file.path}
                                                        url={'#'}
                                                        file={fileCreation.workOrderFile.file}
                                                        remove={() => this.remove('workOrder')}
                                                        progress={fileCreation.workOrderFile.progress}
                                                    />
                                                </div>
                                            }
                                            {!!!(fileCreation.workOrderFile) &&
                                                <section className="file-upload-section" hidden={!!saving}>
                                                    <div {...getRootProps({ className: 'dropzone' })} className="file-upload-section-inner">
                                                        <input {...getInputProps()} />
                                                        <small className="invalid-feedback text-danger" hidden>Work Order file is required.</small>
                                                        <span className="border-bottom mb-2">Drag and drop your work order file here, or click to select a file.</span>
                                                        <small>Supported file types:<span className="ml-2 text-success">{"MS Excel Files (.xls, .xlsx)"}</span></small>
                                                    </div>
                                                </section>
                                            }
                                        </>
                                    )}
                                </Dropzone>
                            </Col>
                        </Row>
                        {/*Mounting Instructions file*/}

                        <Row style={{
                            margin: "1em 0.5em 0 0.5em",
                            borderTop: "2px solid lightgray",
                            borderLeft: "2px solid lightgray",
                            padding: '1em',
                            borderRight: '2px solid lightgray'
                        }}>
                            <Col>
                                <FormGroup>
                                    <FormLabel htmlFor="mountingInstructionsFileName"
                                        text="Mounting Instructions File Name"
                                        required={true} />
                                    <Input
                                        required={true}
                                        id="mountingInstructionsFileName"
                                        name="mountingInstructionsFileName"
                                        className="form-control"
                                        value={fileCreation.mountingInstructionsFileName ?? ''}
                                        onChange={ev => {
                                            const val = ev?.target?.value;
                                            fileCreation.mountingInstructionsFileName = val;
                                            this.setState({ fileCreation: fileCreation });
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <FormLabel htmlFor="mountingInstructionsFileDescription"
                                        text="Mounting Instructions File Description"
                                    />
                                    <Input
                                        type="textarea"
                                        name="mountingInstructionsFileDescription"
                                        value={fileCreation.mountingInstructionsFileDescription ?? ''}
                                        onChange={(evt) => {
                                            let value = evt.target.value;
                                            fileCreation.mountingInstructionsFileDescription = value;
                                            this.setState({ fileCreation: fileCreation });
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{
                            margin: "0 0.5em 0 0.5em",
                            borderBottom: "2px solid lightgray",
                            borderLeft: "2px solid lightgray",
                            padding: '1em',
                            borderRight: '2px solid lightgray'
                        }}>
                            <Col>
                                <Dropzone
                                    /*Only Accept excel files*/
                                    accept={acceptProp}
                                    multiple={false}
                                    onDrop={(acceptedFiles) => {
                                        let { fileCreation, saving } = { ...this.state };

                                        if (!!saving) {
                                            return false;
                                        }

                                        //We are only uploading a single file at a time and only the first in the list
                                        fileCreation.mountingInstructionsFile = { file: acceptedFiles[0], progress: 0.0 };
                                        fileCreation.mountingInstructionsFileName = acceptedFiles[0].name;

                                        this.setState({ fileCreation: fileCreation });
                                    }}
                                    inputContent={(files, extra) => (extra.reject ? 'File type not permitted.' : 'Drag and drop your mounting instructions file here, or click to select a file')}
                                    maxSize={25000000} /*25MB application wide*/
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <>
                                            {
                                                !!(fileCreation.mountingInstructionsFile) &&
                                                <div className="file-uploads-preview">
                                                    <FileLink
                                                        preview={true}
                                                        showFileSize={true}
                                                        key={fileCreation.mountingInstructionsFile.file.path}
                                                        url={'#'}
                                                        file={fileCreation.mountingInstructionsFile.file}
                                                        remove={() => this.remove('mountInst')}
                                                        progress={fileCreation.mountingInstructionsFile.progress}
                                                    />
                                                </div>
                                            }
                                            {!!!(fileCreation.mountingInstructionsFile) &&
                                                <section className="file-upload-section" hidden={!!saving}>
                                                    <div {...getRootProps({ className: 'dropzone' })} className="file-upload-section-inner">
                                                        <input {...getInputProps()} />
                                                        <small className="invalid-feedback text-danger" hidden>Mounting Instructions file is required.</small>
                                                        <span className="border-bottom mb-2">Drag and drop your mounting instructions file here, or click to select a file.</span>
                                                        <small>Supported file types:<span className="ml-2 text-success">{"MS Excel Files (.xls, .xlsx)"}</span></small>
                                                    </div>
                                                </section>
                                            }
                                        </>
                                    )}
                                </Dropzone>
                            </Col>
                        </Row>
                        <Row style={{ padding: '1em' }}>
                            <Col sm={6} md={4} lg={4} >
                                <FormGroup>
                                    <Button type="button"
                                        className="mr-3"
                                        color="success"
                                        disabled={!!saving}
                                        onClick={this.onSubmit}>
                                        <FontAwesomeIcon size="lg" icon={saving ? "fa-circle-notch" : "fa-save"} className={saving ? "mr-2 faSaveSpinner" : "mr-2"} />
                                        {saving ? 'Saving, Please Wait...' : 'Create Change Order'}
                                    </Button>
                                </FormGroup>
                            </Col>
                            <Col sm={6} md={4} lg={4} >
                                <FormGroup>
                                    <Button type="button"
                                        className="mr-3"
                                        disabled={!!saving}
                                        onClick={this.onCancel}>
                                        <FontAwesomeIcon size="lg" icon={"fa-times"} className="mr-2" />
                                        {"Cancel"}
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </AppPageForm>
            </Fragment>
        );
    };
}