import React, { Fragment } from 'react';
import "./OpenIssueForm.scss";

//Contexts
import { FlexRow, onFieldChange, toasty, FlexCenterRow } from '../common/forms/FormElements';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { isEqual} from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTimes,
    faComments,
    faPlusCircle,
    faPaperPlane,
    faSave
} from '@fortawesome/free-solid-svg-icons'
import {
    AppPageForm
} from '../common/forms/FormElements';
import { LinearProgress } from '@material-ui/core';
import { util } from '../Util';
import {
    Button,
    FormGroup,
    Container,
    Row,
    Col
} from 'reactstrap';
import { Prompt, withRouter } from 'react-router-dom';
import { handleFormSaveError } from '../common/forms/ValidationError';
import authService from '../api-authorization/AuthorizeService';
import { getUserProfile } from '../common/UserProfile';
import FormErrorResponseDisplay from '../common/forms/FormErrorResponseDisplay';
import { OpenIssueDTO, OpenIssueNotesDTO } from './SalesOrderTracking';
import { v4 as uuid } from 'uuid';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/dist/styles.css";

class OpenIssueForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            formopened: false,
            formvalidated: false,
            loading: true,
            saving: false,
            perms: [],
            errorresponse: {},
            openIssue: new OpenIssueDTO(),
            currentNewNote: new OpenIssueNotesDTO(),
            lbIsOpen: false,
            lbImgIndex: 0,
            lbImages: []
        }

        this.onChange = this.onChange.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    onChange = onFieldChange;

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps && (this.props.match.params.id !== (prevProps.match.params ?? {}).id))
        {
            this.populateState();
        }
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            let { id } = { ...this.props.match.params };
            const { userPermissions } = await getUserProfile();

            var [response] = await Promise.all([
                util.fetch.get(ApiRoutes.SalesOrderTracking.issueTrackingGet(id), util.fetch.format.json)
            ]);

            var openIssue;

            //handle any issues fetching data
            if (!!response.error) {
                //handle erroneous links entered by the user
                let serviceresponse = {
                    title: 'server error',
                    errors: {
                        exception: [
                            response.error
                        ]
                    }
                }
                this.displaysaveerrors(serviceresponse);
                return false;
            } else {
                openIssue = await response;
            }

            this.setState({
                formvalidated: false,
                saving: false,
                perms: userPermissions,
                loading: false,
                originaldata: openIssue,
                openIssue: openIssue
            });
        }
    }

    onChange = onFieldChange;

    clearSaving = () => this.setState({ saving: false });
    notifySuccess = () => toasty.success('Issue Saved', `Issue saved successfully.`);
    notifyError = (message) => toasty.error('Save Unsuccessful', message);
    handleSaveError = (err) => handleFormSaveError(this, err);
    displaySaveErrors = (response) => this.setState({ errorResponse: response });
    clearSaveErrors = () => this.setState({ errorResponse: {} });

    onCloseClicked = () => {
        this.props.history.push(`${AppNavPaths.IssueTracking}`);
    }

    handleNewNote = () => {
        let { openIssue, currentNewNote } = { ...this.state };

        openIssue.notes.push(currentNewNote);

        this.setState({
            openIssue: openIssue,
            currentNewNote: new OpenIssueNotesDTO()
        });
    }

    onResolveIssue = async () => {
        let { openIssue } = { ...this.state };

        this.setState({ saving: true });

        if (!!!openIssue.notes.length) {
            toasty.error("Issue cannot be resolved without notes.");
            return;
        }

        openIssue.resolved = true;
        
        try {
            let response = await util.fetch.put(ApiRoutes.SalesOrderTracking.issueTrackingPut(), openIssue, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                var serviceResponse = await response.json();
                if (serviceResponse.result === 'SUCCESS') {
                    this.notifySuccess();
                    this.populateState();
                } else {
                    this.notifyError(serviceResponse.message);
                }
            } else {

                if (response.status === 400) {
                    let serviceResponse = await response.json();
                    this.displaySaveErrors(serviceResponse);
                } else {
                    let serviceResponse = {
                        title: 'Server Error',
                        errors: {
                            Exception: [
                                await response.error()
                            ]
                        }
                    }
                    this.displaySaveErrors(serviceResponse);
                }
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    onSaveNotes = async () => {
        let { openIssue } = { ...this.state };

        this.setState({ saving: true });

        if (!!!openIssue.notes.some(n => n.id == 0)) {
            toasty.error("No notes to save.");
            return;
        }

        try {
            let response = await util.fetch.put(ApiRoutes.SalesOrderTracking.issueTrackingPut(), openIssue, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                var serviceResponse = await response.json();
                if (serviceResponse.result === 'SUCCESS') {
                    this.notifySuccess();
                    this.populateState();
                } else {
                    this.notifyError(serviceResponse.message);
                }
            } else {

                if (response.status === 400) {
                    let serviceResponse = await response.json();
                    this.displaySaveErrors(serviceResponse);
                } else {
                    let serviceResponse = {
                        title: 'Server Error',
                        errors: {
                            Exception: [
                                await response.error()
                            ]
                        }
                    }
                    this.displaySaveErrors(serviceResponse);
                }
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    render() {
        const {
            loading,
            openIssue,
            perms,
            originaldata,
            lbImages,
            lbIsOpen,
            lbImgIndex
        } = { ...this.state };

        let { errorResponse, currentNewNote } = { ...this.state };

        (openIssue?.notes ?? []).sort((s1, s2) => s1.openedDate < s2.openedDate ? -1 : 1);

        let canEdit = !!perms.includes("issuetracking.edit");

        let isResolved = !!(openIssue.resolved);
        let canResolve = !!(openIssue.notes ?? []).length;

        if (!!loading) {
            return (<LinearProgress variant="indeterminate" color="secondary" />);
        } else {
            return (
                <Fragment>
                    <AppPageForm
                        formId={"issueTrackingForm"}
                        formHeadingIcon={faComments}
                        formHeading={`Issue: ${openIssue.id} ${openIssue.resolved ? '(Complete)' : '(Open)'}`}
                        formName={"issueTrackingForm"}
                        formRef={this.formRef}
                        setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                        isValidated={this.state.formValidated}
                        saving={this.state.saving}
                        errors={this.state.errors}
                        loading={this.state.loading}
                    >
                        <Container>
                            <Row>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} >
                                    <span>{openIssue.surveyName}</span>
                                </Col>
                                <Col xs={false} sm={false} md={8} lg={8} xl={8} xxl={8} ></Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} >
                                    <span>{openIssue.version.toFixed(1)}</span>
                                </Col>
                                <Col xs={false} sm={false} md={8} lg={8} xl={8} xxl={8}></Col>
                            </Row>
                        </Container>
                        <Container style={{ marginBottom: "1em", padding: "0.5em", border: "1px solid black" }} >
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>{`Section: ${openIssue.sectionName}`}</Col>
                            </Row>
                            {!!openIssue.sectionInstructions &&
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}
                                        style={{ fontSize: "smaller", fontStyle: "italic" }}
                                    >
                                        {openIssue.sectionInstructions}
                                    </Col>
                                </Row>
                            }
                            <Row style={{ marginTop: "1em" }} >
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                    <Container>
                                        <Row style={{ paddingTop: "1em" }} >
                                            <Col xs={6} sm={6} md={8} lg={8} xl={8} xxl={8}>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                    <label >
                                                        {openIssue.question}
                                                    </label>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}
                                                    style={{ fontSize: "smaller", fontStyle: "italic" }}
                                                >
                                                    {openIssue.questionSubText}
                                                </Col>
                                            </Col>
                                            <Col xs={6} sm={6} md={4} lg={4} xl={4} xxl={4}>
                                                <Container>
                                                    <Row>
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                            {openIssue.answerText}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                            {openIssue.answeredBy}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                            {openIssue.answeredOn}
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Col>
                                        </Row>
                                        <Row style={{ paddingTop: "1em" }}>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <label>
                                                    {"Issue Description"}
                                                </label>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <FormGroup>
                                                    <textarea
                                                        disabled={true}
                                                        className={"form-control"}
                                                        value={openIssue.answerNotes}
                                                    ></textarea>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {!!(openIssue.images.length) && 
                                            <Row style={{ paddingTop: "1em" }}>
                                                {openIssue.images.map((i, idx) =>
                                                    <Col key={uuid()} xs={12} sm={6} md={6} lg={3} xl={3} xxl={3} style={{ textAlign: 'center' }} >
                                                        <div className={"answer-image-thumbnail-wrapper"} >
                                                            <img className={"answer-image-thumbnail"}
                                                                src={i.image} alt={""}
                                                                onClick={() => {
                                                                    let imgs = openIssue.images.map((i) => { return { src: i.image } });

                                                                    this.setState({
                                                                        lbIsOpen: true,
                                                                        lbImgIndex: idx,
                                                                        lbImages: imgs
                                                                    });
                                                                }
                                                                }
                                                            />
                                                        </div>
                                                    </Col>
                                                )}
                                            </Row>
                                        }
                                    </Container>
                                </Col>
                            </Row>
                            {!!(openIssue.notes ?? []).length &&
                                openIssue.notes.map((note) =>
                                    <Row key={note.id.toString() + 'issueNote'} >
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                            <Container style={{ marginBottom: "1em", padding: "1em" }}>
                                                <Row>
                                                    <Col xs={6} sm={6} md={8} lg={8} xl={8} xxl={8}>
                                                        {note.notesBy}
                                                    </Col>
                                                    <Col xs={6} sm={6} md={4} lg={4} xl={4} xxl={4}>
                                                        {note.notesOn}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <textarea
                                                            onChange={() => { }}
                                                            disabled={true}
                                                            className={"form-control"}
                                                            value={note.notes}
                                                        ></textarea>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Col>
                                    </Row>
                                )
                            }
                            {!!(canEdit) &&
                                <Row style={{padding:"1em"}} >
                                    <Col xs={6} sm={6} md={8} lg={8} xl={8} xxl={8}>
                                        <textarea
                                            onChange={this.onChange}
                                            name="currentNewNote.notes"
                                            className={"form-control"}
                                            value={currentNewNote.notes}
                                        ></textarea>
                                    </Col>
                                    <Col xs={6} sm={6} md={4} lg={4} xl={4} xxl={4}>
                                        <Button
                                            size="sm"
                                            type="button"
                                            color="primary"
                                            className="ml-2"
                                            disabled={!!!(currentNewNote.notes)}
                                            onClick={() => this.handleNewNote()}
                                        >
                                            <FontAwesomeIcon
                                                className="mr-2"
                                                icon={faPlusCircle} />
                                            {'Add Note'}
                                        </Button>
                                    </Col>
                                </Row>
                            }
                        </Container>
                        <FlexCenterRow className="mb-3">
                            {!!(canEdit) && !!(openIssue.notes.some(n => n.id == 0)) &&
                                <Button
                                    size="sm"
                                    type="button"
                                    color="primary"
                                    disabled={this.state.saving}
                                    className="ml-2"
                                    onClick={() => this.onSaveNotes()}
                                >
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faSave} />
                                    {'Save Notes'}
                                </Button>
                            }
                            {!!!(isResolved) && !!(canResolve) && !!(canEdit) &&
                                <Button
                                    size="sm"
                                    type="button"
                                    color="primary"
                                    disabled={this.state.saving}
                                    className="ml-2"
                                    onClick={() => this.onResolveIssue()}
                                >
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faPaperPlane} />
                                    {'Resolve Issue'}
                                </Button>
                            }
                            <Button
                                size="sm"
                                type="button"
                                color="secondary"
                                disabled={this.state.saving}
                                onClick={this.onCloseClicked}
                                className="ml-2">
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faTimes} />
                                {"Close"}
                            </Button>
                        </FlexCenterRow>
                        <FlexRow>
                            <FormErrorResponseDisplay onClear={this.clearSaveErrors} response={errorResponse} />
                        </FlexRow>
                    </AppPageForm>
                    <Prompt
                        when={!this.state.saving && !isEqual(originaldata, openIssue)}
                        message='You have unsaved changes, are you sure you want to leave?'
                    />
                    <div>
                        <Lightbox
                            open={lbIsOpen}
                            index={lbImgIndex}
                            close={() =>
                                this.setState({
                                    lbIsOpen: false,
                                    lbImgIndex: 0,
                                    lbImages: []
                                })
                            }
                            slides={lbImages}
                        />
                    </div>
                </Fragment>
            )
        }

    }

}
export default withRouter(OpenIssueForm);