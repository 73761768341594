import React, { Fragment } from 'react';

import {
    createDataSource,
    createGridOptions,
    DataGrid,
    // LinkCellWithIconRenderer,
    // ConditionalPureIconCellRenderer,
    // TextFilterDefaults,
    // ConditionalEditLinkCellRenderer,
    // indexCellRenderer
    IconCellRenderer,
    LinkCellRenderer,
    TextFilterDefaults,
    DateFilterDefaults,
    VariableLinkCellRenderer
} from '../common/dataGrid/DataGrid';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import '../../style/DataGridInAppPageFormStyleOverride.scss';

//Contexts
import { FlexRow, onFieldChange, toasty } from '../common/forms/FormElements';
import CommonContext, { ApiRoutes, ApplicationPermissions, AppNavPaths } from '../Common';
import { WorkFlowStatusTag} from './WorkFlowStatusTag';
import { isEqual } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes, faUser } from '@fortawesome/free-solid-svg-icons'
import '../../style/FontIconPicker.scss';
import {
    AppPageForm,
    FlexCenterRow,
    FormGroupColumn,
    FormLabel,
    SubHeading
} from '../common/forms/FormElements';

import { LinearProgress } from '@material-ui/core';
import { util } from '../Util';
import ValidatedSelect from '../common/forms/ValidatedSelect';

import {
    Button,
    FormGroup
} from 'reactstrap';

import { Prompt, withRouter } from 'react-router-dom';
import { handleFormSaveError } from '../common/forms/ValidationError';
import authService from '../api-authorization/AuthorizeService';
import { getUserProfile } from '../common/UserProfile';
import FormErrorResponseDisplay from '../common/forms/FormErrorResponseDisplay';

//#endregion

class WorkFlowStatusTagForm extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            workFlowStatusTag: new WorkFlowStatusTag(),
            mappedInWorkCenters: [],
            mappedOutWorkCenters: [],
            formOpened: false,
            formValidated: false,
            loading: true,
            saving: false,
            statuses: [],
            perms: [],
            errorResponse: {},
            selectedRow: null
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.saveWorkFlowStatusTag = this.saveWorkFlowStatusTag.bind(this);
        this.saveNewWorkFlowStatusTag = this.saveNewWorkFlowStatusTag.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps && (this.props.match.params.id !== (prevProps.match.params ?? {}).id)) {
            this.populateState();
        }
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            const { id } = { ...this.props.match.params };
            const { userPermissions } = await getUserProfile();

            var [workFlowStatusTagResponse, mappedInWorkCentersResponse, mappedOutWorkCentersResponse] = await Promise.all([
                !!id ? util.fetch.get(ApiRoutes.workFlowStatusTag.byId(id), util.fetch.format.none) : new WorkFlowStatusTag(),
                !!id ? util.fetch.get(ApiRoutes.workFlowStatusTag.inWorkCenters(id), util.fetch.format.none) : [],
                !!id ? util.fetch.get(ApiRoutes.workFlowStatusTag.outWorkCenters(id), util.fetch.format.none) : [],
            ]);

            var workFlowStatusTag;
            var mappedInWorkCenters;
            var mappedOutWorkCenters;

            //Handle any issues fetching data
            if (!!id && !workFlowStatusTagResponse.ok) {
                //Handle erroneous links entered by the user
                if (workFlowStatusTagResponse.status === 404)
                    this.props.history.push(AppNavPaths.NotFound);
                else
                    this.props.history.push(AppNavPaths.ServerError);
                return false;
            } else {
                workFlowStatusTag = !!id ? await workFlowStatusTagResponse.json() : workFlowStatusTagResponse;
            }

            if (!!id && !mappedInWorkCentersResponse.ok)
            {
                //Handle erroneous links entered by the user
                if (mappedInWorkCentersResponse.status === 404)
                    this.props.history.push(AppNavPaths.NotFound);
                else
                    this.props.history.push(AppNavPaths.ServerError);
                return false;
            } else {
                mappedInWorkCenters = !!id ? await mappedInWorkCentersResponse.json() : mappedInWorkCentersResponse;
            }

            if (!!id && !mappedOutWorkCentersResponse.ok)
            {
                //Handle erroneous links entered by the user
                if (mappedOutWorkCentersResponse.status === 404)
                    this.props.history.push(AppNavPaths.NotFound);
                else
                    this.props.history.push(AppNavPaths.ServerError);
                return false;
            } else {
                mappedOutWorkCenters = !!id ? await mappedOutWorkCentersResponse.json() : mappedOutWorkCentersResponse;
            }

            //////////////////////////////////////
            const gridOptions = createGridOptions(this);

            gridOptions.components = {
                selectFilter: DataGridSelectFilter,
                selectFloatingFilter: DataGridSelectFloatingFilter,
                nameRenderer: LinkCellRenderer,
                iconRenderer: IconCellRenderer,
                variableLinkRenderer: VariableLinkCellRenderer
            };
            gridOptions.onRowSelected = this.onRowSelected;
            gridOptions.columnDefs = this.getColumnDefs();
            gridOptions.useLoading = true;
            gridOptions.loadingTemplate = '<span className="ag-overlay-loading-center "><i class="faSaveSpinner fa fa-md fa-circle-notch"></i> Loading ...</span>';

            let ds = createDataSource(ApiRoutes.SalesOrderTracking.searchByWorkFlowStatusTagId(id ?? 0), gridOptions);

            this.setState(state => {
                return {
                    loading: false,
                    gridOptions: gridOptions,
                    dataSource: ds
                }
            });
            //////////////////////////////////////
            this.setState(state => {
                return {
                    formValidated: false,
                    saving: false,
                    workFlowStatusTag: workFlowStatusTag,
                    mappedInWorkCenters: mappedInWorkCenters,
                    mappedOutWorkCenters: mappedOutWorkCenters,
                    loading: false,
                    originalData: workFlowStatusTag,
                    perms: userPermissions,
                }
            });
        }
    }

    getColumnDefs() {

        //https://www.ag-grid.com/documentation/javascript/column-definitions/

        let customerFilterParams = {
            suppressFilterButton: true,
            labelText: 'Filter by Customer',
            options: this.state.customers,
            optionsLabel: 'label',
            optionsValue: 'value',
        }

        const defs = [
            {
                headerName: "",
                valueGetter: "node.id",
                sortable: false,
                hide: true,
                flex: 1,
                maxWidth: 35,
                minWidth: 35,
                cellRenderer: this.indexCellRenderer
            },
            {
                colId: 'Id',
                minWidth: 75,
                maxWidth: 75,
                headerName: '',
                sortable: false,
                cellRenderer: 'iconRenderer',
                cellRendererParams: {
                    clicked: id => this.openSalesOrderTrackingSlideOut(id, null),
                    idField: 'id',
                    iconClass: 'fa-edit'
                }
            },
            {
                colId: 'Item_ID_And_Rev',
                headerName: 'Serial#',
                field: 'serialNumber',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'ItemMaster.SalesOrders.First().Sales_Order_ID',
                headerName: 'Quote#',
                field: 'salesOrderNumber',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'ItemMaster.SalesOrders.First().Customer.Customer_Name',
                headerName: 'Dealer/Customer',
                field: 'customer',
                sortable: true,
                flex: 1.5,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: customerFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: customerFilterParams
            },
            {
                colId: 'ItemMaster.Item_Name',
                headerName: 'Model',
                field: 'itemName',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'RequiredDate',
                headerName: 'Required Date',
                field: 'requiredDate',
                sortable: true,
                maxWidth: 120,
                minWidth: 120,
                sort: { direction: 'asc', priority: 0 },
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults
            },
            {
                colId: 'WorkOrderCount',
                headerName: 'Work Orders',
                field: 'workOrderCount',
                sortable: false,
                maxWidth: 140,
                minWidth: 140,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
            }
        }];

        return defs;
    }

    //https://stackoverflow.com/questions/44263350/count-number-of-selected-rows-in-ag-grid
    //Possibly use lodash dequeue?
    onRowSelected(e) {

        const rs = e.api.getSelectedRows();

        this.setState({
            rowsSelected: rs
        });
    }


    //#region METHODS
    onChange = onFieldChange;

    onSubmit() {
        let { workFlowStatusTag } = { ...this.state };
        this.clearSaving()
        this.setState((state) => { return { errors: {}, saving: true }; });

        workFlowStatusTag.systemProvided = false;

        if (this.props.location.pathname === AppNavPaths.WorkFlowStatusTagNew)
            this.saveNewWorkFlowStatusTag(workFlowStatusTag);
        else
            this.saveWorkFlowStatusTag(workFlowStatusTag);
    }

    clearSaving = () => this.setState((state) => { return { saving: false }; });

    notifySuccess = () => toasty.success('Work Flow Status Tag Saved', `Work Flow Status Tag saved successfully.`);
    notifyError = (message) => toasty.error('Save Unsuccessful', message);
    handleSaveError = (err) => handleFormSaveError(this, err);

    displaySaveErrors = (response) => this.setState((state) => { return { errorResponse: response }; });
    clearSaveErrors = () => this.setState((state) => { return { errorResponse: {} }; });

    onCloseClicked = () => {
        this.props.history.push(`${AppNavPaths.WorkFlowStatusTags}`);
    }

    saveNewWorkFlowStatusTag = async (workFlowStatusTag) => {
        try {
            let response = await util.fetch.post(ApiRoutes.workFlowStatusTag.create(), workFlowStatusTag, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                var serviceResponse = await response.json();
                if (serviceResponse.result === 'SUCCESS') {
                    this.notifySuccess();
                    var workFlowStatusTagId = serviceResponse.data;
                    this.props.history.push(`${AppNavPaths.WorkFlowStatusTag}/${workFlowStatusTagId}`);
                } else {
                    this.notifyError(serviceResponse.message);
                }
            } else {

                if (response.status === 400) {
                    let serviceResponse = await response.json();
                    this.displaySaveErrors(serviceResponse);
                } else {
                    let serviceResponse = {
                        title: 'Server Error',
                        errors: {
                            Exception: [
                                await response.text()
                            ]
                        }
                    }
                    this.displaySaveErrors(serviceResponse);
                }
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    saveWorkFlowStatusTag = async (workFlowStatusTag) => {
        try {
            let response = await util.fetch.put(ApiRoutes.workFlowStatusTag.update(workFlowStatusTag.id), workFlowStatusTag, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                var serviceResponse = await response.json();
                if (serviceResponse.result === 'SUCCESS') {
                    this.notifySuccess();
                    this.populateState();
                } else {
                    this.notifyError(serviceResponse.message);
                }
            } else {
                this.displaySaveErrors(await response.json());
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }
    //#endregion

    //#region RENDERING
    getReadOnlyLabel = (text) => <span className="ml-3 pb-3 text-muted font-weight-bold" > {text}</span>;

    render() {
        const { loading, originalData, workFlowStatusTag, mappedInWorkCenters, mappedOutWorkCenters, perms, rowData, gridOptions} = { ...this.state };
        let { errorResponse } = { ...this.state };
        
        if (!!loading) {
            return (<LinearProgress variant="indeterminate" color="secondary" />);
        } else {
            const isNewWorkFlowStatusTag = ((workFlowStatusTag.id ?? 0) <= 0);
            const saveWorkFlowStatusTagButtonText = (!isNewWorkFlowStatusTag ? 'Save' : 'Save New Work Flow Status Tag');

            //field editing permissions
            let canEdit = {
                workFlowStatusTag: !!perms.includes(ApplicationPermissions.work_flow_status_tag_edit),
            };

            let isSystem = workFlowStatusTag?.systemProvided ?? false;

            if (isSystem) {
                canEdit = false;
            }

            let infoSection =
                <Fragment>
                    <hr />
                    <SubHeading className="mb-2 pt-0">{`General Information`}</SubHeading>
                    <FlexCenterRow>
                        <FormGroupColumn>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="inWorkFlowStatusTags"
                                    text="Work Centers where this is an IN tag"
                                    required={false}
                                    helpMessage="Work Centers where this tag is an “In” status tag. When all “In” status tags are on a SN / Sales Order, the SN will show in the Work Center."
                                />
                                <ValidatedSelect
                                    id="inWorkFlowStatusTags"
                                    name="inWorkFlowStatusTags"
                                    isMulti
                                    isDisabled={true}
                                    options={null}
                                    value={mappedInWorkCenters}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.id}
                                    validationMessage=""
                                    readOnly = {true}
                                />
                            </FormGroup>
                        </FormGroupColumn>
                        <FormGroupColumn>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="outWorkFlowStatusTags"
                                    text="Work Centers where this is an OUT tag"
                                    required={false}
                                    helpMessage="Work Centers where this tag is an “Out” status tag. “Out” status tags are applied when the Work Center is completed."
                                />
                                <ValidatedSelect
                                    id="outWorkFlowStatusTags"
                                    name="outWorkFlowStatusTags"
                                    isMulti
                                    isDisabled={true}
                                    options={null}
                                    value={mappedOutWorkCenters}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.id}
                                    validationMessage=""
                                    readOnly={true}
                                />
                            </FormGroup>
                        </FormGroupColumn>
                    </FlexCenterRow>
                    <DataGridToolbar
                        gridTitle="Sales Order Tracking References"
                        mobileTitle="Sales Order Tracking References"
                        entity="work_center"
                        gridApi={this.state.gridApi}
                        dataSource={this.state.dataSource}
                        hideAdd={true}
                        addIcon={'fa-user-plus'}
                        hideExcelButton={true}
                        gridOptions={this.state.gridOptions}
                        //TODO: excel export endpoint
                        serverExport={{ apiPath: ApiRoutes.employees.excelExport(), filePrefix: 'WorkCenterSearch' }}
                        serverExportDisabled={!!this.state.saving || !!this.state.loading || !!this.state.loadingData}
                    >
                    </DataGridToolbar>
                    <DataGrid
                        domLayout={"normal"}
                        rowData={rowData}
                        gridOptions={gridOptions}
                        gridStatus={this.state.gridStatus}
                    />
                </Fragment>

            return (
                <Fragment>
                    <AppPageForm
                        formId={"workFlowStatusTagForm"}
                        formHeadingIcon={faUser}
                        formHeading={isNewWorkFlowStatusTag ? 'New Work Flow Status Tag' : isSystem ? 'System Provided Status Flag' : 'Edit Work Flow Status Tag'}
                        formName={"workFlowStatusTagForm"}
                        formRef={this.formRef}
                        onSubmit={this.onSubmit}
                        setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                        isValidated={this.state.formValidated}
                        saving={this.state.saving}
                        errors={this.state.errors}
                        loading={this.state.loading}
                    >
                        <input type="hidden" id="systemProvided" name="workFlowStatusTag.systemProvided" value="false"></input>
                        <SubHeading className="pt-3">{`Configuration`}</SubHeading>
                        <FlexCenterRow>
                            <FormGroupColumn>
                                <FormGroup>
                                    <FormLabel htmlFor="inputName" text="Work Flow Status Tag Status" required={true} />
                                    <input id="status"
                                        name="workFlowStatusTag.status"
                                        autoComplete="off"
                                        className="form-control"
                                        required
                                        disabled={!isNewWorkFlowStatusTag && !canEdit.workFlowStatusTag}
                                        onChange={!!canEdit.workFlowStatusTag ? this.onChange : undefined }
                                        defaultValue={workFlowStatusTag.status} />
                                    <small className="invalid-feedback text-danger" hidden>Work flow status tag name is required.</small>
                                </FormGroup>
                            </FormGroupColumn>
                        </FlexCenterRow>
                        <FlexCenterRow className="mb-3">
                            {!isSystem && <Button
                                size="sm"
                                type="submit"
                                color="primary"
                                disabled={this.state.saving}
                                name="workFlowStatusTagForm">
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faSave} />
                                {saveWorkFlowStatusTagButtonText}
                            </Button>}
                            <Button
                                size="sm"
                                type="button"
                                color="secondary"
                                disabled={this.state.saving}
                                name="workFlowStatusTagFormClose"
                                onClick={this.onCloseClicked}
                                className="ml-2">
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faTimes} />
                                {"Close"}
                            </Button>
                        </FlexCenterRow>
                        <FlexRow>
                            <FormErrorResponseDisplay onClear={this.clearSaveErrors} response={errorResponse} />
                        </FlexRow>
                    {!!originalData && !!originalData.id && infoSection}
                    </AppPageForm>
                    <Prompt
                        when={!this.state.saving && !isEqual(originalData, workFlowStatusTag)}
                        message='You have unsaved changes, are you sure you want to leave?'
                    />
                </Fragment>
            );
        }

    }
    //#endregion
}

export default withRouter(WorkFlowStatusTagForm);