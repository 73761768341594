import React, { Fragment } from 'react';
import {
    Table,
    Card,
    CardBody,
    CardTitle,
    CardText,
    Row,
    Button
} from 'reactstrap';
import {
    AppPageForm,
    FlexRow
} from '../common/forms/FormElements';
import { withRouter } from 'react-router-dom';
import CommonContext, { ApiRoutes, ApplicationPermissions, AppNavPaths } from '../Common';
import { handleFormSaveError } from '../common/forms/ValidationError';
import authService from '../api-authorization/AuthorizeService';
import { getUserProfile } from '../common/UserProfile';
import FormErrorResponseDisplay from '../common/forms/FormErrorResponseDisplay';
import { LinearProgress } from '@material-ui/core';
import { util } from '../Util';

//Suports the font-awesome icon picker
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

//End icon picker support

class WorkCenterMap extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            formOpened: false,
            formValidated: false,
            loading: true,
            perms: [],
            workCenterMapping: [],
            errorResponse: {}
        }
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            const { userPermissions } = await getUserProfile();

            var [workCenterMapResponse] = await Promise.all([
                util.fetch.get(ApiRoutes.workCenters.getMap(), util.fetch.format.none)
            ]);

            var workCenterMapping;

            //Handle any issues fetching data
            if (!!workCenterMapResponse.error) {
                //Handle erroneous links entered by the user
                let serviceResponse = {
                    title: 'Server Error',
                    errors: {
                        Exception: [
                            workCenterMapResponse.error
                        ]
                    }
                }
                this.displaySaveErrors(serviceResponse);
                return false;
            } else {
                workCenterMapping = await workCenterMapResponse.json();
            }

            this.setState({
                formValidated: false,
                saving: false,
                loading: false,
                perms: userPermissions,
                workCenterMapping: workCenterMapping
            });
        }
    }

    handleSaveError = (err) => handleFormSaveError(this, err);

    onCloseClicked = () => {
        this.props.history.push(`${AppNavPaths.WorkCenters}`);
    }

    render() {
        const {
            loading,
            perms,
            workCenterMapping
        } = { ...this.state };
        let { errorResponse } = { ...this.state };

        //Get largest array length in mapping
        let columnCount = Math.max(...workCenterMapping.map(wcm => wcm.length));
        let headers = [];
        let cells = [];

        for (let cnt = 0; cnt < columnCount; cnt++) {
            //We just need an array of objects to map
            headers.push({});
            cells.push({});
        }

        if (!!loading) {
            return (<LinearProgress variant="indeterminate" color="secondary" />);
        } else {

            return (
                <Fragment>
                    <AppPageForm
                        formId={"workCenterMapForm"}
                        formHeadingIcon={"fa-map"}
                        formHeading={'Work Center Map'}
                        formName={"workCenterMapForm"}
                        formRef={this.formRef}
                        errors={this.state.errors}
                        loading={this.state.loading}
                    >
                        {!!!perms.includes(ApplicationPermissions.work_center_view) &&
                            <h5>User Does not Have Permission to View this Page</h5>
                        }
                        {!!perms.includes(ApplicationPermissions.work_center_view) &&
                            <Row>
                                <Table striped responsive>
                                    <thead>
                                        <tr>
                                            <th>
                                                <Button
                                                    size="sm"
                                                    type="button"
                                                    color="secondary"
                                                    name="workCenterMapClose"
                                                    onClick={this.onCloseClicked}
                                                    className="ml-2">
                                                    <FontAwesomeIcon
                                                        className="mr-2"
                                                        icon="fa-times" />
                                                    {"Close"}
                                                </Button>
                                            </th>
                                            {headers.map((h, ind) =>
                                                <th key={ind.toString() + 'headerCell'}></th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {workCenterMapping.map((wcm, ind) =>
                                            <tr key={ind.toString() + 'workCenterRow'}>
                                                {!!(ind == 0) &&
                                                    <th key={ind.toString() + 'rowheader'} >UnMapped Sync Centers</th>
                                                }
                                                {!!(ind != 0) &&
                                                    <th key={ind.toString() + 'rowheader'}>{`Phase: ${ind}`}</th>
                                                }
                                                {
                                                    cells.map((c, ind) => 
                                                        <td key={ind.toString() + 'rowcell'}>
                                                            {!!(ind < wcm.length) &&
                                                                <Card style={{ textAlign: "center", border: wcm[ind].syncWorkCenterName !== 'None' ? '4px dashed rgb(5, 242, 33)' : '1px solid rgba(0, 0, 0, 0.125)' }}>
                                                                    <CardBody>
                                                                        <CardTitle tag="h5" style={{whiteSpace: "nowrap"} } >
                                                                            <FontAwesomeIcon size='sm' icon={wcm[ind].syncWorkCenterIcon} />
                                                                            {' '}
                                                                            {/*wcm[ind].syncWorkCenterName !== 'None' ? wcm[ind].syncWorkCenterName + ((wcm[ind].apteanWorkCenterName ?? "").length ? "(" + wcm[ind].apteanWorkCenterName + ")" : "") : wcm[ind].apteanWorkCenterName*/}
                                                                            {wcm[ind].syncWorkCenterName !== 'None' ? wcm[ind].syncWorkCenterName : wcm[ind].apteanWorkCenterName}
                                                                        </CardTitle>
                                                                        <CardText>
                                                                            {wcm[ind].description}
                                                                        </CardText>
                                                                    </CardBody>
                                                                </Card>
                                                            }
                                                        </td>   
                                                    )
                                                }
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Row>
                        }
                        <FlexRow>
                            <FormErrorResponseDisplay response={errorResponse} />
                        </FlexRow>
                    </AppPageForm>
                </Fragment>
            );
        }
    }

}
export default withRouter(WorkCenterMap);
