import React from 'react';
import CommonContext, { AppNavPaths, ApiRoutes } from './components/Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faBuilding, faChartLine, faClipboardCheck, faComments } from '@fortawesome/free-solid-svg-icons'
import { faTags } from '@fortawesome/fontawesome-free-solid';
import { Row, Card, CardBody, CardTitle, CardSubtitle, Container, NavLink } from 'reactstrap';
import { Link, withRouter } from "react-router-dom";
import { Can } from './components/Can';
import { CircularProgress } from '@material-ui/core';
import authService from './components/api-authorization/AuthorizeService';
import { util } from './components/Util';

import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons_sol from '@fortawesome/free-solid-svg-icons';
import * as Icons_reg from '@fortawesome/free-regular-svg-icons';

const iconList_reg = Object
    .keys(Icons_reg)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons_reg[icon])

library.add(...iconList_reg)

const iconList_sol = Object
    .keys(Icons_sol)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons_sol[icon])

library.add(...iconList_sol)

class Dashboard extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);

        const abortController = new AbortController();

        this.state = {
            loading: true,
            notification: null,
            abortController: abortController
        }
    }

    componentDidMount = async () => {
        this._subscription = authService.subscribe(() => this.populateState());
        await this.populateState();

        this.getWorkCenterCounts();
    }

    getWorkCenterCounts = async () => {
        let { mappedUserWorkCenters, abortController } = { ...this.state };

        mappedUserWorkCenters.forEach(async (wc) => {
            if (!abortController.signal.aborted) {
                
                util.fetch.get(
                    ApiRoutes.workCenter.getWorkCenterActiveCount(wc.id),
                    util.fetch.format.none,
                    { signal: abortController.signal }
                ).then(async (activeCountResponse) => {
                    //If aborted or errored out, then we may not have a response to check.
                    if (activeCountResponse) {
                        if (!activeCountResponse.ok) {
                            wc.loadingActive = false;
                        } else {
                            var wcCounts = await activeCountResponse.json();
                            wc.activeCount = wcCounts.count;
                            wc.loadingActive = false;
                        }
                        this.setState({
                            mappedUserWorkCenters: mappedUserWorkCenters
                        });
                    }
                });
            }
           
        });
    }

    componentWillUnmount = () => {
        const { abortController } = { ...this.state };

        abortController.abort();

        this.setState = (state, callback) => { return }
    };


    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {

            var [mappedUserWorkCenterResponse] = await Promise.all([
                util.fetch.get(ApiRoutes.workCenter.workcentersbyuser(), util.fetch.format.none),
            ]);

            var mappedUserWorkCenters;

            if (!mappedUserWorkCenterResponse.ok)
            {
                if (mappedUserWorkCenterResponse.status === 404)
                    this.props.history.push(AppNavPaths.NotFound);
                else
                    this.props.history.push(AppNavPaths.ServerError);
                return false;
            } else {
                var mappedUserWorkCentersRaw = await mappedUserWorkCenterResponse.json();

                mappedUserWorkCenters = mappedUserWorkCentersRaw.map((wc) => {
                    wc.activeCount = 0;
                    wc.loadingActive = true;
                    return wc;
                });
            }

            this.setState({
                loading: false,
                mappedUserWorkCenters: mappedUserWorkCenters
            });
        }

    }

    render = () => {
        const { loading, mappedUserWorkCenters } = { ...this.state };

        if (!!loading) {
            return (
                <div className="confirmationContainer h-100 pt-2 d-flex flex-row align-items-center justify-content-center">
                    <CircularProgress color="secondary" variant="indeterminate" />
                </div>
            )
        }

        let shippingWC = mappedUserWorkCenters.find((mwc) => mwc.isShippingActivity);

        let areas = [
            {
                icon: faClipboardCheck,
                url: AppNavPaths.Quotes,
                name: "Quotes",
                permission: "quote",
                action: "view"
            },
            {
                icon: faUser,
                url: AppNavPaths.Employees,
                name: "Employees",
                permission: "employee",
                action: "view"
            },
            {
                icon: faBuilding,
                url: AppNavPaths.WorkCenters,
                name: "Work Centers",
                permission: "work_center",
                action: "view"
            },
            {
                icon: faChartLine,
                url: AppNavPaths.SalesOrderTracking,
                name: "Order Tracking",
                permission: "sales_order_tracking",
                action: "view"
            },
            {
                icon: faTags,
                url: AppNavPaths.WorkFlowStatusTags,
                name: "Work Flow Status Tags",
                permission: "work_flow_status_tag",
                action: "view"
            },
            {
                icon: faComments,
                url: AppNavPaths.IssueTracking,
                name: "Issue Tracking",
                permission: "issuetracking",
                action: "view"
            },
            {
                icon: Icons_sol.faCogs,
                url: AppNavPaths.QuoteChangeOrders,
                name: "Change Orders",
                permission: "quote_change_order",
                action: "view"
            },
            {
                icon: Icons_sol.faTruck,
                url: AppNavPaths.Shipping,
                name: "Shipping",
                permission: "shipping",
                action: "view",
                workCenter: shippingWC
            }
        ];

        return (
            <Container className="h-100 w-100 overflow-x-hidden overflow-y-auto background-white">
                <Row className="dashboard-row">
                    {/*Eventually, we need to check if the user has any of these areas before showing this heading*/ }
                    <div style={{ width: "95%", borderBottom: "1px solid black", marginBottom: "1em" }}>Areas</div>
                    {areas.filter((a) => a.name != 'Shipping' ).map(a =>
                        <Can key={a.name} do={a.action} on={a.permission}>
                            <NavLink className="dashboard-link" tag={Link} to={a.url}>
                                <Card>                                  
                                    <CardBody>
                                        <CardTitle className="d-flex flex-column align-items-center">
                                            {!a.iconStack && <FontAwesomeIcon className="text-muted" size="4x" icon={a.icon} />}
                                            {
                                                !!a.iconStack &&
                                                <span className="fa-stack fa-2x text-muted">
                                                    <i className={`fas ${a.iconStack.primary} fa-stack-2x`}
                                                        data-count={a.iconStack?.count}></i>
                                                    <i className={`dashboard-stacked-icon text-danger fas ${a.iconStack.secondary} fa-stack-1x ml-3 mt-3${!!a.iconStack?.rounded ? ' rounded' : ''}`}                                                        
                                                    ></i>
                                                </span>
                                            }
                                        </CardTitle>
                                        <CardSubtitle className="d-flex flex-column align-items-center">
                                            <h4 className="mt-1 pt-1 pb-1 text-center border-red-top-bottom">{a.name}</h4>
                                        </CardSubtitle>
                                    </CardBody>
                                </Card>
                            </NavLink>
                        </Can>
                    )}
                    {areas.filter((a) => a.name == 'Shipping' ).map(a =>
                        <Can key={a.name} do={a.action} on={a.permission}>
                            <NavLink className="dashboard-link" tag={Link} to={a.url}>
                                <Card>
                                    {!!a.workCenter && a.workCenter.loadingActive &&
                                        <FontAwesomeIcon style={{ position: "absolute", right: "0.5em", top: "0.5em" }} size="lg" icon={"fa-circle-notch"} className={"mr-2 faSaveSpinner"} />
                                    }
                                    {!!a.workCenter && !a.workCenter.loadingActive && a.workCenter.activeCount != 0 &&
                                        <span style={{ position: "absolute", right: "0.5em", top: "0.5em" }} className="nav-menu-notification-badge badge badge-lg badge-danger ml-1">
                                            {a.workCenter.activeCount}
                                        </span>
                                    }
                                    <CardBody>
                                        <CardTitle className="d-flex flex-column align-items-center">
                                            {!a.iconStack && <FontAwesomeIcon className="text-muted" size="4x" icon={a.icon} />}
                                            {
                                                !!a.iconStack &&
                                                <span className="fa-stack fa-2x text-muted">
                                                    <i className={`fas ${a.iconStack.primary} fa-stack-2x`}
                                                        data-count={a.iconStack?.count}></i>
                                                    <i className={`dashboard-stacked-icon text-danger fas ${a.iconStack.secondary} fa-stack-1x ml-3 mt-3${!!a.iconStack?.rounded ? ' rounded' : ''}`}
                                                    ></i>
                                                </span>
                                            }
                                        </CardTitle>
                                        <CardSubtitle className="d-flex flex-column align-items-center">
                                            <h4 className="mt-1 pt-1 pb-1 text-center border-red-top-bottom">{a.name}</h4>
                                        </CardSubtitle>
                                    </CardBody>
                                </Card>
                            </NavLink>
                        </Can>
                    )}
                </Row>
                {!!mappedUserWorkCenters.length && 
                    <Row className="dashboard-row">
                        <div style={{ width: "95%", borderBottom: "1px solid black", marginBottom: "1em" }}>Work Centers</div>
                        {mappedUserWorkCenters.filter((wc) => !wc.isShippingActivity).map(wc =>
                            <NavLink className="dashboard-link" tag={Link} to={`${AppNavPaths.WorkCenterWorkFlow}/${wc.id}`} key={wc.name}>
                                <Card> 
                                    {wc.loadingActive &&
                                        <FontAwesomeIcon style={{ position: "absolute", right: "0.5em", top: "0.5em" }} size="lg" icon={"fa-circle-notch"} className={"mr-2 faSaveSpinner"} />
                                    }
                                    {!wc.loadingActive && wc.activeCount != 0 &&
                                        <span style={{position: "absolute", right: "0.5em", top: "0.5em"}} className="nav-menu-notification-badge badge badge-lg badge-danger ml-1">
                                            {wc.activeCount}
                                        </span>
                                    }
                                    <CardBody>
                                        <CardTitle className="d-flex flex-column align-items-center">
                                            <FontAwesomeIcon className="text-muted" size="4x" icon={wc.iconName} />
                                        </CardTitle>
                                        <CardSubtitle className="d-flex flex-column align-items-center">
                                            <h4 className="mt-1 pt-1 pb-1 text-center border-red-top-bottom">{wc.name}</h4>
                                        </CardSubtitle>
                                    </CardBody>
                                </Card>
                            </NavLink>
                        )}
                    </Row>
                }
            </Container>
        );
    }
}
export default withRouter(Dashboard);