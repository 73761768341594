import React from 'react';
import _ from 'lodash';
import authService from './api-authorization/AuthorizeService'
import { ValidationError, HandledError } from './common/forms/ValidationError';
import moment from 'moment';
import cloneDeep from 'lodash-es/cloneDeep';
import { FlexColumnStart, toasty } from './common/forms/FormElements';

//RLC: Syntactic sugar for vanilla js and other productivity shortcuts.
//================================================================

//Utility services
export const util = {
    //return true if variable equates to undef, null, empty string, empty array, empty object 
    isEmpty: (value) => {
        return (
            (value == null) ||
            (value.hasOwnProperty('length') && value.length === 0) ||
            (value.constructor === Object && Object.keys(value).length === 0)
        )
    },

    form: {
        submitAsync: async function (formElementId) {
            var oFormElement = document.getElementById(formElementId);
            const formData = new FormData(oFormElement);
            return fetch(oFormElement.action, {
                headers: {
                    "RequestVerificationToken": document.getElementsByName("__RequestVerificationToken")[0].value
                },
                method: 'POST',
                body: formData
            });
        },
        serialize: function (formElementId) {
            var obj = {};
            var formData = new FormData(document.getElementById(formElementId));
            for (var key of formData.keys()) {
                obj[key] = formData.get(key);
            }
            return obj;
        }
    },

    array: {
        /**
         * RLC: Upserts objects in an array by provided key match.
         * @param {Object} obj
         * @param {Array} array
         * @param {String} key
         */
        upsert: function (obj, array, key) {
            let cloned = util.object.clone(obj);
            var inx = array.findIndex(item => item[key] === cloned[key]);
            if (inx >= 0) {
                array[inx] = cloned;
            } else {
                array.push(cloned);
            }
            return array;
        },
        /**
         * RLC: Upserts to primitive array.
         * @param {any} array
         * @param {any} value
         */
        insertIfNotExists: function (array, value) {
            if (!value || !array)
                throw new Error('util.array.upsertPrimitive: @array and @value parameters are required.');

            var inx = array.findIndex(item => item === value);
            if (inx < 0) {
                array.push(value);
            }
            return array;
        },
        baseSort: (array, property, ascending) => array.sort((a, b) => (a[property] > b[property] ? (ascending ? 1 : -1) : ascending ? -1 : 1)),
        sortAscending: (array, property) => util.array.baseSort(array, property, true),
        sortDescending: (array, property) => util.array.baseSort(array, property, false),
        areCommon: (a1, a2) => {
            const [shortArr, longArr] = (a1.length < a2.length) ? [a1, a2] : [a2, a1];
            const set = new Set(longArr);
            return shortArr.some(el => set.has(el));
        },
        uniqueObjects: objectsArray => {
            const unique = [...new Set(objectsArray.map((o) => JSON.stringify(o))),].map((string) => JSON.parse(string));
            return unique;
        }
    },

    debounce: function (func, wait, immediate) {
        var timeout;
        return function () {
            var context = this, args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    },

    function: {
        async: function (e) {
            return new Promise((resolve, reject) => {
                setTimeout(() => resolve(e), e * 1000);
            });
        }
    },

    //https://stackoverflow.com/a/67933343/1620827
    getCookie: function (name) {
        let cookieValue = '';
        if (document.cookie && document.cookie !== '') {
            let cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                let cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    },

    //Wrapper over the Fetch API, includes ASP AAF token.
    //TODO: RLC - Need to be able to pass in accept/datatype
    fetch: {
        statusCodes: {
            badRequest: 400,
            unauthorized: 401,
            forbidden: 403,
            notFound: 404,
            methodNotAllowed: 405,
            internalServerError: 500,
            notImplemented: 501,
            serviceNotAvailable: 503
        },
        format: {
            blob: 'blob',
            file: 'file',
            json: 'json',
            none: 'none' //naked fetch, promise response can be manually manipulated
        },
        types: {
            get: 'GET', put: 'PUT', post: 'POST', delete: 'DELETE'
        },
        base: async function (url, method, data, format, options) {

            //RLC: ASP.NET anti forgery token inclusion, if present.
            var aaf_token = null;

            let xsrfToken = util.getCookie('X-DAL-AF');

            var token_elms = document.getElementsByName("__RequestVerificationToken") ?? [];
            if (!!token_elms.length)
                aaf_token = token_elms[0].value

            //RLC: Get OIDC token.
            const token = await authService.getAccessToken();

            var opt = {
                //redirect: 'follow' /* follow 302s */,
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'X-CSRF-TOKEN': xsrfToken
                },
                ...options
            };

            if (aaf_token)
                opt.headers.RequestVerificationToken = aaf_token;

            if (token)
                opt.headers.Authorization = `Bearer ${token}`

            if ((method === 'POST' || method === 'PUT' || method === 'DELETE') && data) {
                if (data) {
                    opt.body = JSON.stringify(data, function (key, value) {
                        if (value === undefined) {
                            return null;
                        }
                        return value;
                    });
                }
            }

            if (format === util.fetch.format.none)
                return fetch(url, opt).catch((error) => {
                    if (error.name !== "AbortError") {
                        //handle other errors
                        console.error("Error occurred:", error);
                        throw error;
                    }
                });
            else
                //Fetch with redirect / error handling / validation
                return fetch(url, opt)
                    .then(async (response) => {
                        //400-500
                        if (!response.ok) {
                            //Server errors
                            if (response.status >= 500 && response.status <= 599) {
                                //Try to handle service response errors and messages with this
                                if (response.status == 500) {
                                    let rj = await response.json();
                                    if (!!rj.error) {
                                        throw new HandledError(rj);
                                    }
                                }
                                else {
                                    throw new Error('Server Error (500) Occurred');
                                }
                            }
                            else if (response.status === util.fetch.statusCodes.methodNotAllowed) {
                                throw new Error('Server Error (405) Method Not Allowed');
                            }
                            else if (response.status === util.fetch.statusCodes.forbidden) {
                                throw new Error('Server Error (403) Occurred - Forbidden/Permission Denied');
                            }
                            else if (response.status === util.fetch.statusCodes.unauthorized) {
                                throw new Error('Server Error (401) Occurred - Unauthorized');
                            }
                            else if (response.status === util.fetch.statusCodes.badRequest) {
                                let rj = await response.json()
                                if (rj.title === "One or more validation errors occurred.") {
                                    throw new ValidationError(rj.errors);
                                }
                            }
                            else {
                                throw new Error(`Server Error Occurred fetching ${url}: ${response.statusText}`);
                            }

                            //200
                        } else {
                            //Files/blob
                            if (format === util.fetch.format.blob) {
                                return await response.blob().then(b => URL.createObjectURL(b));
                            }
                            //Quick json fetch
                            else if (format === util.fetch.format.json) {
                                return await response.json()
                            }
                            else if (format === util.fetch.format.file) {
                                let headers = [...response.headers];

                                let fileNameHeader = headers.find((h) => h[0] == 'usefilename');
                                if (!!(fileNameHeader ?? []).length) {
                                    let filename = fileNameHeader[1];
                                    return {
                                        url: await response.blob().then(b => URL.createObjectURL(b)),
                                        fileName: filename
                                    }
                                } else {
                                    let fileNameHeader = headers.find((h) => h[0] == 'content-disposition');
                                    if (!!(fileNameHeader ?? []).length) {
                                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                                        var matches = filenameRegex.exec(fileNameHeader[1]);
                                        if (matches != null && matches[1]) {
                                            let filename = matches[1].replace(/['"]/g, '');
                                            return {
                                                url: await response.blob().then(b => URL.createObjectURL(b)),
                                                fileName: filename
                                            }
                                        }
                                    }
                                }

                                return {
                                    url: await response.blob().then(b => URL.createObjectURL(b)),
                                    fileName: ""
                                };
                            }
                            else return await response.json() //TODO: make provision for when someone wants a naked fetch / custom                        
                        }
                    }).catch((err) => {
                        //If this is an abort error, just swallow it.
                        if (err.name !== "AbortError") {
                            console.log(err);
                            throw err;
                        }
                    });
        },

        downloadFile: async function (url, data, fileName, options) {
            const response = await util.fetch.post(url, data, util.fetch.format.blob, options);
            const a = document.createElement("a");
            a.href = response;
            a.target = "_blank";
            a.download = fileName;
            document.body.appendChild(a);
            a.click();

            a.remove();
        },

        post: function (url, data, format, options) {
            return util.fetch.base(url, 'POST', data, format, options);
        },

        get: function (url, format, options) {
            return util.fetch.base(url, 'GET', null, format, options);
        },

        put: function (url, data, format, options) {
            return util.fetch.base(url, 'PUT', data, format, options);
        },
        delete: function (url, data, format, options) {
            return util.fetch.base(url, 'DELETE', data, format, options);
        },

        js: function (url, options) {
            return util.fetch.base(url, 'GET', null, util.fetch.format.json, options);
        },

        //RLC: a somewhat OSFA handler for fetch, with error display.
        andGetResponse: async (type, url, data, errorHeader, onFinally) => {
            if (!type || !url)
                throw new Error('util.fetch.andGetResponse: Missing required arguments.');

            let fetcher = null;

            switch (type) {
                case util.fetch.types.post:
                    fetcher = util.fetch.post;
                    break;
                case util.fetch.types.put:
                    fetcher = util.fetch.put;
                    break;
                case util.fetch.types.get:
                    fetcher = util.fetch.get;
                    break;
                case util.fetch.types.delete:
                    fetcher = util.fetch.delete;
                    break;
                default:
                    throw new Error('util.fetch.andGetResponse: Type not found.');
            }

            try {
                let response = await fetcher(url, data, util.fetch.format.none);
                if (response.redirected) {
                    window.location.href = response.url;
                    return false;
                } else if (!!response.ok) {
                    return await response.json();
                } else {
                    var message = '';
                    try {
                        var errResponseData = await response.json();
                        message = errResponseData.message;
                    } catch {

                    } finally {
                        toasty.error(errorHeader ?? 'Error When Processing Request',
                            <FlexColumnStart>
                                <span>There was a server error:</span>
                                {!!message && <span className="pt-2 pb-2 font-weight-bold">{`${message}`}</span>}
                                <span>Please try your request again or contact support for assistance.</span>
                            </FlexColumnStart>
                        );
                    }
                }
            } catch (error) {
                toasty.error((errorHeader ?? 'Server Error'), `There was a server error. ${(!!error?.message ? `[${error.message}]` : '')}  Please try your request again or contact support for assistance.`);
                return null;
            } finally {
                !!onFinally && onFinally();
            }
        }
    },

    file: {
        printFileSize: (bytes, si = false) => {
            let u, b = bytes, t = si ? 1000 : 1024;
            ['', si ? 'k' : 'K', ...'MGTPEZY'].find(x => (u = x, b /= t, b ** 2 < 1));
            return `${u ? (t * b).toFixed(1) : bytes} ${u}${!si && u ? 'i' : ''}B`;
        },
    },

    json: function (json) {
        return JSON.stringify(json, function (key, value) {
            if (value === undefined) {
                return null;
            }
            return value;
        });
    },

    navigation: {
        localRedirect: (context, url) => {
            context.props.history.push(url);
        },
        reloadPage: (context) => {
            context.props.history.go(0);
        }
    },

    number: {
        formatFloat: (number) => {
            if (number.constructor === String) {
                if (!number) return '';
                number = parseFloat(number);
            }
            return number.toFixed(2)
        },
        formatCurrency: (number) => {
            if (number.constructor === String) {
                if (!number) return '';
                return '$' + parseFloat(number).toFixed(2);
            }
            return '$' + number.toFixed(2)
        }
    },

    object: {
        clone: (obj) => cloneDeep(obj),
        keyByValue: (obj, value) => {
            return Object.keys(obj).find(key => obj[key] === value);
        },
        prop: {
            diff: function (firstObject, secondObject) {
                _.reduce(firstObject, function (result, value, key) {
                    return _.isEqual(value, secondObject[key]) ?
                        result : result.concat(key);
                }, []);
            }
        },
        updateByPath: (obj, keys, value) => {
            let key = keys.shift();
            if (keys.length > 0) {
                let tmp = util.object.updateByPath(obj[key], keys, value);
                return { ...obj, [key]: tmp };
            } else {
                return { ...obj, [key]: value };
            }
        }
    },

    string: {
        capitalize: (s) => {
            if (typeof s !== 'string') return ''
            return s.charAt(0).toUpperCase() + s.slice(1)
        },
        format: {
            float: function (strNumber) {
                if (!isNaN(parseFloat(strNumber))) {
                    return parseFloat(strNumber).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                }
                return strNumber;
            }
        },
        toJS: (jsonString) => JSON.parse(JSON.stringify(jsonString)),
        getFileNameExtension: (filename) => {
            var a = filename.split(".");
            if (a.length === 1 || (a[0] === "" && a.length === 2)) {
                return "";
            }
            return (a.pop() || '').toLowerCase();
        },
        cleanText: function (str) {
            let doc = new DOMParser().parseFromString(str, 'text/html');
            return doc.body.textContent || "";
        },
        decodeHTML: function (html_str) {
            var ta = document.createElement('textarea');
            ta.innerHTML = html_str;
            return ta.value;
        }
    },

    select: {
        mapToOptions: (array, labelKey, valueKey) => {
            (array ?? []).map(x => { return { label: x[labelKey], value: x[valueKey] } });
        },
        reduceValue: (selection) => {
            return (selection ?? {}).constructor === Array ? selection.map(x => x.value) : (selection ?? {}).value;
        }
    },

    date: {
        daysOfTheWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        getShortUTC: function (str) {
            if (!str)
                return null;

            const d = new Date(str);
            const ye = d.getUTCFullYear();
            let mo = d.getUTCMonth();
            mo++;
            const da = d.getUTCDate();
            return `${mo}/${da}/${ye}`;
        },
        getShort: function (str) {
            if (!str)
                return null;

            const d = new Date(str);
            const ye = d.getFullYear();
            let mo = d.getMonth();
            mo++;
            const da = d.getDate();
            return `${mo}/${da}/${ye}`;
        },
        getInputFormat: function (str) {
            if (!str)
                return null;

            const d = new Date(str);
            const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
            const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
            const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
            return `${ye}-${mo}-${da}`;
        },
        getWeekStarts(weekStartsOn, start, end) {
            let startDate = start ?? moment(),
                endDate = end ?? moment().add(1, 'y'),
                sundayPosition = weekStartsOn ?? 0, //default to Sunday
                weekStarts = [];

            let currentDate = startDate.clone();
            while (currentDate.day(7 + sundayPosition).isBefore(endDate)) {
                weekStarts.push(currentDate.clone());
            }
            return weekStarts;
        },
        getDaysInRange: (startDate, endDate) => {
            let dates = [];
            //to avoid modifying the original date
            const theDate = new Date(startDate);
            while (theDate <= endDate) {
                dates = [...dates, new Date(theDate)];
                theDate.setDate(theDate.getDate() + 1);
            }
            return dates;
        },
        getDaysArrayOrdered: (startDay, endDay) => {
            if (!Number.isInteger(startDay) || !Number.isInteger(endDay))
                throw new Error("util.date.getDaysIntArray: startDay and endDay must be each be an integer")

            let current = parseInt(startDay);
            let weekStart = 0;
            let dates = [];

            //Add days ordered from the start date, up until Sat.
            while (current <= 6) {
                dates = [...dates, current];
                current += 1;
            }

            //Account for the week start
            while (weekStart < startDay) {
                dates = [...dates, weekStart];
                weekStart += 1;
            }

            return dates;
        },
        getMonthDateRange: (year, month) => {
            var start = moment([year, month - 1]);
            var end = moment(start).endOf('month');
            return { start: start, end: end };
        },
        yearsBetween: (intEndYear, intStartYear = 2021) => {

            const endDate = intEndYear || new Date().getFullYear();
            let yearsBetween = [];
            for (var i = intStartYear; i <= endDate; i++) {
                yearsBetween.push(intStartYear);
                intStartYear++;
            }
            return yearsBetween;
        }
    },

    validation: {
        patterns: {
            email: "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$",
            //chromium's email test
            emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            phone: "\\d{3}[\\-]\\d{3}[\\-]\\d{4}",
            htmlPhone: "[0-9]{3}-[0-9]{3}-[0-9]{4}",
            phoneRegex: /[0-9]{3}-[0-9]{3}-[0-9]{4}/g
        },
        phone: (number) => util.validation.patterns.phoneRegex.test(number),
        email: (str) => util.validation.patterns.emailRegex.test(str)
    },

    geometry: {
        
        liangBarsky: (a, b, box) => {
            const EPSILON = 1e-6;
            const INSIDE = 1;
            const OUTSIDE = 0;

            let clipT = (num, denom, c) => {
                const [tE, tL] = c;
                if (Math.abs(denom) < EPSILON) return num < 0;
                const t = num / denom;

                if (denom > 0) {
                    if (t > tL) return 0;
                    if (t > tE) c[0] = t;
                } else {
                    if (t < tE) return 0;
                    if (t < tL) c[1] = t;
                }
                return 1;
            }

            const [x1, y1] = a;
            const [x2, y2] = b;
            const dx = x2 - x1;
            const dy = y2 - y1;

            if (
                Math.abs(dx) < EPSILON &&
                Math.abs(dy) < EPSILON &&
                x1 >= box[0] &&
                x1 <= box[2] &&
                y1 >= box[1] &&
                y1 <= box[3]
            ) {
                return INSIDE;
            }

            const c = [0, 1];
            if (
                clipT(box[0] - x1, dx, c) &&
                clipT(x1 - box[2], -dx, c) &&
                clipT(box[1] - y1, dy, c) &&
                clipT(y1 - box[3], -dy, c)
            ) {
                const [tE, tL] = c;
                if (tL < 1) {
                    b[0] = x1 + tL * dx;
                    b[1] = y1 + tL * dy;
                }
                if (tE > 0) {
                    a[0] += tE * dx;
                    a[1] += tE * dy;
                }
                return INSIDE;
            }
            return OUTSIDE;
        }
    }

};