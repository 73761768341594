import React from 'react';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    LinkCellRenderer,
    TextFilterDefaults,
    ConditionalEditLinkCellRenderer,
    indexCellRenderer
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { LinearProgress } from '@material-ui/core';
import { PageHeading, PageWrap, SmallPrimaryButton } from '../common/forms/FormElements';
import { util } from '../Util';
import { withRouter, Link } from "react-router-dom";
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import authService from '../api-authorization/AuthorizeService';
import { Can } from '../Can';

class EmployeeIndex extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selectedRow: null
        }
        this.onEditEmployee = this.onEditEmployee.bind(this);
        this.onAddEmployeeClicked = this.onAddEmployeeClicked.bind(this);
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
        this.setState = (state, callback) => { return };
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {

            var [roles, accountStatuses] = await Promise.all([
                util.fetch.js(ApiRoutes.auth.roles()),
                util.fetch.js(ApiRoutes.employeeAccountStatuses.all())
            ]);

            let gridOptions = createGridOptions(this);
            let context = this;

            let initialListFilters = {
                roleId: "",
                state: "",
                dispatchId: 0
            };

            initialListFilters = this.getInitialListFiltersFromCache(initialListFilters);

            gridOptions.components = {
                selectFilter: DataGridSelectFilter,
                selectFloatingFilter: DataGridSelectFloatingFilter,
                nameRenderer: LinkCellRenderer,
                complianceAvailableRenderer: ConditionalEditLinkCellRenderer,
                indexCellRenderer: indexCellRenderer
            };

            let roleFilterParams = {
                suppressFilterButton: true,
                options: roles,
                optionsLabel: 'displayName',
                optionsValue: 'id',
                initialFilterValue: initialListFilters.roleId
            },
            statusFilterParams = {
                suppressFilterButton: true,
                options: accountStatuses,
                optionsLabel: 'description',
                optionsValue: 'id',
                initialFilterValue: 1
            };

            gridOptions.columnDefs = [
                {
                    flex: 0,
                    width: 70,
                    minWidth: 70,
                    maxWidth: 70,
                    headerName: "",
                    valueGetter: "node.id",
                    sortable: false,
                    cellRenderer: 'indexCellRenderer'
                },
                {
                    colId: 'EmployeeUser.User.UserName',
                    sortable: true,
                    headerName: 'Username',
                    field: 'userName',
                    tooltipField: 'userName',
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                    cellRenderer: 'nameRenderer',
                    cellRendererParams: {
                        clicked: this.onEditEmployee,
                        nameField: 'userName',
                        idField: 'id',
                        title: 'View this Employee'
                    }
                },
                {
                    colId: 'LastName',
                    sortable: true,
                    headerName: 'Last Name',
                    field: 'lastName',
                    tooltipField: 'lastName',
                    sort: { direction: 'asc', priority: 0 },
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    }
                },
                {
                    colId: 'FirstName',
                    sortable: true,
                    headerName: 'First Name',
                    field: 'firstName',
                    tooltipField: 'firstName',
                    sort: { direction: 'asc' },
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    }
                },
                {
                    colId: 'Roles',
                    sortable: false,
                    headerName: 'Roles',
                    field: 'rolesFlat',
                    tooltipField: 'rolesFlat',
                    filter: 'selectFilter',
                    floatingFilter: true,
                    filterParams: roleFilterParams,
                    floatingFilterComponent: 'selectFloatingFilter',
                    floatingFilterComponentParams: roleFilterParams,
                },
                {
                    colId: 'Email',
                    sortable: true,
                    field: 'email',
                    tooltipField: 'email',
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    }
                },
                {
                    colId: 'MobilePhone',
                    sortable: false,
                    field: 'mobilePhone',
                    tooltipField: 'mobilePhone',
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults
                },
                {
                    colId: 'AccountStatus.Description',
                    sortable: true,
                    field: 'accountStatus',
                    tooltipField: 'accountStatus',
                    filter: 'selectFilter',
                    floatingFilter: true,
                    filterParams: statusFilterParams,
                    floatingFilterComponent: 'selectFloatingFilter',
                    floatingFilterComponentParams: statusFilterParams
                },
            ];

            //////////////////////////////////////////////////////////
            // RJP - Cache and reload filter and sort changes 
            gridOptions.onFilterChanged = (params) => {
                let filterModel = gridOptions.api.getFilterModel();
                sessionStorage.setItem("employee_gridFilterSettings", JSON.stringify(filterModel));
            }

            gridOptions.onSortChanged = (params) => {
                let sortModel = gridOptions.api.columnModel.getColumnState();
                sessionStorage.setItem("employee_gridSortSettings", JSON.stringify(sortModel));
            }

            gridOptions.onFirstDataRendered = (params) => {
                let sortModel = sessionStorage.getItem("employee_gridSortSettings");
                if (sortModel) {
                    //var obj = JSON.parse(sortModel);
                    //TODO: Uncaught TypeError: gridOptions.api.setSortModel is not a function
                    //gridOptions.api.setSortModel(obj);
                }
            }

            gridOptions.onGridReady = (params) => {
                let filterModel = sessionStorage.getItem("employee_gridFilterSettings");
                if (filterModel) {
                    var obj = JSON.parse(filterModel);

                    // Have to set individually here instead of using the whole model
                    // due to list types requiring a different initialization routine
                    if (!!obj["EmployeeUser.User.UserName"]) {
                        const filterInstance = gridOptions.api.getFilterInstance('EmployeeUser.User.UserName');
                        filterInstance.setModel(obj["EmployeeUser.User.UserName"]);
                        gridOptions.api.onFilterChanged();
                    }
                    if (!!obj.LastName) {
                        const filterInstance = gridOptions.api.getFilterInstance('LastName');
                        filterInstance.setModel(obj.LastName);
                        gridOptions.api.onFilterChanged();
                    }
                    if (!!obj.FirstName) {
                        const filterInstance = gridOptions.api.getFilterInstance('FirstName');
                        filterInstance.setModel(obj.FirstName);
                        gridOptions.api.onFilterChanged();
                    }
                    if (!!obj.Email) {
                        const filterInstance = gridOptions.api.getFilterInstance('Email');
                        filterInstance.setModel(obj.Email);
                        gridOptions.api.onFilterChanged();
                    }
                    if (!!obj.MobilePhone) {
                        const filterInstance = gridOptions.api.getFilterInstance('MobilePhone');
                        filterInstance.setModel(obj.MobilePhone);
                        gridOptions.api.onFilterChanged();
                    }
                }

                params.api.setDatasource(context.state.dataSource);
                context.setState({ gridApi: params.api });
            }
            // END - Cache and reload filter and sort changes 
            /////////////////////////////////////////////////////////

            let ds = createDataSource(ApiRoutes.employees.search(), gridOptions);

            this.setState(state => {
                return {
                    loading: false,
                    gridOptions: gridOptions,
                    dataSource: ds
                }
            });
        }
    }

    getInitialListFiltersFromCache(initialFilters) {

        var json = sessionStorage.getItem("employee_gridFilterSettings");

        if (!!json) {
            var obj = JSON.parse(json);

            initialFilters.groupId = !!obj.Group ? parseInt(obj.Group.value) : 0;
        }

        return initialFilters;
    }

    nameRenderer(params) {
        if (!params.data) return null;
        let url = `${AppNavPaths.Employee}/${params.data.id}`;
        return (<Link className="site-link" to={url} replace>{`${params.data.lastName}, ${params.data.firstName}`}</Link>);
    }

    onAddEmployeeClicked = () =>  this.props.history.push(`${AppNavPaths.EmployeeNew}`);
    onEditEmployee = (id) => this.props.history.push(`${AppNavPaths.Employee}/${id}`);

    render() {

        if (!!this.state.loading || !this.context?.permissions)
            return (<LinearProgress variant="indeterminate" color="secondary" />);

        const { rowData, gridOptions } = { ...this.state };

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon icon={faUser} className="mr-2 text-muted" />
                    <span>Employees</span>
                    <Can I="create" a={"employee"}>
                        <Can I="add" a={"employee"}>
                            <SmallPrimaryButton type="button" onClick={this.onAddEmployeeClicked} className="btn-success float-right">
                                <i className={`fa fa-md mr-2 fa-user-plus`} />
                                Add Employee
                            </SmallPrimaryButton>
                        </Can>
                    </Can>
                </PageHeading>
                <DataGridToolbar
                    entity="employee"
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                    hideAdd={true}
                    addIcon={'fa-user-plus'}
                    hideExcelButton={true}
                    gridOptions={this.state.gridOptions}
                    serverExport={{ apiPath: ApiRoutes.employees.excelExport(), filePrefix: 'EmployeeSearch' }}
                    serverExportDisabled={!!this.state.saving || !!this.state.loading || !!this.state.loadingData}

                >
                </DataGridToolbar>
                <DataGrid
                    domLayout={"normal"}
                    rowData={rowData}
                    gridOptions={gridOptions}
                    gridStatus={this.state.gridStatus}
                />
            </PageWrap>
        );
    }
}

export default withRouter(EmployeeIndex);