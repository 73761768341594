import React, { Fragment } from 'react';

//Contexts
import { FlexRow, onFieldChange, toasty } from '../common/forms/FormElements';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import {
    ExpectedValue,
    ModifyQuestion,
    ModifySurvey,
    QuestionBuilderQuestion,
    SurveySection,
    surveyStatuses,
    SurveySectionQuestion,
    questionInputTypes,
    criteriaTypes,
    ExpectedValueCriteria,
    SurveySectionCriteria
} from './FormsAndSurveys';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSave,
    faTimes,
    faTasks,
    faTimesCircle,
    faArrowAltCircleUp,
    faArrowAltCircleDown,
    faEdit,
    faTrashAlt,
    faScrewdriverWrench,
    faBan,
    faPlus
} from '@fortawesome/free-solid-svg-icons'
import {
    AppPageForm,
    FormGroupColumn,
    FormLabel,
    GroupedRow
} from '../common/forms/FormElements';
import { LinearProgress } from '@material-ui/core';
import { util } from '../Util';
import {
    Button,
    FormGroup,
    Container,
    Row,
    Table,
    Col,
    Input,
    Label,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Modal,
    InputGroup,
    InputGroupText
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { handleFormSaveError } from '../common/forms/ValidationError';
import authService from '../api-authorization/AuthorizeService';
import { getUserProfile } from '../common/UserProfile';
import FormErrorResponseDisplay from '../common/forms/FormErrorResponseDisplay';
import { faPlusCircle } from '@fortawesome/fontawesome-free-solid';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { v4 as uuid } from 'uuid';
import './FormBuilder.scss';

class FormBuilder extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            formopened: false,
            formvalidated: false,
            loading: true,
            saving: false,
            perms: [],
            errorresponse: {},
            buildForm: new ModifySurvey(),
            statuses: [],
            questions: [],
            //Sections
            newSection: null, //set to a new section to start editing
            workingSection: null, //Set when adding section questions to avoid showing add in all sections
            updatingSection: null, //set to the id of section to edit it
            //Section Questions
            newSectionQuestion: null, //set to a new section question to edit
            updatingSectionQuestion: null, //set to an id of a section question to edit
            //Questions
            newQuestion: null, //set to a new ModifyQuestion to begin creating
            updatingQuestion: null, //set to the Id of a question to edit it

            //Section Restrictions
            allLiftTypes: [],
            allCustomers: [],
            allProductFamilies: [],
            allProductCategories: [],
            allModels: [],
            allLiftOptions: [],
            allMountingInstructions: [],
            allSalesOrderTrackingDerived: [],
            allCriteriaTypes: [],
            allExportFormatFields: [],
            assignedExportFormatFields: [],

            questionbuilder: {
                unitsofmeasure: [],
                optiongroups: [],
                inputtypes: [],
                expectedDataTypes: [],
                expectedRelationships: []
            },

            addToExpectedValue: null,
            newExpectedValueCriteria: null,
            newExpectedValueCriteriaDerivedFieldName: null,
            newExpectedValueCriteriaType: null,
            newExpectedValueCriteriaRel: null,
            newExpectedValueCriteriaCriteriaType: null,
            addExpectedValueRestriction: false,
            newExpectedValueCriteriaOverride: false,

            newSectionCriteria: null,
            newSectionCriteriaDerivedFieldName: null,
            newSectionCriteriaType: null,
            newSectionCriteriaRel: null,
            newSectionCriteriaCriteriaType: null,
            addSectionRestriction: false
        }

        this.onChange = this.onChange.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps && (this.props.match.params.id !== (prevProps.match.params ?? {}).id)) {
            this.populateState();
        }
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            let { id } = { ...this.props.match.params };
            let { assignedExportFormatFields } = { ...this.state };
            const { userPermissions } = await getUserProfile();

            var allExportFormatFields = [];

            var [
                formresponse,
                statuses,
                questions,
                allLiftTypes,
                allCustomers,
                allProductFamilies,
                allProductCategories,
                allModels,
                allLiftOptions,
                allMountingInstructions,
                allSalesOrderTrackingDerived,
                allCriteriaTypes,
            ] = await Promise.all([
                !!id ? util.fetch.post(ApiRoutes.forms.buildbyid(id), util.fetch.format.none) : new ModifySurvey(),
                util.fetch.js(ApiRoutes.typeAheads.surveyStatuses()),
                util.fetch.js(ApiRoutes.typeAheads.questions()),
                util.fetch.js(ApiRoutes.typeAheads.getLiftTypes()),
                util.fetch.js(ApiRoutes.typeAheads.apteanCustomers()),
                util.fetch.js(ApiRoutes.typeAheads.apteanProductFamilies()),
                util.fetch.js(ApiRoutes.typeAheads.apteanProductCategories()),
                util.fetch.js(ApiRoutes.typeAheads.getLiftModels()),
                util.fetch.js(ApiRoutes.typeAheads.getLiftOptions()),
                util.fetch.js(ApiRoutes.typeAheads.getMountingInstructions()),
                util.fetch.js(ApiRoutes.typeAheads.getSalesOrderTrackingDerived()),
                util.fetch.js(ApiRoutes.typeAheads.getCriteriaTypes())
            ]);

            //fix numbers because everything is coming across as string and this fails the includes predicate
            statuses = statuses.map((ss) => { return { value: Number(ss.value), label: ss.label }; });
            questions = questions.map((q) =>
                { return { value: Number(q.value), label: q.label }; }
            ).sort((q1, q2) => q1.label < q2.label ? -1 : 1);

            allLiftTypes = allLiftTypes.map((lt) => { return { value: Number(lt.value), label: lt.label }; });
            allCustomers = allCustomers.map((ac) => { return { value: Number(ac.value), label: ac.label }; });
            allModels = allModels.map((am) => { return { value: Number(am.value), label: am.label }; });
            allLiftOptions = allLiftOptions.map((lo) => { return { value: Number(lo.value), label: lo.label }; });
            allMountingInstructions = allMountingInstructions.map((mi) => { return { value: Number(mi.value), label: mi.label }; });
            allCriteriaTypes = allCriteriaTypes.filter(ct => ct.value != 1).map((ct) => { return { value: Number(ct.value), label: ct.label }; });

            var form;

            //handle any issues fetching data
            if (!!id && !!formresponse.error) {
                //handle erroneous links entered by the user
                let serviceresponse = {
                    title: 'server error',
                    errors: {
                        exception: [
                            formresponse.error
                        ]
                    }
                }
                this.displaysaveerrors(serviceresponse);
                return false;
            } else {
                form = formresponse;

                if (form.survey.surveyExportFormatId != null) {
                    [allExportFormatFields] = await Promise.all([
                        util.fetch.post(ApiRoutes.forms.exportformatfields(form.survey.surveyExportFormatId))
                    ]);

                    allExportFormatFields = allExportFormatFields.map((ef) => { return { value: Number(ef.value), label: ef.label }; });
                }
            }

            if (form?.questions?.length ?? false) {
                let builderQuestions = await Promise.all(form.questions.map(async (q) => {
                    let qbques = new QuestionBuilderQuestion();
                    qbques.question = q.question;
                    qbques.expectedValues = q.expectedValues;

                    if (!!q.optionGroupId) {
                        qbques.optionGroupId = q.optionGroupId;
                        qbques.useOptionGroup = await this.getOptionGroupToUse(q.optionGroupId);
                    }

                    return qbques;
                }));
                Object.assign(form.questions, builderQuestions);
            }

            if (form?.sectionQuestions?.length ?? false) {
                form.sectionQuestions.forEach((secq) => {
                    if (secq.surveyExportFormatFieldId != null) {
                        let assigned = allExportFormatFields.find(ff => ff.value == secq.surveyExportFormatFieldId);
                        let aind = allExportFormatFields.indexOf(assigned);
                        assignedExportFormatFields.push(assigned); //add the value to the assigned
                        allExportFormatFields.splice(aind, 1); //remove from the unassigned fields
                    }
                });
            }

            //Question builder state
            var [uoms, groups, inputtypes, expectvaltypes, expectedvalrels] = await Promise.all([
                util.fetch.js(ApiRoutes.typeAheads.unitsOfMeasure()),
                util.fetch.js(ApiRoutes.typeAheads.questionOptionGroups()),
                util.fetch.js(ApiRoutes.typeAheads.questionInputTypes()),
                util.fetch.js(ApiRoutes.typeAheads.questionExpectedValueTypes()),
                util.fetch.js(ApiRoutes.typeAheads.questionExpectedValRelationships())
            ]);

            uoms = uoms.map((ss) => { return { value: Number(ss.value), label: ss.label }; });
            groups = groups.map((ss) => { return { value: Number(ss.value), label: ss.label }; });
            inputtypes = inputtypes.map((ss) => { return { value: Number(ss.value), label: ss.label }; });
            expectvaltypes = expectvaltypes.map((evt) => { return { value: Number(evt.value), label: evt.label }; });
            expectedvalrels = expectedvalrels.map((evr) => { return { value: Number(evr.value), label: evr.label }; });

            let questionBuilderState = {
                unitsofmeasure: uoms,
                optiongroups: groups,
                inputtypes: inputtypes,
                expectedDataTypes: expectvaltypes,
                expectedRelationships: expectedvalrels
            };

            this.setState({
                formvalidated: false,
                saving: false,
                perms: userPermissions,
                loading: false,
                originaldata: form,
                buildForm: form,
                statuses: statuses,
                questions: questions,
                questionbuilder: questionBuilderState,
                allLiftTypes: allLiftTypes,
                allCustomers: allCustomers,
                allProductFamilies: allProductFamilies,
                allProductCategories: allProductCategories,
                allModels: allModels,
                allLiftOptions: allLiftOptions,
                allMountingInstructions: allMountingInstructions,
                allSalesOrderTrackingDerived: allSalesOrderTrackingDerived,
                allCriteriaTypes: allCriteriaTypes,

                allExportFormatFields: allExportFormatFields,
                assignedExportFormatFields: assignedExportFormatFields
            });
        }
    }

    getOptionGroupToUse = async (groupId) => {
        
        var [groupResponse] = await Promise.all([
            util.fetch.post(ApiRoutes.questionoptiongroups.byid(groupId), util.fetch.format.none)
        ]);

        if (!!groupResponse.error) {
            //Handle erroneous links entered by the user
            let serviceResponse = {
                title: 'Server Error',
                errors: {
                    Exception: [
                        groupResponse.error
                    ]
                }
            }
            this.displaySaveErrors(serviceResponse);
            return false;
        }
        return groupResponse;
    }

    onChange = onFieldChange;

    clearSaving = () => this.setState({ saving: false });

    notifySuccess = () => toasty.success('Form Saved', `Form saved successfully.`);
    notifyError = (message) => toasty.error('Save Unsuccessful', message);
    handleSaveError = (err) => handleFormSaveError(this, err);

    displaySaveErrors = (response) => this.setState({ errorResponse: response });
    clearSaveErrors = () => this.setState({ errorResponse: {} });

    onCloseClicked = () => {
        this.props.history.push(`${AppNavPaths.Forms}`);
    }

    //#region Sections
    onAddSection = async (sectionId) => {
        let { buildForm } = { ...this.state };

        let newOrder = 1;

        if (!!(buildForm.sections ?? []).length) {
            newOrder = Math.max(...buildForm.sections.map(s => s.order)) + 1; //Last Order +1
        }

        let newSection = new SurveySection();
        newSection.order = newOrder;
        newSection.surveyId = buildForm.survey.id;

        this.setState({
            newSection: newSection,
            workingSection: sectionId
        });
    }

    onEditSection = async (section) => {
        //deep Copy
        let newSection = JSON.parse(JSON.stringify(section));

        this.setState({
            newSection: newSection,
            updatingSection: section.id
        });
    }

    onCancelAddUpdateSection = async () => {
        this.setState({
            newSection: null,
            updatingSection: null,
            workingSection: null
        });
    }

    onSaveNewSection = async () => {
        this.clearSaving()
        this.setState({ errors: {}, saving: true });
        let { newSection, buildForm } = { ...this.state };

        try {
            let response = await util.fetch.post(ApiRoutes.forms.section(), newSection, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                newSection.id = await response.json();
                buildForm.sections.push(newSection);

                this.setState({
                    buildForm: buildForm,
                    newSection: null,
                    workingSection: null
                });

            } else {

                if (response.status === 400) {
                    let serviceResponse = await response.json();
                    this.displaySaveErrors(serviceResponse);
                } else {

                    let errorResp = await response.json();

                    if (errorResp.errors) {
                        this.displaySaveErrors(errorResp);
                    } else {
                        let serviceResponse = {
                            title: 'Server Error',
                            errors: {
                                Exception: [
                                    await response.text()
                                ]
                            }
                        }
                        this.displaySaveErrors(serviceResponse);
                    }
                }
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    //pass an Id to essentially make a silent update to a section
    //used within the component to update the order for a section
    //without popping that section up as the section being edited 
    //and breaking that workflow.   
    onUpdateSection = async (id) => {
        this.clearSaving()
        this.setState({ errors: {}, saving: true });
        let { buildForm, updatingSection, newSection } = { ...this.state };

        let updateThis = !!id ? buildForm.sections.find(s => s.id == id) : newSection;

        try {
            let response = await util.fetch.put(ApiRoutes.forms.section(), updateThis, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                
                if (!!!id) {
                    //we got here by updating a section
                    let updated = buildForm.sections.find(s => s.id === updatingSection);
                    Object.assign(updated, newSection); //Copy Over

                    this.setState({
                        updatingSection: null,
                        newSection: null,
                        workingSection: null
                    });

                } 

                this.setState({
                    buildForm: buildForm
                });
            } else {
                this.displaySaveErrors(await response.json());
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    moveSectionUp = async (index) => {
        let { buildForm } = { ...this.state };

        if (index != 0) {
            let tomove = buildForm.sections[index];
            tomove.order = tomove.order - 1;
            let sibling = buildForm.sections[index - 1];
            sibling.order = sibling.order + 1;

            buildForm.sections[index - 1] = tomove;
            buildForm.sections[index] = sibling;

            this.setState({
                buildForm: buildForm
            });

            await this.onUpdateSection(tomove.id);
            await this.onUpdateSection(sibling.id);
        }
    }

    moveSectionDown = async (index) => {
        let { buildForm } = { ...this.state };

        //if it isn't already the last element in the list
        if (index+1 != buildForm.sections.length) {
            let tomove = buildForm.sections[index];
            tomove.order = tomove.order + 1;
            let sibling = buildForm.sections[index + 1];
            sibling.order = sibling.order - 1;

            buildForm.sections[index + 1] = tomove;
            buildForm.sections[index] = sibling;

            this.setState({
                buildForm: buildForm
            });

            await this.onUpdateSection(tomove.id);
            await this.onUpdateSection(sibling.id);
        }
    }
        
    onRemoveSection = async (sectionId) => {
        this.clearSaving()
        this.setState({ errors: {}, saving: true });
        let { buildForm } = { ...this.state };

        if (buildForm.sectionQuestions.some(sq => sq.surveySectionId === sectionId)) {
            this.notifyError("Please remove all section questions first");
        } else {
            let updateThis = buildForm.sections.find(s => s.id === sectionId);

            try {
                let response = await util.fetch.delete(ApiRoutes.forms.section(), updateThis, util.fetch.format.none);
                if (response.redirected) {
                    window.location.href = response.url;
                } else if (!!response.ok) {

                    var ind = buildForm.sections.indexOf(updateThis);
                    buildForm.sections.splice(ind, 1);

                    if (buildForm.sections.length) {
                        let neworder = updateThis.order;
                        let remainingSections = buildForm.sections.filter(s => s.order > neworder).sort( (s1,s2) => s1.order < s2.order ? -1 : 1);
                        if (remainingSections.length) {
                            remainingSections.forEach(async (s) => {
                                s.order = neworder++;
                                await this.onUpdateSection(s.id);
                            });
                        }
                    }

                    this.setState({
                        buildForm: buildForm
                    });
                } else {
                    this.displaySaveErrors(await response.json());
                }
            } catch (error) {
                this.notifyError(error.toString());
            } finally {
                this.clearSaving();
            }
        }
    }

    onAddNewSectionCriteria = async () => {
        let {
            newSection,
            newSectionCriteria,
            newSectionCriteriaDerivedFieldName,
            newSectionCriteriaType,
            newSectionCriteriaRel,
            newSectionCriteriaCriteriaType
        } = { ...this.state };

        let newCriteria = new SurveySectionCriteria();

        newCriteria.derivedFieldName = newSectionCriteriaDerivedFieldName?.label;
        newCriteria.expectedValue = newSectionCriteria.label; //We take the label not the value this is what the restriction should contain
        newCriteria.criteriaTypeId = newSectionCriteriaCriteriaType.value;
        newCriteria.criteriaTypeText = newSectionCriteriaCriteriaType.label;
        newCriteria.expectedValueTypeId = newSectionCriteriaType.value;
        newCriteria.valueTypeText = newSectionCriteriaType.label;
        newCriteria.expectedValueRelationshipId = newSectionCriteriaRel.value;
        newCriteria.relaionshipText = newSectionCriteriaRel.label;

        newSection.criteria.push(newCriteria);

        this.setState({
            newSection: newSection,
            newSectionCriteria: null,
            newSectionCriteriaDerivedFieldName: null,
            newSectionCriteriaType: null,
            newSectionCriteriaRel: null,
            newSectionCriteriaCriteriaType: null,
            addSectionRestriction: false
        });
    }

    onRemoveSectionCriteria = async (ind) => {
        let { newSection } = { ...this.state };

        newSection.criteria.splice(ind, 1);

        this.setState({
            newSection: newSection
        });
    }
    //#endregion

    //#region Section Questions

    onAddSectionQuestion = async (sectionId) => {
        let { buildForm } = { ...this.state };

        let newOrder = 1;

        if (!!(buildForm.sectionQuestions ?? []).length) {
            let sectionQuestions = buildForm.sectionQuestions.filter(sq => sq.surveySectionId === sectionId)

            if (!!sectionQuestions.length) {
                newOrder = Math.max(...sectionQuestions.map(sq => sq.order)) +1; 
            }
        }

        let newSectionQuestion = new SurveySectionQuestion();
        newSectionQuestion.order = newOrder;
        newSectionQuestion.surveySectionId = sectionId;
        newSectionQuestion.answerRequired = true;

        this.setState({
            newSectionQuestion: newSectionQuestion,
            workingSection: sectionId
        });
    }

    onEditSectionQuestion = async (sectionQuestion) => {
        //deep Copy
        let newSectionQuestion = JSON.parse(JSON.stringify(sectionQuestion));        
        this.setState({
            newSectionQuestion: newSectionQuestion,
            updatingSectionQuestion: sectionQuestion.id,
            workingSection: sectionQuestion.surveySectionId
        });
    }

    onCancelAddUpdateSectionQuestion = async () => {        
        this.setState({
            newSectionQuestion: null,
            updatingSectionQuestion: null,
            workingSection: null
        });
    }

    onSaveNewSectionQuestion = async () => {
        this.clearSaving()
        this.setState({ errors: {}, saving: true });
        let { newSectionQuestion, buildForm, allExportFormatFields, assignedExportFormatFields } = { ...this.state };

        //we only need to handle when a new one is chosen
        if (newSectionQuestion.surveyExportFormatFieldId != null) {
            let assigned = allExportFormatFields.find(ff => ff.value == newSectionQuestion.surveyExportFormatFieldId);
            let aind = allExportFormatFields.indexOf(assigned);
            assignedExportFormatFields.push(assigned); //add the value to the assigned
            allExportFormatFields.splice(aind, 1); //remove from the unassigned fields
        }

        try {
            let response = await util.fetch.post(ApiRoutes.forms.sectionquestion(), newSectionQuestion, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {

                if (!buildForm.questions.some(q => q.question.id == newSectionQuestion.questionId)) {
                    
                    var [questionResponse] = await Promise.all([
                        util.fetch.post(
                            ApiRoutes.questions.byid(newSectionQuestion.questionId),
                            util.fetch.format.none
                        )
                    ]);

                    let qbques = new QuestionBuilderQuestion();
                    qbques.question = questionResponse.question;
                    qbques.expectedValues = questionResponse.expectedValues;

                    if (!!questionResponse.optionGroupId) {
                        qbques.optionGroupId = questionResponse.optionGroupId;
                        qbques.useOptionGroup = await this.getOptionGroupToUse(questionResponse.optionGroupId);
                    }
                    buildForm.questions.push(qbques);
                }

                newSectionQuestion.id = await response.json();
                buildForm.sectionQuestions.push(newSectionQuestion);

                this.setState({
                    buildForm: buildForm,
                    newSectionQuestion: null,
                    workingSection: null
                });

            } else {

                if (response.status === 400) {
                    let serviceResponse = await response.json();
                    this.displaySaveErrors(serviceResponse);
                } else {

                    let errorResp = await response.json();

                    if (errorResp.errors) {
                        this.displaySaveErrors(errorResp);
                    } else {
                        let serviceResponse = {
                            title: 'Server Error',
                            errors: {
                                Exception: [
                                    await response.text()
                                ]
                            }
                        }
                        this.displaySaveErrors(serviceResponse);
                    }
                }
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    moveSectionQuestionUp = async (sectionQuestionId) => {
        let { buildForm } = { ...this.state };

        let tomove = buildForm.sectionQuestions.find(sq => sq.id === sectionQuestionId);

        //Check to make sure not at top of list for safety
        if (tomove.order !== 1) {
            tomove.order = tomove.order - 1;
            //find the sibling from the same section with the new order value so we can increment it 
            let sibling = buildForm.sectionQuestions.find(sq =>
                sq.surveySectionId === tomove.surveySectionId && sq.order === tomove.order && sq.id !== sectionQuestionId
            );
            sibling.order = sibling.order + 1;

            this.setState({
                buildForm: buildForm
            });

            await this.onUpdateSectionQuestion(tomove.id);
            await this.onUpdateSectionQuestion(sibling.id);
        }
    }

    moveSectionQuestionDown = async (sectionQuestionId) => {
        let { buildForm } = { ...this.state };

        let tomove = buildForm.sectionQuestions.find(sq => sq.id === sectionQuestionId);

        let allSectionQuestions = buildForm.sectionQuestions.filter(sq =>
            sq.surveySectionId == tomove.surveySectionId && sq.id !== sectionQuestionId
        );

        let maxOrder = Math.max(...allSectionQuestions.map(sq => sq.order));

        //Safety check to ensure we can't dump the last element out of order
        if (tomove.order !== maxOrder) {
            tomove.order = tomove.order + 1;
            let sibling = allSectionQuestions.find(sq =>
                sq.surveySectionId === tomove.surveySectionId && sq.order === tomove.order && sq.id !== sectionQuestionId
            );
            sibling.order = sibling.order - 1;

            this.setState({
                buildForm: buildForm
            });

            await this.onUpdateSectionQuestion(tomove.id);
            await this.onUpdateSectionQuestion(sibling.id);
        }
    }

    onUpdateSectionQuestion = async (id) => {
        this.clearSaving()
        this.setState({ errors: {}, saving: true });
        let { buildForm, updatingSectionQuestion, newSectionQuestion, allExportFormatFields, assignedExportFormatFields } = { ...this.state };

        let updateThis = !!id ? buildForm.sectionQuestions.find(s => s.id === id) : newSectionQuestion;

        if (!!id) {
            if (newSectionQuestion?.surveyExportFormatFieldId != null) {
                if (updateThis?.surveyExportFormatFieldId != null) {
                    if (updateThis.surveyExportFormatFieldId != newSectionQuestion.surveyExportFormatFieldId) {
                        //if the values are different, we need to remove the existing value from the assigned
                        //values

                        let passigned = assignedExportFormatFields.find(ff => ff.value == updateThis.surveyExportFormatFieldId);
                        let paind = assignedExportFormatFields.indexOf(passigned);
                        allExportFormatFields.push(passigned); //add the value to all
                        assignedExportFormatFields.splice(paind, 1); //Remove from assigned

                        let assigned = allExportFormatFields.find(ff => ff.value == newSectionQuestion.surveyExportFormatFieldId);
                        let aind = allExportFormatFields.indexOf(assigned);
                        assignedExportFormatFields.push(assigned); //add the value to the assigned
                        allExportFormatFields.splice(aind, 1); //remove from the unassigned fields
                    }
                    //If the values are the same, we don't need to do anything
                } else {
                    let assigned = allExportFormatFields.find(ff => ff.value == newSectionQuestion.surveyExportFormatFieldId);
                    let aind = allExportFormatFields.indexOf(assigned);
                    assignedExportFormatFields.push(assigned); //add the value to the assigned
                    allExportFormatFields.splice(aind, 1); //remove from the unassigned fields
                }
            } else {
                //If there was an existing vlaue that is removed, we need to take it out of the assigned values
                //and put it back in the all collection
                if (updateThis.surveyExportFormatFieldId != null) {
                    let assigned = assignedExportFormatFields.find(ff => ff.value == updateThis.surveyExportFormatFieldId);
                    let aind = assignedExportFormatFields.indexOf(assigned);
                    allExportFormatFields.push(assigned); //add the value to all
                    assignedExportFormatFields.splice(aind, 1); //Remove from assigned
                }
            }
        } else {
            //If the id is empty, then all we need to do is add it because it can't exist yet
            if (newSectionQuestion?.surveyExportFormatFieldId != null) {
                let assigned = allExportFormatFields.find(ff => ff.value == newSectionQuestion.surveyExportFormatFieldId);
                let aind = allExportFormatFields.indexOf(assigned);
                assignedExportFormatFields.push(assigned); //add the value to the assigned
                allExportFormatFields.splice(aind, 1); //remove from the unassigned fields
            }
        }

        try {
            let response = await util.fetch.put(ApiRoutes.forms.sectionquestion(), updateThis, util.fetch.format.none);
            
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {


                if (!!!id) {

                    //updating an existing section question
                    //if we don't have this quesiton as a builder in the form yet, load it
                    if (!buildForm.questions.some(q => q.question.id == newSectionQuestion.questionId)) {
                        
                        var [questionResponse] = await Promise.all([
                            util.fetch.post(
                                ApiRoutes.questions.byid(newSectionQuestion.questionId),
                                util.fetch.format.none
                            )
                        ]);

                        let qbques = new QuestionBuilderQuestion();
                        qbques.question = questionResponse.question;
                        qbques.expectedValues = questionResponse.expectedValues;

                        if (!!questionResponse.optionGroupId) {
                            qbques.optionGroupId = questionResponse.optionGroupId;
                            qbques.useOptionGroup = await this.getOptionGroupToUse(questionResponse.optionGroupId);
                        }
                        buildForm.questions.push(qbques);
                    }
                    
                    let updated = buildForm.sectionQuestions.find(sq => sq.id === updatingSectionQuestion);
                    Object.assign(updated, newSectionQuestion); //Overwrite with update

                    this.setState({                       
                        updatingSectionQuestion: null,
                        newSectionQuestion: null,
                        workingSection: null
                    });
                }

                this.setState({
                    buildForm: buildForm
                });
            } else {
                this.displaySaveErrors(await response.json());
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    onRemoveSectionQuestion = async (sectionQuestionId) => {
        this.clearSaving()
        this.setState({ errors: {}, saving: true });
        let { buildForm, allExportFormatFields, assignedExportFormatFields } = { ...this.state };

        let updateThis = buildForm.sectionQuestions.find(s => s.id === sectionQuestionId);

        //If the section question was assigned an export field, add it back into the pool
        if (updateThis.surveyExportFormatFieldId != null) {
            let assigned = assignedExportFormatFields.find(ff => ff.value == updateThis.surveyExportFormatFieldId);
            let aind = assignedExportFormatFields.indexOf(assigned);
            allExportFormatFields.push(assigned); //add the value to all
            assignedExportFormatFields.splice(aind, 1); //Remove from assigned
        }

        try {
            let response = await util.fetch.delete(ApiRoutes.forms.sectionquestion(), updateThis, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {

                var ind = buildForm.sectionQuestions.indexOf(updateThis);
                buildForm.sectionQuestions.splice(ind, 1);

                let allSectionQuestions = buildForm.sectionQuestions.filter(sq =>
                    sq.surveySectionId == updateThis.surveySectionId
                );

                //There are questions left 
                if (allSectionQuestions.length) {
                    let neworder = updateThis.order;
                    //Get any remaining questions with an order greater than what we removed, sorted by order
                    let reorderSectionQuestions = allSectionQuestions.filter(sq => sq.order > neworder).sort((sq1,sq2) => sq1.order < sq2.order ? -1 : 1);
                    if (reorderSectionQuestions.length) {
                        reorderSectionQuestions.forEach(async (sq) => {
                            sq.order = neworder++;
                            await this.onUpdateSectionQuestion(sq.id);
                        });
                    }
                }

                this.setState({
                    buildForm: buildForm,
                    updatingSection: null
                });
            } else {
                this.displaySaveErrors(await response.json());
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }
    //#endregion

    //#region Question Builder

    onSetNewExpectedGroupValue = async (newval) => {
        let { newQuestion } = { ...this.state };

        //an existing value
        if ((newQuestion.newExpectedValue ?? "").length) {
            if (newQuestion.newExpectedValue.indexOf(newval) > -1) {
                //value is already in the answer
                let allvals = newQuestion.newExpectedValue.split(",");
                allvals.splice(allvals.indexOf(newval), 1);
                newQuestion.newExpectedValue = allvals.join(",");
            } else {
                newQuestion.newExpectedValue = newQuestion.newExpectedValue + ',' + newval.toString();
            }
        } else {
            newQuestion.newExpectedValue = newval.toString();
        }

        this.setState({
            newQuestion: newQuestion
        });
    }

    onSetNewExpectedValue = async (newval) => {
        let { newQuestion } = { ...this.state };

        newQuestion.newExpectedValue = newval.toString();

        this.setState({
            newQuestion: newQuestion
        });
    }

    onChangeNewExpectedValType = async (newValType) => {
        let { newQuestion } = { ...this.state };

        newQuestion.newExpectedValueType = newValType;

        this.setState({
            newQuestion: newQuestion
        });
    }

    onChangeNewExpectedValRel = async (newValRel) => {
        let { newQuestion } = { ...this.state };

        newQuestion.newExpectedValueRel = newValRel;

        this.setState({
            newQuestion: newQuestion
        });
    }

    onExpectedValueRemoved = async (index) => {
        let { newQuestion } = { ...this.state };

        newQuestion.expectedValues.splice(index, 1);

        this.setState({
            newQuestion: newQuestion
        });
    }

    onAddNewExpectedValue = async () => {
        let { newQuestion } = { ...this.state };

        let {
            newExpectedValue,
            newExpectedValueType,
            newExpectedValueRel,
            selectedGroupOptions
        } = { ...newQuestion };

        let newVal = new ExpectedValue();

        if (selectedGroupOptions.length) {
            newVal.expectedValue = selectedGroupOptions.map(o => o.choiceText).join(",");
        } else {
            newVal.expectedValue = (newExpectedValue ?? "").toString();
        }
        newVal.questionExpectedValueTypeId = newExpectedValueType.value;
        newVal.questionExpectedValueRelationshipId = newExpectedValueRel.value;
        newVal.valueTypeText = newExpectedValueType.label;
        newVal.relationshipText = newExpectedValueRel.label;

        if (newQuestion.question.inputTypeId == questionInputTypes.Derived) {
            newVal.salesOrderTrackingDerived = true;
        }

        newQuestion.expectedValues.push(newVal);

        newQuestion.newExpectedValue = null;
        newQuestion.newExpectedValueType = null;
        newQuestion.newExpectedValueRel = null;
        newQuestion.selectedGroupOptions = [];

        this.setState({
            newQuestion: newQuestion
        });
    }

    onAddNewExpectedValueCriteria = async () => {
        let {
            newQuestion,
            addToExpectedValue,
            newExpectedValueCriteriaDerivedFieldName,
            newExpectedValueCriteria,
            newExpectedValueCriteriaType,
            newExpectedValueCriteriaRel,
            newExpectedValueCriteriaCriteriaType
        } = { ...this.state };

        let newCriteria = new ExpectedValueCriteria();

        newCriteria.expectedValue = newExpectedValueCriteria.label; //the restriction is always the actual text

        newCriteria.derivedFieldName = newExpectedValueCriteriaDerivedFieldName?.label;
        newCriteria.criteriaTypeId = newExpectedValueCriteriaCriteriaType.value;
        newCriteria.expectedValueTypeId = newExpectedValueCriteriaType.value;
        newCriteria.expectedValueRelationshipId = newExpectedValueCriteriaRel.value;
        newCriteria.valueTypeText = newExpectedValueCriteriaType.label;
        newCriteria.relationshipText = newExpectedValueCriteriaRel.label;
        newCriteria.criteriaTypeText = newExpectedValueCriteriaCriteriaType.label;

        newQuestion.expectedValues[addToExpectedValue].criteria.push(newCriteria);

        this.setState({
            newQuestion: newQuestion,
            addToExpectedValue: null,
            newExpectedValueCriteriaDerivedFieldName: null,
            newExpectedValueCriteria: null,
            newExpectedValueCriteriaType: null,
            newExpectedValueCriteriaRel: null,
            newExpectedValueCriteriaCriteriaType: null,
            addExpectedValueRestriction: false,
            newExpectedValueCriteriaOverride: false
        });
    }

    onRemoveNewExpectedValueCriteria = async (ev_index, cev_index) => {
        let { newQuestion } = { ...this.state };

        newQuestion.expectedValues[ev_index].criteria.splice(cev_index, 1);

        this.setState({
            newQuestion: newQuestion
        });
    }

    onAddQuestion = async () => {
        this.setState({
            newQuestion: new QuestionBuilderQuestion()
        });
    }

    onEditQuestion = async (questionId) => {
        let { buildForm } = { ...this.state };

        if (!buildForm.questions.some(q => q.question.id == questionId)) {

            var [questionResponse] = await Promise.all([
                util.fetch.post(
                    ApiRoutes.questions.byid(questionId),
                    util.fetch.format.none
                )
            ]);

            let qbques = new QuestionBuilderQuestion();
            qbques.question = questionResponse.question;
            qbques.expectedValues = questionResponse.expectedValues;

            if (!!questionResponse.optionGroupId) {
                qbques.optionGroupId = questionResponse.optionGroupId;
                qbques.useOptionGroup = await this.getOptionGroupToUse(questionResponse.optionGroupId);
            }
            buildForm.questions.push(qbques);
        }

        let question = buildForm.questions.find(q => q.question.id === questionId);

        let newQuestion = JSON.parse(JSON.stringify(question));

        this.setState({
            newQuestion: newQuestion,
            updatingQuestion: questionId
        });
    }

    onCancelAddUpdateQuestion = async () => {
        this.setState({
            newQuestion: null,
            updatingQuestion: null
        });
    }

    onSaveNewQuestion = async () => {
        this.setState({ saving: true });

        let {
            newQuestion,
            newSectionQuestion,
            buildForm,
            questions
        } = { ...this.state };

        let question = new ModifyQuestion();
        question.question = newQuestion.question;
        question.expectedValues = newQuestion.expectedValues;
        question.optionGroupId = newQuestion.optionGroupId;

        try {
            let response = await util.fetch.post(ApiRoutes.questions.create(), question, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                let questionId = await response.json();

                newQuestion.question.id = questionId;

                //currently creating a section question
                if (!!newSectionQuestion) {
                    newSectionQuestion.questionId = questionId
                }

                questions.push({ value: questionId, label: newQuestion.question.title });
                questions.sort((q1, q2) => q1.label < q2.label ? -1 : 1);

                buildForm.questions.push(newQuestion);

                this.setState({
                    buildForm: buildForm,
                    questions: questions,
                    newQuestion: null
                });

            } else {
                if (response.status === 400) {
                    let serviceResponse = await response.json();
                    this.displaySaveErrors(serviceResponse);
                } else {

                    let errorResp = await response.json();

                    if (errorResp.errors) {
                        this.displaySaveErrors(errorResp);
                    } else {
                        let serviceResponse = {
                            title: 'Server Error',
                            errors: {
                                Exception: [
                                    await response.text()
                                ]
                            }
                        }
                        this.displaySaveErrors(serviceResponse);
                    }
                }
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    onUpdateQuestion = async () => {
        this.setState({ saving: true });

        let { updatingQuestion, newQuestion, buildForm, questions } = { ...this.state };

        let question = new ModifyQuestion();
        question.question = newQuestion.question;
        question.expectedValues = newQuestion.expectedValues;
        question.optionGroupId = newQuestion.optionGroupId;

        try {
            let response = await util.fetch.put(ApiRoutes.questions.byid(question.question.id), question, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {

                let updated = buildForm.questions.find(q => q.question.id === updatingQuestion);
                Object.assign(updated, newQuestion); //overwrite with updated 

                //update the typeahead
                questions.find(q => q.value === updatingQuestion).label = question.question.title;

                this.setState({
                    buildForm: buildForm,
                    questions: questions,
                    updatingQuestion: null,
                    newQuestion: null
                });

            } else {
                this.displaySaveErrors(await response.json());
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    //#endregion

    //#region RENDERING
    render() {
        const {
            loading,
            perms,
            buildForm,
            questions,
            questionbuilder,
            allLiftTypes,
            allCustomers,
            allProductFamilies,
            allProductCategories,
            allModels,
            allLiftOptions,
            allMountingInstructions,
            allSalesOrderTrackingDerived,
            allCriteriaTypes
        } = { ...this.state };

        buildForm.sections.sort((s1, s2) => s1.order < s2.order ? -1 : 1);

        let {
            errorResponse,
            newSection, //set to a new section to start editing
            updatingSection, //set to the id of section to edit it
            newSectionQuestion, //set to a new section question to edit
            updatingSectionQuestion, //set to an id of a section question to edit
            newQuestion, //set to a new ModifyQuestion to begin creating
            updatingQuestion, //set to the Id of a question to edit it
            workingSection,
            newExpectedValueCriteria,
            newExpectedValueCriteriaType,
            newExpectedValueCriteriaRel,
            newExpectedValueCriteriaCriteriaType,
            addExpectedValueRestriction,
            newSectionCriteria,
            newSectionCriteriaType,
            newSectionCriteriaRel,
            newSectionCriteriaCriteriaType,
            addSectionRestriction,
            allExportFormatFields,
            assignedExportFormatFields,
            newExpectedValueCriteriaDerivedFieldName,
            newSectionCriteriaDerivedFieldName,
            newExpectedValueCriteriaOverride
        } = { ...this.state };

        const allowEmpty = [
            questionInputTypes.Text,
            questionInputTypes.Numeric,
            questionInputTypes.SingleLineText
        ];

        if (!!loading) {
            return (<LinearProgress variant="indeterminate" color="secondary" />);
        } else {

            //field editing permissions
            const canEdit = !!perms.includes("forms.edit");
            const canCreate = !!perms.includes("forms.create");

            return (
                <Fragment>
                    <AppPageForm
                        formId={"formBuilderForm"}
                        formHeadingIcon={faTasks}
                        formHeading={`Form: ${buildForm.survey.name}`}
                        formName={"formBuilderForm"}
                        formRef={this.formRef}
                        onSubmit={this.onSubmit}
                        setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                        isValidated={this.state.formValidated}
                        saving={this.state.saving}
                        errors={this.state.errors}
                        loading={this.state.loading}
                    >
                        <Container>
                            <Row>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} >
                                    <FontAwesomeIcon
                                        className="mr-2 text-dark pr-2 zoom"
                                        onClick={() => this.props.history.push(`${AppNavPaths.Forms}/${buildForm.survey.id}`)}
                                        icon={faEdit} />
                                    <span>{buildForm.survey.name}</span>
                                </Col>
                                <Col xs={false} sm={false} md={8} lg={8} xl={8} xxl={8} ></Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} >
                                    <span>{buildForm.survey.version.toFixed(1)}</span>
                                </Col>
                                <Col xs={false} sm={false} md={8} lg={8} xl={8} xxl={8}></Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} >
                                    <span>{surveyStatuses.byId(buildForm.survey.surveyStatusId)}</span>
                                </Col>
                                <Col xs={false} sm={false} md={8} lg={8} xl={8} xxl={8}></Col>
                            </Row>
                        </Container>
                        {!!(buildForm.sections ?? []).length &&
                            buildForm.sections.map((s, ind) => 
                                <Container key={s.id.toString() + ind.toString()}
                                    style={{ marginBottom: "1em", padding: "0.5em", border: "1px solid black" }}
                                >
                                    <Row>
                                        <Col xs={4} sm={4} md={2} lg={1} xl={1} xxl={1}>
                                            {(s.order != 1) &&
                                                < FontAwesomeIcon
                                                    className="mr-2 text-dark pr-2 zoom"
                                                    onClick={() => this.moveSectionUp(ind)}
                                                    icon={faArrowAltCircleUp} />
                                            }
                                            {' '}
                                            {(s.order != buildForm.sections.length) &&
                                                <FontAwesomeIcon
                                                    className="mr-2 text-dark pr-2 zoom"
                                                    onClick={() => this.moveSectionDown(ind)}
                                                    icon={faArrowAltCircleDown} />
                                            }
                                        </Col>
                                        <Col xs={7} sm={7} md={9} lg={10} xl={10} xxl={10}>
                                            <FontAwesomeIcon
                                                className="mr-2 text-dark pr-2 zoom"
                                                onClick={() => { this.onEditSection(s) }}
                                                icon={faScrewdriverWrench} />
                                            {` Section: ${s.sectionName}`}
                                        </Col>
                                        <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
                                            {!!!buildForm.sectionQuestions.some(sq => sq.surveySectionId == s.id) &&
                                                <FontAwesomeIcon
                                                    className="mr-2 text-dark pr-2 zoom"
                                                    onClick={() => this.onRemoveSection(s.id)}
                                                    icon={faTrashAlt} />
                                            }
                                        </Col>
                                    </Row>
                                    {!!s.instructions &&
                                        <Row>
                                            <Col xs={4} sm={4} md={2} lg={1} xl={1} xxl={1}></Col>
                                            <Col xs={8} sm={8} md={10} lg={11} xl={11} xxl={11} style={{fontSize: "smaller", fontStyle: "italic"}} >
                                                {s.instructions}
                                            </Col>
                                        </Row>
                                    }
                                    {!!buildForm.sectionQuestions.some(sq => sq.surveySectionId == s.id)
                                        &&
                                        <Row>
                                            <Col xs={4} sm={4} md={2} lg={1} xl={1} xxl={1}></Col>
                                            <Col xs={8} sm={8} md={10} lg={11} xl={11} xxl={11}>
                                                <Table>
                                                    <colgroup>
                                                        <col style={{ width: "5%" }} />
                                                        <col style={{ width: "5%" }} />
                                                        <col style={{ width: "10%" }} />
                                                        <col style={{ width: "60%" }} />
                                                        <col style={{ width: "10%" }} />
                                                        <col style={{ width: "5%" }} />
                                                        <col style={{ width: "5%" }} />
                                                    </colgroup>
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Order</th>
                                                            <th>Question Type</th>
                                                            <th>Question</th>
                                                            <th>Required ?</th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            buildForm.sectionQuestions.filter((sq) => sq.surveySectionId == s.id)
                                                                .sort((sq1, sq2) => sq1.order < sq2.order ? -1 : 1)
                                                                .map((sq) =>
                                                                    <tr key={sq.id.toString() + 'sectionQuestion'} style={{ color: sq.deleted ? 'red' : 'inherit', textDecoration: sq.deleted ? 'line-through' : 'none' }} >
                                                                        <td>
                                                                            {(sq.order != 1) &&
                                                                                <FontAwesomeIcon
                                                                                    className="mr-2 text-dark pr-2 zoom"
                                                                                    onClick={() => this.moveSectionQuestionUp(sq.id)}
                                                                                    icon={faArrowAltCircleUp} />
                                                                            }
                                                                            {(sq.order != Math.max(...buildForm.sectionQuestions.filter((sq) => sq.surveySectionId == s.id)
                                                                                .sort((sq1, sq2) => sq1.order < sq2.order ? -1 : 1).map((sq) => sq.order))) &&
                                                                                <FontAwesomeIcon
                                                                                    className="mr-2 text-dark pr-2 zoom"
                                                                                    onClick={() => this.moveSectionQuestionDown(sq.id)}
                                                                                    icon={faArrowAltCircleDown} />
                                                                            }
                                                                        </td>
                                                                        <td>{sq.order}</td>
                                                                        <td>{(buildForm?.questions?.find((q) => q.question.id == sq.questionId)?.question?.inputTypeText ?? "")}</td>
                                                                        <td>
                                                                            <div className="question-box">
                                                                                <p className="question-name">{(buildForm?.questions?.find((q) => q.question.id == sq.questionId)?.question?.name ?? "")}</p>
                                                                                <p>{(buildForm?.questions?.find((q) => q.question.id == sq.questionId)?.question?.title ?? "")}</p>
                                                                            </div>
                                                                        </td>
                                                                        <td>{sq.answerRequired ? "Yes" : "No"}</td>
                                                                        <td>
                                                                            <FontAwesomeIcon
                                                                                className="mr-2 text-dark pr-2 zoom"
                                                                                onClick={() => this.onEditSectionQuestion(sq)}
                                                                                icon={faEdit} />
                                                                        </td>
                                                                        <td>
                                                                            <FontAwesomeIcon
                                                                                className="mr-2 text-dark pr-2 zoom"
                                                                                onClick={() => this.onRemoveSectionQuestion(sq.id)}
                                                                                icon={faTrashAlt} />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    }
                                    {!!!newSectionQuestion &&
                                        <Row className={'align-items-center justify-content-center'}>
                                            <Button
                                                size="sm"
                                                type="button"
                                                color="primary"
                                                name="addNewSectionQuestionButton"
                                                onClick={() => { this.onAddSectionQuestion(s.id) }}
                                            >
                                                <FontAwesomeIcon
                                                    className="mr-2"
                                                    icon={faPlusCircle} />
                                                {"Add Question"}
                                            </Button>
                                        </Row>
                                    }
                                    {(!!newSectionQuestion && workingSection == s.id) &&
                                        <Row>
                                            <Container style={{ marginBottom: "0.5em", padding: "0.5em", border: "1px solid gray", width: "96%" }} >
                                                {(!!newSectionQuestion && !!!newQuestion && workingSection == s.id) &&
                                                    <Row>
                                                        <Col xs={11} sm={11} md={7} lg={7} xl={7} xxl={9}>
                                                            <FormGroup>
                                                                <FormLabel htmlFor="newSectionQuestionQuestion" text="Select Existing Question" />
                                                                <ValidatedSelect
                                                                    id="newSectionQuestionQuestion"
                                                                    name="newSectionQuestion.questionId"
                                                                    options={questions}
                                                                    value={(questions ?? []).find(s => s.value == newSectionQuestion.questionId) ?? ''}
                                                                    onChange={(selection) => {
                                                                        newSectionQuestion.questionId = selection?.value;
                                                                        this.setState({ newSectionQuestion: newSectionQuestion });
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
                                                            {!!newSectionQuestion.questionId &&
                                                                <FormGroup>
                                                                    <FormLabel htmlFor="newSectionQuestionQuestion" text="." style={{ color: "rgba(0,0,0,0)" }} />
                                                                    <Button
                                                                        title={"Edit Selected Question"}
                                                                        type={"button"}
                                                                        onClick={() => { this.onEditQuestion(newSectionQuestion.questionId) }}
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            className="mr-2"
                                                                            icon={faScrewdriverWrench} />
                                                                    </Button>
                                                                </FormGroup>
                                                            }
                                                        </Col>
                                                        <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={2}>
                                                           
                                                            <FormGroup style={{ marginTop: "1.5em" }} >
                                                                <Input
                                                                    id="inputNewSectionQuestionRequired"
                                                                    name="newSectionQuestion.answerRequired"
                                                                    autoComplete="off"
                                                                    className="form-control"
                                                                    onChange={this.onChange}
                                                                    type="checkbox"
                                                                    checked={newSectionQuestion?.answerRequired ?? false}
                                                                />
                                                                <Label style={{ color: "#777", fontWeight: "600", fontSize: ".9em", lineHeight: "45px" }}
                                                                    check htmlFor="inputNewSectionQuestionRequired" >
                                                                    {"Answer Required?"}
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                }
                                                {(!!newSectionQuestion && !!!newQuestion && workingSection == s.id && !!(buildForm.survey.surveyExportFormatId)) &&
                                                    <Row>
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                            <FormGroup>
                                                                <FormLabel htmlFor="newSectionQuestionSurveyExportField" text="Answer for Export Field" />
                                                                <ValidatedSelect
                                                                    id="newSectionQuestionSurveyExportField"
                                                                    name="newSectionQuestion.surveyExportFormatFieldId"
                                                                    options={allExportFormatFields}
                                                                    value={(
                                                                        (assignedExportFormatFields ?? []).find(s => s.value == (newSectionQuestion?.surveyExportFormatFieldId ?? 0))
                                                                        ?? (allExportFormatFields ?? []).find(s => s.value == (newSectionQuestion?.surveyExportFormatFieldId ?? 0)) ) ?? ''}
                                                                    onChange={(selection) => {
                                                                        newSectionQuestion.surveyExportFormatFieldId = selection?.value;
                                                                        this.setState({ newSectionQuestion: newSectionQuestion });
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                }
                                                {(!!newSectionQuestion && !!!newQuestion && workingSection == s.id) &&
                                                    <Row>
                                                        <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={10}></Col>
                                                        <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={2}>
                                                            {!!canCreate &&
                                                                <Button
                                                                    size="sm"
                                                                    type="button"
                                                                    color="primary"
                                                                    name="addNewQuestionButton"
                                                                    onClick={this.onAddQuestion}
                                                                    style={{ marginTop: "1em" }}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        className="mr-2"
                                                                        icon={faPlusCircle} />
                                                                    {"Create New Question"}
                                                                </Button>
                                                            }
                                                        </Col>
                                                    </Row>
                                                }
                                                {(!!newSectionQuestion && !!newQuestion && workingSection == s.id) &&
                                                    <Row>
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                            <Container>
                                                                <GroupedRow>
                                                                    <FormGroupColumn>
                                                                        <FormGroup>
                                                                            <FormLabel htmlFor="inputName" text="Question Name" required={true} />
                                                                            <input id="inputName"
                                                                                name="newQuestion.question.name"
                                                                                autoComplete="off"
                                                                                className="form-control"
                                                                                required
                                                                                onChange={this.onChange}
                                                                                defaultValue={newQuestion.question.name} />
                                                                            <small className="invalid-feedback text-danger" hidden>Question Name is required.</small>
                                                                        </FormGroup>
                                                                        <FormGroup>
                                                                            <FormLabel htmlFor="inputTitle" text="Question Text" required={true} />
                                                                            <input id="inputTitle"
                                                                                name="newQuestion.question.title"
                                                                                autoComplete="off"
                                                                                className="form-control"
                                                                                required
                                                                                onChange={this.onChange}
                                                                                defaultValue={newQuestion.question.title} />
                                                                            <small className="invalid-feedback text-danger" hidden>Question Text is required.</small>
                                                                        </FormGroup>
                                                                        <FormGroup>
                                                                            <FormLabel htmlFor="inputSubText" text="Question Sub Text" />
                                                                            <textarea id="inputSubText"
                                                                                name="newQuestion.question.subText"
                                                                                autoComplete="off"
                                                                                className="form-control"
                                                                                onChange={this.onChange}
                                                                                defaultValue={newQuestion.question.subText} />
                                                                        </FormGroup>
                                                                        <FormGroup>
                                                                            <FormLabel htmlFor="inputInputType" text="Input For Question" />
                                                                            <ValidatedSelect
                                                                                id="inputInputType"
                                                                                name="newQuestion.question.inputTypeId"
                                                                                options={questionbuilder.inputtypes}
                                                                                value={(questionbuilder.inputtypes ?? []).find(s => s.value == newQuestion.question.inputTypeId) ?? ''}
                                                                                onChange={(selection) => {
                                                                                    newQuestion.question.inputTypeId = selection?.value;
                                                                                    newQuestion.question.inputTypeText = selection?.label;
                                                                                    this.setState({ newQuestion: newQuestion });
                                                                                }}
                                                                            />
                                                                        </FormGroup>
                                                                        {(newQuestion.question.inputTypeId != questionInputTypes.None && newQuestion.question.inputTypeId != 0) &&
                                                                            <>
                                                                                {((newQuestion.question.inputTypeId == questionInputTypes.OptionGroup)
                                                                                    || (newQuestion.question.inputTypeId == questionInputTypes.Select)) &&
                                                                                    <FormGroup>
                                                                                        <FormLabel htmlFor="inputOptionGroup" text="Option Group" />
                                                                                        <ValidatedSelect
                                                                                            id="inputOptionGroup"
                                                                                            name="newQuestion.optionGroupId"
                                                                                            options={questionbuilder.optiongroups}
                                                                                            value={(questionbuilder.optiongroups ?? []).find(s => s.value == newQuestion.optionGroupId) ?? ''}
                                                                                            onChange={async (selection) => {
                                                                                                newQuestion.optionGroupId = selection?.value;
                                                                                                newQuestion.useOptionGroup = await this.getOptionGroupToUse(selection?.value);
                                                                                                this.setState({ newQuestion: newQuestion });
                                                                                            }}
                                                                                        />
                                                                                    </FormGroup>
                                                                                }
                                                                                {(newQuestion.question.inputTypeId == questionInputTypes.Numeric) &&
                                                                                    <FormGroup>
                                                                                        <FormLabel htmlFor="inputUnitOfMeasure" text="Unit Of Measurement" />
                                                                                        <ValidatedSelect
                                                                                            id="inputUnitOfMeasure"
                                                                                            name="newQuestion.question.unitOfMeasureId"
                                                                                            options={questionbuilder.unitsofmeasure}
                                                                                            value={(questionbuilder.unitsofmeasure ?? []).find(s => s.value == newQuestion.question.unitOfMeasureId) ?? ''}
                                                                                            onChange={(selection) => {
                                                                                                newQuestion.question.unitOfMeasureId = selection?.value;
                                                                                                this.setState({ newQuestion: newQuestion });
                                                                                            }}
                                                                                        />
                                                                                    </FormGroup>
                                                                                }
                                                                                <FormGroup>
                                                                                    <Input
                                                                                        id="inputExpectedValueIsDefault"
                                                                                        name="newQuestion.question.expectedValueIsDefault"
                                                                                        autoComplete="off"
                                                                                        className="form-control"
                                                                                        onChange={this.onChange}
                                                                                        type="checkbox"
                                                                                        checked={newQuestion?.question?.expectedValueIsDefault ?? false}
                                                                                    />
                                                                                    <Label style={{ color: "#777", fontWeight: "600", fontSize: ".9em", lineHeight: "45px" }}
                                                                                        check htmlFor="inputExpectedValueIsDefault" >
                                                                                        {"Exected Value is Default?"}
                                                                                    </Label>
                                                                                </FormGroup>
                                                                            </>
                                                                        }
                                                                    </FormGroupColumn>
                                                                    <FormGroupColumn>
                                                                        <FormGroup>
                                                                            <Input
                                                                                id="inputAllowPicture"
                                                                                name="newQuestion.question.allowPicture"
                                                                                autoComplete="off"
                                                                                className="form-control"
                                                                                onChange={this.onChange}
                                                                                type="checkbox"
                                                                                checked={newQuestion?.question?.allowPicture ?? false}
                                                                                style={{ marginLeft: "1em" }}
                                                                            />
                                                                            <Label style={{ color: "#777", fontWeight: "600", fontSize: ".9em", lineHeight: "45px" }}
                                                                                check htmlFor="newQuestion.question.allowPicture" >
                                                                                {"Allow Pictures?"}
                                                                            </Label>
                                                                        </FormGroup>
                                                                        <FormGroup>
                                                                            <FormGroup>
                                                                                <Input
                                                                                    id="inputIsHidden"
                                                                                    name="newQuestion.question.isHidden"
                                                                                    autoComplete="off"
                                                                                    className="form-control"
                                                                                    onChange={this.onChange}
                                                                                    type="checkbox"
                                                                                    checked={newQuestion?.question?.isHidden ?? false}
                                                                                    style={{ marginLeft: "1em" }}
                                                                                />
                                                                                <Label style={{ color: "#777", fontWeight: "600", fontSize: ".9em", lineHeight: "45px" }}
                                                                                    check htmlFor="newQuestion.question.isHidden" >
                                                                                    {"Is Hidden?"}
                                                                                </Label>
                                                                            </FormGroup>
                                                                        </FormGroup>
                                                                    </FormGroupColumn>
                                                                </GroupedRow>
                                                                {(newQuestion.question.inputTypeId !== questionInputTypes.None) &&
                                                                    <Row className={'align-items-center justify-content-center'}>
                                                                        <Table style={{ width: "100%" }}>
                                                                            <colgroup>
                                                                                <col style={{ width: "60%" }} />
                                                                                <col style={{ width: "10%" }} />
                                                                                <col style={{ width: "10%" }} />
                                                                                <col style={{ width: "20%" }} />
                                                                            </colgroup>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Expected Value</th>
                                                                                    <th>Value Is</th>
                                                                                    <th>Relationship</th>
                                                                                    <th></th>
                                                                                    <th></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {!!(newQuestion.expectedValues ?? []).length &&
                                                                                    (newQuestion.expectedValues ?? []).map((ev, ind) =>
                                                                                        <Fragment key={uuid()}>
                                                                                            <tr key={(ev.Id ?? 0).toString() + ind.toString()} style={{ background: ind % 2 == 0 ? "inherit" : "#FAFAFA", borderTop: "0.25em solid black" }} >
                                                                                                <td>{ev.expectedValue}</td>
                                                                                                <td>{ev.valueTypeText}</td>
                                                                                                <td>{ev.relationshipText}</td>
                                                                                                <td>
                                                                                                    <FontAwesomeIcon
                                                                                                        className="mr-2"
                                                                                                        onClick={() => this.onExpectedValueRemoved(ind)}
                                                                                                        icon={faTrashAlt} />
                                                                                                </td>
                                                                                                <td>
                                                                                                    <FontAwesomeIcon
                                                                                                        className="mr-2 text-dark pr-2 zoom"
                                                                                                        onClick={() => {
                                                                                                            newQuestion.expectedValues[ind].showCriteria = !!!(newQuestion.expectedValues[ind].showCriteria);
                                                                                                            this.setState({ newQuestion: newQuestion });
                                                                                                        }}
                                                                                                        icon={faBan} />
                                                                                                </td>
                                                                                            </tr>
                                                                                            {!!ev.showCriteria &&
                                                                                                <tr key={uuid()} style={{ background: ind % 2 == 0 ? "inherit" : "#FAFAFA" }}>
                                                                                                    <td colSpan={4} >
                                                                                                        <Button
                                                                                                            size="sm"
                                                                                                            type="button"
                                                                                                            color="primary"
                                                                                                            name="addExpectedValueRestriction"
                                                                                                            onClick={() => {
                                                                                                                this.setState({
                                                                                                                    addExpectedValueRestriction: true,
                                                                                                                    addToExpectedValue: ind
                                                                                                                });
                                                                                                            }}
                                                                                                        >
                                                                                                            <FontAwesomeIcon
                                                                                                                className="mr-2 text-dark pr-2 zoom"
                                                                                                                icon={faPlus} />
                                                                                                            {"Add Restriction"}
                                                                                                        </Button>
                                                                                                        {!!(ev.criteria.length) &&
                                                                                                            <Table style={{ width: "100%", marginTop: "0.25em" }}>
                                                                                                                <colgroup>
                                                                                                                    <col style={{ width: "40%" }} />
                                                                                                                    <col style={{ width: "20%" }} />
                                                                                                                    <col style={{ width: "15%" }} />
                                                                                                                    <col style={{ width: "15%" }} />
                                                                                                                    <col style={{ width: "10%" }} />
                                                                                                                </colgroup>
                                                                                                                <thead>
                                                                                                                    <tr>
                                                                                                                        <th>Restriction Value</th>
                                                                                                                        <th>Type</th>
                                                                                                                        <th>Value Is</th>
                                                                                                                        <th>Relationship</th>
                                                                                                                        <th></th>
                                                                                                                    </tr>
                                                                                                                </thead>
                                                                                                                <tbody>
                                                                                                                    {!!(ev.criteria ?? []).length &&
                                                                                                                        (ev.criteria ?? []).map((evc, cind) =>
                                                                                                                            <tr key={uuid()}>
                                                                                                                                <td>{
                                                                                                                                    !!(evc.derivedFieldName) ? evc.derivedFieldName + ": " + evc.expectedValue : evc.expectedValue
                                                                                                                                }</td>
                                                                                                                                <td>{evc.criteriaTypeText}</td>
                                                                                                                                <td>{evc.valueTypeText}</td>
                                                                                                                                <td>{evc.relationshipText}</td>
                                                                                                                                <td>
                                                                                                                                    <FontAwesomeIcon
                                                                                                                                        className="mr-2"
                                                                                                                                        onClick={() => this.onRemoveNewExpectedValueCriteria(ind, cind)}
                                                                                                                                        icon={faTrashAlt} />
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        )
                                                                                                                    }
                                                                                                                </tbody>
                                                                                                            </Table>
                                                                                                        }
                                                                                                    </td>
                                                                                                </tr>
                                                                                            }
                                                                                        </Fragment>
                                                                                    )
                                                                                }
                                                                                {!!!(newQuestion.expectedValues ?? []).length &&
                                                                                    <tr><td colSpan={5}>No Expected Values</td></tr>
                                                                                }
                                                                                <tr>
                                                                                    {(newQuestion.question.inputTypeId == questionInputTypes.Derived) &&
                                                                                        <td>
                                                                                            <FormGroup>
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    disabled={!canEdit}
                                                                                                    name="newQuestion.newExpectedValue"
                                                                                                    value={newQuestion.newExpectedValue ?? ""}
                                                                                                    onChange={this.onChange}
                                                                                                />
                                                                                            </FormGroup>
                                                                                        </td>
                                                                                    }
                                                                                    {(newQuestion.question.inputTypeId == questionInputTypes.Numeric) &&
                                                                                        <td>
                                                                                            <FormGroup>
                                                                                                <Input
                                                                                                    type="number"
                                                                                                    name="newQuestion.newExpectedValue"
                                                                                                    value={newQuestion.newExpectedValue ?? 0}
                                                                                                    onChange={this.onChange}
                                                                                                />
                                                                                            </FormGroup>
                                                                                        </td>
                                                                                    }
                                                                                    {(newQuestion.question.inputTypeId == questionInputTypes.SingleLineText) &&
                                                                                        <td>
                                                                                            <FormGroup>
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    name="newQuestion.newExpectedValue"
                                                                                                    value={newQuestion.newExpectedValue ?? ""}
                                                                                                    onChange={this.onChange}
                                                                                                />
                                                                                            </FormGroup>
                                                                                        </td>
                                                                                    }
                                                                                    {(newQuestion.question.inputTypeId == questionInputTypes.Text) &&
                                                                                        <td>
                                                                                            <FormGroup>
                                                                                                <textarea
                                                                                                    name="newQuestion.newExpectedValue"
                                                                                                    value={newQuestion.newExpectedValue ?? ""}
                                                                                                    onChange={this.onChange}
                                                                                                />
                                                                                            </FormGroup>
                                                                                        </td>
                                                                                    }
                                                                                    {(newQuestion.question.inputTypeId == questionInputTypes.DateTime) &&
                                                                                        <td>
                                                                                            <FormGroup>
                                                                                                <Input
                                                                                                    type="date"
                                                                                                    disabled={!canEdit}
                                                                                                    name="newQuestion.newExpectedValue"
                                                                                                    value={newQuestion.newExpectedValue ?? ""}
                                                                                                    onChange={this.onChange}
                                                                                                />
                                                                                            </FormGroup>
                                                                                        </td>
                                                                                    }
                                                                                    {(newQuestion.question.inputTypeId == questionInputTypes.OptionGroup) &&
                                                                                        <td>
                                                                                            {!!(newQuestion.useOptionGroup) &&
                                                                                                <>
                                                                                                    {!!newQuestion.useOptionGroup.group.isExclusiveChoice &&
                                                                                                        <FormGroup>
                                                                                                            {
                                                                                                                newQuestion.useOptionGroup.options.map((op) =>
                                                                                                                    <FormGroup key={op.id + op.choiceText} check>
                                                                                                                        <Input
                                                                                                                            name="optionGroup"
                                                                                                                            type="radio"
                                                                                                                            value={op.choiceText}
                                                                                                                            checked={(newQuestion?.newExpectedValue ?? "") == op.choiceText}
                                                                                                                            className="form-control"
                                                                                                                            onChange={() => { this.onSetNewExpectedValue(op.choiceText) }}
                                                                                                                        />
                                                                                                                        {' '}
                                                                                                                        <Label style={{ color: "#777", fontWeight: "600", fontSize: ".9em", lineHeight: "45px" }} check>
                                                                                                                            {op.choiceText}
                                                                                                                        </Label>
                                                                                                                    </FormGroup>
                                                                                                                )
                                                                                                            }
                                                                                                        </FormGroup>
                                                                                                    }
                                                                                                    {!!!newQuestion.useOptionGroup.group.isExclusiveChoice &&
                                                                                                        <FormGroup>
                                                                                                            {
                                                                                                                newQuestion.useOptionGroup.options.map((op) =>
                                                                                                                    <FormGroup key={op.id + op.choiceText} check>
                                                                                                                        <Input
                                                                                                                            name="optionGroup"
                                                                                                                            type="checkbox"
                                                                                                                            checked={(newQuestion?.newExpectedValue ?? "").includes(op.choiceText)}
                                                                                                                            className="form-control"
                                                                                                                            onChange={() => { this.onSetNewExpectedGroupValue(op.choiceText) }}
                                                                                                                        />
                                                                                                                        {' '}
                                                                                                                        <Label style={{ color: "#777", fontWeight: "600", fontSize: ".9em", lineHeight: "45px" }} check>
                                                                                                                            {op.choiceText}
                                                                                                                        </Label>
                                                                                                                    </FormGroup>
                                                                                                                )
                                                                                                            }
                                                                                                        </FormGroup>
                                                                                                    }
                                                                                                </>
                                                                                            }
                                                                                            {!!!(newQuestion.useOptionGroup) &&
                                                                                                <span>No Option Group Chosen</span>
                                                                                            }
                                                                                        </td>
                                                                                    }
                                                                                    {(newQuestion.question.inputTypeId == questionInputTypes.Select) &&
                                                                                        <td>
                                                                                            {!!(newQuestion.useOptionGroup) &&
                                                                                                <>
                                                                                                    {newQuestion.useOptionGroup.group.isExclusiveChoice &&
                                                                                                        <FormGroup>
                                                                                                            <ValidatedSelect
                                                                                                                id="selectNewExpectedValue"
                                                                                                                name="newQuestion.newExpectedValue"
                                                                                                                options={newQuestion.useOptionGroup.options}
                                                                                                                value={(newQuestion.useOptionGroup.options ?? []).find(s => s.choiceText == newQuestion.newExpectedValue) ?? ''}
                                                                                                                getOptionLabel={option => option.choiceText}
                                                                                                                getOptionValue={option => option.id}
                                                                                                                validationMessage=""
                                                                                                                onChange={(selection) => {
                                                                                                                    newQuestion.newExpectedValue = selection?.choiceText;
                                                                                                                    this.setState({ newQuestion: newQuestion });
                                                                                                                }}
                                                                                                            />
                                                                                                        </FormGroup>
                                                                                                    }
                                                                                                    {!newQuestion.useOptionGroup.group.isExclusiveChoice &&
                                                                                                        <FormGroup >
                                                                                                            <ValidatedSelect
                                                                                                                id="selectNewExpectedValueMlti"
                                                                                                                name="newQuestion.selectedGroupOptions"
                                                                                                                isMulti
                                                                                                                options={newQuestion.useOptionGroup.options}
                                                                                                                value={newQuestion.selectedGroupOptions ?? ''}
                                                                                                                getOptionLabel={option => option.choiceText}
                                                                                                                getOptionValue={option => option.id}
                                                                                                                validationMessage=""
                                                                                                                onChange={(selection) => {
                                                                                                                    newQuestion.selectedGroupOptions = selection;
                                                                                                                    this.setState({ newQuestion: newQuestion });
                                                                                                                }}
                                                                                                            />
                                                                                                        </FormGroup>
                                                                                                    }
                                                                                                </>
                                                                                            }
                                                                                            {!!!(newQuestion.useOptionGroup) &&
                                                                                                <span>No Option Group Chosen</span>
                                                                                            }
                                                                                        </td>
                                                                                    }
                                                                                    <td>
                                                                                        <FormGroup>
                                                                                            <ValidatedSelect
                                                                                                id="inputAddExpectedValType"
                                                                                                options={questionbuilder.expectedDataTypes}
                                                                                                onChange={this.onChangeNewExpectedValType}
                                                                                                validationMessage=""
                                                                                                value={newQuestion.newExpectedValueType}
                                                                                            />
                                                                                            <small className="invalid-feedback text-danger" hidden></small>
                                                                                        </FormGroup>
                                                                                    </td>
                                                                                    <td>
                                                                                        <FormGroup>
                                                                                            <ValidatedSelect
                                                                                                id="inputAddExpectedValRel"
                                                                                                options={questionbuilder.expectedRelationships}
                                                                                                onChange={this.onChangeNewExpectedValRel}
                                                                                                validationMessage=""
                                                                                                value={newQuestion.newExpectedValueRel}
                                                                                            />
                                                                                            <small className="invalid-feedback text-danger" hidden></small>
                                                                                        </FormGroup>
                                                                                    </td>
                                                                                    <td>
                                                                                        {(!!(newQuestion?.newExpectedValueType ?? false)
                                                                                            && !!(newQuestion?.newExpectedValueRel ?? false)
                                                                                            && (
                                                                                                !!(newQuestion?.selectedGroupOptions ?? []).length ||
                                                                                                (
                                                                                                    !!(newQuestion?.newExpectedValue)
                                                                                                    || (!!!(newQuestion?.newExpectedValue) && allowEmpty.includes(newQuestion.question.inputTypeId))
                                                                                                )
                                                                                            )
                                                                                        ) &&
                                                                                            <Button
                                                                                                size="sm"
                                                                                                type="button"
                                                                                                color="primary"
                                                                                                onClick={this.onAddNewExpectedValue}
                                                                                                name="addNewExpectedValue">
                                                                                                <FontAwesomeIcon
                                                                                                    className="mr-2"
                                                                                                    icon={faPlusCircle} />
                                                                                                {"Add New"}
                                                                                            </Button>
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </Table>
                                                                    </Row>
                                                                }
                                                                <Row className={'align-items-center justify-content-center'}>
                                                                    <Col>
                                                                        <Button
                                                                            size="sm"
                                                                            type="button"
                                                                            color="primary"
                                                                            name="saveNewQuestionButton"
                                                                            onClick={!!updatingQuestion ? this.onUpdateQuestion : this.onSaveNewQuestion}
                                                                        >
                                                                            <FontAwesomeIcon
                                                                                className="mr-2"
                                                                                icon={faSave} />
                                                                            {!!updatingQuestion ? "Update" : "Save"}
                                                                        </Button>
                                                                    </Col>
                                                                    <Col>
                                                                        <Button
                                                                            size="sm"
                                                                            type="button"
                                                                            color="secondary"
                                                                            name="cancelNewQuestionButton"
                                                                            onClick={this.onCancelAddUpdateQuestion}
                                                                            className="ml-2">
                                                                            <FontAwesomeIcon
                                                                                className="mr-2"
                                                                                icon={faTimes} />
                                                                            {"Cancel"}
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </Col>
                                                    </Row>
                                                }
                                                {(!!newSectionQuestion && !!!newQuestion && workingSection == s.id) &&
                                                    <GroupedRow>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                            <Button
                                                                size="sm"
                                                                type="button"
                                                                color="primary"
                                                                name="saveNewSectionQuestionButton"
                                                                onClick={() => { !!updatingSectionQuestion ? this.onUpdateSectionQuestion() : this.onSaveNewSectionQuestion() }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    className="mr-2"
                                                                    icon={faSave} />
                                                                {!!updatingSectionQuestion ? "Update" : "Save New Question"}
                                                            </Button>
                                                        </Col>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                            <Button
                                                                size="sm"
                                                                type="button"
                                                                color="primary"
                                                                name="cancelNewSectionButton"
                                                                onClick={() => { this.onCancelAddUpdateSectionQuestion() }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    className="mr-2"
                                                                    icon={faTimesCircle} />
                                                                {"Cancel"}
                                                            </Button>
                                                        </Col>
                                                    </GroupedRow>
                                                }
                                            </Container>
                                        </Row>
                                    }
                                </Container>
                            )
                        }
                        {!!!newSection  &&
                            <Row className={'align-items-center justify-content-center'}>
                                <Button
                                    size="sm"
                                    type="button"
                                    color="primary"
                                    name="addNewSectionButton"
                                    onClick={this.onAddSection}
                                >
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faPlusCircle} />
                                    {"Add Section"}
                                </Button>
                            </Row>
                        }
                        {!!newSection &&
                            <Container style={{ marginBottom: "1em", padding: "0.5em", border: "1px solid black" }} >
                                <GroupedRow>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <FormGroup>
                                            <FormLabel htmlFor="inputNewSectionName" text="Name" required={true} />
                                            <input id="inputNewSectionName"
                                                name="newSection.sectionName"
                                                autoComplete="off"
                                                className="form-control"
                                                required
                                                onChange={this.onChange}
                                                defaultValue={newSection.sectionName} />
                                            <small className="invalid-feedback text-danger" hidden>Section name is required.</small>
                                        </FormGroup>
                                    </Col>
                                </GroupedRow>
                                <GroupedRow>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <FormGroup>
                                            <FormLabel htmlFor="inputNewSectionInstructions" text="Instructions" />
                                            <textarea id="inputNewSectionInstructions"
                                                name="newSection.instructions"
                                                className="form-control"
                                                onChange={this.onChange}
                                                defaultValue={newSection.instructions} />
                                        </FormGroup>
                                    </Col>
                                </GroupedRow>
                                <GroupedRow>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        {!!!addSectionRestriction && 
                                            <Button
                                                size="sm"
                                                type="button"
                                                color="primary"
                                                name="addSectionRestriction"
                                                onClick={() => {
                                                    this.setState({
                                                        addSectionRestriction: true
                                                    });
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    className="mr-2 text-dark pr-2 zoom"
                                                    icon={faPlus} />
                                                {"Add Restriction"}
                                            </Button>
                                        }
                                        <Table style={{ width: "100%", marginTop: "0.25em" }}>
                                            <colgroup>
                                                <col style={{ width: "40%" }} />
                                                <col style={{ width: "20%" }} />
                                                <col style={{ width: "15%" }} />
                                                <col style={{ width: "15%" }} />
                                                <col style={{ width: "10%" }} />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>Restriction Value</th>
                                                    <th>Type</th>
                                                    <th>Value Is</th>
                                                    <th>Relationship</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!!(newSection.criteria ?? []).length &&
                                                    (newSection.criteria ?? []).map((cr, cind) =>
                                                        <tr key={uuid()}>
                                                            <td>{
                                                                !!(cr.derivedFieldName) ? cr.derivedFieldName + ": " + cr.expectedValue : cr.expectedValue
                                                            }</td>
                                                            <td>{cr.criteriaTypeText}</td>
                                                            <td>{cr.valueTypeText}</td>
                                                            <td>{cr.relationshipText}</td>
                                                            <td>
                                                                <FontAwesomeIcon
                                                                    className="mr-2"
                                                                    onClick={() => this.onRemoveSectionCriteria(cind)}
                                                                    icon={faTrashAlt}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </GroupedRow>
                                {!!addSectionRestriction &&
                                    <Fragment>
                                        <GroupedRow>
                                            <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                <FormGroup>
                                                    <FormLabel htmlFor="secCriteriaType" text="Restriction Type" />
                                                    <ValidatedSelect
                                                        id="secCriteriaType"
                                                        formRef={this.formRef}
                                                        name="newSectionCriteriaCriteriaType"
                                                        options={allCriteriaTypes}
                                                        value={(allCriteriaTypes ?? []).find(s => s.value == (newSectionCriteriaCriteriaType ?? {}).value) ?? ''}
                                                        onChange={(selection) => {
                                                            newSectionCriteria = null;
                                                            newSectionCriteriaCriteriaType = selection;
                                                            this.setState({
                                                                newSectionCriteriaCriteriaType: newSectionCriteriaCriteriaType,
                                                                newSectionCriteria: newSectionCriteria
                                                            });
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                {!!((newSectionCriteriaCriteriaType ?? {}).value == criteriaTypes.Customer) &&
                                                    <FormGroup>
                                                        <FormLabel htmlFor="secCriteriaCustomerValue" text="Choose Customer" />
                                                        <ValidatedSelect
                                                            id="secCriteriaCustomerValue"
                                                            formRef={this.formRef}
                                                            name="newSectionCriteria"
                                                            options={allCustomers}
                                                            value={(allCustomers ?? []).find(s => s.value == (newSectionCriteria ?? {}).value) ?? ''}
                                                            onChange={(selection) => {
                                                                newSectionCriteria = selection;
                                                                this.setState({ newSectionCriteria: newSectionCriteria });
                                                            }}
                                                        />
                                                    </FormGroup>
                                                }
                                                {!!((newSectionCriteriaCriteriaType ?? {}).value == criteriaTypes.Category) &&
                                                    <FormGroup>
                                                        <FormLabel htmlFor="secCriteriaCategoryValue" text="Choose Product Category" />
                                                        <ValidatedSelect
                                                            id="secCriteriaCategoryValue"
                                                            formRef={this.formRef}
                                                            name="newSectionCriteria"
                                                            options={allProductCategories}
                                                            value={(allProductCategories ?? []).find(s => s.value == (newSectionCriteria ?? {}).value) ?? ''}
                                                            onChange={(selection) => {
                                                                newSectionCriteria = selection;
                                                                this.setState({ newSectionCriteria: newSectionCriteria });
                                                            }}
                                                        />
                                                    </FormGroup>
                                                }
                                                {!!((newSectionCriteriaCriteriaType ?? {}).value == criteriaTypes.Family) &&
                                                    <FormGroup>
                                                        <FormLabel htmlFor="secCriteriaFamilyValue" text="Choose Product Family" />
                                                        <ValidatedSelect
                                                            id="secCriteriaFamilyValue"
                                                            formRef={this.formRef}
                                                            name="newSectionCriteria"
                                                            options={allProductFamilies}
                                                            value={(allProductFamilies ?? []).find(s => s.value == (newSectionCriteria ?? {}).value) ?? ''}
                                                            onChange={(selection) => {
                                                                newSectionCriteria = selection;
                                                                this.setState({ newSectionCriteria: newSectionCriteria });
                                                            }}
                                                        />
                                                    </FormGroup>
                                                }
                                                {!!((newSectionCriteriaCriteriaType ?? {}).value == criteriaTypes.Model) &&
                                                    <FormGroup>
                                                        <FormLabel htmlFor="secCriteriaModelValue" text="Choose Model" />
                                                        <ValidatedSelect
                                                            id="secCriteriaModelValue"
                                                            formRef={this.formRef}
                                                            name="newSectionCriteria"
                                                            options={allModels}
                                                            value={(allModels ?? []).find(s => s.value == (newSectionCriteria ?? {}).value) ?? ''}
                                                            onChange={(selection) => {
                                                                newSectionCriteria = selection;
                                                                this.setState({ newSectionCriteria: newSectionCriteria });
                                                            }}
                                                        />
                                                    </FormGroup>
                                                }
                                                {!!((newSectionCriteriaCriteriaType ?? {}).value == criteriaTypes.LiftOption) &&
                                                    <FormGroup>
                                                        <FormLabel htmlFor="secCriteriaLiftOptionValue" text="Choose Lift Option" />
                                                        <ValidatedSelect
                                                            id="secCriteriaLiftOptionValue"
                                                            formRef={this.formRef}
                                                            name="newSectionCriteria"
                                                            options={allLiftOptions}
                                                            value={(allLiftOptions ?? []).find(s => s.value == (newSectionCriteria ?? {}).value) ?? ''}
                                                            onChange={(selection) => {
                                                                newSectionCriteria = selection;
                                                                this.setState({ newSectionCriteria: newSectionCriteria });
                                                            }}
                                                        />
                                                    </FormGroup>
                                                }
                                                {!!((newSectionCriteriaCriteriaType ?? {}).value == criteriaTypes.MountingInstruction) &&
                                                    <FormGroup>
                                                        <FormLabel htmlFor="secCriteriaMountingInstructionValue" text="Choose Mounting Instruction" />
                                                        <ValidatedSelect
                                                            id="secCriteriaMountingInstructionValue"
                                                            formRef={this.formRef}
                                                            name="newSectionCriteria"
                                                            options={allMountingInstructions}
                                                            value={(allMountingInstructions ?? []).find(s => s.value == (newSectionCriteria ?? {}).value) ?? ''}
                                                            onChange={(selection) => {
                                                                newSectionCriteria = selection;
                                                                this.setState({ newSectionCriteria: newSectionCriteria });
                                                            }}
                                                        />
                                                    </FormGroup>
                                                }
                                                {!!((newSectionCriteriaCriteriaType ?? {}).value == criteriaTypes.LiftType) &&
                                                    <FormGroup>
                                                        <FormLabel htmlFor="secCriteriaLiftTypeValue" text="Choose Lift Type" />
                                                        <ValidatedSelect
                                                            id="secCriteriaLiftTypeValue"
                                                            formRef={this.formRef}
                                                            name="newSectionCriteria"
                                                            options={allLiftTypes}
                                                            value={(allLiftTypes ?? []).find(s => s.value == (newSectionCriteria ?? {}).value) ?? ''}
                                                            onChange={(selection) => {
                                                                newSectionCriteria = selection;
                                                                this.setState({ newSectionCriteria: newSectionCriteria });
                                                            }}
                                                        />
                                                    </FormGroup>
                                                }
                                                {!!((newSectionCriteriaCriteriaType ?? {}).value == criteriaTypes.SalesOrderTrackingDerived) &&
                                                    <>
                                                        <FormGroup>
                                                            <FormLabel htmlFor="secCriteriaSOTDerivedValue" text="Choose Property" />
                                                            <ValidatedSelect
                                                                id="secCriteriaSOTDerivedValue"
                                                                formRef={this.formRef}
                                                                name="newSectionCriteriaDerivedFieldName"
                                                                options={allSalesOrderTrackingDerived}
                                                                value={(allSalesOrderTrackingDerived ?? []).find(s => s.value == (newSectionCriteriaDerivedFieldName ?? {}).value) ?? ''}
                                                                onChange={(selection) => {
                                                                    newSectionCriteriaDerivedFieldName = selection;
                                                                    this.setState({ newSectionCriteriaDerivedFieldName: newSectionCriteriaDerivedFieldName });
                                                                }}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Input
                                                                type="text"
                                                                disabled={!canEdit}
                                                                name="newSectionCriteria"
                                                                value={newSectionCriteria?.label ?? ""}
                                                                onChange={(e) => {
                                                                    newSectionCriteria = !!(e.target.value) ? { label: e.target.value } : null;
                                                                    this.setState({ newSectionCriteria: newSectionCriteria });
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </>
                                                }
                                            </Col>
                                            <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                {!!(newSectionCriteriaCriteriaType) &&
                                                    <FormGroup>
                                                        <FormLabel htmlFor="newSectionCriteriaType" text="Evaluation Type" />
                                                        <ValidatedSelect
                                                            id="newSectionCriteriaType"
                                                            formRef={this.formRef}
                                                            options={questionbuilder.expectedDataTypes}
                                                            onChange={(selection) => {
                                                                newSectionCriteriaType = selection;
                                                                this.setState({ newSectionCriteriaType: newSectionCriteriaType });
                                                            }}
                                                            validationMessage=""
                                                            value={newSectionCriteriaType}
                                                        />
                                                        <small className="invalid-feedback text-danger" hidden></small>
                                                    </FormGroup>
                                                }
                                            </Col>
                                            <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                {!!(newSectionCriteriaCriteriaType) &&
                                                    <FormGroup>
                                                        <FormLabel htmlFor="newSectionCriteriaRel" text="Restriction Relationship" />
                                                        <ValidatedSelect
                                                            id="newSectionCriteriaRel"
                                                            formRef={this.formRef}
                                                            options={questionbuilder.expectedRelationships}
                                                            onChange={(selection) => {
                                                                newSectionCriteriaRel = selection;
                                                                this.setState({ newSectionCriteriaRel: newSectionCriteriaRel });
                                                            }}
                                                            validationMessage=""
                                                            value={newSectionCriteriaRel}
                                                        />
                                                        <small className="invalid-feedback text-danger" hidden></small>
                                                    </FormGroup>
                                                }
                                            </Col>
                                        </GroupedRow>
                                        <GroupedRow>
                                            <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                                            </Col>
                                            <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
                                                {(!!(newSectionCriteriaCriteriaType) && !!(newSectionCriteriaRel) &&
                                                    !!(newSectionCriteriaType) && !!(newSectionCriteria))
                                                    && (
                                                        !!((newSectionCriteriaCriteriaType ?? {}).value != criteriaTypes.SalesOrderTrackingDerived) ||
                                                        (!!((newSectionCriteriaCriteriaType ?? {}).value == criteriaTypes.SalesOrderTrackingDerived)
                                                            && !!(newSectionCriteriaDerivedFieldName))
                                                    ) &&
                                                    <Button
                                                        size="sm"
                                                        type="button"
                                                        color="primary"
                                                        name="addSectionRestriction"
                                                        onClick={() => this.onAddNewSectionCriteria()}
                                                    >
                                                        <FontAwesomeIcon
                                                            className="mr-2"
                                                            icon={faPlus} />
                                                        {"Add"}
                                                    </Button>
                                                }
                                            </Col>
                                            <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
                                                <Button
                                                    size="sm"
                                                    type="button"
                                                    color="secondary"
                                                    name="cancelSectionRestriction"
                                                    onClick={() => this.setState({ addSectionRestriction: false })}
                                                    className="ml-2">
                                                    <FontAwesomeIcon
                                                        className="mr-2"
                                                        icon={faTimes} />
                                                    {"Cancel"}
                                                </Button>
                                            </Col>
                                        </GroupedRow>
                                    </Fragment>
                                }
                                <GroupedRow>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                        <Button
                                            size="sm"
                                            type="button"
                                            color="primary"
                                            name="saveNewSectionButton"
                                            onClick={() => { !!updatingSection ? this.onUpdateSection() : this.onSaveNewSection() }}
                                        >
                                            <FontAwesomeIcon
                                                className="mr-2"
                                                icon={faSave} />
                                            { !!updatingSection ? "Update" : "Save New Section"}
                                        </Button>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                        
                                        <Button
                                            size="sm"
                                            type="button"
                                            color="primary"
                                            name="cancelNewSectionButton"
                                            onClick={this.onCancelAddUpdateSection}
                                        >
                                            <FontAwesomeIcon
                                                className="mr-2"
                                                icon={faTimesCircle} />
                                            {"Cancel"}
                                        </Button>
                                    </Col>
                                </GroupedRow>
                            </Container>
                        }
                        <FlexRow>
                            <FormErrorResponseDisplay onClear={this.clearSaveErrors} response={errorResponse} />
                        </FlexRow>
                        <Modal isOpen={addExpectedValueRestriction} size={"xl"}>
                            <ModalHeader>Add Restriction</ModalHeader>
                            <ModalBody>
                                <GroupedRow>
                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                        <FormGroup>
                                            <FormLabel htmlFor="evCriteriaType" text="Restriction Type" />
                                            <ValidatedSelect
                                                id="evCriteriaType"
                                                formRef={this.formRef}
                                                name="newExpectedValueCriteriaCriteriaType"
                                                options={allCriteriaTypes}
                                                value={(allCriteriaTypes ?? []).find(s => s.value == (newExpectedValueCriteriaCriteriaType ?? {}).value) ?? ''}
                                                onChange={(selection) => {
                                                    newExpectedValueCriteria = null;
                                                    newExpectedValueCriteriaCriteriaType = selection;
                                                    this.setState({
                                                        newExpectedValueCriteriaCriteriaType: newExpectedValueCriteriaCriteriaType,
                                                        newExpectedValueCriteria: newExpectedValueCriteria
                                                    });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                        {!!((newExpectedValueCriteriaCriteriaType ?? {}).value == criteriaTypes.Customer) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="evCriteriaCustomerValue" text="Choose Customer" />
                                                <ValidatedSelect
                                                    id="evCriteriaCustomerValue"
                                                    formRef={this.formRef}
                                                    name="newExpectedValueCriteria"
                                                    options={allCustomers}
                                                    value={(allCustomers ?? []).find(s => s.value == (newExpectedValueCriteria ?? {}).value) ?? ''}
                                                    onChange={(selection) => {
                                                        newExpectedValueCriteria = selection;
                                                        this.setState({ newExpectedValueCriteria: newExpectedValueCriteria });
                                                    }}
                                                />
                                            </FormGroup>
                                        }
                                        {!!((newExpectedValueCriteriaCriteriaType ?? {}).value == criteriaTypes.Category) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="evCriteriaCategoryValue" text="Choose Product Category" />
                                                <ValidatedSelect
                                                    id="evCriteriaCategoryValue"
                                                    formRef={this.formRef}
                                                    name="newExpectedValueCriteria"
                                                    options={allProductCategories}
                                                    value={(allProductCategories ?? []).find(s => s.value == (newExpectedValueCriteria ?? {}).value) ?? ''}
                                                    onChange={(selection) => {
                                                        newExpectedValueCriteria = selection;
                                                        this.setState({ newExpectedValueCriteria: newExpectedValueCriteria });
                                                    }}
                                                />
                                            </FormGroup>
                                        }
                                        {!!((newExpectedValueCriteriaCriteriaType ?? {}).value == criteriaTypes.Family) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="evCriteriaFamilyValue" text="Choose Product Family" />
                                                <ValidatedSelect
                                                    id="evCriteriaFamilyValue"
                                                    formRef={this.formRef}
                                                    name="newExpectedValueCriteria"
                                                    options={allProductFamilies}
                                                    value={(allProductFamilies ?? []).find(s => s.value == (newExpectedValueCriteria ?? {}).value) ?? ''}
                                                    onChange={(selection) => {
                                                        newExpectedValueCriteria = selection;
                                                        this.setState({ newExpectedValueCriteria: newExpectedValueCriteria });
                                                    }}
                                                />
                                            </FormGroup>
                                        }
                                        {!!((newExpectedValueCriteriaCriteriaType ?? {}).value == criteriaTypes.Model) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="evCriteriaModelValue" text="Choose Model" />
                                                <ValidatedSelect
                                                    id="evCriteriaModelValue"
                                                    formRef={this.formRef}
                                                    name="newExpectedValueCriteria"
                                                    options={allModels}
                                                    value={(allModels ?? []).find(s => s.value == (newExpectedValueCriteria ?? {}).value) ?? ''}
                                                    onChange={(selection) => {
                                                        newExpectedValueCriteria = selection;
                                                        this.setState({ newExpectedValueCriteria: newExpectedValueCriteria });
                                                    }}
                                                />
                                            </FormGroup>
                                        }
                                        {!!((newExpectedValueCriteriaCriteriaType ?? {}).value == criteriaTypes.LiftOption) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="evCriteriaLiftOptionValue" text="Choose Lift Option" />
                                                <InputGroup>
                                                    <InputGroupText>
                                                        <span style={
                                                            { position: 'absolute', top: '-2px', left: '1.125em', fontSize: '9px' }
                                                        }>Override</span>
                                                        <Input
                                                            id="evCriteriaLiftOptionValueOverride"
                                                            name="newExpectedValueCriteriaOverride"
                                                            autoComplete="off"
                                                            onChange={this.onChange}
                                                            type="checkbox"
                                                            checked={newExpectedValueCriteriaOverride ?? false}
                                                            style={{ position: 'relative', top: '2px', marginLeft: 'auto', width: '2em', height: '1.5em' }}
                                                        />
                                                    </InputGroupText>
                                                    {!!!newExpectedValueCriteriaOverride &&
                                                        <div class="form-control" style={{ padding: 0, border: 'none' }}>
                                                            <ValidatedSelect
                                                                id="evCriteriaLiftOptionValue"
                                                                formRef={this.formRef}
                                                                name="newExpectedValueCriteria"
                                                                options={allLiftOptions}
                                                                value={(allLiftOptions ?? []).find(s => s.value == (newExpectedValueCriteria ?? {}).value) ?? ''}
                                                                onChange={(selection) => {
                                                                    newExpectedValueCriteria = selection;
                                                                    this.setState({ newExpectedValueCriteria: newExpectedValueCriteria });
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    {!!newExpectedValueCriteriaOverride &&
                                                        <Input
                                                            type="text"
                                                            style={{ flexGrow: 3 }}
                                                            name="newExpectedValueCriteria"
                                                            value={newExpectedValueCriteria?.label ?? ""}
                                                            onChange={(e) => {
                                                                newExpectedValueCriteria = !!(e.target.value) ? { label: e.target.value } : null;;
                                                                this.setState({ newExpectedValueCriteria: newExpectedValueCriteria });
                                                            }}
                                                        />
                                                    }
                                                </InputGroup>
                                            </FormGroup>
                                        }
                                        {!!((newExpectedValueCriteriaCriteriaType ?? {}).value == criteriaTypes.MountingInstruction) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="evCriteriaMountingInstructionValue" text="Choose Mounting Instruction" />
                                                <InputGroup>
                                                    <InputGroupText>
                                                        <span style={
                                                            { position: 'absolute', top: '-2px', left: '1.125em', fontSize: '9px' }
                                                        }>Override</span>
                                                        <Input
                                                            id="evCriteriaLiftOptionValueOverride"
                                                            name="newExpectedValueCriteriaOverride"
                                                            autoComplete="off"
                                                            onChange={this.onChange}
                                                            type="checkbox"
                                                            checked={newExpectedValueCriteriaOverride ?? false}
                                                            style={{ position: 'relative', top: '2px', marginLeft: 'auto', width: '2em', height: '1.5em' }}
                                                        />
                                                    </InputGroupText>
                                                    {!!!newExpectedValueCriteriaOverride &&
                                                        <div class="form-control" style={{ padding: 0, border: 'none' }}>
                                                            <ValidatedSelect
                                                                id="evCriteriaMountingInstructionValue"
                                                                formRef={this.formRef}
                                                                name="newExpectedValueCriteria"
                                                                options={allMountingInstructions}
                                                                value={(allMountingInstructions ?? []).find(s => s.value == (newExpectedValueCriteria ?? {}).value) ?? ''}
                                                                onChange={(selection) => {
                                                                    newExpectedValueCriteria = selection;
                                                                    this.setState({ newExpectedValueCriteria: newExpectedValueCriteria });
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    {!!newExpectedValueCriteriaOverride &&
                                                        <Input
                                                            type="text"
                                                            style={{ flexGrow: 3 }}
                                                            name="newExpectedValueCriteria"
                                                            value={newExpectedValueCriteria?.label ?? ""}
                                                            onChange={(e) => {
                                                                newExpectedValueCriteria = !!(e.target.value) ? { label: e.target.value } : null;;
                                                                this.setState({ newExpectedValueCriteria: newExpectedValueCriteria });
                                                            }}
                                                        />
                                                    }
                                                </InputGroup>
                                            </FormGroup>
                                        }
                                        {!!((newExpectedValueCriteriaCriteriaType ?? {}).value == criteriaTypes.LiftType) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="evCriteriaLiftTypeValue" text="Choose Lift Type" />
                                                <ValidatedSelect
                                                    id="evCriteriaLiftTypeValue"
                                                    formRef={this.formRef}
                                                    name="newExpectedValueCriteria"
                                                    options={allLiftTypes}
                                                    value={(allLiftTypes ?? []).find(s => s.value == (newExpectedValueCriteria ?? {}).value) ?? ''}
                                                    onChange={(selection) => {
                                                        newExpectedValueCriteria = selection;
                                                        this.setState({ newExpectedValueCriteria: newExpectedValueCriteria });
                                                    }}
                                                />
                                            </FormGroup>
                                        }
                                        {!!((newExpectedValueCriteriaCriteriaType ?? {}).value == criteriaTypes.SalesOrderTrackingDerived) &&
                                            <>
                                                <FormGroup>
                                                    <FormLabel htmlFor="evCriteriaSOTDerivedValue" text="Choose Property" />
                                                    <ValidatedSelect
                                                        id="evCriteriaSOTDerivedValue"
                                                        formRef={this.formRef}
                                                        name="newExpectedValueCriteriaDerivedFieldName"
                                                        options={allSalesOrderTrackingDerived}
                                                        value={(allSalesOrderTrackingDerived ?? []).find(s => s.value == (newExpectedValueCriteriaDerivedFieldName ?? {}).value) ?? ''}
                                                        onChange={(selection) => {
                                                            newExpectedValueCriteriaDerivedFieldName = selection;
                                                            this.setState({ newExpectedValueCriteriaDerivedFieldName: newExpectedValueCriteriaDerivedFieldName });
                                                        }}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        name="newExpectedValueCriteria"
                                                        value={newExpectedValueCriteria?.label ?? ""}
                                                        onChange={(e) => {
                                                            newExpectedValueCriteria = !!(e.target.value) ? { label: e.target.value } : null;
                                                            this.setState({ newExpectedValueCriteria: newExpectedValueCriteria });
                                                        }}
                                                    />
                                                </FormGroup>
                                            </>
                                        }
                                    </Col>
                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                        {!!(newExpectedValueCriteriaCriteriaType) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="newExpectedValueCriteriaType" text="Evaluation Type" />
                                                <ValidatedSelect
                                                    id="newExpectedValueCriteriaType"
                                                    formRef={this.formRef}
                                                    options={questionbuilder.expectedDataTypes}
                                                    onChange={(selection) => {
                                                        newExpectedValueCriteriaType = selection;
                                                        this.setState({ newExpectedValueCriteriaType: newExpectedValueCriteriaType });
                                                    }}
                                                    validationMessage=""
                                                    value={newExpectedValueCriteriaType}
                                                />
                                                <small className="invalid-feedback text-danger" hidden></small>
                                            </FormGroup>
                                        }
                                    </Col>
                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                        {!!(newExpectedValueCriteriaCriteriaType) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="newExpectedValueCriteriaRel" text="Restriction Relationship" />
                                                <ValidatedSelect
                                                    id="newExpectedValueCriteriaRel"
                                                    formRef={this.formRef}
                                                    options={questionbuilder.expectedRelationships}
                                                    onChange={(selection) => {
                                                        newExpectedValueCriteriaRel = selection;
                                                        this.setState({ newExpectedValueCriteriaRel: newExpectedValueCriteriaRel });
                                                    }}
                                                    validationMessage=""
                                                    value={newExpectedValueCriteriaRel}
                                                />
                                                <small className="invalid-feedback text-danger" hidden></small>
                                            </FormGroup>
                                        }
                                    </Col>
                                </GroupedRow>
                            </ModalBody>
                            <ModalFooter>
                                {(!!(newExpectedValueCriteriaCriteriaType) && !!(newExpectedValueCriteriaRel) &&
                                    !!(newExpectedValueCriteriaType) && !!(newExpectedValueCriteria))
                                    && (
                                        !!((newExpectedValueCriteriaCriteriaType ?? {}).value != criteriaTypes.SalesOrderTrackingDerived) ||
                                        (!!((newExpectedValueCriteriaCriteriaType ?? {}).value == criteriaTypes.SalesOrderTrackingDerived)
                                            && !!(newExpectedValueCriteriaDerivedFieldName))
                                    ) &&
                                    <Button
                                        size="sm"
                                        type="button"
                                        color="primary"
                                        name="addExpectedValueRestriction"
                                        onClick={() => this.onAddNewExpectedValueCriteria()}
                                    >
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faPlus} />
                                        {"Add"}
                                    </Button>
                                }
                                <Button
                                    size="sm"
                                    type="button"
                                    color="secondary"
                                    name="cancelExpectedValueRestriction"
                                    onClick={() => this.setState({ addExpectedValueRestriction: false })}
                                    className="ml-2">
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faTimes} />
                                    {"Cancel"}
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </AppPageForm>
                </Fragment>
            );
        }

    }
}

export default withRouter(FormBuilder);