export class Survey {
    id = 0;
    name = "";
    instructions = "";
    notes = "";
    version = 1.0;
    surveyStatusId = 1; //any New Survey starts as a draft
    restrictedToCustomer = "";
    restrictedToCategory = "";
    restrictedToFamily = "";
    criteria = [];
    surveyExportFormatId = null;
}

export class UnitOfMeasurement {
    id = 0;
    name = "";
    unitOfMeasurementDataTypeId = 0;
    dataTypeName = "";
}

export class SurveySection {
    id = 0;
    surveyId = 0;
    orer = 0;
    sectionName = "";
    instructions = "";
    surveyName = "";
    criteria = [];
    deleted = false;
}

export class SurveyCriteria {
    id = 0;
    surveyId = 0;
    surveyName = "";
    expectedValue = "";
    derivedFieldName = "";
    criteriaTypeId = 0;
    criteriaTypeText = "";
    expectedValueTypeId = 0;
    valueTypeText = "";
    expectedValueRelationshipId = 0;
    relaionshipText = "";
}

export class SurveySectionCriteria {
    id = 0;
    surveySectionId = 0;
    surveySectionQuesitonId = null;
    expectedValue = "";
    derivedFieldName = "";
    criteriaTypeId = 0;
    criteriaTypeText = "";
    expectedValueTypeId = 0;
    valueTypeText = "";
    expectedValueRelationshipId = 0;
    relaionshipText = "";
    surveySectionText = "";
    surveySectionQuestionText = "";
}

export class SurveySectionQuestionCriteria {
    id = 0;
    surveySectionQuestionId = 0;
    dependentSurveySectionQuesitonId = 0;
    expectedValue = "";
    derivedFieldName = "";
    questionExpectedValueTypeId = "";
    surveySectionQuestionText = "";
    dependentSurveySectionQuestionText = "";
}

export class SurveySectionQuestion {
    id = 0;
    order = 0;
    surveySectionId = 0;
    questionId = 0;
    questionRequired = false;
    answerRequired = false;
    surveySectionText = "";
    questionText = "";
    surveyExportFormatFieldId = null;
    deleted = false;
}

export class Question {
    id = 0;
    name = "";
    title = "";
    subText = "";
    unitOfMeasureId = 1;
    unitOfMeasurementText = "None";
    UnitOfMeasurementDataType = "";
    inputTypeId = -1;
    inputTypeText = "None";
    isHidden = false;
    expectedValueIsDefault = false;
}

export class QuestionGroup {
    id = 0;
    name = "";
    isExclusiveChoice = false;
    questions = "";
    choices = "";
}

export class QuestionOption {
    id = 0;
    choiceText = "";
    groups = "";
}

export class ExpectedValueCriteria {
    id = 0;
    questionExpectedValueId = 0;
    expectedValue = "";
    derivedFieldName = "";
    expectedValueTypeId = 0;
    valueTypeText = "";
    expectedValueRelationshipId = 0;
    relationshipText = "";
    criteriaTypeId = 0;
    criteriaTypeText = "";
}

export class ExpectedValue {
    id = 0;
    expectedValue = "";
    questionExpectedValueTypeId = 0;
    questionId = 0;
    questionExpectedValueRelationshipId = 0;
    questionText = "";
    valueTypeText = "";
    relationshipText = "";
    criteria = [];
    showCriteria = false;
    salesOrderTrackingDerived = false;
}

export class QuestionOptionGroup {
    id = 0;
    order = 0;
    questionOptionId = 0;
    questionGroupId = 0;
    questionOptionText = "";
    questionGroupName = "";
}

export class ModifyQuestionGroup {

    group = new QuestionGroup();
    optionGroups = [];
    options = [];
}

export class ModifyQuestion {
    question = new Question();
    expectedValues = [];
    optionGroupId = 0;
}

export class QuestionBuilderQuestion {
    question = new Question();
    expectedValues = [];
    optionGroupId = 0;
    //Builder state
    newExpectedValue = null;
    newExpectedValueType = null;
    newExpectedValueRel = null;
    useOptionGroup = null;
    selectedGroupOptions = [];
}

export class ModifySurvey {
    survey = new Survey();
    sections = []; //List Of Survey Section
    sectionCriteria = [] //List of SurveySectionCriteria
    sectionQuestions = [] //List of SurveySectionQuestion
    sectionQuestionCriteria = [] //List of SurveySectionQuestionCriteria
    questions = []; //List of ModifyQuestion
}

export class AnswerDTO {
    id = 0;
    salesOrderTrackingSurveyId = 0;
    answerText = "";
    isIssue = "";
    notes = "";
    employeeId = null; //Nullable Long
    employeeName = "";

    questionId = 0; //Section Question id
    questionText = "";
    sectionName = "";
    surveyName = "";
    images = []; //Array of AnswerImageDTO
}

export class AnswerImageDTO {
    documentId = 0;
    image = ""; //base 64 image string
}

export class SalesOrderTrackingSurveyDTO 
{
    id = 0;
    workCenterSurveyId = 0;
    salesOrderTrackingId = 0;
    salesOrderTrackingSurveyCompletionStatusId = 0;
    workCenterName = "";
    salesOrderTrackingSerialNumber = "";
    salesOrderTrackingSurveyCompletionStatus = ""
    customerId = "";
    category = "";
    family = "";
    readOnly = false;

    survey = {}; //SurveyDTO
    sections = []; //List of SurveySectionDTO
    sectionCriteria = [] //List of SurveySectionCriteriaDTO
    sectionQuestions = [] //List of SurveySectionQuestionDTO
    sectionQuestionCriteria = []; //List of SurveySectionQuestionCriteriaDTO
    questions = []; // List of QuestionImplemented
    answers =[]; //List of AnswerDTO
}

export const questionExpectedValueRelationship = {
    And: 1,
    Or: 2,
    ExclusiveOr: 3
};

export const questionEpectedValueType = {
    Equal: 1,
    LessThan: 2,
    GreaterThan: 3,
    NotEqual: 4,
    StartsWith: 5,
    Contains: 6,
    EndsWith: 7
}

export const unitOfMeasurementDataTypes = {
    Text: 1,
    Number: 2,
    Decimal: 3,
    TrueFalse: 4,
    DateTime: 5
}

export const questionInputTypes = {
    Derived: -2,
    None: -1,
    Text: 1,
    Select: 2,
    OptionGroup: 3,
    SingleLineText: 4,
    Numeric: 5,
    DateTime: 6
}

export const surveyStatuses = {
    Draft: 1,
    Published: 2,
    Deprecated: 3,
    Deleted: 4,
    byId: function (id) {
        switch (id) {
            case this.Draft: return "Draft"
            case this.Published: return "Published"
            case this.Deprecated: return "Depreated"
            case this.Deleted: return "Deleted"
            default: return "Unknown"
        };
    }
};

export const criteriaTypes = {
    Standard: 1,
    Customer: 2,
    Category: 3,
    Family: 4,
    Model: 5,
    LiftOption: 6,
    MountingInstruction: 7,
    LiftType: 8,
    SalesOrderTrackingDerived: 9
}