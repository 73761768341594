export class ShippingDTO {
    id = 0;
    shippingNumber = "";
    isComplete = false;
    salesOrderTrackingIds = []; //list of long ids
}

export class ShippingSearchResultDTO {
    id = 0;
    shippingNumber = "";
    shippingSerialNumbers = "";
    isComplete = false;
    createdOn = new Date();
}