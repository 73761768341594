import React, { Fragment } from 'react';

//Contexts
import { FlexRow, onFieldChange, toasty } from '../common/forms/FormElements';
import { Address } from '../address/Address';
/*import { Can } from '../Can';*/
import CommonContext, { ApiRoutes, ApplicationPermissions, AppNavPaths } from '../Common';
import { Employee } from './Employee';
import { EmployeeAddressInfo } from './EmployeeAddressInfo';
import './EmployeeForm.scss';
import { isEqual, debounce } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes, faUser } from '@fortawesome/free-solid-svg-icons'
import {
    AppPageForm,
    FlexCenterRow,
    FormGroupColumn,
    FormLabel,
    GroupedRow,
    SubHeading
} from '../common/forms/FormElements';

import { LinearProgress } from '@material-ui/core';
import { util } from '../Util';
import ValidatedSelect from '../common/forms/ValidatedSelect';

import {
    Button,
    Col,
    FormGroup
} from 'reactstrap';

import { Prompt, withRouter } from 'react-router-dom';
import AddressFormNew from '../address/AddressFormNew';
import { handleFormSaveError } from '../common/forms/ValidationError';
import authService from '../api-authorization/AuthorizeService';
import { getUserProfile } from '../common/UserProfile';
import FormErrorResponseDisplay from '../common/forms/FormErrorResponseDisplay';

//#region CONSTANTS
const AccountStatus = {
    Active: 1,
    Inactive: 2
}

//#endregion

class EmployeeForm extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.formRef = React.createRef();
        this.addressFormRef = React.createRef();

        this.state = {
            addressFormTitle: '',
            addressFormValidated: false,
            employee: new Employee(),
            formOpened: false,
            formValidated: false,
            roles: [],
            loading: true,
            saving: false,
            selectedAddress: null,
            statuses: [],
            showAddressForm: false,
            perms: [],
            isAdmin: false,
            errorResponse: {}
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onAddAddress = this.onAddAddress.bind(this);
        this.onEditAddress = this.onEditAddress.bind(this);
        this.onSaveAddress = this.onSaveAddress.bind(this);
        this.saveEmployee = this.saveEmployee.bind(this);
        this.saveNewEmployee = this.saveNewEmployee.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps && (this.props.match.params.id !== (prevProps.match.params ?? {}).id)) {
            this.populateState();
        }
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            let employee = null;
            const { id } = { ...this.props.match.params };
            const { currentUser, userPermissions } = await getUserProfile();
            const userRoles = currentUser.roles.map(x => x.name);
            const userIsAdmin = userRoles.includes('Administrator');

            //If not onboarding a new emp, make sure the user has the regular emp add perm.
            if (!id && !userPermissions.includes(ApplicationPermissions.employee_create)) {
                this.props.history.push(AppNavPaths.NoPermission);
                return false;
            }

            //Permissions for new emp good, get the rest of the data.
            var [roles, employeeResponse, statuses] = await Promise.all([
                util.fetch.js(ApiRoutes.auth.roles()),
                !!id ? util.fetch.get(ApiRoutes.employee.byId(id), util.fetch.format.none) : new Employee(),
                util.fetch.js(ApiRoutes.employeeAccountStatuses.all())
            ]);

            //Handle any issues when fetching the employee.
            if (!!id && !employeeResponse.ok) {
                //Handle erroneous links entered by the user.
                if (employeeResponse.status === 404)
                    this.props.history.push(AppNavPaths.NotFound);
                else
                    this.props.history.push(AppNavPaths.ServerError);
                return false;
            } else {
                employee = !!id ? await employeeResponse.json() : employeeResponse;
            }

            if (employee.accountStatus?.id !== AccountStatus.NewHire) {
                //Don't display unconfirmed status if not onboarding.
                statuses = statuses.filter(x => x.id !== AccountStatus.NewHire);
            }

            this.setState(state => {
                return {
                    formValidated: false,
                    addressFormValidated: false,
                    payRateFormValidated: false,
                    saving: false,
                    selectedAddress: null,
                    selectedPayRate: null,
                    employee: employee,
                    roles: roles,
                    loading: false,
                    originalData: employee,
                    statuses: statuses,
                    perms: userPermissions,
                    currentUser: currentUser,
                    isAdmin: userIsAdmin
                }
            });
        }
    }

    //#region METHODS
    onChange = onFieldChange;

    onRoleChange = value => {
        let { employee } = { ...this.state };

        employee.roles = value;

        this.setState({ employee: employee });
    }

    onAccountStatusChanged = selection => {
        this.setState((state) => {
            let { employee, statuses } = state;
            employee.accountStatus = statuses.find(s => s.id === selection.id);
            if (employee.accountStatus.id == AccountStatus.Inactive) {
                employee.systemAdded = false;
            }
            return { employee: employee }
        });
    }

    onEmailNotifyChanged = e => {
        var emp = this.state.employee;
        emp.emailNotify = e.target.checked;
        this.setState({ employee: emp });
    }

    onAddAddress = (type) => {
        let { employee } = this.state;
        let new_address = new Address();
        //This is a new address, embed the emp Id and company.
        new_address.employeeId = employee.id;
        new_address.name = `Home - ${employee.firstName} ${employee.lastName}`;
        let formTitle = `New ${type === 'home' ? 'Home' : 'Routing'} Address`;
        this.addressFormRef.current.open(new_address, formTitle)
    }

    //Address table edit click handler
    onEditAddress(address) {
        let formTitle = `Edit Address`;
        this.addressFormRef.current.open({ ...address }, formTitle);
    }

    //After address save callback
    onSaveAddress = (address) => {
        let { employee } = this.state;
        employee.addresses = util.array.upsert(address, employee.addresses, 'id');
        this.setState({ employee: employee, selectedAddress: null });
    }

    onActiveClicked = e => {
        this.setState(state => {
            var emp = state.employee;
            emp.userDetails.active = true;
            return { employee: emp }
        })
    }

    onInactiveClicked = e => {
        this.setState(state => {
            var emp = state.employee;
            emp.userDetails.active = false;
            return { employee: emp }
        })
    }

    async updateEmployee() {
        let { employee } = this.state;
        var emp = await util.fetch.js(ApiRoutes.employee.byId(employee.id));
        this.setState({ employee: emp });
    }

    onSubmit() {
        let { employee } = { ...this.state };

        if (!employee.id) {
            //Validation
            if (!employee.userName) {
                toasty.error('Please generate the username before saving the employee.');
                return false;
            }
        }

        /** Revert temporary hashes used in row keys. */
        for (let addr of employee.addresses) {
            addr.id = addr.id.constructor === String ? null : addr.id;
        }

        //Clear any fluent api errors
        this.clearSaving()

        this.setState((state) => { return { errors: {}, saving: true }; });

        if (this.props.location.pathname === AppNavPaths.EmployeeNew)
            this.saveNewEmployee(employee)
        else
            this.saveEmployee(employee)
    }

    clearSaving = () => this.setState((state) => { return { saving: false }; });

    notifySuccess = () => toasty.success('Employee Saved', `Employee saved successfully.`);
    notifyError = (message) => toasty.error('Save Unsuccessful', message);
    handleSaveError = (err) => handleFormSaveError(this, err);

    displaySaveErrors = (response) => this.setState((state) => { return { errorResponse: response }; });
    clearSaveErrors = () => this.setState((state) => { return { errorResponse: {} }; });

    onCloseClicked = () => {
        this.props.history.push(`${AppNavPaths.Employees}`);
    }

    saveNewEmployee = async (employee) => {
        try {
            let response = await util.fetch.post(ApiRoutes.employee.create(), employee, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                var serviceResponse = await response.json();
                if (serviceResponse.result === 'SUCCESS') {
                    this.notifySuccess();
                    if (employee.accountStatus.id === AccountStatus.NewHire) {
                        this.props.history.push(`${AppNavPaths.Employees}`);
                    } else {
                        var employeeId = serviceResponse.data;
                        this.props.history.push(`${AppNavPaths.Employee}/${employeeId}`);
                    }
                } else {
                    this.notifyError(serviceResponse.message);
                }
            } else {

                if (response.status === 400) {
                    let serviceResponse = await response.json();
                    this.displaySaveErrors(serviceResponse);
                } else {
                    let serviceResponse = {
                        title: 'Server Error',
                        errors: {
                            Exception: [
                                await response.text()
                            ]
                        }
                    }
                    this.displaySaveErrors(serviceResponse);
                }
                
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    saveEmployee = async (employee) => {
        try {
            let response = await util.fetch.put(ApiRoutes.employee.update(employee.id), employee, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                var serviceResponse = await response.json();
                if (serviceResponse.result === 'SUCCESS') {
                    this.notifySuccess();
                    this.populateState();
                } else {
                    this.notifyError(serviceResponse.message);
                }
            } else {
                this.displaySaveErrors(await response.json());
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    onFirstNameChanged = debounce(async event => {
        const value = event.target.value;
        const { employee } = { ...this.state };

        employee.firstName = value;

        await this.setState({ employee: { ...employee } });

        if (!!value && !!employee.lastName) this.onGetUsername();
    }, 500);

    onLastNameChanged = debounce(async event => {
        const value = event.target.value;
        const { employee } = { ...this.state };

        employee.lastName = value;

        await this.setState({ employee: { ...employee } });

        //anytime the name changes, the user needs to get a generated username again.
        if (!!value && !!employee.firstName) this.onGetUsername();
    }, 500);

    onGetUsername = async () => {
        const { employee } = { ...this.state };
        const username = `${employee.firstName.charAt(0)}${employee.lastName}`.replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase();
        try {
            const suggestion = await util.fetch.js(ApiRoutes.employee.suggestUsername(username));
            employee.userName = suggestion;
            await this.setState({ employee: { ...employee } });
        } catch {
            toasty.error('There was a server error when retrieving a username suggestion.  Please try your request again or contact support.');
            return null;
        }
    }

    onMobilePhoneChanged = event => {
        const value = event.target.value;
        const { employee } = { ...this.state };
        employee.mobilePhone = value;
        if (!!value && !!util.validation.phone(value))
            employee.smsNotify = true;
        this.setState({ employee: { ...employee } });
    }

    onEmailChanged = event => {
        const value = event.target.value;
        const { employee } = { ...this.state };
        employee.email = value;
        if (!!value)
            employee.emailNotify = true;
        this.setState({ employee: { ...employee } });
    }
    //#endregion

    //#region RENDERING
    getReadOnlyLabel = (text) => <span className="ml-3 pb-3 text-muted font-weight-bold" > {text}</span>;

    passwordFields() {

        //Only required for new employees.
        //If password is empty for an existing employee, that's OK - password will be unchanged.
        const isPasswordRequired = !!!this.state.employee.id;

        const helpMessage = isPasswordRequired ? null : "Leave blank if you do not want to reset the user's password.  If you change a password, the user will be required to update it on next login.";

        return (
            <>
                <FormGroup>
                    <FormLabel htmlFor="password" text="Password" required={isPasswordRequired}
                        helpMessage={helpMessage} />
                    <input
                        id="password"
                        name="employee.password"
                        autoComplete="new-password"
                        type="password"
                        className="form-control"
                        required={isPasswordRequired}
                        onChange={this.onChange}
                        defaultValue={this.state.employee.password}
                    />
                    <small className="invalid-feedback text-danger" hidden>A Password is required.</small>
                </FormGroup>
                <FormGroup hidden={!isPasswordRequired}>
                    <FormLabel htmlFor="confirmPassword" text="Confirm Password" required={isPasswordRequired} />
                    <input
                        id="confirmPassword"
                        name="employee.confirmPassword"
                        autoComplete="new-password"
                        type="password"
                        className="form-control"
                        required={isPasswordRequired}
                        onChange={this.onChange}
                        defaultValue={this.state.employee.confirmPassword} />
                    <small className="invalid-feedback text-danger" hidden>Password Confirmation is required and must match the password.</small>
                </FormGroup>
            </>
        )
    }

    render() {
        const { loading, originalData, employee, roles, statuses, perms} = { ...this.state };
        let { errorResponse } = { ...this.state };
        const employeeArray = [employee];
        
        if (employee) {
            employeeArray[0].displayName = `${employee.lastName}, ${employee.firstName}`;
        }

        if (!!loading || !roles.length || !perms.length) {
            return (<LinearProgress variant="indeterminate" color="secondary" />);
        } else {
            const adminSelected = (employee.roles || []).map((v, i) => v.displayName).includes('Administrator');
            const new_emp = ((employee.id ?? 0) <= 0);
            const pwdFields = this.passwordFields();
            const saveEmployeeButtonText = (!new_emp ? 'Save' : 'Save New Employee');

            //field editing permissions
            const canEdit = {
                role: !!perms.includes(ApplicationPermissions.employee_role_edit),
                name: !!perms.includes(ApplicationPermissions.employee_name_edit),
                username: !!perms.includes(ApplicationPermissions.employee_username_edit),
                number: !!perms.includes(ApplicationPermissions.employee_number_edit),
                notifications: !!perms.includes(ApplicationPermissions.employee_notifications_edit),
                import: !!perms.includes(ApplicationPermissions.employee_importupdates_edit),
                status: !!perms.includes(ApplicationPermissions.employee_accountstatus_edit),
                password: !!perms.includes(ApplicationPermissions.employee_password_edit),
                homeAddress: !!perms.includes(ApplicationPermissions.employee_home_address_edit)
            };

            return (
                <Fragment>
                    <AppPageForm
                        formId={"empForm"}
                        formHeadingIcon={faUser}
                        formHeading={new_emp ? 'New Employee' : 'Edit Employee'}
                        formName={"empForm"}
                        formRef={this.formRef}
                        onSubmit={this.onSubmit}
                        setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                        isValidated={this.state.formValidated}
                        saving={this.state.saving}
                        errors={this.state.errors}
                        loading={this.state.loading}
                    >
                        <SubHeading className="pt-3">{`General Information`}</SubHeading>
                        <GroupedRow>
                            <FormGroupColumn>
                                {
                                    <FormGroup>
                                        <FormLabel htmlFor="selectUserRole" text="User Roles" required={true} />
                                        <ValidatedSelect
                                            id="roles"
                                            name="roles"
                                            isMulti
                                            required
                                            isDisabled={!canEdit.role}
                                            options={roles}
                                            value={employee.roles ?? ''}
                                            getOptionLabel={option => option.displayName}
                                            getOptionValue={option => option.id}
                                            onChange={!!canEdit.role ? this.onRoleChange : undefined}
                                            validationMessage="At least one role is required."
                                        />
                                    </FormGroup>
                                }
                                <FormGroup>
                                    <FormLabel htmlFor="inputFirstName" text="First Name" required={true} />
                                    <input id="firstName"
                                        name="employee.firstName"
                                        autoComplete="off"
                                        className="form-control"
                                        required
                                        disabled={!new_emp && !canEdit.name}
                                        onChange={!!canEdit.name ? this.onChange : undefined }
                                        defaultValue={employee.firstName} />
                                    <small className="invalid-feedback text-danger" hidden>First name is required.</small>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="inputLastName" text="Last Name" required={true} />
                                    <input id="lastName"
                                        name="employee.lastName"
                                        autoComplete="off"
                                        className="form-control"
                                        required
                                        disabled={!new_emp && !canEdit.name}
                                        onChange={!!canEdit.name ? this.onChange : undefined}
                                        defaultValue={employee.lastName} />
                                    <small className="invalid-feedback text-danger" hidden>Last name is required.</small>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="userName" text="Username" required={true} />
                                    {
                                        <>
                                            <input id="userName"
                                                disabled={!canEdit.username}
                                                name="employee.userName"
                                                autoComplete="off"
                                                className="form-control"
                                                required
                                                onChange={!!canEdit.username ? this.onChange : undefined}
                                                defaultValue={employee.userName ?? ''} />
                                            <small className="invalid-feedback text-danger">Username is required.</small>
                                        </>
                                    }
                                </FormGroup>
                                {
                                    ((!!canEdit.number && !!new_emp) || !new_emp) &&
                                    <FormGroup>
                                            <FormLabel htmlFor="employeeNumber" required={true} text="Employee Number" />
                                        <input id="employeeNumber"
                                            name="employee.employeeNumber"
                                            autoComplete="off"
                                            required={true}
                                            className="form-control"
                                            disabled={!canEdit.number}
                                            onChange={!canEdit.number ? undefined : this.onChange}
                                            defaultValue={employee.employeeNumber}
                                        />
                                        <small className="invalid-feedback text-danger">Employee Number is required.</small>
                                    </FormGroup>
                                }
                            </FormGroupColumn>
                            <FormGroupColumn>
                                <FormGroup>
                                    <FormLabel htmlFor="mobilePhone" text="Phone" required={!!employee.smsNotify} />
                                    <input id="mobilePhone"
                                        placeholder="ex: 555-555-5555"
                                        name="employee.mobilePhone"
                                        pattern={util.validation.patterns.htmlPhone}
                                        autoComplete="off"
                                        type="tel"
                                        className="form-control"
                                        disabled={!canEdit.notifications}
                                        onChange={!!canEdit.notifications ? this.onMobilePhoneChanged : undefined}
                                        defaultValue={employee.mobilePhone}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="email" text="Email" required={true} />
                                    <input id="email"
                                        placeholder="ex: employee@provider.com"
                                        name="employee.email"
                                        autoComplete="off"
                                        type="email"
                                        required
                                        className="form-control"
                                        disabled={!canEdit.notifications}
                                        onChange={!!canEdit.notifications ? this.onEmailChanged : undefined}
                                        defaultValue={employee.email ?? ''}
                                    />
                                    <small className="invalid-feedback text-danger">Employee email is required.</small>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="accountStatus" text="Account Status" required={true} />
                                    <ValidatedSelect
                                        isDisabled={!canEdit.status}
                                        id="accountStatus"
                                        name="accountStatus"
                                        options={statuses}
                                        value={(statuses ?? []).find(x => x.id === employee.accountStatus.id) ?? ''}
                                        getOptionLabel={option => option.description}
                                        getOptionValue={option => option.id}
                                        onChange={!!canEdit.status ? this.onAccountStatusChanged : undefined}
                                        required={true}
                                        validationMessage="Account status (Active/Inactive) is required."
                                    />
                                </FormGroup>
                                {pwdFields}
                            </FormGroupColumn>
                        </GroupedRow>
                        {
                            !new_emp && 
                            <>                                
                                <SubHeading>Addresses</SubHeading>
                                <GroupedRow>
                                    <Col sm="12">
                                        <EmployeeAddressInfo
                                            userPermissions={perms}
                                            onAddAddress={this.onAddAddress}
                                            addresses={this.state.employee.addresses}
                                            showRoutingAddress={!adminSelected}
                                            onEditAddress={this.onEditAddress}
                                        />
                                    </Col>
                                </GroupedRow>                               
                            </>
                        }
                        <FlexCenterRow className="mb-3">
                            <Button
                                size="sm"
                                type="submit"
                                color="primary"
                                disabled={this.state.saving}
                                name="empForm">
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faSave} />
                                {saveEmployeeButtonText}
                            </Button>
                            <Button
                                size="sm"
                                type="button"
                                color="secondary"
                                name="empFormClose"
                                disabled={this.state.saving}
                                onClick={this.onCloseClicked}
                                className="ml-2">
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faTimes} />
                                {"Close"}
                            </Button>
                        </FlexCenterRow>
                        <FlexRow>
                            <FormErrorResponseDisplay onClear={this.clearSaveErrors} response={errorResponse} />
                        </FlexRow>
                    </AppPageForm>
                    {
                        <>
                            {
                                <AddressFormNew
                                    id="empAddressForm"
                                    ref={this.addressFormRef}
                                    onSaveCallback={(address) => this.onSaveAddress(address)}
                                />
                            }
                        </>
                    }
                    <Prompt
                        when={!this.state.saving && !isEqual(originalData, employee)}
                        message='You have unsaved changes, are you sure you want to leave?'
                    />
                </Fragment>
            );
        }

    }
    //#endregion
}

export default withRouter(EmployeeForm);