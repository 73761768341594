export class WorkFlowStatusTag {
    id = null;
    status = '';
    systemProvided = false;

    constructor(id, status, sysProvided){
        this.id = id;
        this.status = status;
        this.systemProvided = sysProvided;
    }
}