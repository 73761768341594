import React from 'react';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    LinkCellRenderer,
    ConditionalPureIconCellRenderer,
    TextFilterDefaults,
    ConditionalEditLinkCellRenderer,
    indexCellRenderer
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTags } from '@fortawesome/free-solid-svg-icons'
import { LinearProgress } from '@material-ui/core';
import { PageHeading, PageWrap, SmallPrimaryButton } from '../common/forms/FormElements';
import { withRouter } from "react-router-dom";
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import authService from '../api-authorization/AuthorizeService';
import { Can } from '../Can';

class WorkFlowStatusTagsIndex extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selectedRow: null
        }
        this.onEditWorkFlowStatusTag = this.onEditWorkFlowStatusTag.bind(this);
        this.onAddWorkFlowStatusTagClicked = this.onAddWorkFlowStatusTagClicked.bind(this);
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
        this.setState = (state, callback) => { return };
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {

            let gridOptions = createGridOptions(this);
            let context = this;

            gridOptions.components = {
                selectFilter: DataGridSelectFilter,
                selectFloatingFilter: DataGridSelectFloatingFilter,
                nameRenderer: LinkCellRenderer,
                iconRenderer: ConditionalPureIconCellRenderer,
                complianceAvailableRenderer: ConditionalEditLinkCellRenderer,
                indexCellRenderer: indexCellRenderer
            };

            gridOptions.columnDefs = [
                {
                    flex: 0,
                    width: 70,
                    minWidth: 70,
                    maxWidth: 70,
                    headerName: "",
                    valueGetter: "node.id",
                    sortable: false,
                    cellRenderer: 'indexCellRenderer'
                },
                {
                    colId: 'Status',
                    sortable: true,
                    headerName: 'Status',
                    field: 'status',
                    tooltipField: 'status',
                    sort: { direction: 'asc', priority: 0 },
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                    cellRenderer: 'nameRenderer',
                    cellRendererParams: {
                        clicked: this.onEditWorkFlowStatusTag,
                        nameField: 'status',
                        idField: 'id',
                        title: 'View this Work Flow Status Tag'
                    }
                },
                {
                    flex: 0,
                    width: 40,
                    minWidth: 40,
                    maxWidth: 40,
                    colId: 'SystemProvided',
                    sortable: false,
                    headerName: ' ',
                    field: 'systemProvided',
                    tooltipField: 'systemProvided',
                    cellRenderer: 'iconRenderer',
                    cellRendererParams: {
                        iconClass: 'fa-lock'
                    }
                }
            ];

            // Cache and reload filter and sort changes 
            gridOptions.onFilterChanged = (params) => {
                let filterModel = gridOptions.api.getFilterModel();
                sessionStorage.setItem("workFlowStatusTag_gridFilterSettings", JSON.stringify(filterModel));
            }

            gridOptions.onSortChanged = (params) => {
                let sortModel = gridOptions.api.columnModel.getColumnState();
                sessionStorage.setItem("workFlowStatusTag_gridSortSettings", JSON.stringify(sortModel));
            }

            gridOptions.onFirstDataRendered = (params) => {
                let sortModel = sessionStorage.getItem("workFlowStatusTag_gridSortSettings");
                if (sortModel) {
                    //var obj = JSON.parse(sortModel);
                    //TODO: Uncaught TypeError: gridOptions.api.setSortModel is not a function
                    //gridOptions.api.setSortModel(obj);
                }
            }

            gridOptions.onGridReady = (params) => {
                let filterModel = sessionStorage.getItem("workCenter_gridFilterSettings");
                if (filterModel) {
                    var obj = JSON.parse(filterModel);
                    
                    if (!!obj['WorkFlowStatusTags.Status']) {
                        //todo: filterInstance not working
                        //const filterInstance = gridOptions.api.getFilterInstance('WorkFlowStatusTags.Status');
                        //filterInstance.setModel(obj['WorkFlowStatusTags.Status']);
                        gridOptions.api.onFilterChanged();
                    }
                }

                params.api.setDatasource(context.state.dataSource);
                context.setState({ gridApi: params.api });
            }

            let ds = createDataSource(ApiRoutes.workFlowStatusTags.search(), gridOptions);

            this.setState(state => {
                return {
                    loading: false,
                    gridOptions: gridOptions,
                    dataSource: ds
                }
            });
        }
    }

    getInitialListFiltersFromCache(initialFilters) {

        var json = sessionStorage.getItem("workFlowStatusTag_gridFilterSettings");

        if (!!json) {
            var obj = JSON.parse(json);

            initialFilters.groupId = !!obj.Group ? parseInt(obj.Group.value) : 0;
        }

        return initialFilters;
    }

    onAddWorkFlowStatusTagClicked = () =>  this.props.history.push(`${AppNavPaths.WorkFlowStatusTagNew}`);
    onEditWorkFlowStatusTag = (id) => this.props.history.push(`${AppNavPaths.WorkFlowStatusTag}/${id}`);

    render() {

        if (!!this.state.loading || !this.context?.permissions)
            return (<LinearProgress variant="indeterminate" color="secondary" />);

        const { rowData, gridOptions } = { ...this.state };

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon icon={faTags} className="mr-2 text-muted" />
                    <span>Work Flow Status Tags</span>
                    <Can I="create" a={"work_flow_status_tag"}>
                        <SmallPrimaryButton type="button" onClick={this.onAddWorkFlowStatusTagClicked} className="float-right btn-success">
                            <i className={`fa fa-md mr-2 fa-plus-square`} />
                            Add Work Flow Status Tag
                        </SmallPrimaryButton>
                    </Can>
                </PageHeading>
                <DataGridToolbar
                    entity="work_flow_status_tag"
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                    hideAdd={true}
                    addIcon={'fa-user-plus'}
                    hideExcelButton={true}
                    gridOptions={this.state.gridOptions}
                    //TODO: excel export endpoint
                    serverExport={{ apiPath: ApiRoutes.workFlowStatusTags.excelExport(), filePrefix: 'WorkFlowStatusTagSearch' }}
                    serverExportDisabled={!!this.state.saving || !!this.state.loading || !!this.state.loadingData}

                >
                </DataGridToolbar>
                <DataGrid
                    domLayout={"normal"}
                    rowData={rowData}
                    gridOptions={gridOptions}
                    gridStatus={this.state.gridStatus}
                />
            </PageWrap>
        );
    }
}

export default withRouter(WorkFlowStatusTagsIndex);