import React from 'react';
import AddressInput from './AddressInput';
import CommonContext from '../Common';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons'
import { FormLabel, onFieldChange, onReactSelectChanged, FormCheckbox } from '../common/forms/FormElements';
import SlideForm from '../common/forms/SlideForm';
import {  Row, Col, FormGroup } from 'reactstrap';
import { Address } from './Address';

export default class AddressFormNew extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.addRef = React.createRef();
        this.state = {
            address: new Address(),
            formTitle: 'New Address',
            formValidated: false,
            loading: true,
            mapHidden: true,
            errors: {},
            validationMessage: '',
            show: false,
            showSameAs: false,
            showSameAsTitle: ''
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
    }

    componentDidMount = () => this.populateState();
    populateState = async () => this.setState({ loading: false });
    onChange = onFieldChange;
    onSelectChange = onReactSelectChanged;

    onSubmit = async e => {
        this.props.onSaveCallback({ ...this.state.address });
        this.onClose();
    }

    resetForm = () => this.setState({ formValidated: false });

    open = (address, title, showSameAs, showSameAsTitle) => {
        this.resetForm();
        let currentAddress = { ...address };
        this.context.setFormOpened(true);
        this.setState({
            address: currentAddress,
            show: true,
            formTitle: title ?? (address.id ? 'Edit Address' : 'New Address'),
            showSameAs: showSameAs,
            showSameAsTitle: showSameAsTitle
        }, () => {
                //callback after its visible
                this.addRef.current.focusAddress();
        });
    }

    onClose = () => {
        this.resetForm();
        this.context.setFormOpened(false);
        this.setState({ show: false });
    }

    onCheckedChanged = (e) => {
        let { address } = this.state;
        address[e.target.name] = e.target.checked;
        this.setState({ address: address });
    }

    render() {
        const { address, errors, formValidated, validationMessage } = this.state;
        if (!address) return '';
        return (            

            <SlideForm
                loading={this.state.loading}
                show={this.state.show}
                id={this.props.id ?? "addressForm"}
                formIcon={faAddressCard}
                formTitle={this.state.formTitle}
                ref={this.formRef}
                setIsValidated={(value) => this.setState({ formValidated: value })}
                isValidated={formValidated}
                onSubmit={this.onSubmit}
                onClose={this.onClose}
                onDelete={this.onDelete}
                errors={errors}
                onClearErrors={this.onClearErrors}
                validationMessage={validationMessage}
            >
                <Row>
                    <Col>                        
                        <AddressInput
                            ref={this.addRef}
                            namePrefix={'address'}
                            single={true}
                            onChange={this.onChange}
                            address={address}
                            onStateChanged={(item) => {
                                let abbr = (item ?? {}).value ?? '';
                                this.setState(state => (address.state = abbr, state));
                            }}
                        />
                        {!!this.state.showSameAs &&
                            <FormCheckbox
                                small={true}
                                className="ml-3 mt-2"
                                id={"duplicate"}
                                name={"duplicate"}
                                checked={address.duplicate}
                                onChange={this.onCheckedChanged}
                                labelText={this.state.showSameAsTitle ?? ''}
                            />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col hidden={this.state.mapHidden}>
                        <FormLabel text="" />
                        <FormGroup>
                            <div id="emp_routing_map" className="border border-default">

                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </SlideForm>
        );
    }
}