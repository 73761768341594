import { ApiRoutes } from "../Common";
import { util } from "../Util";

export const getUserProfile = async () => {
    const [currentUser, userPermissions, siteLogo] = await Promise.all([
        util.fetch.js(ApiRoutes.auth.profile()),
        util.fetch.js(ApiRoutes.auth.permissions()),
        util.fetch.js(ApiRoutes.common.getLogo())
    ]);

    const permissions = userPermissions.map(x => `${x.permission}.${x.action}`);

    currentUser.isAdmin = !!((currentUser.roles ?? []).filter(x => x.displayName === 'Administrator') ?? []).length;

    //This is where we add custom mapped permissions

    return { currentUser: currentUser, userPermissions: permissions, logo: siteLogo}
}