import React from 'react';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    LinkCellRenderer,
    ConditionalPureIconCellRenderer,
    TextFilterDefaults,
    IndexCellRendererWithEdit,
    DateFilterDefaults
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons'
import { PageHeading, PageWrap } from '../common/forms/FormElements';
import { withRouter } from "react-router-dom";
import { LinearProgress } from '@material-ui/core';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import authService from '../api-authorization/AuthorizeService';
import { util } from '../Util';

class QuoteChangeOrderIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selectedRow: null
        }
        this.onEditQuoteChangeOrder = this.onEditQuoteChangeOrder.bind(this);
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
        this.setState = (state, callback) => { return };
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {

            let gridOptions = createGridOptions(this);
            let context = this;

            let [statusList] = await Promise.all([
                util.fetch.js(ApiRoutes.typeAheads.quoteChangeOrderStatuses())
            ]);

            let statusFilterParams = {
                suppressFilterButton: true,
                labelText: 'Filter by Status',
                options: statusList.map((s) => { return { label: s.label, value: s.label } }),
                optionsLabel: 'label',
                optionsValue: 'value',
            }

            gridOptions.components = {
                selectFilter: DataGridSelectFilter,
                selectFloatingFilter: DataGridSelectFloatingFilter,
                nameRenderer: LinkCellRenderer,
                iconRenderer: ConditionalPureIconCellRenderer,
                indexCellRenderer: IndexCellRendererWithEdit
            };

            gridOptions.columnDefs = [
                {
                    flex: 0,
                    width: 100,
                    minWidth: 100,
                    maxWidth: 100,
                    headerName: "",
                    valueGetter: "node.id",
                    sortable: false,
                    cellStyle: { color: "rgba(0,0,0,0)" },
                    cellRenderer: 'indexCellRenderer',
                    cellRendererParams: {
                        clicked: this.onEditQuoteChangeOrder,
                        nameField: 'name',
                        idField: 'id',
                        title: 'View or edit this change order'
                    }
                },
                {
                    colId: 'QuoteNumber',
                    sortable: true,
                    headerName: 'QuoteNumber',
                    field: 'quoteNumber',
                    tooltipField: 'quoteNumber',
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'PreviousQuoteNumber',
                    sortable: true,
                    headerName: 'PreviousQuoteNumber',
                    field: 'previousQuoteNumber',
                    tooltipField: 'previousQuoteNumber',
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'QuoteChangeOrderRelationship.Quote.SalesOrderTracking.SerialNumber',
                    sortable: true,
                    headerName: 'Serial Number',
                    field: 'serialNumber',
                    tooltipField: 'serialNumber',
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'CreatedOn',
                    headerName: 'Created On',
                    field: 'createdOn',
                    sortable: true,
                    sort: { direction: 'asc', priority: 0 },
                    filter: 'agDateColumnFilter',
                    filterParams: DateFilterDefaults
                },                
                {
                    colId: 'Status.Name',
                    headerName: 'Status',
                    field: 'status',
                    sortable: true,
                    filter: 'selectFilter',
                    floatingFilter: true,
                    filterParams: statusFilterParams,
                    floatingFilterComponent: 'selectFloatingFilter',
                    floatingFilterComponentParams: statusFilterParams
                }
            ];

            gridOptions.useLoading = true;
            gridOptions.loadingTemplate = '<span className="ag-overlay-loading-center "><i class="faSaveSpinner fa fa-md fa-circle-notch"></i> Loading ...</span>';

            gridOptions.onGridReady = (params) => {
                params.api.setDatasource(context.state.dataSource);
                context.setState({ gridApi: params.api });
            };

            let ds = createDataSource(ApiRoutes.quote.changeorder.search(), gridOptions);

            this.setState(state => {
                return {
                    loading: false,
                    gridOptions: gridOptions,
                    dataSource: ds
                }
            });
        }
    }

    getInitialListFiltersFromCache(initialFilters) {

        var json = sessionStorage.getItem("quote_change_order_gridFilterSettings");

        if (!!json) {
            var obj = JSON.parse(json);

            initialFilters.groupId = !!obj.Group ? parseInt(obj.Group.value) : 0;
        }

        return initialFilters;
    }

    onEditQuoteChangeOrder = (id) => this.props.history.push(`${AppNavPaths.QuoteChangeOrder}/${id}`);

    render() {

        if (!!this.state.loading || !this.context?.permissions)
            return (<LinearProgress variant="indeterminate" color="secondary" />);

        const { rowData, gridOptions } = { ...this.state };

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon icon={faClipboardCheck} className="mr-2 text-muted" />
                    <span>Change Orders</span>
                </PageHeading>
                <DataGridToolbar
                    entity="quoteChangeOrder"
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                    hideAdd={true}
                    addIcon={'fa-user-plus'}
                    hideExcelButton={true}
                    gridOptions={this.state.gridOptions}
                    serverExport={{ apiPath: ApiRoutes.quote.changeorder.excelExport(), filePrefix: 'QuoteChangeOrderSearch' }}
                    serverExportDisabled={!!this.state.saving || !!this.state.loading || !!this.state.loadingData}
                >
                </DataGridToolbar>
                <DataGrid
                    domLayout={"normal"}
                    rowData={rowData}
                    gridOptions={gridOptions}
                    gridStatus={this.state.gridStatus}
                />
            </PageWrap>
        );
    }
}

export default withRouter(QuoteChangeOrderIndex);