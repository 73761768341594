import React, { Fragment } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

/*
 * 
 * how to use creatable 
 * - you will need to handle on create with handleCreate
 * - handle create should add the new vlaue to the option list and set
 * - the value 
 *  <ValidatedSelect
        id="inputOptionGroup"
        name="quote.quoteCustomer.name"
        autoComplete="off"
        required
        creatable={true}
        isClearable={true}
        disabled={!isNewQuote && !canEdit.quote}
        options={customerOptions}
        validationMessage={"Customer name is required"}
        value={(customerOptions ?? []).find(s => s.value == quote.quoteCustomer.name) ?? ''}
        handleCreate={(neval) => { this.handleCreateCustomer(neval) }}
        onChange={(selection) => {
            quote.quoteCustomer.name = selection?.value;
            this.setState({ quote: quote });
        }}
        getNewOptionData={(inputValue, optionLabel) =>( { id: inputValue, name: optionLabel })}
    />
 * */

export const ReactSelectStyles = {
    menu: provided => ({ ...provided, zIndex: 9999 }),
    control: (base) => ({
        ...base,
        //height: 35, //Was causing to not expand with multi
        minHeight: 35,
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        '&:hover': { borderColor: '#ccc' }, // border style on hover
        border: '1px solid #ccc', // default border color
        boxShadow: 'none', // no box-shadow
    }),
    dropdownIndicator: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    clearIndicator: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
    }),
};

export const CompactSelectStyles = {
    ...ReactSelectStyles,
    control: (base) => ({
        ...base,
        //height: 28, //Was causing to not expand with multi
        minHeight: 28,
        minMenuHeight: 100,
        menuHeight: 200,
        maxMenuHeight: 200,
        fontSize: '0.74375rem',
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        '&:hover': { borderColor: '#ccc' }, // border style on hover
        border: '1px solid #ccc', // default border color
        boxShadow: 'none', // no box-shadow
    })
};

/*
 * In order to auto receive html validation on the select for single/multiselect entries,
 * place this component in a <Form> and within a <FormGroup>.  See updateReactSelectStyles,
 * which keys in on certain elements to provide validation messages and automatic validation.
 * */
export default class ValidatedSelect extends React.Component {
    constructor(props) {
        super(props)
        this.inputRef = React.createRef();
        this.selectRef = React.createRef();
    }

    //When changing props programatically, this will fire our onchange event.
    componentDidUpdate(prevProps) {  
        if (prevProps.value !== this.props.value) {
            this.updateReactSelectStyles()
        }
    }

    updateReactSelectStyles() {
        //Don't display validation messages until the first submission attempt of the form.
        //Key in on Bootstrap validation classes to display red-border and/or validation
        //messages under the dropdown.
        let input = this.inputRef.current;
        //let react_select_hidden_input = this.selectRef.current.select.inputRef;

        let form = input.closest('form');

        let form_was_validated = false;

        if (!!this.props.formRef) {

            form = this.props.formRef.current;
            form_was_validated = form.props.isValidated;
        } else {
            form_was_validated = ((form ?? {}).classList ?? []).contains('was-validated');
        }
        
        let form_group = input.closest('.form-group');
        let select = form_group.querySelector('.react-select');
        let errorLabel = form_group.querySelector(".invalid-feedback");

        //Show-hide validation messages
        if (form_was_validated) {
            if (errorLabel) {
                errorLabel.hidden = input.validity.valid;
            }

            //Add form-control styles
            if (input && input.validity.valid) {
                select.classList.remove('is-invalid');
                select.classList.add('is-valid');
            } else {
                select.classList.add('is-invalid');
                select.classList.remove('is-valid');
            }
        }
    }
    
    render() {
        const hasValue = !!this.props.isMulti ? !!(this.props.value ?? '').length : !!this.props.value;

        const readOnly = !!this.props.readOnly;

        if (!!this.props.creatable) {
            return (
                <Fragment>
                    {readOnly &&
                        <CreatableSelect
                            ref={this.selectRef}
                            classNamePrefix="react-select"
                            className={(this.props.required ? 'required ' : '') + this.props.className}
                            styles={ReactSelectStyles}
                            isDisabled={true}
                            isLoading={this.props.isLoading}
                            components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                                Placeholder: () => null
                            }}
                            {...this.props}
                            onCreateOption={this.props.handleCreate}
                            options={this.props.options}
                            value={this.props.value}
                        />
                    }
                    {!readOnly &&
                        <CreatableSelect
                            ref={this.selectRef}
                            classNamePrefix="react-select"
                            className={(this.props.required ? 'required ' : '') + this.props.className}
                            styles={ReactSelectStyles}
                            isDisabled={this.props.isDisabled}
                            isLoading={this.props.isLoading}
                            components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                                Placeholder: () => null
                            }}
                            {...this.props}
                            onCreateOption={this.props.handleCreate}
                            options={this.props.options}
                            value={this.props.value}
                        />
                    }

                    {!this.props.disabled && (
                        <input
                            ref={this.inputRef}
                            type="text"
                            className="react-select-validated-input"
                            tabIndex={-1}
                            autoComplete="off"
                            style={{ opacity: 0, height: 0, margin: 0, padding: 0, border: 'none' }}
                            value={hasValue ? JSON.stringify(this.props.value) : ''}
                            required={this.props.required}
                            pattern="\[.+?\]|\{.+?\}"
                            onChange={(e) => { }}
                        />
                    )}

                    <small className="invalid-feedback text-danger">{this.props.validationMessage}</small>
                </Fragment>
            );
        } else {

            return (
                <Fragment>
                    {readOnly && <Select
                        styles={ReactSelectStyles}
                        className={'react-select required ' + this.props.className}
                        classNamePrefix="react-select"
                        ref={this.selectRef}
                        components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            Placeholder: () => null
                        }}
                        {...this.props}
                    />}
                    {!readOnly && <Select
                        styles={ReactSelectStyles}
                        className={'react-select required ' + this.props.className}
                        classNamePrefix="react-select"
                        ref={this.selectRef}
                        components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                        }}
                        {...this.props}
                    />}

                    {!this.props.disabled && (
                        <input
                            ref={this.inputRef}
                            type="text"
                            className="react-select-validated-input"
                            tabIndex={-1}
                            autoComplete="off"
                            style={{ opacity: 0, height: 0, margin: 0, padding: 0, border: 'none' }}
                            value={hasValue ? JSON.stringify(this.props.value) : ''}
                            required={this.props.required}
                            pattern="\[.+?\]|\{.+?\}"
                            onChange={(e) => { }}
                        />
                    )}
                    <small className="invalid-feedback text-danger">{this.props.validationMessage}</small>
                </Fragment>
            );
        }
    }
}