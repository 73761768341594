import React, { Fragment } from 'react';
import cls from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faSave,
    faThumbsUp,
    faTimes,
    faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
    FormCircularProgress,
    FormValidated,
    SmallButton,
    SmallPrimaryButton,
} from './FormElements';
import ValidationMessageDisplay from './ValidationMessageDisplay';

export default class SlideForm extends React.Component {
    render() {
        const {
            className,
            size,
            show,
            fromLeft,
            id,
            showNav,
            showDelete,
            loading,
            readOnly,
            hideToolbar,
            children,
            removeUnavailable,
            entityId,
            secondarySaveButtonText,
            onSecondarySubmit,
            showThisValidation,
            removeFormWrapper, //wraps child content in a <div> instead of a <form>, in case child content itself contains a <form>
        } = this.props;

        const slideFormBody =
        <Fragment>
            <header className="slide-form-header border-bottom d-flex flex-column position-relative mb-0">
                <span
                    className="cursor-pointer site-form-close"
                    onClick={this.props.onClose}
                >
                    <FontAwesomeIcon size="lg" icon={faTimes} />
                </span>
                <div className="h5">
                    <div className="d-flex flex-row align-items-center mb-0">
                        <FontAwesomeIcon
                            className="mr-2 text-muted"
                            icon={this.props.formIcon}
                        />
                        <div>{this.props.formTitle}</div>
                        {!!showNav && (
                            <div className="d-flex flex-row align-items-center ml-auto mr-5">
                                <FontAwesomeIcon
                                    className="nav-icon mr-2"
                                    title="Navigate Back"
                                    icon={faAngleDoubleLeft}
                                    onClick={this.props.onNavBack}
                                />
                                <FontAwesomeIcon
                                    className="nav-icon"
                                    title="Navigate Forward"
                                    icon={faAngleDoubleRight}
                                    onClick={
                                        this.props.onNavForward
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>
                {!!this.props.onSubmit &&
                    !readOnly &&
                    !hideToolbar && (
                        <div className="d-flex flex-row align-items-center">
                            <div className="text-muted">
                                <SmallButton
                                    type="submit"
                                    name={id}
                                >
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faSave}
                                    />
                                    {`${
                                        this.props.saveButtonText ??
                                        'Save'
                                    }`}
                                </SmallButton>

                                {Boolean(
                                    !removeUnavailable &&
                                        (entityId || showDelete)
                                ) && (
                                    <SmallButton
                                        onClick={
                                            this.props.onDelete
                                        }
                                    >
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faTrashAlt}
                                        />
                                        Remove
                                    </SmallButton>
                                )}
                            </div>

                            {Boolean(
                                secondarySaveButtonText &&
                                    onSecondarySubmit
                            ) && (
                                <>
                                    <SmallPrimaryButton
                                        type="submit"
                                        className={
                                            'ml-auto btn-primary btn-success'
                                        }
                                        name={`secondary-${id}`}
                                        onClick={onSecondarySubmit}
                                    >
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faThumbsUp}
                                        />
                                        {secondarySaveButtonText}
                                    </SmallPrimaryButton>
                                </>
                            )}
                        </div>
                    )}
            </header>
            {(showThisValidation ?? true) &&
                <ValidationMessageDisplay
                    onClear={this.props.onClearErrors}
                    errors={this.props.errors}
                    message={this.props.validationMessage}
                />
            }
            {!!loading && <FormCircularProgress />}
            {!loading && (
                <fieldset
                    disabled={readOnly}
                    className="flex-fill d-flex flex-column overflow-y-auto p-2"
                >
                    {children}
                </fieldset>
            )}
        </Fragment>

        return (
            <Fragment>
                <div
                    className={cls(`slide-form ${className ?? ''} `, {
                        'col-xl-4 col-md-6 col-sm-12': !size,
                        show: show,
                        [size]: !!size,
                        left: !!fromLeft,
                    })}
                >
                    {removeFormWrapper &&
                    <div
                        ref={this.props.formRef}
                        className="m-0 pb-2 w-100"
                        id={id}
                        name={id}
                    >
                        {slideFormBody}
                    </div>}
                    {!removeFormWrapper && <FormValidated
                        ref={this.props.formRef}
                        setIsValidated={this.props.setIsValidated}
                        isValidated={this.props.isValidated}
                        isValid={this.props.isValid}
                        className="m-0 pb-2 w-100"
                        id={id}
                        name={id}
                        onSubmit={this.props.onSubmit}
                    >
                        {slideFormBody}
                    </FormValidated>}
                </div>
                <div className="overlay" hidden={!show}></div>
            </Fragment>
        );
    }
}
