import React, { Fragment } from 'react';

//Contexts
import { FlexRow, onFieldChange, toasty } from '../common/forms/FormElements';
import CommonContext, { ApiRoutes, ApplicationPermissions, AppNavPaths } from '../Common';
import { WorkCenter, WorkCenterRestriction } from './WorkCenter';
import { isEqual, sortBy } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes, faUser, faPlus, faTrashAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import FontIconPicker from '@fonticonpicker/react-fonticonpicker';
import '../../style/FontIconPicker.scss';
import * as iconDefs from '../common/FontIconPickerHelper';
import {
    AppPageForm,
    FlexCenterRow,
    FormGroupColumn,
    FormLabel,
    GroupedRow,
    SubHeading
} from '../common/forms/FormElements';
import { LinearProgress } from '@material-ui/core';
import { util } from '../Util';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import {
    Button,
    FormGroup,
    Table,
    Col,
    Input,
    InputGroup,
    InputGroupText,
    Label
} from 'reactstrap';
import { Prompt, withRouter } from 'react-router-dom';
import { handleFormSaveError } from '../common/forms/ValidationError';
import authService from '../api-authorization/AuthorizeService';
import { getUserProfile } from '../common/UserProfile';
import FormErrorResponseDisplay from '../common/forms/FormErrorResponseDisplay';
import { WorkFlowStatusTag } from '../workflow/WorkFlowStatusTag';
import { v4 as uuid } from 'uuid';
import { criteriaTypes } from '../formsAndSurveys/FormsAndSurveys';

//#endregion

class WorkCenterForm extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            workCenter: new WorkCenter(),
            formOpened: false,
            formValidated: false,
            erpWorkCenterChoices: [],
            workFlowStatusTagChoices: [],
            loading: true,
            saving: false,
            statuses: [],
            perms: [],
            surveys: [],
            errorResponse: {},
            //Restriction Support
            allLiftTypes: [],
            allCustomers: [],
            allProductFamilies: [],
            allProductCategories: [],
            allModels: [],
            allLiftOptions: [],
            allMountingInstructions: [],
            allSalesOrderTrackingDerived: [],
            allCriteriaTypes: [],
            expectedDataTypes: [],
            expectedRelationships: [],
            newCriteria: null,
            newCriteriaDerivedFieldName: null,
            newCriteriaType: null,
            newCriteriaRel: null,
            newCriteriaCriteriaType: null,
            newCriteriaOverride: false,
            addRestriction: false
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.saveWorkCenter = this.saveWorkCenter.bind(this);
        this.saveNewWorkCenter = this.saveNewWorkCenter.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps && (this.props.match.params.id !== (prevProps.match.params ?? {}).id)) {
            this.populateState();
        }
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            const { id } = { ...this.props.match.params };
            const { userPermissions } = await getUserProfile();

            var [
                erpWorkCenterChoices,
                workFlowStatusTagChoices,
                workCenterResponse,
                surveys,
                allLiftTypes,
                allCustomers,
                allProductFamilies,
                allProductCategories,
                allModels,
                allLiftOptions,
                allMountingInstructions,
                allSalesOrderTrackingDerived,
                allCriteriaTypes,
                expectvaltypes,
                expectedvalrels
            ] = await Promise.all([
                util.fetch.js(ApiRoutes.workCenters.allERP()),
                util.fetch.js(ApiRoutes.workFlowStatusTags.all()),
                !!id ? util.fetch.get(ApiRoutes.workCenter.byId(id), util.fetch.format.none) : new WorkCenter(),
                util.fetch.js(ApiRoutes.workCenter.surveys(id ?? 0)),
                util.fetch.js(ApiRoutes.typeAheads.getLiftTypes()),
                util.fetch.js(ApiRoutes.typeAheads.apteanCustomers()),
                util.fetch.js(ApiRoutes.typeAheads.apteanProductFamilies()),
                util.fetch.js(ApiRoutes.typeAheads.apteanProductCategories()),
                util.fetch.js(ApiRoutes.typeAheads.getLiftModels()),
                util.fetch.js(ApiRoutes.typeAheads.getLiftOptions()),
                util.fetch.js(ApiRoutes.typeAheads.getMountingInstructions()),
                util.fetch.js(ApiRoutes.typeAheads.getSalesOrderTrackingDerived()),
                util.fetch.js(ApiRoutes.typeAheads.getCriteriaTypes()),
                util.fetch.js(ApiRoutes.typeAheads.getWorkCenterRestrictionTypes()),
                util.fetch.js(ApiRoutes.typeAheads.getWorkCenterRestrictionRelationships())
            ]);

            erpWorkCenterChoices = sortBy(erpWorkCenterChoices, ['name']);
            workFlowStatusTagChoices = sortBy(workFlowStatusTagChoices, ['status']);

            allLiftTypes = allLiftTypes.map((lt) => { return { value: Number(lt.value), label: lt.label }; });
            allCustomers = allCustomers.map((ac) => { return { value: Number(ac.value), label: ac.label }; });
            allModels = allModels.map((am) => { return { value: Number(am.value), label: am.label }; });
            allLiftOptions = allLiftOptions.map((lo) => { return { value: Number(lo.value), label: lo.label }; });
            allMountingInstructions = allMountingInstructions.map((mi) => { return { value: Number(mi.value), label: mi.label }; });
            allCriteriaTypes = allCriteriaTypes.filter(ct => ct.value != 1).map((ct) => { return { value: Number(ct.value), label: ct.label }; });
            expectvaltypes = expectvaltypes.map((evt) => { return { value: Number(evt.value), label: evt.label }; });
            expectedvalrels = expectedvalrels.map((evr) => { return { value: Number(evr.value), label: evr.label }; });

            var workCenter;

            //Handle any issues fetching data
            if (!!id && !workCenterResponse.ok) {
                //Handle erroneous links entered by the user
                if (workCenterResponse.status === 404)
                    this.props.history.push(AppNavPaths.NotFound);
                else
                    this.props.history.push(AppNavPaths.ServerError);
                return false;
            } else {
                workCenter = !!id ? await workCenterResponse.json() : workCenterResponse;
            }

            //patch up in/out tags to match format expected by select component
            workCenter.workCenterTags.forEach((wct, idx, arr) => {
                var dto = workFlowStatusTagChoices.find((t) => t.id === wct.workFlowStatusTag_Id)
                arr[idx] = new WorkFlowStatusTag(dto.id, dto.status);
            });
            workCenter.completedTags.forEach((ct, idx, arr) => {
                var dto = workFlowStatusTagChoices.find((t) => t.id === ct.workFlowStatusTag_Id)
                arr[idx] = new WorkFlowStatusTag(dto.id, dto.status);
            });

            this.setState( {
                formValidated: false,
                saving: false,
                workCenter: workCenter,
                erpWorkCenterChoices: erpWorkCenterChoices,
                workFlowStatusTagChoices: workFlowStatusTagChoices,
                loading: false,
                originalData: workCenter,
                perms: userPermissions,
                surveys: surveys,
                allLiftTypes: allLiftTypes,
                allCustomers: allCustomers,
                allProductFamilies: allProductFamilies,
                allProductCategories: allProductCategories,
                allModels: allModels,
                allLiftOptions: allLiftOptions,
                allMountingInstructions: allMountingInstructions,
                allSalesOrderTrackingDerived: allSalesOrderTrackingDerived,
                allCriteriaTypes: allCriteriaTypes,
                expectedDataTypes: expectvaltypes,
                expectedRelationships: expectedvalrels,
            });
        }
    }

    //#region METHODS
    onChange = onFieldChange;

    onInWorkFlowStatusTagsChange = selectedTags => {
        let { workCenter } = { ...this.state };

        //persist if work flow status tags are present, otherwise clear the mapping
        if(!!selectedTags){
            workCenter.workCenterTags = selectedTags;
        } else {
            workCenter.workCenterTags = null;
        }

        this.setState({ workCenter: workCenter });
    }

    onOutWorkFlowStatusTagsChange = selectedTags => {
        let { workCenter } = { ...this.state };

        //persist if work flow status tags are present, otherwise clear the mapping
        if(!!selectedTags){
            workCenter.completedTags = selectedTags;
        } else {
            workCenter.completedTags = null;
        }

        this.setState({ workCenter: workCenter });
    }

    onMappedWorkCenterChange = selectedWorkCenter => {
        let { workCenter } = { ...this.state };

        //persist if mapped work center is present, otherwise clear the mapping
        if(!!selectedWorkCenter){
            workCenter.mappedWorkCenter = selectedWorkCenter;
            workCenter.mappedWorkCenter_Id = selectedWorkCenter.id;
            workCenter.mappedWorkCenterName = selectedWorkCenter.name;
        } else {
            workCenter.mappedWorkCenter = null;
            workCenter.mappedWorkCenter_Id = '';
            workCenter.mappedWorkCenterName = '';
        }

        this.setState({ workCenter: workCenter });
    }

    onWorkCenterIconChange = newIconName => {
        let { workCenter } = { ...this.state };
        workCenter.iconName = newIconName;
        this.setState({ workCenter: workCenter });
    }

    onAddNewCriteria = async () => {
        let {
            workCenter,
            newCriteria,
            newCriteriaDerivedFieldName,
            newCriteriaType,
            newCriteriaRel,
            newCriteriaCriteriaType
        } = { ...this.state };

        let newWorkCenterRestriction = new WorkCenterRestriction();

        newWorkCenterRestriction.derivedFieldName = newCriteriaDerivedFieldName?.label;
        newWorkCenterRestriction.expectedValue = newCriteria.label; //We take the label not the value this is what the restriction should contain
        newWorkCenterRestriction.criteriaTypeId = newCriteriaCriteriaType.value;
        newWorkCenterRestriction.criteriaTypeText = newCriteriaCriteriaType.label;
        newWorkCenterRestriction.expectedValueTypeId = newCriteriaType.value;
        newWorkCenterRestriction.workCenterRestrictionTypeText = newCriteriaType.label;
        newWorkCenterRestriction.expectedValueRelationshipId = newCriteriaRel.value;
        newWorkCenterRestriction.workCenterRestrictionRelationshipText = newCriteriaRel.label;

        workCenter.workCenterRestrictionCriteria.push(newWorkCenterRestriction);

        this.setState({
            workCenter: workCenter,
            newCriteriaDerivedFieldName: null,
            newCriteria: null,
            newCriteriaType: null,
            newCriteriaRel: null,
            newCriteriaCriteriaType: null,
            addRestriction: false,
            newCriteriaOverride: false
        });
    }

    onRemoveCriteria = async (ind) => {
        let { workCenter } = { ...this.state };

        workCenter.workCenterRestrictionCriteria.splice(ind, 1);

        this.setState({
            workCenter: workCenter
        });
    }

    onSubmit() {
        let { workCenter } = { ...this.state };
        this.clearSaving()
        this.setState((state) => { return { errors: {}, saving: true }; });

        if (this.props.location.pathname === AppNavPaths.WorkCenterNew)
            this.saveNewWorkCenter(workCenter);
        else
            this.saveWorkCenter(workCenter);
    }

    clearSaving = () => this.setState((state) => { return { saving: false }; });

    notifySuccess = () => toasty.success('Work Center Saved', `Work Center saved successfully.`);
    notifyError = (message) => toasty.error('Save Unsuccessful', message);
    handleSaveError = (err) => handleFormSaveError(this, err);

    displaySaveErrors = (response) => this.setState((state) => { return { errorResponse: response }; });
    clearSaveErrors = () => this.setState((state) => { return { errorResponse: {} }; });

    onCloseClicked = () => {
        this.props.history.push(`${AppNavPaths.WorkCenters}`);
    }

    saveNewWorkCenter = async (workCenter) => {
        try {
            let response = await util.fetch.post(ApiRoutes.workCenter.create(), workCenter, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                var serviceResponse = await response.json();
                if (serviceResponse.result === 'SUCCESS') {
                    this.notifySuccess();
                    var workCenterId = serviceResponse.data;
                    this.props.history.push(`${AppNavPaths.WorkCenter}/${workCenterId}`);
                } else {
                    this.notifyError(serviceResponse.message);
                }
            } else {

                if (response.status === 400) {
                    let serviceResponse = await response.json();
                    this.displaySaveErrors(serviceResponse);
                } else {
                    let serviceResponse = {
                        title: 'Server Error',
                        errors: {
                            Exception: [
                                await response.text()
                            ]
                        }
                    }
                    this.displaySaveErrors(serviceResponse);
                }
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    saveWorkCenter = async (workCenter) => {
        try {
            let response = await util.fetch.put(ApiRoutes.workCenter.update(workCenter.id), workCenter, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                var serviceResponse = await response.json();
                if (serviceResponse.result === 'SUCCESS') {
                    this.notifySuccess();
                    this.populateState();
                } else {
                    this.notifyError(serviceResponse.message);
                }
            } else {
                this.displaySaveErrors(await response.json());
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    onDeleteWorkCenter = async () => {
        let { workCenter } = { ...this.state };
        try {
            let response = await util.fetch.delete(
                ApiRoutes.workCenter.delete(workCenter.id), null, util.fetch.format.none
            );
            console.log(response);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                var serviceResponse = await response.json();
                if (serviceResponse.result === 'SUCCESS') {
                    this.notifySuccess();

                    this.props.history.push(`${AppNavPaths.WorkCenters}`);
                } else {
                    this.notifyError(serviceResponse.message);
                    this.clearSaving();
                }
            } else {
                this.displaySaveErrors(await response.json());
                this.clearSaving();
            }
        } catch (error) {
            this.notifyError(error.toString());
            this.clearSaving();
        } 
    }

    //#endregion

    //#region RENDERING
    getReadOnlyLabel = (text) => <span className="ml-3 pb-3 text-muted font-weight-bold" > {text}</span>;

    render() {
        const {
            loading,
            originalData,
            workCenter,
            erpWorkCenterChoices,
            workFlowStatusTagChoices,
            perms,
            surveys,
            allLiftTypes,
            allCustomers,
            allProductFamilies,
            allProductCategories,
            allModels,
            allLiftOptions,
            allMountingInstructions,
            allSalesOrderTrackingDerived,
            allCriteriaTypes,
            expectedDataTypes,
            expectedRelationships
        } = { ...this.state };
        let {
            errorResponse,
            newCriteria,
            newCriteriaType,
            newCriteriaRel,
            newCriteriaCriteriaType,
            newCriteriaDerivedFieldName,
            addRestriction,
            newCriteriaOverride
        } = { ...this.state };
        
        if (!!loading) {
            return (<LinearProgress variant="indeterminate" color="secondary" />);
        } else {
            const isNewWorkCenter = ((workCenter.id ?? 0) <= 0);
            const saveWorkCenterButtonText = (!isNewWorkCenter ? 'Save' : 'Save New Work Center');

            //field editing permissions
            const canEdit = {
                workCenter: !!perms.includes(ApplicationPermissions.work_center_edit)
            };

            const canDelete = !!perms.includes("work_center.delete");

            //System work centers are not editable
            if (workCenter.systemProvided) {
                canEdit.workCenter = false;
            }

            return (
                <Fragment>
                    <AppPageForm
                        formId={"workCenterForm"}
                        formHeadingIcon={faUser}
                        formHeading={isNewWorkCenter ? 'New Work Center' : workCenter.systemProvided ? 'System Provided Work Center' : workCenter.isShippingActivity ? 'Edit Work Center (Shipping Activity)' : 'Edit Work Center' }
                        formName={"workCenterForm"}
                        formRef={this.formRef}
                        onSubmit={this.onSubmit}
                        setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                        isValidated={this.state.formValidated}
                        saving={this.state.saving}
                        errors={this.state.errors}
                        loading={this.state.loading}
                    >
                        <SubHeading className="pt-3">{`General Information`}</SubHeading>
                        <GroupedRow>
                            <FormGroupColumn>
                                <FormGroup>
                                    <FormLabel htmlFor="inputName" text="Work Center Name" required={true} />
                                    <input id="name"
                                        name="workCenter.name"
                                        autoComplete="off"
                                        className="form-control"
                                        required
                                        disabled={!isNewWorkCenter && !canEdit.workCenter}
                                        onChange={!!canEdit.workCenter ? this.onChange : undefined }
                                        defaultValue={workCenter.name} />
                                    <small className="invalid-feedback text-danger" hidden>Work center name is required.</small>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="inputDescription" text="Work Center Description" required={true} />
                                    <input id="description"
                                        name="workCenter.description"
                                        autoComplete="off"
                                        className="form-control"
                                        required
                                        disabled={!isNewWorkCenter && !canEdit.workCenter}
                                        onChange={!!canEdit.workCenter ? this.onChange : undefined}
                                        defaultValue={workCenter.description} />
                                    <small className="invalid-feedback text-danger" hidden>Description is required.</small>
                                </FormGroup>
                                <div style={{ display: "flex", justifyContent: "space-between"}} >
                                    <FormGroup>
                                        <FormLabel htmlFor="inputIcon" text="Work Center Icon" />
                                        <FontIconPicker
                                            icons={iconDefs.fontAwesome}
                                            value={workCenter.iconName}
                                            onChange={this.onWorkCenterIconChange}
                                            isMulti={false}
                                            closeOnSelect={true}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label style={{ color: "#777", fontWeight: "600", fontSize: ".9em", marginBottom: "1.5em" }}
                                            check htmlFor="workCenter.requiresIssueResolution" >
                                            {"Issues Must Be Resolved"}
                                        </Label>
                                        <Input
                                            id="requiresIssueResolution"
                                            name="workCenter.requiresIssueResolution"
                                            autoComplete="off"
                                            style={{position: "relative",marginTop: "unset", marginLeft: "unset"}}
                                            className="form-control"
                                            disabled={!isNewWorkCenter && !canEdit.workCenter}
                                            onChange={!!canEdit ? this.onChange : undefined}
                                            type="checkbox"
                                            checked={workCenter.requiresIssueResolution}
                                        />
                                    </FormGroup>
                                </div> 
                            </FormGroupColumn>
                            <FormGroupColumn>
                                <FormGroup>
                                    <FormLabel htmlFor="selectMappedWorkCenter" text="Mapped iERP90 Work Center" required={false} />
                                    <ValidatedSelect
                                        id="mappedWorkCenter"
                                        name="mappedWorkCenter"
                                        isDisabled={!canEdit.workCenter}
                                        options={erpWorkCenterChoices}
                                        value={workCenter.mappedWorkCenter ?? ''}
                                        getOptionLabel={option => `${option.name} | load type: ${option.loadType ? option.loadType : 'n/a'} | location: ${option.location ? option.location : 'n/a'}`}
                                        getOptionValue={option => option.id}
                                        onChange={!!canEdit.workCenter ? this.onMappedWorkCenterChange : undefined}
                                        validationMessage=""
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="selectInWorkFlowStatusTags"
                                        text="In Work Flow Status Tags"
                                        required={false}
                                        helpMessage="When all “In” status tags are on a SN / Sales Order, the SN will show in the Work Center"
                                    />
                                    <ValidatedSelect
                                        id="selectInWorkFlowStatusTags"
                                        name="selectInWorkFlowStatusTags"
                                        isMulti
                                        isDisabled={!canEdit.workCenter}
                                        options={workFlowStatusTagChoices}
                                        value={workCenter.workCenterTags ?? ''}
                                        getOptionLabel={option => option.status}
                                        getOptionValue={option => option.id}
                                        onChange={!!canEdit.workCenter ? this.onInWorkFlowStatusTagsChange : undefined}
                                        validationMessage=""
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="selectOutWorkFlowStatusTags"
                                        text="Out Work Flow Status Tags"
                                        required={false}
                                        helpMessage="“Out” status tags are applied when Work Center is completed"
                                    />
                                    <ValidatedSelect
                                        id="selectOutWorkFlowStatusTags"
                                        name="selectOutWorkFlowStatusTags"
                                        isMulti
                                        isDisabled={!canEdit.workCenter}
                                        options={workFlowStatusTagChoices}
                                        value={workCenter.completedTags ?? ''}
                                        getOptionLabel={option => option.status}
                                        getOptionValue={option => option.id}
                                        onChange={!!canEdit.workCenter ? this.onOutWorkFlowStatusTagsChange : undefined}
                                        validationMessage=""
                                    />
                                </FormGroup>
                            </FormGroupColumn>
                        </GroupedRow>
                        <FlexCenterRow>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="selectAssociatedForms"
                                    text="Associated Forms"
                                    required={false}
                                    helpMessage="Forms that are completed as part of the SYNC workflow"
                                />
                                {!!(surveys ?? []).length && 
                                    <ValidatedSelect
                                        id="selectAssociatedForms"
                                        name="workCenter.surveys"
                                        isMulti
                                        isDisabled={!canEdit.workCenter}
                                        options={surveys}
                                        value={workCenter.surveys ?? ''}
                                        getOptionLabel={option => option.surveyName}
                                        getOptionValue={option => option.surveyId}
                                        onChange={(selection) => {
                                            if (canEdit.workCenter) {
                                                workCenter.surveys = selection;
                                                this.setState({ workCenter: workCenter });
                                            }
                                        }}
                                        validationMessage={""}
                                        styles={{
                                            multiValueLabel: (styles, { data }) => ({
                                                ...styles,
                                                textDecoration: data.deleted ? "line-through" : "inherit",
                                                color: data.deleted ? "red" : "inherit"
                                            })
                                        }}
                                    />
                                }
                                {!!!(surveys ?? []).length &&
                                    <input disabled={true} value={"No Published Forms"} />
                                }
                            </FormGroup>
                        </FlexCenterRow>
                        {workCenter.isShippingActivity &&
                            <GroupedRow>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                    <p style={{ color: "red", textAlign: "center", fontWeight: "bolder", padding: "1em", border: "2px solid red" }}>Workcenters associated with the Shipping Activity cannot have restrictions.</p>
                                </Col>
                            </GroupedRow>
                        }
                        {!workCenter.isShippingActivity &&
                            <GroupedRow>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                    {!!!addRestriction &&
                                        <Button
                                            size="sm"
                                            type="button"
                                            color="primary"
                                            name="addRestriction"
                                            onClick={() => {
                                                this.setState({
                                                    addRestriction: true
                                                });
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                className="mr-2 text-dark pr-2 zoom"
                                                icon={faPlus} />
                                            {"Add Restriction"}
                                        </Button>
                                    }
                                    <Table style={{ width: "100%", marginTop: "0.25em" }}>
                                        <colgroup>
                                            <col style={{ width: "40%" }} />
                                            <col style={{ width: "20%" }} />
                                            <col style={{ width: "15%" }} />
                                            <col style={{ width: "15%" }} />
                                            <col style={{ width: "10%" }} />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>Restriction Value</th>
                                                <th>Type</th>
                                                <th>Value Is</th>
                                                <th>Relationship</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!!(workCenter.workCenterRestrictionCriteria ?? []).length &&
                                                (workCenter.workCenterRestrictionCriteria ?? []).map((cr, cind) =>
                                                    <tr key={uuid()}>
                                                        <td>{
                                                            !!(cr.derivedFieldName) ? cr.derivedFieldName + ": " + cr.expectedValue : cr.expectedValue
                                                        }</td>
                                                        <td>{cr.criteriaTypeText}</td>
                                                        <td>{cr.workCenterRestrictionTypeText}</td>
                                                        <td>{cr.workCenterRestrictionRelationshipText}</td>
                                                        <td>
                                                            <FontAwesomeIcon
                                                                className="mr-2"
                                                                onClick={() => this.onRemoveCriteria(cind)}
                                                                icon={faTrashAlt}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </GroupedRow>
                        }
                        {!!addRestriction &&
                            <Fragment>
                                <GroupedRow>
                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                        <FormGroup>
                                            <FormLabel htmlFor="secCriteriaType" text="Restriction Type" />
                                            <ValidatedSelect
                                                id="secCriteriaType"
                                                formRef={this.formRef}
                                                name="newCriteriaCriteriaType"
                                                options={allCriteriaTypes}
                                                value={(allCriteriaTypes ?? []).find(s => s.value == (newCriteriaCriteriaType ?? {}).value) ?? ''}
                                                onChange={(selection) => {
                                                    newCriteria = null;
                                                    newCriteriaCriteriaType = selection;
                                                    this.setState({
                                                        newCriteriaCriteriaType: newCriteriaCriteriaType,
                                                        newCriteria: newCriteria
                                                    });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                        {!!((newCriteriaCriteriaType ?? {}).value == criteriaTypes.Customer) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="secCriteriaCustomerValue" text="Choose Customer" />
                                                <ValidatedSelect
                                                    id="secCriteriaCustomerValue"
                                                    formRef={this.formRef}
                                                    name="newCriteria"
                                                    options={allCustomers}
                                                    value={(allCustomers ?? []).find(s => s.value == (newCriteria ?? {}).value) ?? ''}
                                                    onChange={(selection) => {
                                                        newCriteria = selection;
                                                        this.setState({ newCriteria: newCriteria });
                                                    }}
                                                />
                                            </FormGroup>
                                        }
                                        {!!((newCriteriaCriteriaType ?? {}).value == criteriaTypes.Category) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="secCriteriaCategoryValue" text="Choose Product Category" />
                                                <ValidatedSelect
                                                    id="secCriteriaCategoryValue"
                                                    formRef={this.formRef}
                                                    name="newCriteria"
                                                    options={allProductCategories}
                                                    value={(allProductCategories ?? []).find(s => s.value == (newCriteria ?? {}).value) ?? ''}
                                                    onChange={(selection) => {
                                                        newCriteria = selection;
                                                        this.setState({ newCriteria: newCriteria });
                                                    }}
                                                />
                                            </FormGroup>
                                        }
                                        {!!((newCriteriaCriteriaType ?? {}).value == criteriaTypes.Family) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="secCriteriaFamilyValue" text="Choose Product Family" />
                                                <ValidatedSelect
                                                    id="secCriteriaFamilyValue"
                                                    formRef={this.formRef}
                                                    name="newCriteria"
                                                    options={allProductFamilies}
                                                    value={(allProductFamilies ?? []).find(s => s.value == (newCriteria ?? {}).value) ?? ''}
                                                    onChange={(selection) => {
                                                        newCriteria = selection;
                                                        this.setState({ newCriteria: newCriteria });
                                                    }}
                                                />
                                            </FormGroup>
                                        }
                                        {!!((newCriteriaCriteriaType ?? {}).value == criteriaTypes.Model) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="secCriteriaModelValue" text="Choose Model" />
                                                <InputGroup>
                                                    <InputGroupText>
                                                        <span style={
                                                            { position: 'absolute', top: '-2px', left: '1.125em', fontSize: '9px' }
                                                        }>Override</span>
                                                        <Input
                                                            id="evCriteriaModelValueOverride"
                                                            name="newCriteriaOverride"
                                                            autoComplete="off"
                                                            onChange={this.onChange}
                                                            type="checkbox"
                                                            checked={newCriteriaOverride ?? false}
                                                            style={{ position: 'relative', top: '2px', marginLeft: 'auto', width: '2em', height: '1.5em' }}
                                                        />
                                                    </InputGroupText>
                                                    {!!!newCriteriaOverride &&
                                                        <div className="form-control" style={{ padding: 0, border: 'none' }}>
                                                            <ValidatedSelect
                                                                id="secCriteriaModelValue"
                                                                formRef={this.formRef}
                                                                name="newCriteria"
                                                                options={allModels}
                                                                style={{ flexGrow: 3 }}
                                                                value={(allModels ?? []).find(s => s.value == (newCriteria ?? {}).value) ?? ''}
                                                                onChange={(selection) => {
                                                                    newCriteria = selection;
                                                                    this.setState({ newCriteria: newCriteria });
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    {!!newCriteriaOverride &&
                                                        <Input
                                                            type="text"
                                                            style={{ flexGrow: 3 }}
                                                            name="newCriteria"
                                                            value={newCriteria?.label ?? ""}
                                                            onChange={(e) => {
                                                                newCriteria = !!(e.target.value) ? { label: e.target.value } : null;;
                                                                this.setState({ newCriteria: newCriteria });
                                                            }}
                                                        />
                                                    }
                                                </InputGroup>
                                            </FormGroup>
                                        }
                                        {!!((newCriteriaCriteriaType ?? {}).value == criteriaTypes.LiftOption) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="secCriteriaLiftOptionValue" text="Choose Lift Option" />
                                                <InputGroup>
                                                    <InputGroupText>
                                                        <span style={
                                                            { position: 'absolute', top: '-2px', left: '1.125em', fontSize: '9px' }
                                                        }>Override</span>
                                                        <Input
                                                            id="evCriteriaLiftOptionValueOverride"
                                                            name="newCriteriaOverride"
                                                            autoComplete="off"
                                                            onChange={this.onChange}
                                                            type="checkbox"
                                                            checked={newCriteriaOverride ?? false}
                                                            style={{ position: 'relative', top: '2px', marginLeft: 'auto', width: '2em', height: '1.5em' }}
                                                        />
                                                    </InputGroupText>
                                                    {!!!newCriteriaOverride &&
                                                        <div className="form-control" style={{ padding: 0, border: 'none' }}>
                                                            <ValidatedSelect
                                                                id="secCriteriaLiftOptionValue"
                                                                formRef={this.formRef}
                                                                name="newCriteria"
                                                                options={allLiftOptions}
                                                                style={{ flexGrow: 3 }}
                                                                value={(allLiftOptions ?? []).find(s => s.value == (newCriteria ?? {}).value) ?? ''}
                                                                onChange={(selection) => {
                                                                    newCriteria = selection;
                                                                    this.setState({ newCriteria: newCriteria });
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    {!!newCriteriaOverride &&
                                                        <Input
                                                            type="text"
                                                            style={{ flexGrow: 3 }}
                                                            name="newCriteria"
                                                            value={newCriteria?.label ?? ""}
                                                            onChange={(e) => {
                                                                newCriteria = !!(e.target.value) ? { label: e.target.value } : null;;
                                                                this.setState({ newCriteria: newCriteria });
                                                            }}
                                                        />
                                                    }
                                                </InputGroup>
                                            </FormGroup>
                                        }
                                        {!!((newCriteriaCriteriaType ?? {}).value == criteriaTypes.MountingInstruction) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="secCriteriaMountingInstructionValue" text="Choose Mounting Instruction" />
                                                <InputGroup>
                                                    <InputGroupText>
                                                        <span style={
                                                            { position: 'absolute', top: '-2px', left: '1.125em', fontSize: '9px' }
                                                        }>Override</span>
                                                        <Input
                                                            id="secCriteriaMountingInstructionValueOverride"
                                                            name="newCriteriaOverride"
                                                            autoComplete="off"
                                                            onChange={this.onChange}
                                                            type="checkbox"
                                                            checked={newCriteriaOverride ?? false}
                                                            style={{ position: 'relative', top: '2px', marginLeft: 'auto', width: '2em', height: '1.5em' }}
                                                        />
                                                    </InputGroupText>
                                                    {!!!newCriteriaOverride &&
                                                        <div className="form-control" style={{ padding: 0, border: 'none' }}>
                                                            <ValidatedSelect
                                                                id="secCriteriaMountingInstructionValue"
                                                                formRef={this.formRef}
                                                                name="newCriteria"
                                                                options={allMountingInstructions}
                                                                value={(allMountingInstructions ?? []).find(s => s.value == (newCriteria ?? {}).value) ?? ''}
                                                                onChange={(selection) => {
                                                                    newCriteria = selection;
                                                                    this.setState({ newCriteria: newCriteria });
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    {!!newCriteriaOverride &&
                                                        <Input
                                                            type="text"
                                                            style={{ flexGrow: 3 }}
                                                            name="newCriteria"
                                                            value={newCriteria?.label ?? ""}
                                                            onChange={(e) => {
                                                                newCriteria = !!(e.target.value) ? { label: e.target.value } : null;;
                                                                this.setState({ newCriteria: newCriteria });
                                                            }}
                                                        />
                                                    }
                                                </InputGroup>
                                            </FormGroup>
                                        }
                                        {!!((newCriteriaCriteriaType ?? {}).value == criteriaTypes.LiftType) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="secCriteriaLiftTypeValue" text="Choose Lift Type" />
                                                <ValidatedSelect
                                                    id="secCriteriaLiftTypeValue"
                                                    formRef={this.formRef}
                                                    name="newCriteria"
                                                    options={allLiftTypes}
                                                    value={(allLiftTypes ?? []).find(s => s.value == (newCriteria ?? {}).value) ?? ''}
                                                    onChange={(selection) => {
                                                        newCriteria = selection;
                                                        this.setState({ newCriteria: newCriteria });
                                                    }}
                                                />
                                            </FormGroup>
                                        }
                                        {!!((newCriteriaCriteriaType ?? {}).value == criteriaTypes.SalesOrderTrackingDerived) &&
                                            <>
                                                <FormGroup>
                                                    <FormLabel htmlFor="secCriteriaSOTDerivedValue" text="Choose Property" />
                                                    <ValidatedSelect
                                                        id="secCriteriaSOTDerivedValue"
                                                        formRef={this.formRef}
                                                        name="newCriteriaDerivedFieldName"
                                                        options={allSalesOrderTrackingDerived}
                                                        value={(allSalesOrderTrackingDerived ?? []).find(s => s.value == (newCriteriaDerivedFieldName ?? {}).value) ?? ''}
                                                        onChange={(selection) => {
                                                            newCriteriaDerivedFieldName = selection;
                                                            this.setState({ newCriteriaDerivedFieldName: newCriteriaDerivedFieldName });
                                                        }}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        name="newCriteria"
                                                        value={newCriteria?.label ?? ""}
                                                        onChange={(e) => {
                                                            newCriteria = !!(e.target.value) ? { label: e.target.value } : null;
                                                            this.setState({ newCriteria: newCriteria });
                                                        }}
                                                    />
                                                </FormGroup>
                                            </>
                                        }
                                    </Col>
                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                        {!!(newCriteriaCriteriaType) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="newCriteriaType" text="Evaluation Type" />
                                                <ValidatedSelect
                                                    id="newCriteriaType"
                                                    formRef={this.formRef}
                                                    options={expectedDataTypes}
                                                    onChange={(selection) => {
                                                        newCriteriaType = selection;
                                                        this.setState({ newCriteriaType: newCriteriaType });
                                                    }}
                                                    validationMessage=""
                                                    value={newCriteriaType}
                                                />
                                                <small className="invalid-feedback text-danger" hidden></small>
                                            </FormGroup>
                                        }
                                    </Col>
                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                        {!!(newCriteriaCriteriaType) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="newCriteriaRel" text="Restriction Relationship" />
                                                <ValidatedSelect
                                                    id="newCriteriaRel"
                                                    formRef={this.formRef}
                                                    options={expectedRelationships}
                                                    onChange={(selection) => {
                                                        newCriteriaRel = selection;
                                                        this.setState({ newCriteriaRel: newCriteriaRel });
                                                    }}
                                                    validationMessage=""
                                                    value={newCriteriaRel}
                                                />
                                                <small className="invalid-feedback text-danger" hidden></small>
                                            </FormGroup>
                                        }
                                    </Col>
                                </GroupedRow>
                                <GroupedRow>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                                    </Col>
                                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
                                        {(!!(newCriteriaCriteriaType) && !!(newCriteriaRel) &&
                                            !!(newCriteriaType) && !!(newCriteria))
                                            && (
                                                !!((newCriteriaCriteriaType ?? {}).value != criteriaTypes.SalesOrderTrackingDerived) ||
                                                (!!((newCriteriaCriteriaType ?? {}).value == criteriaTypes.SalesOrderTrackingDerived)
                                                    && !!(newCriteriaDerivedFieldName))
                                            ) &&
                                            <Button
                                                size="sm"
                                                type="button"
                                                color="primary"
                                                name="addRestriction"
                                                onClick={() => this.onAddNewCriteria()}
                                            >
                                                <FontAwesomeIcon
                                                    className="mr-2"
                                                    icon={faPlus} />
                                                {"Add"}
                                            </Button>
                                        }
                                    </Col>
                                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
                                        <Button
                                            size="sm"
                                            type="button"
                                            color="secondary"
                                            name="cancelRestriction"
                                            onClick={() => this.setState({ addRestriction: false })}
                                            className="ml-2">
                                            <FontAwesomeIcon
                                                className="mr-2"
                                                icon={faTimes} />
                                            {"Cancel"}
                                        </Button>
                                    </Col>
                                </GroupedRow>
                            </Fragment>
                        }
                        <FlexCenterRow className="mb-3">
                            {!!canEdit.workCenter &&
                                <Button
                                    size="sm"
                                    type="submit"
                                    color="primary"
                                    disabled={this.state.saving}
                                    name="workCenterForm">
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faSave} />
                                    {saveWorkCenterButtonText}
                                </Button>
                            }
                            {canDelete && !workCenter.systemProvided && !workCenter.isShippingActivity && 
                                <Button
                                    size="sm"
                                    type="button"
                                    color="secondary"
                                    disabled={this.state.saving}
                                    name="workCenterFormDelete"
                                    onClick={this.onDeleteWorkCenter}
                                    className="ml-2">
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faTrash} />
                                    {"Delete"}
                                </Button> 
                            }
                            <Button
                                size="sm"
                                type="button"
                                color="secondary"
                                disabled={this.state.saving}
                                name="workCenterFormClose"
                                onClick={this.onCloseClicked}
                                className="ml-2">
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faTimes} />
                                {"Close"}
                            </Button>
                        </FlexCenterRow>
                        <FlexRow>
                            <FormErrorResponseDisplay onClear={this.clearSaveErrors} response={errorResponse} />
                        </FlexRow>
                    </AppPageForm>
                    <Prompt
                        when={!this.state.saving && !isEqual(originalData, workCenter)}
                        message='You have unsaved changes, are you sure you want to leave?'
                    />
                </Fragment>
            );
        }

    }
    //#endregion
}

export default withRouter(WorkCenterForm);