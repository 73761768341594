import React, { Fragment } from 'react';

//Contexts
import { FlexRow, onFieldChange, toasty } from '../common/forms/FormElements';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { ExpectedValue, ModifyQuestion, questionInputTypes, ExpectedValueCriteria, criteriaTypes } from './FormsAndSurveys';
import { isEqual } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes, faTasks, faPlusCircle, faTrashAlt, faPlus, faBan } from '@fortawesome/free-solid-svg-icons'
import {
    AppPageForm,
    FlexCenterRow,
    FormLabel,
    GroupedRow,
    FormGroupColumn
} from '../common/forms/FormElements';

import { LinearProgress } from '@material-ui/core';
import { util } from '../Util';

import {
    Button,
    FormGroup,
    Input,
    Table,
    Label,
    Badge,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Modal,
    Col,
    InputGroup,
    InputGroupText
} from 'reactstrap';

import { Prompt, withRouter } from 'react-router-dom';
import { handleFormSaveError } from '../common/forms/ValidationError';
import authService from '../api-authorization/AuthorizeService';
import { getUserProfile } from '../common/UserProfile';
import FormErrorResponseDisplay from '../common/forms/FormErrorResponseDisplay';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { v4 as uuid } from 'uuid';

//#endregion
//Anything done to fix or improve here that is not strictly cosmetic needs to also be done in the form builder
class QuestionsForm extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            //general form state
            formOpened: false,
            formValidated: false,
            loading: true,
            saving: false,
            errorResponse: {},
            //Form Specific state
            question: new ModifyQuestion(),
            unitsofmeasure: [],
            optiongroups: [],
            inputtypes: [],
            expectedDataTypes: [],
            expectedRelationships: [],
            newExpectedValue: null,
            newExpectedValueType: null,
            newExpectedValueRel: null,
            useOptionGroup: null,
            selectedGroupOptions: [],

            addToExpectedValue: null,
            newExpectedValueCriteria: null,
            newExpectedValueCriteriaOverride: false,
            newExpectedValueCriteriaDerivedFieldName: null,
            newExpectedValueCriteriaType: null,
            newExpectedValueCriteriaRel: null,
            newExpectedValueCriteriaCriteriaType: null,

            allLiftTypes: [],
            allCustomers: [],
            allProductFamilies: [],
            allProductCategories: [],
            allModels: [],
            allLiftOptions: [],
            allMountingInstructions: [],
            allSalesOrderTrackingDerived: [],
            allCriteriaTypes: [],
            addExpectedValueRestriction: false
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.save = this.save.bind(this);
        this.saveNew = this.saveNew.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps && (this.props.match.params.id !== (prevProps.match.params ?? {}).id)) {
            this.populateState();
        }
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            let { id } = { ...this.props.match.params };
            const { userPermissions } = await getUserProfile();

            if (id == 'new') {
                id = undefined; //If the Id is identified as being a new UOM, then just kill the id so we don't call the API
            }

            var [
                questionResponse,
                uoms,
                groups,
                inputtypes,
                expectvaltypes,
                expectedvalrels,
                allLiftTypes,
                allCustomers,
                allProductFamilies,
                allProductCategories,
                allModels,
                allLiftOptions,
                allMountingInstructions,
                allSalesOrderTrackingDerived,
                allCriteriaTypes
            ] = await Promise.all([
                !!id ? util.fetch.post(ApiRoutes.questions.byid(id), util.fetch.format.none) : new ModifyQuestion(),
                util.fetch.js(ApiRoutes.typeAheads.unitsOfMeasure()),
                util.fetch.js(ApiRoutes.typeAheads.questionOptionGroups()),
                util.fetch.js(ApiRoutes.typeAheads.questionInputTypes()),
                util.fetch.js(ApiRoutes.typeAheads.questionExpectedValueTypes()),
                util.fetch.js(ApiRoutes.typeAheads.questionExpectedValRelationships()),
                util.fetch.js(ApiRoutes.typeAheads.getLiftTypes()),
                util.fetch.js(ApiRoutes.typeAheads.apteanCustomers()),
                util.fetch.js(ApiRoutes.typeAheads.apteanProductFamilies()),
                util.fetch.js(ApiRoutes.typeAheads.apteanProductCategories()),
                util.fetch.js(ApiRoutes.typeAheads.getLiftModels()),
                util.fetch.js(ApiRoutes.typeAheads.getLiftOptions()),
                util.fetch.js(ApiRoutes.typeAheads.getMountingInstructions()),
                util.fetch.js(ApiRoutes.typeAheads.getSalesOrderTrackingDerived()),
                util.fetch.js(ApiRoutes.typeAheads.getCriteriaTypes()),
            ]);

            //Fix numbers because everything is coming across as string and this fails the includes predicate
            uoms = uoms.map((ss) => { return { value: Number(ss.value), label: ss.label }; });
            groups = groups.map((ss) => { return { value: Number(ss.value), label: ss.label }; });
            inputtypes = inputtypes.map((ss) => { return { value: Number(ss.value), label: ss.label }; });
            expectvaltypes = expectvaltypes.map((evt) => { return { value: Number(evt.value), label: evt.label }; });
            expectedvalrels = expectedvalrels.map((evr) => { return { value: Number(evr.value), label: evr.label }; });

            allLiftTypes = allLiftTypes.map((lt) => { return { value: Number(lt.value), label: lt.label }; });
            allCustomers = allCustomers.map((ac) => { return { value: Number(ac.value), label: ac.label }; });
            allModels = allModels.map((am) => { return { value: Number(am.value), label: am.label }; });
            allLiftOptions = allLiftOptions.map((lo) => { return { value: Number(lo.value), label: lo.label }; });
            allMountingInstructions = allMountingInstructions.map((mi) => { return { value: Number(mi.value), label: mi.label }; });
            allCriteriaTypes = allCriteriaTypes.filter(ct => ct.value != 1).map((ct) => { return { value: Number(ct.value), label: ct.label }; });

            var question;

            //Handle any issues fetching data
            if (!!id && !!questionResponse.error) {
                //Handle erroneous links entered by the user
                let serviceResponse = {
                    title: 'Server Error',
                    errors: {
                        Exception: [
                            questionResponse.error
                        ]
                    }
                }
                this.displaySaveErrors(serviceResponse);
                return false;
            } else {
                question = questionResponse;
            }

            if (question.optionGroupId) {
                this.getOptionGroupToUse(question.optionGroupId);
            }

            if (question.expectedValues == null) {
                question.expectedValues = [];
            }

            this.setState({
                formValidated: false,
                saving: false,
                loading: false,
                perms: userPermissions,
                originalData: question,
                question: question,
                unitsofmeasure: uoms,
                optiongroups: groups,
                inputtypes: inputtypes,
                expectedDataTypes: expectvaltypes,
                expectedRelationships: expectedvalrels,
                allLiftTypes: allLiftTypes,
                allCustomers: allCustomers,
                allProductFamilies: allProductFamilies,
                allProductCategories: allProductCategories,
                allModels: allModels,
                allLiftOptions: allLiftOptions,
                allMountingInstructions: allMountingInstructions,
                allSalesOrderTrackingDerived: allSalesOrderTrackingDerived,
                allCriteriaTypes: allCriteriaTypes
            });
        }
    }

    //#region METHODS
    onChange = onFieldChange;

    getOptionGroupToUse = async (groupId) => {
        let { question } = { ...this.state };

        var [groupResponse ] = await Promise.all([
            util.fetch.post(ApiRoutes.questionoptiongroups.byid(groupId), util.fetch.format.none)
        ]);
        
        if (!!groupResponse.error) {
            //Handle erroneous links entered by the user
            let serviceResponse = {
                title: 'Server Error',
                errors: {
                    Exception: [
                        groupResponse.error
                    ]
                }
            }
            this.displaySaveErrors(serviceResponse);
            return false;
        }

        question.optionGroupId = groupId;

        this.setState({
            useOptionGroup: groupResponse
        });
    }

    onSubmit() {
        let { question } = { ...this.state };
        this.clearSaving()
        this.setState({ errors: {}, saving: true });

        if (this.props.location.pathname === AppNavPaths.QuestionsNew) {
            this.saveNew(question);
        } else {
            this.save(question);
        }
    }

    clearSaving = () => this.setState({ saving: false });

    notifySuccess = () => toasty.success('Question Saved', `Question saved successfully.`);
    notifyError = (message) => toasty.error('Save Unsuccessful', message);
    handleSaveError = (err) => handleFormSaveError(this, err);

    displaySaveErrors = (response) => this.setState({ errorResponse: response });
    clearSaveErrors = () => this.setState({ errorResponse: {} });

    onCloseClicked = () => {
        this.props.history.push(AppNavPaths.Questions);
    }

    onSetNewExpectedGroupValue = async (newval) => {
        let { newExpectedValue } = { ...this.state };

        //an existing value
        if ((newExpectedValue ?? "").length) {
            //If the value is not in the asnwer, we need to be sure to normalize how values are added
            //to avoid adding them arbitrarily and having the order in which the check boxes are clicked
            //be impotant to the answer
            if (newExpectedValue.indexOf(newval) > -1) {
                //value is already in the answer
                let allvals = newExpectedValue.split(",");
                allvals.splice(allvals.indexOf(newval.toString()), 1);
                newExpectedValue = allvals.sort((a, b) => a < b ? -1 : 1).join(",");
            } else {
                let allvals = newExpectedValue.split(",");
                allvals.push(newval.toString());
                newExpectedValue = allvals.sort((a, b) => a < b ? -1 : 1).join(",");
            } 
        } else {
            newExpectedValue = newval.toString();
        }

        this.setState({
            newExpectedValue: newExpectedValue
        });
    }

    onSetNewExpectedValue = async (newval) => {
        this.setState({
            newExpectedValue: newval.toString()
        });
    }

    onChangeNewExpectedValType = async (newValType) => {
        this.setState({
            newExpectedValueType: newValType
        });
    }

    onChangeNewExpectedValRel = async (newValRel) => {
        this.setState({
            newExpectedValueRel: newValRel
        });
    }

    onExpectedValueRemoved = async (index) => {
        let { question } = { ...this.state };

        question.expectedValues.splice(index, 1);

        this.setState({
            question: question
        });
    }

    onAddNewExpectedValue = async () => {
        let {
            question,
            newExpectedValue,
            newExpectedValueType,
            newExpectedValueRel,
            selectedGroupOptions
        } = { ...this.state };

        let newVal = new ExpectedValue();       

        if (selectedGroupOptions.length) {
            newVal.expectedValue = selectedGroupOptions.map(o => o.choiceText).sort((a,b) => a < b ? -1 : 1 ).join(",");
        } else {
            newVal.expectedValue = (newExpectedValue ?? "").toString();
        }
        newVal.questionExpectedValueTypeId = newExpectedValueType.value;
        newVal.questionExpectedValueRelationshipId = newExpectedValueRel.value;
        newVal.valueTypeText = newExpectedValueType.label;
        newVal.relationshipText = newExpectedValueRel.label;

        if (question.question.inputTypeId == questionInputTypes.Derived) {
            newVal.salesOrderTrackingDerived = true;
        }

        question.expectedValues.push(newVal);

        this.setState({
            question: question,
            newExpectedValue: null,
            newExpectedValueType: null,
            newExpectedValueRel: null,
            selectedGroupOptions: []
        });
    }

    onAddNewExpectedValueCriteria = async () => {
        let {
            question,
            addToExpectedValue,
            newExpectedValueCriteria,
            newExpectedValueCriteriaType,
            newExpectedValueCriteriaRel,
            newExpectedValueCriteriaCriteriaType,
            newExpectedValueCriteriaDerivedFieldName
        } = { ...this.state };

        let newCriteria = new ExpectedValueCriteria();

        newCriteria.expectedValue = newExpectedValueCriteria?.label; //the restriction is always the actual text
        newCriteria.derivedFieldName = newExpectedValueCriteriaDerivedFieldName?.label;
        newCriteria.criteriaTypeId = newExpectedValueCriteriaCriteriaType.value;
        newCriteria.expectedValueTypeId = newExpectedValueCriteriaType.value;
        newCriteria.expectedValueRelationshipId = newExpectedValueCriteriaRel.value;
        newCriteria.valueTypeText = newExpectedValueCriteriaType.label;
        newCriteria.relationshipText = newExpectedValueCriteriaRel.label;
        newCriteria.criteriaTypeText = newExpectedValueCriteriaCriteriaType.label;

        question.expectedValues[addToExpectedValue].criteria.push(newCriteria);

        this.setState({
            question: question,
            addToExpectedValue: null,
            newExpectedValueCriteriaDerivedFieldName: null,
            newExpectedValueCriteria: null,
            newExpectedValueCriteriaType: null,
            newExpectedValueCriteriaRel: null,
            newExpectedValueCriteriaCriteriaType: null,
            addExpectedValueRestriction: false,
            newExpectedValueCriteriaOverride: false
        });
    }

    onRemoveNewExpectedValueCriteria = async (ev_index, cev_index) => {
        let { question } = { ...this.state };

        question.expectedValues[ev_index].criteria.splice(cev_index, 1);

        this.setState({
            question: question
        });
    }

    saveNew = async (question) => {
        try {
            let response = await util.fetch.post(ApiRoutes.questions.create(), question, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                let questionId = await response.json();
                this.notifySuccess();
                this.props.history.push(`${AppNavPaths.Questions}/${questionId}`);
            } else {
                if (response.status === 400) {
                    let serviceResponse = await response.json();
                    this.displaySaveErrors(serviceResponse);
                } else {

                    let errorResp = await response.json();

                    if (errorResp.errors) {
                        this.displaySaveErrors(errorResp);
                    } else {
                        let serviceResponse = {
                            title: 'Server Error',
                            errors: {
                                Exception: [
                                    await response.text()
                                ]
                            }
                        }
                        this.displaySaveErrors(serviceResponse);
                    }
                }
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    save = async (question) => {
        try {
            let response = await util.fetch.put(ApiRoutes.questions.byid(question.question.id), question, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                this.notifySuccess();
                this.populateState();
            } else {
                this.displaySaveErrors(await response.json());
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    //#endregion

    //#region RENDERING
    
    render() {
        const {
            loading,
            perms,
            originalData,
            question,
            unitsofmeasure,
            optiongroups,
            inputtypes,
            expectedDataTypes,
            expectedRelationships,
            newExpectedValueType,
            newExpectedValueRel,
            useOptionGroup,
            //Criteria 
            allLiftTypes,
            allCustomers,
            allProductFamilies,
            allProductCategories,
            allModels,
            allLiftOptions,
            allMountingInstructions,
            allSalesOrderTrackingDerived,
            allCriteriaTypes
        } = { ...this.state };

        let {
            errorResponse,
            newExpectedValue,
            selectedGroupOptions,
            newExpectedValueCriteria,
            addExpectedValueRestriction,
            newExpectedValueCriteriaType,
            newExpectedValueCriteriaRel,
            newExpectedValueCriteriaCriteriaType,
            newExpectedValueCriteriaDerivedFieldName,
            newExpectedValueCriteriaOverride
        } = { ...this.state };

        const allowEmpty = [
            questionInputTypes.Text,
            questionInputTypes.Numeric,
            questionInputTypes.SingleLineText
        ];

        if (!!loading) {
            return (<LinearProgress variant="indeterminate" color="secondary" />);
        } else {
            const isNew = ((question.question.id ?? 0) <= 0);
            const saveButtonText = (!isNew ? 'Save' : 'Save New Question');

            //field editing permissions
            const canEdit = !!perms.includes("question.edit") || (isNew && !!perms.includes("question.create"));

            return (
                <Fragment>
                    <AppPageForm
                        formId={"questionForm"}
                        formHeadingIcon={faTasks}
                        formHeading={isNew ? 'New Question' : 'Edit Question'}
                        formName={"questionForm"}
                        formRef={this.formRef}
                        onSubmit={this.onSubmit}
                        setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                        isValidated={this.state.formValidated}
                        saving={this.state.saving}
                        errors={this.state.errors}
                        loading={this.state.loading}
                    >
                        <GroupedRow>
                            <FormGroupColumn>
                                <FormGroup>
                                    <FormLabel htmlFor="inputName" text="Question Name" required={true} />
                                    <input id="inputName"
                                        name="question.question.name"
                                        autoComplete="off"
                                        className="form-control"
                                        required
                                        disabled={!isNew && !canEdit}
                                        onChange={!!canEdit ? this.onChange : undefined}
                                        defaultValue={question.question.name} />
                                    <small className="invalid-feedback text-danger" hidden>Question Name is required.</small>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="inputTitle" text="Question Text" required={true} />
                                    <input id="inputTitle"
                                        name="question.question.title"
                                        autoComplete="off"
                                        className="form-control"
                                        required
                                        disabled={!isNew && !canEdit}
                                        onChange={!!canEdit ? this.onChange : undefined}
                                        defaultValue={question.question.title} />
                                    <small className="invalid-feedback text-danger" hidden>Question Text is required.</small>
                                </FormGroup>
                            </FormGroupColumn>
                            <FormGroupColumn>
                                <FormGroup>
                                    <Input
                                        id="inputAllowPicture"
                                        name="question.question.allowPicture"
                                        autoComplete="off"
                                        className="form-control"
                                        disabled={!isNew && !canEdit}
                                        onChange={!!canEdit ? this.onChange : undefined}
                                        type="checkbox"
                                        checked={question?.question?.allowPicture ?? false}
                                    />
                                    <Label style={{ color: "#777", fontWeight: "600", fontSize: ".9em", lineHeight: "45px" }}
                                        check htmlFor="question.question.allowPicture" >
                                        {"Allow Pictures?"}
                                    </Label>
                                </FormGroup>
                                <FormGroup>
                                    <FormGroup>
                                        <Input
                                            id="inputIsHidden"
                                            name="question.question.isHidden"
                                            autoComplete="off"
                                            className="form-control"
                                            disabled={!isNew && !canEdit}
                                            onChange={!!canEdit ? this.onChange : undefined}
                                            type="checkbox"
                                            checked={question?.question?.isHidden ?? false}
                                        />
                                        <Label style={{ color: "#777", fontWeight: "600", fontSize: ".9em", lineHeight: "45px" }}
                                            check htmlFor="question.question.isHidden" >
                                            {"Is Hidden?"}
                                        </Label>
                                    </FormGroup>
                                </FormGroup>
                            </FormGroupColumn>
                            <FormGroupColumn>
                                <FormGroup>
                                    <FormLabel htmlFor="inputSubText" text="Question Sub Text" />
                                    <textarea id="inputSubText"
                                        name="question.question.subText"
                                        autoComplete="off"
                                        className="form-control"
                                        disabled={!isNew && !canEdit}
                                        onChange={!!canEdit ? this.onChange : undefined}
                                        defaultValue={question.question.subText} />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="inputInputType" text="Input For Question" />
                                    <ValidatedSelect
                                        id="inputInputType"
                                        name="question.question.inputTypeId"
                                        options={inputtypes}
                                        value={(inputtypes ?? []).find(s => s.value == question.question.inputTypeId) ?? ''}
                                        onChange={(selection) => {
                                            question.question.inputTypeId = selection?.value;

                                            if (question.question.inputTypeId == questionInputTypes.DateTime) {
                                                question.question.unitOfMeasureId = -1; //Is System only uom for date time
                                            } else {
                                                question.question.unitOfMeasureId = 1; //Is System only uom for None
                                            }

                                            this.setState({ question: question });
                                        }}
                                    />
                                </FormGroup>
                            </FormGroupColumn>
                            <FormGroupColumn>
                                {(question.question.inputTypeId != questionInputTypes.None && question.question.inputTypeId != 0) &&
                                    <>
                                        {((question.question.inputTypeId == questionInputTypes.OptionGroup)
                                            || (question.question.inputTypeId == questionInputTypes.Select)) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="inputOptionGroup" text="Option Group" />
                                                <ValidatedSelect
                                                    id="inputOptionGroup"
                                                    name="question.optionGroupId"
                                                    options={optiongroups}
                                                    value={(optiongroups ?? []).find(s => s.value == question.optionGroupId) ?? ''}
                                                    onChange={(selection) => {
                                                        question.optionGroupId = selection?.value;
                                                        this.getOptionGroupToUse(selection?.value);
                                                        this.setState({ question: question });
                                                    }}
                                                />
                                            </FormGroup>
                                        }
                                        {(question.question.inputTypeId == questionInputTypes.Numeric) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="inputUnitOfMeasure" text="Unit Of Measurement" />
                                                <ValidatedSelect
                                                    id="inputUnitOfMeasure"
                                                    name="question.question.unitOfMeasureId"
                                                    options={unitsofmeasure}
                                                    value={(unitsofmeasure ?? []).find(s => s.value == question.question.unitOfMeasureId) ?? ''}
                                                    onChange={(selection) => {
                                                        question.question.unitOfMeasureId = selection?.value;
                                                        this.setState({ question: question });
                                                    }}
                                                />
                                            </FormGroup>
                                        }
                                        <FormGroup>
                                            <Input
                                                id="inputExpectedValueIsDefault"
                                                name="question.question.expectedValueIsDefault"
                                                autoComplete="off"
                                                className="form-control"
                                                disabled={!isNew && !canEdit}
                                                onChange={!!canEdit ? this.onChange : undefined}
                                                type="checkbox"
                                                checked={question?.question?.expectedValueIsDefault ?? false}
                                            />
                                            <Label style={{ color: "#777", fontWeight: "600", fontSize: ".9em", lineHeight: "45px" }}
                                                check htmlFor="question.question.expectedValueIsDefault" >
                                                {"Exected Value is Default?"}
                                            </Label>
                                        </FormGroup>
                                    </>
                                }
                            </FormGroupColumn>
                        </GroupedRow>
                        {(question.question.inputTypeId !== questionInputTypes.None) &&
                            <FlexCenterRow>
                                <Table style={{ width: "100%" }}>
                                    <colgroup>
                                        <col style={{ width: "60%" }} />
                                        <col style={{ width: "10%" }} />
                                        <col style={{ width: "10%" }} />
                                        <col style={{ width: "17%" }} />
                                        <col style={{ width: "3%" }} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>Expected Value</th>
                                            <th>Value Is</th>
                                            <th>Relationship</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!!(question.expectedValues ?? []).length &&
                                            (question.expectedValues ?? []).map((ev, ind) =>
                                                <Fragment key={uuid()}>
                                                    <tr key={uuid()} style={{ background: ind % 2 == 0 ? "inherit" : "#FAFAFA", borderTop: "0.25em solid black" }} >
                                                        <td>
                                                            <span>{ev.expectedValue}</span>
                                                            {!!(question?.question?.expectedValueIsDefault) && !!(ind == 0) &&
                                                                <Badge style={{marginLeft:"1em"}}>Default</Badge>
                                                            }
                                                        </td>
                                                        <td>{ev.valueTypeText}</td>
                                                        <td>{ev.relationshipText}</td>
                                                        <td>
                                                            <FontAwesomeIcon
                                                                className="mr-2 text-dark pr-2 zoom"
                                                                onClick={() => this.onExpectedValueRemoved(ind)}
                                                                icon={faTrashAlt} />
                                                        </td>
                                                        <td>
                                                            <FontAwesomeIcon
                                                                className="mr-2 text-dark pr-2 zoom"
                                                                onClick={() => {
                                                                    question.expectedValues[ind].showCriteria = !!!(question.expectedValues[ind].showCriteria);
                                                                    this.setState({question: question});
                                                                }}
                                                                icon={faBan} />
                                                        </td>
                                                    </tr>
                                                    {!!ev.showCriteria && 
                                                        <tr key={uuid()} style={{ background: ind % 2 == 0 ? "inherit" : "#FAFAFA" }}>
                                                            <td colSpan={4} >
                                                                <Button
                                                                    size="sm"
                                                                    type="button"
                                                                    color="primary"
                                                                    name="addExpectedValueRestriction"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            addExpectedValueRestriction: true,
                                                                            addToExpectedValue: ind
                                                                        });
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        className="mr-2 text-dark pr-2 zoom"
                                                                        icon={faPlus} />
                                                                    {"Add Restriction"}
                                                                </Button>
                                                                {!!(ev.criteria.length) &&
                                                                    <Table style={{ width: "100%", marginTop: "0.25em" }}>
                                                                        <colgroup>
                                                                            <col style={{ width: "40%" }} />
                                                                            <col style={{ width: "20%" }} />
                                                                            <col style={{ width: "15%" }} />
                                                                            <col style={{ width: "15%" }} />
                                                                            <col style={{ width: "10%" }} />
                                                                        </colgroup>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Restriction Value</th>
                                                                                <th>Type</th>
                                                                                <th>Value Is</th>
                                                                                <th>Relationship</th>
                                                                                <th></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {!!(ev.criteria ?? []).length &&
                                                                                (ev.criteria ?? []).map((evc, cind) =>
                                                                                    <tr key={uuid()}>
                                                                                        <td>{
                                                                                            !!(evc.derivedFieldName) ? evc.derivedFieldName + ": " + evc.expectedValue : evc.expectedValue
                                                                                        }</td>
                                                                                        <td>{evc.criteriaTypeText}</td>
                                                                                        <td>{evc.valueTypeText}</td>
                                                                                        <td>{evc.relationshipText}</td>
                                                                                        <td>
                                                                                            <FontAwesomeIcon
                                                                                                className="mr-2"
                                                                                                onClick={() => this.onRemoveNewExpectedValueCriteria(ind, cind)}
                                                                                                icon={faTrashAlt} />
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                        </tbody>
                                                                    </Table>
                                                                }
                                                            </td>
                                                        </tr>
                                                    }
                                                </Fragment>
                                            )
                                        }
                                        {!!!(question.expectedValues ?? []).length &&
                                            <tr><td colSpan={5}>No Expected Values</td></tr>
                                        }
                                        <tr>
                                            {(question.question.inputTypeId == questionInputTypes.Derived) &&
                                                <td>
                                                    <FormGroup>
                                                        <ValidatedSelect
                                                            id="qevCriteriaSOTDerivedValue"
                                                            formRef={this.formRef}
                                                            name="newExpectedValue"
                                                            options={allSalesOrderTrackingDerived}
                                                            value={(allSalesOrderTrackingDerived ?? []).find(s => s.value == (newExpectedValue ?? '')) ?? ''}
                                                            onChange={(selection) => {
                                                                newExpectedValue = selection?.value;
                                                                this.setState({ newExpectedValue: newExpectedValue });
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </td>
                                            }
                                            {(question.question.inputTypeId == questionInputTypes.Numeric) &&
                                                <td>
                                                    <FormGroup>
                                                        <Input
                                                            type="number"
                                                            name="newExpectedValue"
                                                            disabled={!canEdit}
                                                            value={newExpectedValue ?? 0}
                                                            onChange={this.onChange}
                                                        />
                                                    </FormGroup>
                                                </td>
                                            }
                                            {(question.question.inputTypeId == questionInputTypes.SingleLineText) &&
                                                <td>
                                                    <FormGroup>
                                                        <Input
                                                            type="text"
                                                            disabled={!canEdit}
                                                            name="newExpectedValue"
                                                            value={newExpectedValue ?? ""}
                                                            onChange={this.onChange}
                                                        />
                                                    </FormGroup>
                                                </td>
                                            }
                                            {(question.question.inputTypeId == questionInputTypes.Text) &&
                                                <td>
                                                    <FormGroup>
                                                        <textarea
                                                            disabled={!canEdit}
                                                            name="newExpectedValue"
                                                            value={newExpectedValue ?? ""}
                                                            onChange={this.onChange}
                                                        />
                                                    </FormGroup>
                                                </td>
                                            }
                                            {(question.question.inputTypeId == questionInputTypes.DateTime) &&
                                                <td>
                                                    <FormGroup>
                                                        <Input
                                                            type="date"
                                                            disabled={!canEdit}
                                                            name="newExpectedValue"
                                                            value={newExpectedValue ?? ""}
                                                            onChange={this.onChange}
                                                        />
                                                    </FormGroup>
                                                </td>
                                            }
                                            {(question.question.inputTypeId == questionInputTypes.OptionGroup) &&
                                                <td>
                                                    {!!(useOptionGroup) &&
                                                        <>
                                                            {!!useOptionGroup.group.isExclusiveChoice &&
                                                                <FormGroup>
                                                                    {
                                                                        useOptionGroup.options.map((op) =>
                                                                            <FormGroup key={op.id + op.choiceText} check>
                                                                                <Input
                                                                                    name="optionGroup"
                                                                                    type="radio"
                                                                                    value={op.choiceText}
                                                                                    checked={newExpectedValue == op.choiceText}
                                                                                    className="form-control"
                                                                                    onChange={() => { this.onSetNewExpectedValue(op.choiceText) }}
                                                                                />
                                                                                {' '}
                                                                                <Label style={{ color: "#777", fontWeight: "600", fontSize: ".9em", lineHeight: "45px" }} check>
                                                                                    {op.choiceText}
                                                                                </Label>
                                                                            </FormGroup>
                                                                        )
                                                                    }
                                                                </FormGroup>
                                                            }
                                                            {!!!useOptionGroup.group.isExclusiveChoice &&
                                                                <FormGroup>
                                                                    {
                                                                        useOptionGroup.options.map((op) =>
                                                                            <FormGroup key={op.id + op.choiceText} check>
                                                                                <Input
                                                                                    name="optionGroup"
                                                                                    type="checkbox"
                                                                                    checked={(newExpectedValue ?? "").includes(op.choiceText)}
                                                                                    className="form-control"
                                                                                    onChange={() => { this.onSetNewExpectedGroupValue(op.choiceText) }}
                                                                                />
                                                                                {' '}
                                                                                <Label style={{ color: "#777", fontWeight: "600", fontSize: ".9em", lineHeight: "45px" }} check>
                                                                                    {op.choiceText}
                                                                                </Label>
                                                                            </FormGroup>
                                                                        )
                                                                    }
                                                                </FormGroup>
                                                            }
                                                        </>
                                                    }
                                                    {!!!(useOptionGroup) &&
                                                        <span>No Option Group Chosen</span>
                                                    }
                                                </td>
                                            }
                                            {(question.question.inputTypeId == questionInputTypes.Select) &&
                                                <td>
                                                    {!!(useOptionGroup) &&
                                                        <>
                                                            {useOptionGroup.group.isExclusiveChoice &&
                                                                <FormGroup>
                                                                    <ValidatedSelect
                                                                        id="selectNewExpectedValue"
                                                                        name="newExpectedValue"
                                                                        options={useOptionGroup.options}
                                                                        value={(useOptionGroup.options ?? []).find(s => s.choiceText == newExpectedValue) ?? ''}
                                                                        getOptionLabel={option => option.choiceText}
                                                                        getOptionValue={option => option.id}
                                                                        validationMessage=""
                                                                        onChange={(selection) => {
                                                                            newExpectedValue = selection?.choiceText;
                                                                            this.setState({ newExpectedValue: newExpectedValue });
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            }
                                                            {!useOptionGroup.group.isExclusiveChoice &&
                                                                <FormGroup >
                                                                    <ValidatedSelect
                                                                        id="selectNewExpectedValueMlti"
                                                                        name="selectedGroupOptions"
                                                                        isMulti
                                                                        options={useOptionGroup.options}
                                                                        value={selectedGroupOptions ?? ''}
                                                                        getOptionLabel={option => option.choiceText}
                                                                        getOptionValue={option => option.id}
                                                                        validationMessage=""
                                                                        onChange={(selection) => {
                                                                            selectedGroupOptions = selection;
                                                                            this.setState({ selectedGroupOptions: selectedGroupOptions });
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            }
                                                        </>
                                                    }
                                                    {!!!(useOptionGroup) &&
                                                        <span>No Option Group Chosen</span>
                                                    }
                                                </td>
                                            }
                                            <td>
                                                <FormGroup>
                                                    <ValidatedSelect
                                                        id="inputAddExpectedValType"
                                                        isDisabled={!canEdit}
                                                        options={expectedDataTypes}
                                                        onChange={!!canEdit ? this.onChangeNewExpectedValType : undefined}
                                                        validationMessage=""
                                                        value={newExpectedValueType}
                                                    />
                                                    <small className="invalid-feedback text-danger" hidden></small>
                                                </FormGroup>
                                            </td>
                                            <td>
                                                <FormGroup>
                                                    <ValidatedSelect
                                                        id="inputAddExpectedValRel"
                                                        isDisabled={!canEdit}
                                                        options={expectedRelationships}
                                                        onChange={!!canEdit ? this.onChangeNewExpectedValRel : undefined}
                                                        validationMessage=""
                                                        value={newExpectedValueRel}
                                                    />
                                                    <small className="invalid-feedback text-danger" hidden></small>
                                                </FormGroup>
                                            </td>
                                            <td>
                                                {(!!newExpectedValueType && !!newExpectedValueRel
                                                    && (
                                                        !!(selectedGroupOptions ?? []).length || 
                                                        (
                                                            !!(newExpectedValue)
                                                            || (!!!(newExpectedValue) && allowEmpty.includes(question.question.inputTypeId))
                                                        )
                                                    )
                                                ) &&
                                                    <Button
                                                        size="sm"
                                                        type="button"
                                                        color="primary"
                                                        onClick={this.onAddNewExpectedValue}
                                                        name="addNewExpectedValue">
                                                        <FontAwesomeIcon
                                                            className="mr-2"
                                                            icon={faPlusCircle} />
                                                        {"Add New"}
                                                    </Button>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </FlexCenterRow>
                        }
                        <FlexCenterRow className="mb-3">
                            {!!canEdit &&
                                <Button
                                    size="sm"
                                    type="submit"
                                    color="primary"
                                    disabled={this.state.saving}
                                    name="formForm">
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faSave} />
                                    {saveButtonText}
                                </Button>
                            }
                            <Button
                                size="sm"
                                type="button"
                                color="secondary"
                                name="formFormClose"
                                disabled={this.state.saving}
                                onClick={this.onCloseClicked}
                                className="ml-2">
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faTimes} />
                                {"Close"}
                            </Button>
                        </FlexCenterRow>
                        <FlexRow>
                            <FormErrorResponseDisplay onClear={this.clearSaveErrors} response={errorResponse} />
                        </FlexRow>
                        <Prompt
                            when={!this.state.saving && !isEqual(originalData, question)}
                            message='You have unsaved changes, are you sure you want to leave?'
                        />
                        <Modal isOpen={addExpectedValueRestriction} size={"xl"}>
                            <ModalHeader>Add Restriction</ModalHeader>
                            <ModalBody>
                                <GroupedRow>
                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                        <FormGroup>
                                            <FormLabel htmlFor="evCriteriaType" text="Restriction Type" />
                                            <ValidatedSelect
                                                id="evCriteriaType"
                                                formRef={this.formRef}
                                                name="newExpectedValueCriteriaCriteriaType"
                                                options={allCriteriaTypes}
                                                value={(allCriteriaTypes ?? []).find(s => s.value == (newExpectedValueCriteriaCriteriaType ?? {}).value) ?? ''}
                                                onChange={(selection) => {
                                                    newExpectedValueCriteria = null;
                                                    newExpectedValueCriteriaCriteriaType = selection;
                                                    this.setState({
                                                        newExpectedValueCriteriaCriteriaType: newExpectedValueCriteriaCriteriaType,
                                                        newExpectedValueCriteria: newExpectedValueCriteria
                                                    });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                        {!!((newExpectedValueCriteriaCriteriaType ?? {}).value == criteriaTypes.Customer) && 
                                            <FormGroup>
                                                <FormLabel htmlFor="evCriteriaCustomerValue" text="Choose Customer" />
                                                <ValidatedSelect
                                                    id="evCriteriaCustomerValue"
                                                    formRef={this.formRef}
                                                    name="newExpectedValueCriteria"
                                                    options={allCustomers}
                                                    value={(allCustomers ?? []).find(s => s.value == (newExpectedValueCriteria ?? {}).value) ?? ''}
                                                    onChange={(selection) => {
                                                        newExpectedValueCriteria = selection;
                                                        this.setState({ newExpectedValueCriteria: newExpectedValueCriteria });
                                                    }}
                                                />
                                            </FormGroup>
                                        }
                                        {!!((newExpectedValueCriteriaCriteriaType ?? {}).value == criteriaTypes.Category) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="evCriteriaCategoryValue" text="Choose Product Category" />
                                                <ValidatedSelect
                                                    id="evCriteriaCategoryValue"
                                                    formRef={this.formRef}
                                                    name="newExpectedValueCriteria"
                                                    options={allProductCategories}
                                                    value={(allProductCategories ?? []).find(s => s.value == (newExpectedValueCriteria ?? {}).value) ?? ''}
                                                    onChange={(selection) => {
                                                        newExpectedValueCriteria = selection;
                                                        this.setState({ newExpectedValueCriteria: newExpectedValueCriteria });
                                                    }}
                                                />
                                            </FormGroup>
                                        }
                                        {!!((newExpectedValueCriteriaCriteriaType ?? {}).value == criteriaTypes.Family) &&
                                            <FormGroup>
                                            <FormLabel htmlFor="evCriteriaFamilyValue" text="Choose Product Family" />
                                                <ValidatedSelect
                                                    id="evCriteriaFamilyValue"
                                                    formRef={this.formRef}
                                                    name="newExpectedValueCriteria"
                                                    options={allProductFamilies}
                                                    value={(allProductFamilies ?? []).find(s => s.value == (newExpectedValueCriteria ?? {}).value) ?? ''}
                                                    onChange={(selection) => {
                                                        newExpectedValueCriteria = selection;
                                                        this.setState({ newExpectedValueCriteria: newExpectedValueCriteria });
                                                    }}
                                                />
                                            </FormGroup>
                                        }
                                        {!!((newExpectedValueCriteriaCriteriaType ?? {}).value == criteriaTypes.Model) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="evCriteriaModelValue" text="Choose Model" />
                                                <InputGroup>
                                                    <InputGroupText>
                                                        <span style={
                                                            { position: 'absolute', top: '-2px', left: '1.125em', fontSize: '9px' }
                                                        }>Override</span>
                                                        <Input
                                                            id="evCriteriaModelValueOverride"
                                                            name="newExpectedValueCriteriaOverride"
                                                            autoComplete="off"
                                                            onChange={this.onChange}
                                                            type="checkbox"
                                                            checked={newExpectedValueCriteriaOverride ?? false}
                                                            style={{ position: 'relative', top: '2px', marginLeft: 'auto', width: '2em', height: '1.5em' }}
                                                        />
                                                    </InputGroupText>
                                                    {!!!newExpectedValueCriteriaOverride &&
                                                        <div className="form-control" style={{ padding: 0, border: 'none' }}>
                                                            <ValidatedSelect
                                                                id="evCriteriaModelValue"
                                                                formRef={this.formRef}
                                                                name="newExpectedValueCriteria"
                                                                style={{ flexGrow: 3 }}
                                                                options={allModels}
                                                                value={(allModels ?? []).find(s => s.value == (newExpectedValueCriteria ?? {}).value) ?? ''}
                                                                onChange={(selection) => {
                                                                    newExpectedValueCriteria = selection;
                                                                    this.setState({ newExpectedValueCriteria: newExpectedValueCriteria });
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    {!!newExpectedValueCriteriaOverride &&
                                                        <Input
                                                            type="text"
                                                            style={{ flexGrow: 3 }}
                                                            name="newExpectedValueCriteria"
                                                            value={newExpectedValueCriteria?.label ?? ""}
                                                            onChange={(e) => {
                                                                newExpectedValueCriteria = !!(e.target.value) ? { label: e.target.value } : null;;
                                                                this.setState({ newExpectedValueCriteria: newExpectedValueCriteria });
                                                            }}
                                                        />
                                                    }
                                                </InputGroup>
                                            </FormGroup>
                                        }
                                        {!!((newExpectedValueCriteriaCriteriaType ?? {}).value == criteriaTypes.LiftOption) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="evCriteriaLiftOptionValue" text="Choose Lift Option" />
                                                <InputGroup>
                                                    <InputGroupText>
                                                        <span style={
                                                            { position: 'absolute', top: '-2px', left: '1.125em', fontSize: '9px' }
                                                        }>Override</span>
                                                        <Input
                                                            id="evCriteriaLiftOptionValueOverride"
                                                            name="newExpectedValueCriteriaOverride"
                                                            autoComplete="off"
                                                            onChange={this.onChange}
                                                            type="checkbox"
                                                            checked={newExpectedValueCriteriaOverride ?? false}
                                                            style={{ position: 'relative', top: '2px', marginLeft: 'auto', width: '2em', height:'1.5em' }}
                                                        />
                                                    </InputGroupText>
                                                    {!!!newExpectedValueCriteriaOverride &&
                                                        <div className="form-control" style={{padding:0, border: 'none'}}>
                                                            <ValidatedSelect
                                                                id="evCriteriaLiftOptionValue"
                                                                style={{ flexGrow: 3 }}
                                                                formRef={this.formRef}
                                                                name="newExpectedValueCriteria"
                                                                options={allLiftOptions}
                                                                value={(allLiftOptions ?? []).find(s => s.value == (newExpectedValueCriteria ?? {}).value) ?? ''}
                                                                onChange={(selection) => {
                                                                    newExpectedValueCriteria = selection;
                                                                    this.setState({ newExpectedValueCriteria: newExpectedValueCriteria });
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    {!!newExpectedValueCriteriaOverride &&
                                                        <Input
                                                            type="text"
                                                            style={{ flexGrow: 3 }}
                                                            name="newExpectedValueCriteria"
                                                            value={newExpectedValueCriteria?.label ?? ""}
                                                            onChange={(e) => {
                                                                newExpectedValueCriteria = !!(e.target.value) ? { label: e.target.value } : null;;
                                                                this.setState({ newExpectedValueCriteria: newExpectedValueCriteria });
                                                            }}
                                                        />
                                                    }
                                                </InputGroup>                                                    
                                            </FormGroup>
                                        }
                                        {!!((newExpectedValueCriteriaCriteriaType ?? {}).value == criteriaTypes.MountingInstruction) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="evCriteriaMountingInstructionValue" text="Choose Mounting Instruction" />
                                                <InputGroup>
                                                    <InputGroupText>
                                                        <span style={
                                                            { position: 'absolute', top: '-2px', left: '1.125em', fontSize: '9px' }
                                                        }>Override</span>
                                                        <Input
                                                            id="evCriteriaLiftOptionValueOverride"
                                                            name="newExpectedValueCriteriaOverride"
                                                            autoComplete="off"
                                                            onChange={this.onChange}
                                                            type="checkbox"
                                                            checked={newExpectedValueCriteriaOverride ?? false}
                                                            style={{ position: 'relative', top: '2px', marginLeft: 'auto', width: '2em', height: '1.5em' }}
                                                        />
                                                    </InputGroupText>
                                                    {!!!newExpectedValueCriteriaOverride &&
                                                        <div className="form-control" style={{ padding: 0, border: 'none' }}>
                                                            <ValidatedSelect
                                                                id="evCriteriaMountingInstructionValue"
                                                                formRef={this.formRef}
                                                                name="newExpectedValueCriteria"
                                                                options={allMountingInstructions}
                                                                value={(allMountingInstructions ?? []).find(s => s.value == (newExpectedValueCriteria ?? {}).value) ?? ''}
                                                                onChange={(selection) => {
                                                                    newExpectedValueCriteria = selection;
                                                                    this.setState({ newExpectedValueCriteria: newExpectedValueCriteria });
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    {!!newExpectedValueCriteriaOverride &&
                                                        <Input
                                                            type="text"
                                                            style={{ flexGrow: 3 }}
                                                            name="newExpectedValueCriteria"
                                                            value={newExpectedValueCriteria?.label ?? ""}
                                                            onChange={(e) => {
                                                                newExpectedValueCriteria = !!(e.target.value) ? { label: e.target.value } : null;;
                                                                this.setState({ newExpectedValueCriteria: newExpectedValueCriteria });
                                                            }}
                                                        />
                                                    }
                                                </InputGroup>
                                            </FormGroup>
                                        }
                                        {!!((newExpectedValueCriteriaCriteriaType ?? {}).value == criteriaTypes.LiftType) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="evCriteriaLiftTypeValue" text="Choose Lift Type" />
                                                <ValidatedSelect
                                                    id="evCriteriaLiftTypeValue"
                                                    formRef={this.formRef}
                                                    name="newExpectedValueCriteria"
                                                    options={allLiftTypes}
                                                    value={(allLiftTypes ?? []).find(s => s.value == (newExpectedValueCriteria ?? {}).value) ?? ''}
                                                    onChange={(selection) => {
                                                        newExpectedValueCriteria = selection;
                                                        this.setState({ newExpectedValueCriteria: newExpectedValueCriteria });
                                                    }}
                                                />
                                            </FormGroup>
                                        }
                                        {!!((newExpectedValueCriteriaCriteriaType ?? {}).value == criteriaTypes.SalesOrderTrackingDerived) &&
                                            <>
                                                <FormGroup>
                                                    <FormLabel htmlFor="secCriteriaSOTDerivedValue" text="Choose Property" />
                                                    <ValidatedSelect
                                                        id="secCriteriaSOTDerivedValue"
                                                        formRef={this.formRef}
                                                        name="newExpectedValueCriteriaDerivedFieldName"
                                                        options={allSalesOrderTrackingDerived}
                                                        value={(allSalesOrderTrackingDerived ?? []).find(s => s.value == (newExpectedValueCriteriaDerivedFieldName ?? {}).value) ?? ''}
                                                        onChange={(selection) => {
                                                            newExpectedValueCriteriaDerivedFieldName = selection;
                                                            this.setState({ newExpectedValueCriteriaDerivedFieldName: newExpectedValueCriteriaDerivedFieldName });
                                                        }}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        disabled={!canEdit}
                                                        name="newExpectedValueCriteria"
                                                        value={newExpectedValueCriteria?.label ?? ""}
                                                        onChange={(e) => {
                                                            newExpectedValueCriteria = !!(e.target.value) ? { label: e.target.value } : null;
                                                            this.setState({ newExpectedValueCriteria: newExpectedValueCriteria });
                                                        }}
                                                    />
                                                </FormGroup>
                                            </>
                                        }
                                    </Col>
                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                        {!!(newExpectedValueCriteriaCriteriaType) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="newExpectedValueCriteriaType" text="Evaluation Type" />
                                                <ValidatedSelect
                                                    id="newExpectedValueCriteriaType"
                                                    formRef={this.formRef}
                                                    options={expectedDataTypes}
                                                    onChange={(selection) => {
                                                        newExpectedValueCriteriaType = selection;
                                                        this.setState({ newExpectedValueCriteriaType: newExpectedValueCriteriaType });
                                                    }}
                                                    validationMessage=""
                                                    value={newExpectedValueCriteriaType}
                                                />
                                                <small className="invalid-feedback text-danger" hidden></small>
                                            </FormGroup>
                                        }
                                    </Col>
                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                        {!!(newExpectedValueCriteriaCriteriaType) &&
                                            <FormGroup>
                                                <FormLabel htmlFor="newExpectedValueCriteriaRel" text="Restriction Relationship" />
                                                <ValidatedSelect
                                                    id="newExpectedValueCriteriaRel"
                                                    formRef={this.formRef}
                                                    options={expectedRelationships}
                                                    onChange={(selection) => {
                                                        newExpectedValueCriteriaRel = selection;
                                                        this.setState({ newExpectedValueCriteriaRel: newExpectedValueCriteriaRel });
                                                    }}
                                                    validationMessage=""
                                                    value={newExpectedValueCriteriaRel}
                                                />
                                                <small className="invalid-feedback text-danger" hidden></small>
                                            </FormGroup>
                                        }
                                    </Col>
                                </GroupedRow>
                            </ModalBody>
                            <ModalFooter>
                                {(!!(newExpectedValueCriteriaCriteriaType) && !!(newExpectedValueCriteriaRel) &&
                                    !!(newExpectedValueCriteriaType) && !!(newExpectedValueCriteria))
                                    && (
                                        !!((newExpectedValueCriteriaCriteriaType ?? {}).value != criteriaTypes.SalesOrderTrackingDerived) ||
                                        (!!((newExpectedValueCriteriaCriteriaType ?? {}).value == criteriaTypes.SalesOrderTrackingDerived)
                                            && !!(newExpectedValueCriteriaDerivedFieldName))
                                    ) &&
                                    <Button
                                        size="sm"
                                        type="button"
                                        color="primary"
                                        name="addExpectedValueRestriction"
                                        onClick={() => this.onAddNewExpectedValueCriteria()}
                                    >
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faPlus} />
                                        {"Add"}
                                    </Button>
                                }
                                <Button
                                    size="sm"
                                    type="button"
                                    color="secondary"
                                    name="cancelCompleteQuote"
                                    onClick={() => this.setState({ addExpectedValueRestriction: false })}
                                    className="ml-2">
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faTimes} />
                                    {"Cancel"}
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </AppPageForm>
                </Fragment>
            );
        }
    }
    //#endregion
}

export default withRouter(QuestionsForm);