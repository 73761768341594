import React, { Fragment } from 'react';
import { Can } from '../../Can';
import cls from 'classnames';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import CommonContext from '../../Common';
import { Button, Col, Container, Popover, PopoverHeader, PopoverBody, Row, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ValidationMessageDisplay from './ValidationMessageDisplay';
import WarningMessageDisplay from './WarningMessageDisplay';
import { faCaretDown, faCaretUp, faCheckCircle, faExclamationTriangle, faInfoCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { cssTransition, toast } from 'react-toastify';
import { util } from '../../Util';
import _ from 'lodash-es';

export function formIsReady() {
    return (!this.state?.loading === false && !!this.context?.tenant?.tenantSettings && !!this.context?.permissions);
}

export function getTenantSetting(settingName) {
    const name = _.camelCase(settingName);
    return this.context.tenant.tenantSettings[name].value;
}

/**
 * Generic handler for form fields.  Inputs must have the same name as the property on the object.
 * You must also bind the target method to your class.  Also requires that the name of the input 
 * matches your object property hierarchy (ex. entity.prop1.prop2).
 * 
 * Updated: RLC 11/2/20
 * 
 * @param {any} e
 */
export function onFieldChange(e) {
    let props = e.target.name.split('.'), value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState(state => {
        let newState = util.object.updateByPath({ ...state }, props, value);
        return newState;
    });
}

export function onFloatFieldBlur(e) {
    let props = e.target.name.split('.'), value = e.target.value;
    if (value !== "")
        value = parseFloat(value).toFixed(2);
    this.setState(state => {
        let newState = util.object.updateByPath({ ...state }, props, value);
        return newState;
    });
}

/**
 * Generic simple select handler for react select input.
 * You must also bind the target method to your class to change the {this} context.
 * Also requires that the name of the input matches your object property hierarchy
 *  (ex. entity.prop1.prop2). Supports multiselects.
 * 
 * @param {any} selection 
 */
export function onReactSelectChanged(selection, event) {
    let props = event.name.split('.'), value = util.select.reduceValue(selection);
    this.setState(state => {
        let newState = util.object.updateByPath({ ...state }, props, value);
        return newState;
    });
}

export const toasty = {
    success: (header, message, options) => {
        toast.dismiss();
        toast.success(<ToastMessage icon={faCheckCircle} header={header} message={message} />, { ...options ?? {}, toastId: message })
    },

    error: (header, message, options) => {
        toast.dismiss();
        toast.error(<ToastMessage icon={faExclamationTriangle} header={header} message={message} />, { ...options ?? {}, toastId: message })
    },

    info: (header, message, options) => {
        toast.dismiss();
        toast.error(<ToastMessage icon={faInfoCircle} header={header} message={message} />, { ...options ?? {}, toastId: message })
    },

    warning: (header, message, options) => {
        toast.dismiss();
        toast.warning(<ToastMessage icon={faInfoCircle} header={header} message={message} />, { ...options ?? {}, toastId: message })
    }
};

export const FadeTransition = cssTransition({
    enter: 'fade',
    exit: 'fade',
});

export class AppPageForm extends React.Component {
    render() {
        return (
            <Fragment>
                <LinearProgress className={cls('m-0', { 'opacity-0': !this.props.saving })} variant="indeterminate" color="secondary" />

                <fieldset
                    className={cls(`${this.props.parentClass ?? ''} flex-fill overflow-x-hidden container opacity-1 position-relative`, {'overflow-y-auto': !this.props.loading, 'overflow-y-hidden': !!this.props.loading })}
                    disabled={
                        !!this.props.viewOnly || !!this.props.saving || !!this.props.formShown || !!this.props.loading
                    }>
                    <FormBlocker show={!!this.props.loading} showProgress={ true } />
                    <Row>
                        <FormValidated
                            className={cls(
                                "page-hidden col",
                                { "ready": !this.props.loading }
                            )}
                            id={this.props.formId}
                            name={this.props.formName}
                            ref={this.props.formRef}
                            onSubmit={this.props.onSubmit}
                            setIsValidated={this.props.setIsValidated}
                            isValidated={this.props.isValidated}
                        >
                            <Container fluid className="m-0">
                                {!this.props.collapseFormHeading &&
                                    <Row>
                                        <Col>
                                            <PageHeading className="mt-3">
                                                <FontAwesomeIcon icon={this.props.formHeadingIcon} className="mr-2 text-muted" />
                                                {this.props.formHeading}
                                            </PageHeading>
                                        </Col>
                                    </Row>
                                }
                                {
                                    !!this.props.onBack &&
                                    <FlexStartRow className="mb-2">
                                        <small
                                            className="text-info cursor-pointer text-right"
                                            onClick={this.props.onBack}
                                        >
                                            <i className="fa fa-angle-double-left mr-1"></i>
                                            {this.props.backLabel ?? 'Back'}
                                        </small>
                                    </FlexStartRow>
                                }
                                {!!!this.props.ValidationBottom &&
                                    <Row>
                                        <ValidationMessageDisplay onClear={this.props.onClearErrors} errors={this.props.errors} />
                                        <WarningMessageDisplay onClear={this.props.onClearWarnings} warnings={this.props.warnings} />
                                    </Row>
                                }
                                {this.props.children}
                                {!!this.props.ValidationBottom &&
                                    <Row>
                                        <ValidationMessageDisplay onClear={this.props.onClearErrors} errors={this.props.errors} />
                                        <WarningMessageDisplay onClear={this.props.onClearWarnings} warnings={this.props.warnings} />
                                    </Row>
                                }
                            </Container>
                        </FormValidated>
                    </Row>
                </fieldset>

            </Fragment>
        );
    }
}

export class CollapseUnderlineHeader extends React.Component {
    render = () => {
        return (
            <>
                <h4 onClick={this.props.toggleCollapse}>
                    <span>{this.props.headerText}</span><FontAwesomeIcon icon={this.props.isOpen ? faCaretUp : faCaretDown} />
                </h4>
                <Collapse isOpen={this.props.isOpen} className={cls(this.props.className ?? '', "pl-3 pr-1 site-collapser")}>
                    {this.props.children}
                </Collapse>
            </>
        )
    }
}

export class FlexColumnStart extends React.Component {
    render() {
        return (
            <div className={`d-flex flex-column justify-content-start ${this.props.className}`}>{this.props.children}</div>
        );
    }
}

export class FlexColumnCenter extends React.Component {
    render() {
        return (
            <div id={ this.props.id } className={`d-flex flex-column justify-content-center ${this.props.className ?? ''}`}>{this.props.children}</div>
        );
    }
}

export class FlexRow extends React.Component {
    render() {
        return (
            <div className={`d-flex flex-row align-items-center ${this.props.className}`}>{this.props.children}</div>
        );
    }
}

export class FlexBetweenRow extends React.Component {
    render() {
        return (
            <div id={this.props.id} className={`d-flex flex-row align-items-center justify-content-between ${this.props.className ?? ''}`}>{this.props.children}</div>
        );
    }
}

export class FlexCenterRow extends React.Component {
    render() {
        return (
            <div id={this.props.id} {...this.props} className={`d-flex flex-row align-items-center justify-content-center ${this.props.className ?? ''}`}>{this.props.children}</div>
        );
    }
}

export class FlexStartRow extends React.Component {
    render() {
        return (
            <div id={this.props.id} className={`flex-fill d-flex flex-row align-items-center justify-content-start ${this.props.className ?? ''}`}>{this.props.children}</div>
        );
    }
}

export class FlexEndRow extends React.Component {
    render() {
        return (
            <div id={this.props.id} className={`flex-fill d-flex flex-row align-items-center justify-content-end ${this.props.className ?? ''}`}>{this.props.children}</div>
        );
    }
}

export class FormBlocker extends React.Component {
    render() {
        const marginTop = this.props.allowMenu ? '42px' :'0';
        return (
            <div style={{ marginTop: marginTop }} className={cls(`formblocker ${this.props.className ?? ''}`, { 'show': !!this.props.show, 'no-progress': !this.props.showProgress })} onClick={(event) => { event.stopPropagation() }}>
                {!!this.props.showProgress && <h1 className="formblocker-inner-progress"><i className="fa fa-spin fa-circle-notch text-danger"></i></h1>}
            </div>
        )
    }
}

export class FormCheckbox extends React.Component {
    render() {
        return (
            <div className={cls("form-check form-check-inline mr-0", this.props.className)}>
                <input
                    id={this.props.id}
                    name={this.props.name}
                    className="d-none chk-i"
                    checked={this.props.checked}
                    type="checkbox"
                    onChange={this.props.onChange}
                    disabled={this.props.disabled}
                />                      
                <label className={"chk"} htmlFor={this.props.id} disabled={this.props.disabled}></label>
                {this.props.children}
                {!!this.props.small
                    ? <small className={`ml-1 form-check-label ${this.props.labelClass ?? ''}`}>{this.props.labelText}</small>
                    : <span className={`ml-1 form-check-label ${this.props.labelClass ?? ''}`}>{this.props.labelText}</span>
                }                
            </div>
        );
    }
}

export class FormCircularProgress extends React.Component {
    render() {
        return (<div className="d-flex flex-row h-100 align-items-center justify-content-center">
            <CircularProgress variant="indeterminate" color="secondary" />
        </div>);
    }
}

export class FormDivider extends React.Component {
    render() {
        return (
            <Row className="m-0 pt-0">
                <Col>
                    <hr />
                </Col>
            </Row>
        )
    }
}

export class FormGroupColumn extends React.Component {
    render() {
        return (
            <div className={cls({ 'col-xl-6 col-lg-6 col-md-6 col-sm-12': !this.props.single })}>
                {this.props.children}
            </div>)
    }
}

export class FormLabel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hidden: false,
            helpOpen: false,
            idPrefix: this.props.id ?? _.uniqueId('formlabel_id_')
        }
    }

    toggle = () => this.setState({ helpOpen: !this.state.helpOpen });

    renderHelp(idPrefix) {
        return (
            !!(this.props.helpMessage ?? '').length
                ?
                <Fragment>
                    <span id={`${idPrefix}_help_popover`} style={{ cursor: 'pointer', marginBottom: '.5rem' }}>
                        <FontAwesomeIcon className="ml-2" icon={faQuestionCircle} />
                    </span>
                    <Popover placement="right" isOpen={this.state.helpOpen} target={`${idPrefix}_help_popover`} toggle={this.toggle}>
                        <PopoverHeader>{`${this.props.text} Help`}</PopoverHeader>
                        <PopoverBody>{this.props.helpMessage}</PopoverBody>
                    </Popover>
                </Fragment>
                : ''
        );
    }

    render() {
        const { idPrefix } = { ...this.state };
        var classNames = cls("control-label", this.props.className, { required: !!this.props.required });
        return (
            <div className="d-flex flex-row flex-nowrap align-items-center">
                <label id={this.props.id}
                    className={classNames}
                    htmlFor={this.props.htmlFor}
                    hidden={this.state.hidden}>
                    {this.props.text}
                </label>
                {this.renderHelp(idPrefix)}
            </div>
        );
    }
}

/**
 * Form component that handles form validation errors.
 * Uses HTML5 checkValidity and toggles bootstrap styles
 * accordingly.
 **/
export class FormValidated extends React.Component {

    validate = () => {
        const formEl = this.formEl; //the form dom element.

        for (let elem of formEl) {
            //Handle react select controls.
            if (elem.classList.contains('react-select-validated-input')) {

                let form_group = elem.parentNode;
                let select = form_group.querySelector('.react-select');
                if (select) {
                    if (elem.validity.valid) {
                        select.classList.remove('is-invalid');
                        select.classList.add('is-valid');
                    } else {
                        select.classList.add('is-invalid');
                        select.classList.remove('is-valid');
                    }
                }
            }
        }

        var valid = formEl.checkValidity();

        return valid;
    };

    //Wrap the handler and call it as provided if the form was valid.
    submitHandler = event => {
        event.preventDefault();

        let valid = this.validate();

        if (valid) {
            this.props.onSubmit();
        }

        //isValid is an optional function that will be used to tell if the form was valid
        //during validation. 
        if (typeof this.props.isValid === 'function') {
            this.props.isValid(valid);
        }

        this.props.setIsValidated(true);
    };

    render() {
        let classNames = [];
        classNames = cls(this.props.className, {
            'was-validated': !!this.props.isValidated
        })
        return (
            <form
                id={this.props.id}
                className={cls('page-form', classNames)}
                noValidate
                ref={form => (this.formEl = form)}
                onSubmit={this.submitHandler} //take the submit handler defined by props.
                onKeyDown={event => {
                    //Prevent enter key submitting the form.
                    if (event.key === 'Enter' && event.shiftKey === false) {
                        event.preventDefault();
                    }
                }}
            >
                {this.props.children}
            </form>
        );
    }
}

export class GroupedRow extends React.Component {
    render() {
        return (<Row className="grouped-row">{this.props.children}</Row>)
    }
}

export class InputPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hidden: false
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <input id={this.props.id}
                autoComplete={this.props.new ? "new-password" : "current-password"}
                type="password"
                className="form-control"
                required
                defaultValue={this.props.value}
                hidden={this.state.hidden} />
        );
    }
}

export class PageButtonToolbar extends React.Component {
    render() {
        return (
            <Row>
                <div className="d-flex flex-row align-items-center justify-content-end text-muted bottom-underline col mb-2 pb-1">
                    {this.props.children}
                </div>
            </Row>
        );
    }
}

export class PageHeading extends React.Component {
    render() {
        return (
            <Row className="page-heading-row">
                <Col>
                    <h5 className={cls("w-100 bottom-underline mb-1 pb-3", this.props.className)}>
                        {this.props.children}
                    </h5>
                </Col>
            </Row>
        );
    }
}

export class PageWrap extends React.Component {
    render = () => <div id={this.props.id ?? "sitePageWrap"} className="d-flex flex-fill flex-column flex-nowrap p-3 site-page-wrap">{this.props.children}</div>
}

export class SimpleTable extends React.Component {
    render() {
        return (
            <Can I="view" a={this.props.permission}>
                <div className="container-fluid simple-table-container">
                    <Row>
                        <Col className="d-flex flex-row">
                            <div className="flex-fill d-flex flex-row align-items-center justify-content-start">
                                {this.props.leftToolbarContent}
                            </div>
                            <div className="d-flex flex-row align-items-center justify-content-end">
                                {!!this.props.onAddFunction
                                    ?
                                    <Can I="create" a={this.props.permission}>
                                        <CommonContext.Consumer>
                                            {(value) => {
                                                return (
                                                    <SmallButton
                                                        type="button"
                                                        disabled={!!value.formIsOpen || this.props.addButtonEnabled === false}
                                                        onClick={() => { this.props.onAddFunction() }}
                                                    >
                                                        <i className="fa fa-plus-circle fa-md mr-2" />{this.props.addLabelText}
                                                    </SmallButton>);
                                            }}
                                        </CommonContext.Consumer>
                                    </Can>
                                    : ''
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <table id={ this.props.id } className={cls("table table-sm", { "border-top-0": this.props.noTopBorder, 'scrollable-table': !!this.props.scrollable }, this.props.className)}>
                                <thead>
                                    <tr key="headerRow">
                                        {this.props.tableHeaderLabels.map(h => <th key={h.name} className={h.class} style={{ width: h.width ?? 'auto' }}>{h.name}</th>)}
                                        {!!this.props.editable
                                            &&
                                            <Can I="edit" a={this.props.permission}>
                                                <th className="text-center" style={{ width: '30px' }}></th>
                                            </Can>
                                        }
                                    </tr>
                                </thead>
                                <tbody style={{ maxHeight: this.props.maxBodyHeight ?? 'auto', overflowY: this.props.maxBodyHeight ? 'scroll' : 'unset' }}>
                                    {!(this.props.entities ?? []).length
                                        ?
                                        <tr key="emptyRow">
                                            <td colSpan={this.props.tableHeaderLabels.length + (!!this.props.editable ? 1 : 0)} className="text-center">
                                                {this.props.noDataText}
                                            </td>
                                        </tr>
                                        : this.props.rowRenderer()
                                    }
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </div>
            </Can>
        );
    }
}

export class SmallButton extends React.Component {

    render() {
        var classNames = cls("site-button-small", this.props.className);
        return (
            <Button color="outline-primary" style={{ border: 'none', borderRadius: '0 !important' }} type="button" size="sm" className={classNames} {...this.props}>
                {this.props.children}
            </Button>
        );
    }
}

export class SmallPrimaryButton extends React.Component {

    render() {
        const classNames = cls("site-button-small", this.props.className);
        return (
            <Button color='primary' type="button" size="sm" className={classNames} {...this.props}>
                {this.props.children}
            </Button>
        );
    }
}

export class SmallOutlineButton extends React.Component {

    render() {
        var classNames = cls("site-button-small", this.props.className);
        return (
            <Button
                type="button"
                size="sm"
                color="outline-primary"
                className={classNames}
                {...this.props}
            >
                {this.props.text ?? this.props.children}
            </Button>
        );
    }
}

export class SubHeading extends React.Component {
    render() {
        var classNames = cls("page-subheading mb-1 mt-0", this.props.className);
        return (
            <Row className={classNames}>
                <Col className="d-flex flex-row justify-content-center">
                    <div className="h6 text-center p-1 m-0">
                        {this.props.children}
                    </div>
                </Col>
            </Row>
        );
    }
}

export class ToastMessage extends React.Component {
    render() {
        return (
            <div className="d-flex flex-column flex-nowrap">
                <div className="pb-2"><strong><FontAwesomeIcon className="mr-2" size="lg" icon={this.props.icon ?? faInfoCircle} />{this.props.header}</strong></div>
                <div>
                    {this.props.message}
                </div>
            </div>
        );
    }
}

export class ValidationErrorMessage extends React.Component {
    render() {
        return (<small className="invalid-feedback text-danger">{this.props.children}</small>);
    }
}

export class YesNoBadge extends React.Component {
    render() {
        return (
            <span className={`badge badge-yes-no ${!!this.props.value ? 'badge-success' : 'badge-secondary'}`}>
                {!!this.props.value ? 'YES' : 'NO'}
            </span>
        );
    }
}
